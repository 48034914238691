import { FC } from "react";
import styles from "./home.module.scss";
import { background, logoCircle, quotes } from "../../constants/assets";
import Accounts from "../accounts/accounts";
import Login from "../login/login";
import Register from "../register/register/register";
import CongratsRegister from "../register/congrats/congrats";
import CongratsResetPassword from "../resetPassword/congrats/congratsResetPassword";
import Email from "../resetPassword/email/email";
import CheckEmail from "../resetPassword/checkEmail/checkEmail";
import ResetPassword from "../resetPassword/resetPassword/resetPassword";

const Home: FC = ({ match }: any) => {
  const { component } = match.params;
  const CurrentComponent = () => {
    switch (component) {
      case "accounts":
        return <Accounts />;
      case "login":
        return <Login />;
      case "register":
        return <Register />;
      case "congratsRegister":
        return <CongratsRegister />;
      case "congratsResetPassword":
        return <CongratsResetPassword />;
      case "email":
        return <Email />;
      case "checkEmail":
        return <CheckEmail />;
      case "resetPassword":
        return <ResetPassword />;
      default:
        return <Accounts />;
    }
  };

  return (
    <div>
      <div className={styles.rightPanelContainer}>{CurrentComponent()}</div>

      <div className={styles.logo}>
        <img src={logoCircle} alt="" />
        <div />
      </div>

      <div className={styles.sloganBlock}>
        <img className={styles.quotes} src={quotes} alt="" />
        <p className={styles.sloganTitle}>Believers & Achievers</p>
        <span className={styles.sloganContent}>
          We believe sport is for all, inclusion in sport <br /> and society,
          and that all those involved should <br /> be given a full opportunity
          to experience sport <br /> to its fullest capacity.
          <br />
        </span>
        <img
          className={`${styles.quotes} ${styles.quotesFlip}`}
          src={quotes}
          alt=""
        />
      </div>

      <div className={styles.cover} />
      <div className={styles.g42} />
      <div className={styles.overlayBlackLinear} />
      <div className={styles.overlayBlack} />
      <img className={styles.bg} src={background} alt="" />
    </div>
  );
};

export default Home;
