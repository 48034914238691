import { Avatar, Cancel, Save, ArrowDownDefault } from "constants/assets";
import NumberFormat from "react-number-format";
import clsx from "clsx";
import MenuActions from "components/menuActions/menuActions";
import { components, DropdownIndicatorProps } from "react-select";
import { ChangeEvent, FC, SetStateAction } from "react";
import { ClickEvent } from "@szhsin/react-menu";
import { Select } from "components/select/select";
import styles from "./coachInvolvement.module.scss";

type Props = {
  onSelectCoach?: (opt: SetStateAction<Option | null>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: ClickEvent) => void;
  onRevert: () => void;
  onSave: () => void;
  coach?: Array<Option>;
  selectCoach?: Option;
  readOnly?: boolean;
  showButtons?: boolean;
  actions: string[];
  error?: boolean;
  showDropdown?: boolean;
  isValid: { hours?: boolean; minutes?: boolean; athletes?: any };
  data: {
    hours?: string;
    minutes?: string;
    name?: string;
    training?: { category: { name: string } };
    event?: { category: { name: string } };
  };
  parent?: string;
};
export interface Option {
  value?: string;
  label?: string;
}

const CoachInvolvement: FC<Props> = ({
  onChange,
  onSelectCoach,
  onRevert,
  onSave,
  onClick,
  coach,
  selectCoach,
  readOnly,
  showButtons,
  showDropdown,
  actions,
  error,
  isValid,
  data,
  parent = "training",
}) => {
  const Buttons = () => {
    return (
      <>
        <div role="button" aria-hidden="true" onMouseDown={onRevert}>
          <Cancel />
        </div>
        <div role="button" aria-hidden="true" onMouseDown={onSave}>
          <Save />
        </div>
      </>
    );
  };

  const setSelectedCoach = (opt: SetStateAction<Option | null>) => {
    if (onSelectCoach) onSelectCoach(opt);
  };

  const dropdownOption = (dropDownProps: DropdownIndicatorProps<any>) => (
    <components.DropdownIndicator {...dropDownProps}>
      <ArrowDownDefault />
    </components.DropdownIndicator>
  );

  return (
    <>
      <div className={styles.resultsDetailsBlock}>
        <form className={styles.involvementForm}>
          <div className={styles.athleteInfo}>
            <div className={styles.avatarSection}>
              <div className={styles.avatar}>
                <Avatar />
              </div>
              <div className={styles.athleteName}>
                {showDropdown ? (
                  <Select
                    placeholder="Attendee"
                    isSearchable={true}
                    className={clsx(
                      styles.selectAthlete,
                      !isValid.athletes && error && styles.errorInput
                    )}
                    name="athletes"
                    value={selectCoach}
                    options={coach}
                    components={{ DropdownIndicator: dropdownOption }}
                    onChange={setSelectedCoach}
                  />
                ) : (
                  <span>{data.name}</span>
                )}
              </div>
            </div>
            <div className={styles.line} />
            <div className={styles.categorySection}>
              <div className={styles.category}>
                <span className={styles.categoryName}>Category</span>
                <div className={styles.categoryDropDown}>
                  <span className={styles.categoryValue}>
                    {data.training?.category.name || data.event?.category.name}
                  </span>
                </div>
              </div>
              <div className={styles.line} />
              <div
                className={clsx(
                  styles.category,
                  styles.categoryTime,
                  showButtons && styles.gap
                )}
              >
                <label className={styles.categoryName}> Time Spent </label>
                <div className={styles.athleteRow}>
                  <div
                    className={clsx(
                      styles.inputBlock,
                      styles.subInput,
                      styles.hours
                    )}
                  >
                    <div
                      className={clsx(
                        styles.numbersRowInput,
                        !isValid.hours && error && styles.errorInput,
                        !readOnly && styles.thickBorder
                      )}
                    >
                      <NumberFormat
                        className={styles.input}
                        id="hours"
                        name="hours"
                        maxLength={2}
                        placeholder="00"
                        allowNegative={false}
                        allowLeadingZeros={false}
                        allowEmptyFormatting={true}
                        readOnly={readOnly}
                        onChange={onChange}
                        value={data.hours}
                      />
                      <span className={styles.measure}>Hours</span>
                    </div>
                  </div>

                  <div className={clsx(styles.inputBlock, styles.subInput)}>
                    <div
                      className={clsx(
                        styles.numbersRowInput,
                        styles.minutesBlock,
                        !isValid.minutes && error && styles.errorInput,
                        !readOnly && styles.thickBorder
                      )}
                    >
                      <NumberFormat
                        className={styles.input}
                        id="minutes"
                        name="minutes"
                        allowNegative={false}
                        placeholder="00"
                        allowLeadingZeros={false}
                        allowEmptyFormatting={true}
                        maxLength={2}
                        readOnly={readOnly}
                        onChange={onChange}
                        value={data.minutes}
                      />

                      <span className={styles.measure}>Minutes</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.menu}>
              {!showButtons && (
                <MenuActions
                  onClick={onClick}
                  actions={actions}
                  className={clsx(
                    parent === "eventsAdmin"
                      ? "involvementBottom"
                      : "involvement"
                  )}
                />
              )}
              {showButtons && (
                <div className={styles.buttons}>
                  <Buttons />{" "}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CoachInvolvement;
