import clsx from "clsx";
import { File, Calendar, NoGoal, Result } from "../../../constants/assets";
import styles from "./emptyBlock.module.scss";

// TODO move to the components root, as it's used in other parts og the app
// src/components/events/components/event-info/event-info.tsx
const EmptyBlock = ({ type, text }: any) => {
  const Icon: any = () => {
    switch (type) {
      case "activitiesList":
        return <Calendar />;
      case "resultComingSoon":
        return <Result />;
      case "goals":
        return <NoGoal />;
      default:
        return <File />;
    }
  };

  return (
    <>
      <div
        className={clsx(
          styles.emptyBlock,
          (type === "activitiesList" || type === "resultComingSoon") &&
            styles.activitiesList
        )}
      >
        <div className={styles.elements}>
          <div className={styles.icon}>
            <Icon />
          </div>
          <div className={styles.title}>
            <span>{text}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyBlock;
