// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import validator from "validator";
import styles from "./register.module.scss";
import {
  SignInUser,
  SignInLock,
  PasswordInputEye,
  Warning,
  LeftArrow,
  TiTick,
} from "../../../constants/assets";
import Signup from "../../../apis/auth/register";

const Register: FC = ({ history }: any) => {
  const setAction = () => {
    return (
      localStorage.getItem("accountType") || history.push("/home/accounts")
    );
  };
  const accountType = setAction();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrengthBar, setPasswordStrengthBar] = useState(0);
  const [strength, setStrength] = useState("Weak");
  const [registering, setRegistering] = useState(false);
  const [hintError, setHintError] = useState(false);
  const [error, setError] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  const [form, setForm] = useState({
    login: "",
    password: "",
    confirmPassword: "",
    accountType: setAction(),
  });
  const passwordStrength = (password: string, returnScore = true) => {
    return validator.isStrongPassword(password, {
      minLength: 8,
      minLowercase: 0,
      minUppercase: 1,
      minNumbers: 0,
      minSymbols: 1,
      returnScore,
      pointsPerUnique: 0,
      pointsPerRepeat: 0,
      pointsForContainingLower: 5,
      pointsForContainingUpper: 10,
      pointsForContainingNumber: 5,
      pointsForContainingSymbol: 10,
    });
  };
  const validateLogin = () => validator.isEmail(form.login);
  const validatePassword = () => passwordStrength(form.password, false);
  const validateConfirmPassword = () =>
    form.password === form.confirmPassword && form.password.length > 0;

  const validateForm = () =>
    !!accountType &&
    validateLogin() &&
    validatePassword() &&
    validateConfirmPassword();

  const [isValid, setIsValid] = useState({
    login: true,
    password: true,
    confirmPassword: true,
  });

  const handleInputChange = (e: {
    target: { name: string; value: string | any[] };
  }) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name === "password") {
      const password = {
        strength: passwordStrength(e.target.value),
        strong: passwordStrength(e.target.value, false),
      };
      if (!password.strong) {
        setPasswordStrengthBar({
          width: e.target.value.length === 0 ? "0%" : "33%",
        });
        setStrength("Weak");
      } else if (password.strong && e.target.value.length < 16) {
        setPasswordStrengthBar({ width: "66%" });
        setStrength("Medium");
      } else if (password.strong && e.target.value.length >= 16) {
        setPasswordStrengthBar({ width: "100%" });
        setStrength("Strong");
      }

      setIsValid((prev) => ({ ...prev, password: password.strong }));
    } else if (e.target.name === "confirmPassword") {
      setIsValid((prev) => ({
        ...prev,
        confirmPassword: validateConfirmPassword(),
      }));
    }
  };

  const showPasswordHandler = (
    target: string,
    bool: boolean | ((prevState: boolean) => boolean)
  ) => {
    if (target === "passwordEye") setShowPassword(bool);
    else if (target === "confirmPasswordEye") setShowConfirmPassword(bool);
  };

  const handlePasswordEyeMouseDown = (e: { currentTarget: { name: any } }) => {
    if ("ontouchend" in document) {
      return;
    }
    showPasswordHandler(e.currentTarget.name, true);
  };
  const handlePasswordEyeMouseUp = (e: { currentTarget: { name: any } }) => {
    if ("ontouchend" in document) {
      return;
    }
    showPasswordHandler(e.currentTarget.name, false);
  };

  const handlePasswordEyeTouchStart = (e: { currentTarget: { name: any } }) => {
    showPasswordHandler(e.currentTarget.name, true);
  };

  const handlePasswordEyeTouchEnd = (e: { currentTarget: { name: any } }) => {
    showPasswordHandler(e.currentTarget.name, false);
  };

  const handlePasswordEyeKeyDown = (e: {
    code: string;
    currentTarget: { name: any };
  }) => {
    if (e.code === "Space" || e.code === "Enter") {
      showPasswordHandler(e.currentTarget.name, true);
    }
  };

  const handlePasswordEyeKeyUp = (e: {
    code: string;
    currentTarget: { name: any };
  }) => {
    if (e.code === "Space" || e.code === "Enter") {
      showPasswordHandler(e.currentTarget.name, false);
    }
  };

  useEffect(() => {
    setError(!!alertMsg);
  }, [alertMsg]);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!validatePassword() || !validateConfirmPassword() || !validateLogin()) {
      setIsValid({
        login: validateLogin(),
        password: validatePassword(),
        confirmPassword: validateConfirmPassword(),
      });
      if (!validateLogin()) setAlertMsg("Please enter a valid email address.");
      if (!validatePassword() || !validateConfirmPassword()) setHintError(true);
      return;
    }

    if (validateForm()) {
      e.preventDefault();
      setRegistering(true);
      setHintError(false);

      const result = await Signup(form);
      if (result?.data) {
        setRegistering(false);
        history.push("/home/congratsRegister");
      } else {
        if (result.response.data.errorKey === "10102")
          setAlertMsg(
            "The email is already registered. Please go back to sign in."
          );
        else if (result.response.data.errorKey === "10101")
          setAlertMsg("The email is not registered in the Heroes of Hope.");
        else if (result.response.data.errorKey === "21007")
          setAlertMsg(
            "This user is disabled. Please contact administrator for support."
          );

        setError(true);
        setForm({
          login: "",
          password: "",
          confirmPassword: "",
          accountType: setAction(),
        });

        setIsValid({ login: true, password: true, confirmPassword: true });
        setRegistering(false);
      }
    }
  };

  const handleEmailBlur = (e: { target: { value: string | any[] } }) => {
    if (e.target.value.length) {
      setIsValid((prev) => ({ ...prev, login: validateLogin() }));
      if (!validateLogin()) {
        setAlertMsg("Please enter a valid email address.");
      } else setAlertMsg("");
    } else {
      setIsValid((prev) => ({ ...prev, login: true }));
      setAlertMsg("");
    }
  };

  const handlePasswordBlur = () => {
    setIsValid((prev) => ({ ...prev, password: true }));
    setHintError(false);
  };

  const handleConfirmPasswordBlur = () => {
    setIsValid((prev) => ({ ...prev, confirmPassword: true }));
    setHintError(false);
  };

  const handleBack = () => {
    history.push("/home/accounts");
  };
  const setStyle = () => {
    if (accountType === "admin") return styles.admin;
    if (accountType === "athlete") return styles.athlete;
    if (accountType === "coach") return styles.coach;
    return <></>;
  };

  const showPasswordLabel = () => {
    return showPassword ? "text" : "password";
  };
  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.title}>
          <div className={setStyle()} />
          <span>
            {accountType === "coach"
              ? "Junior Coach Registration "
              : `${accountType} Registration`}
          </span>{" "}
        </div>
        <div className={styles.subTitle}>
          <span>
            Please input your email registered in Heroes of Hope to proceed.
          </span>
        </div>
        <form className={styles.form} onSubmit={handleSubmit} noValidate={true}>
          <div>
            <div className={styles.inputsBlock}>
              <div className={styles.inputBlock}>
                <label htmlFor="login" className={styles.label}>
                  Email
                  <div className={styles.inputWrapper}>
                    <div
                      className={clsx(
                        styles.iconWrapper,
                        form.login.length > 0 && styles.activeIcon
                      )}
                    >
                      <SignInUser />
                    </div>

                    <input
                      onBlur={handleEmailBlur}
                      type="email"
                      name="login"
                      value={form.login}
                      placeholder="Type your Email"
                      onChange={handleInputChange}
                      className={clsx(
                        styles.input,
                        !isValid.login && styles.errorInput
                      )}
                    />
                  </div>
                </label>

                {error && (
                  <div className={styles.errorBlock}>
                    <div className={styles.warningIcon}>
                      <Warning />
                    </div>
                    <div className={styles.errorMessage}>{alertMsg}</div>
                  </div>
                )}
              </div>
              <div className={styles.inputBlock}>
                <label htmlFor="password" className={styles.label}>
                  New Password
                  <div className={styles.inputWrapper}>
                    <div
                      className={clsx(
                        styles.iconWrapper,
                        form.password.length > 0 && styles.activeIcon
                      )}
                    >
                      <SignInLock />
                    </div>
                    <input
                      onBlur={handlePasswordBlur}
                      type={showPasswordLabel()}
                      name="password"
                      value={form.password}
                      placeholder="Create a Password"
                      onChange={handleInputChange}
                      className={clsx(
                        styles.input,
                        !isValid.password && styles.errorInput
                      )}
                    />
                    <button
                      type="button"
                      name="passwordEye"
                      className={clsx(
                        styles.iconWrapper,
                        styles.passwordEyeIcon,
                        showPassword && styles.activeIcon
                      )}
                      onMouseDown={handlePasswordEyeMouseDown}
                      onMouseUp={handlePasswordEyeMouseUp}
                      onTouchStart={handlePasswordEyeTouchStart}
                      onTouchEnd={handlePasswordEyeTouchEnd}
                      onKeyDown={handlePasswordEyeKeyDown}
                      onKeyUp={handlePasswordEyeKeyUp}
                    >
                      <PasswordInputEye />
                    </button>
                  </div>
                </label>

                {form.password.length >= 8 &&
                  passwordStrength(form.password, false) && (
                    <TiTick className={styles.tickMark} />
                  )}
              </div>

              <div className={styles.inputBlock}>
                <label htmlFor="password" className={styles.label}>
                  Confirm Password
                  <div className={styles.inputWrapper}>
                    <div
                      className={clsx(
                        styles.iconWrapper,
                        form.password.length > 0 && styles.activeIcon
                      )}
                    >
                      <SignInLock />
                    </div>
                    <input
                      onBlur={handleConfirmPasswordBlur}
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={form.confirmPassword}
                      placeholder="Confirm a New Password"
                      onChange={handleInputChange}
                      className={clsx(
                        styles.input,
                        !isValid.confirmPassword && styles.errorInput
                      )}
                    />
                    <button
                      type="button"
                      name="confirmPasswordEye"
                      className={clsx(
                        styles.iconWrapper,
                        styles.passwordEyeIcon,
                        showConfirmPassword && styles.activeIcon
                      )}
                      onMouseDown={handlePasswordEyeMouseDown}
                      onMouseUp={handlePasswordEyeMouseUp}
                      onTouchStart={handlePasswordEyeTouchStart}
                      onTouchEnd={handlePasswordEyeTouchEnd}
                      onKeyDown={handlePasswordEyeKeyDown}
                      onKeyUp={handlePasswordEyeKeyUp}
                    >
                      <PasswordInputEye />
                    </button>
                  </div>
                </label>

                {form.confirmPassword === form.password &&
                  form.confirmPassword.length >= 8 && (
                    <TiTick className={styles.tickMark} />
                  )}
              </div>
            </div>

            <div className={styles.blockFeatures}>
              <div
                className={clsx(
                  hintError && styles.passwordHintError,
                  styles.passwordHint
                )}
              >
                <span>
                  *Password should contain: Min. 8 characters, at least one
                  uppercase letter and one symbol.
                </span>
              </div>
            </div>

            {form.password.length > 0 && (
              <div className={styles.bar}>
                <div className={styles.barLabels}>
                  <span>Strength</span>
                  <span>{strength}</span>
                </div>
                <div className={styles.animateBarContainer}>
                  <div
                    className={clsx(styles.animateBar, styles[strength])}
                    style={{ width: passwordStrengthBar.width }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.buttonBlock}>
            <button
              type="button"
              className={styles.backButton}
              onClick={handleBack}
            >
              <LeftArrow />
              <span> Back </span>
            </button>
            <button
              type="submit"
              tabIndex={0}
              disabled={registering}
              className={validateForm() ? styles.button : styles.buttonDisabled}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Register);
