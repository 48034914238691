import { SetStateAction, useContext, useEffect, useState } from "react";
import { components, DropdownIndicatorProps } from "react-select";
import clsx from "clsx";
import GroupsList from "apis/groups/groupsList";
import AthletesGroup from "apis/groups/athletesGroup";
import styles from "./addEditParticipant.module.scss";
import {
  ParticipantModal,
  ArrowDown,
  Expand,
  Warning,
} from "../../../constants/assets";
import { AppContext } from "../../../App";
import AthletesList from "../../../apis/athletesList";
import CoachList from "../../../apis/management/coachList";
import { Select } from "../../select/select";
import AddRegister from "../../../apis/events/addRegister";
import UpdateRegister from "../../../apis/events/updateRegister";
import AddCoachRegister from "../../../apis/events/addCoachRegister";
import UpdateCoachRegister from "../../../apis/events/updateCoachRegister";

export interface Option {
  value?: string;
  label?: string;
  parentName?: string;
  mobile?: string;
  email?: string;
}

const AddEditParticipants = ({
  state,
  selectedParticipants,
  participantType,
  mode,
  participantInfo,
  dispatch,
}: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const [athletes, setAthletes] = useState([]);
  const [readOnly] = useState(true);
  const [selectAthlete, setSelectAthlete] = useState<Option | any>(null);
  const { id } = state;
  const [selectGroup, setSelectGroup] = useState<Option | any>(null);
  const [error, setError] = useState(false);
  const [groups, setGroups] = useState([]);
  const [athletesGroup, setAthletesGroup] = useState([]);
  const [isExpand, setIsExpand] = useState(true);
  const [option, setOption] = useState("athlete");

  useEffect(() => {
    (async function list() {
      if (option === "athlete") {
        setSelectGroup(null);
        setAthletesGroup([]);
        setError(false);

        const response: any =
          participantType === "coach"
            ? await CoachList(false)
            : await AthletesList(false);
        if (response?.data) {
          const athlete = response.data.map(
            (item: {
              id: any;
              firstName: any;
              lastName: any;
              parentName: any;
              mobile: any;
              email: any;
            }) => ({
              value: item.id,
              label: `${item.firstName} ${item.lastName}`,
              parentName: item.parentName,
              mobile: item.mobile,
              email: item.email,
            })
          );

          setAthletes(
            athlete.filter(
              (element: { value: any }) =>
                !selectedParticipants.find(
                  (item: { value: any }) => item.value === element.value
                )
            )
          );
          if (mode === "Edit") setSelectAthlete(participantInfo);
        }
      } else if (option === "group") {
        setSelectAthlete(null);
        setError(false);

        const response: any = await GroupsList();
        if (response?.data) {
          setGroups(
            response.data.map((item: { id: any; name: any }) => ({
              value: item.id,
              label: `${item.name}`,
            }))
          );
        }
      }
    })();
  }, [option]);

  const handleSave = () => {
    (async function update() {
      if (mode === "Edit") {
        const params =
          participantType === "coach"
            ? {
                id: participantInfo.id,
                form: {
                  event: { id },
                  coach: { id: selectAthlete?.value },
                  registrationStatus: "REGISTER",
                },
              }
            : {
                id: participantInfo.id,
                form: {
                  event: { id },
                  athlete: { id: selectAthlete?.value },
                  registrationStatus: "REGISTER",
                },
              };
        const response: any =
          participantType === "coach"
            ? await UpdateCoachRegister(params)
            : await UpdateRegister(params);
        if (response.status === 200) {
          appDispatch({ type: "hideModal" });
          dispatch({ type: "refreshData", payload: "participants" });
        } else if (response.response.data.errorKey === "20129") {
          setError(true);
        }
      } else {
        const params =
          participantType === "coach"
            ? {
                event: { id },
                coach: { id: selectAthlete?.value },
                registrationStatus: "REGISTER",
                option,
              }
            : {
                event: { id },
                athlete: { id: selectAthlete?.value },
                group: { id: selectGroup?.value },
                registrationStatus: "REGISTER",
                option,
              };
        const response: any =
          participantType === "coach"
            ? await AddCoachRegister(params)
            : await AddRegister(params);
        if (response?.data) {
          appDispatch({ type: "hideModal" });
          dispatch({ type: "refreshData", payload: "participants" });
        } else if (response.response.data.errorKey === "20129") {
          setError(true);
        }
      }
    })();
  };
  const dropdownOption = (dropDownProps: DropdownIndicatorProps<any>) => (
    <components.DropdownIndicator {...dropDownProps}>
      <ArrowDown />
    </components.DropdownIndicator>
  );

  const setSelectedAthlete = (opt: SetStateAction<Option | null>) => {
    setSelectAthlete(opt);
    setError(false);
  };

  const setSelectedGroup = async (opt: SetStateAction<Option | null | any>) => {
    setSelectGroup(opt);
    setError(false);

    const response: any = await AthletesGroup(opt.value);
    if (response?.data) {
      setAthletesGroup(response.data);
    }
  };

  const onChangeValue = (event: any) => {
    setOption(event.currentTarget.id);
  };
  const expand = () => {
    setIsExpand(!isExpand);
  };
  const RadioButton = () => {
    return (
      <div className={styles.radioButton}>
        <label id="athlete" aria-hidden="true" onClick={onChangeValue}>
          <input
            type="radio"
            checked={option === "athlete"}
            value="athlete"
            name="option"
            id="athlete"
            readOnly={true}
          />{" "}
          Athlete
        </label>
        <label id="group" aria-hidden="true" onClick={onChangeValue}>
          <input
            type="radio"
            checked={option === "group"}
            value="group"
            name="option"
            readOnly={true}
          />{" "}
          Group
        </label>
      </div>
    );
  };
  return (
    <>
      <div className={styles.addEditBlock}>
        <>
          <div className={styles.title}>
            <ParticipantModal />
            <span>{`${mode}`} Participant</span>
          </div>

          {mode === "Add" && participantType !== "coach" && (
            <div>
              <RadioButton />
            </div>
          )}

          {option === "athlete" ? (
            <div className={styles.inputBlocks}>
              <form>
                <div className={styles.athleteRow}>
                  <div className={styles.inputBlock}>
                    <div className={styles.inputWrapper}>
                      <Select
                        placeholder="Attendee"
                        isSearchable={true}
                        className={styles.selectAthlete}
                        name="athletes"
                        value={selectAthlete}
                        options={athletes}
                        components={{ DropdownIndicator: dropdownOption }}
                        onChange={setSelectedAthlete}
                      />
                    </div>
                  </div>

                  <div className={clsx(styles.inputBlock, styles.subInput)}>
                    <div className={styles.inputWrapper}>
                      <input
                        readOnly={readOnly}
                        type="text"
                        name="parentName"
                        value={selectAthlete?.parentName}
                        placeholder="Parent Name"
                        className={styles.input}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.infoRow}>
                  <div className={styles.inputBlock}>
                    <div className={styles.inputWrapper}>
                      <input
                        readOnly={readOnly}
                        type="text"
                        name="phone"
                        value={selectAthlete?.mobile}
                        placeholder="Phone"
                        className={styles.input}
                      />
                    </div>
                  </div>

                  <div className={styles.inputBlock}>
                    <div className={styles.inputWrapper}>
                      <input
                        readOnly={readOnly}
                        type="text"
                        name="email"
                        value={selectAthlete?.email}
                        placeholder="Email"
                        className={styles.input}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className={styles.inputBlocks}>
              <form>
                <div className={styles.athleteRow}>
                  <div className={styles.inputBlock}>
                    <div className={styles.inputWrapper}>
                      {groups.length === 0 && (
                        <span className={styles.noGroups}>
                          You don&apos;t have any added groups yet
                        </span>
                      )}
                      {groups.length > 0 && (
                        <Select
                          placeholder="Select group"
                          isSearchable={true}
                          className={clsx(
                            styles.selectGroup,
                            error && styles.errorInput
                          )}
                          name="groups"
                          value={selectGroup}
                          options={groups}
                          components={{ DropdownIndicator: dropdownOption }}
                          onChange={setSelectedGroup}
                        />
                      )}
                    </div>
                    {error && (
                      <div className={styles.errorBlock}>
                        <div className={styles.warningIcon}>
                          <Warning />
                        </div>
                        <div className={styles.errorMessage}>
                          The capacity will be exceeded, please choose different
                          group or increase the capacity
                        </div>
                      </div>
                    )}

                    <div
                      className={clsx(
                        styles.membersBlock,
                        !isExpand && styles.fullHeight
                      )}
                    >
                      <div className={styles.membersName}>
                        {athletesGroup.map((item: any, index: number) => (
                          <>
                            <div className={styles.memberBlock}>
                              <span>
                                {`${item.athlete.firstName} ${item.athlete.lastName}`}
                              </span>
                            </div>

                            {index === 6 && (
                              <div
                                className={clsx(
                                  styles.expandBlock,
                                  !isExpand && styles.changePosition
                                )}
                              >
                                <Expand
                                  className={styles.expand}
                                  onClick={expand}
                                />
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}

          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={() => appDispatch({ type: "hideModal" })}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className={clsx(
                styles.yesBtn,
                !selectAthlete && !selectGroup && styles.buttonDisabled
              )}
              disabled={!selectAthlete && !selectGroup}
              onClick={handleSave}
            >
              <span>Save</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default AddEditParticipants;
