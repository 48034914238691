// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import "./App.scss";
import { createContext, useReducer, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter,
  Switch,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import clsx from "clsx";
import PersonalInfoCoach from "apis/personalInfoCoach";
import initialValues from "./constants/initialValues";
import useAppReducer from "./reducers/useAppReducer";
import Home from "./components/home/home";
import Landing from "./components/landing/landing";
import Main from "./layout/main/main";
import ValidateToken from "./apis/auth/validateToken";
import PersonalInfo from "./apis/personalInfo";
import Modal from "./common/modal";
import { TrainingActivities } from "./components/trainingActivities/trainingActivities";

export const AppContext = createContext({});

const elementListHideMobileNavigation = ["input", "textarea"];

const TrainingActivitiesRoute = (
  props: JSX.IntrinsicAttributes &
    RouteComponentProps<{ id?: string | undefined }, StaticContext, unknown>
) => (
  <Main>
    <TrainingActivities {...props} />
  </Main>
);

const App = () => {
  const [appState, appDispatch] = useReducer(useAppReducer, initialValues);
  const [checkingUser, setCheckingUser] = useState(true);
  const [isMobileNavigationHidden, setIsMobileNavigationHidden] =
    useState(false);

  useEffect(() => {
    (async function token() {
      const response = await ValidateToken();
      if (response.status === 200)
        appDispatch({ type: "setAuthState", payload: { ...response.data } });
      setCheckingUser(false);
    })();
  }, []);

  const handleFocus = ({ target, type }) => {
    const {
      nodeName,
      disabled = false,
      readOnly = false,
      inputMode,
      ariaAutoComplete,
    } = target;
    const elementName = String(nodeName).toLowerCase();
    const isElementInList =
      elementListHideMobileNavigation.includes(elementName);

    if (isElementInList && type === "blur") {
      setIsMobileNavigationHidden(false);
    } else {
      setIsMobileNavigationHidden(
        isElementInList &&
          !(
            disabled ||
            readOnly ||
            inputMode === "none" ||
            ariaAutoComplete === "list"
          )
      );
    }
  };

  const handleModalFocus = (event: { target: any; type: any }) => {
    if (!appState.showModal) {
      return;
    }
    handleFocus(event);
  };

  const handleContentFocus = (event: { target: any; type: any }) => {
    if (appState.showModal) {
      return;
    }
    handleFocus(event);
  };

  useEffect(() => {
    if (appState.id)
      (async function personal() {
        const response =
          appState.accountType === "coach"
            ? await PersonalInfoCoach(appState.id)
            : await PersonalInfo(appState.id);
        if (response.status === 200)
          appDispatch({ type: "setUserInfo", payload: { ...response.data } });
      })();
  }, [appState.id]);

  return (
    <>
      <AppContext.Provider value={{ appState, appDispatch }}>
        <Modal
          onFocus={handleModalFocus}
          onClick={handleModalFocus}
          onBlur={handleModalFocus}
          showModal={appState.showModal}
        />
        <BrowserRouter>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onFocus={handleContentFocus}
            onClick={handleContentFocus}
            onBlur={handleContentFocus}
            className={clsx("appContent", {
              hideMobileNav: isMobileNavigationHidden,
            })}
          >
            {!appState.user && !checkingUser ? (
              <Switch>
                <Route
                  exact={true}
                  path="/home/:component=checkEmail"
                  component={Home}
                />
                <Route
                  path="/home/:component=resetPassword/:token"
                  component={Home}
                />
                <Route path="/home/:component" component={Home} />
                <Redirect to="/home/accounts" component={Home} />
              </Switch>
            ) : (
              appState.user && (
                <Switch>
                  <Redirect from="/main/trainings" to="/main/activities" />
                  <Route
                    exact={true}
                    path="/main/activities"
                    component={TrainingActivitiesRoute}
                  />
                  <Route
                    path="/main/activities/:id"
                    component={TrainingActivitiesRoute}
                  />
                  <Route path="/main/:component" component={Main} />
                  <Route exact={true} path="/landing" component={Landing} />
                  <Redirect to="/landing" component={Landing} />
                </Switch>
              )
            )}
          </div>
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
};

export default App;
