import React, { FC, useContext } from "react";
import { DispatchProps } from "components/management/types";
import styles from "./deleteGroup.module.scss";
import { Group } from "../../../../constants/assets";
import { AppContext } from "../../../../App";
import RemoveGroup from "../../../../apis/management/deleteGroup";

export type Props = {
  id: number;
  dispatch: React.Dispatch<DispatchProps>;
};

const DeleteGroup: FC<Props> = ({ dispatch, id }) => {
  const { appDispatch } = useContext<any>(AppContext);

  const handleDelete = async () => {
    const response: any = await RemoveGroup({
      id,
    });
    if (response.status === 200 || response.status === 204) {
      appDispatch({ type: "hideModal" });
      dispatch({ type: "refreshData", payload: "groups" });
    }
  };

  return (
    <>
      <div className={styles.deleteBlock}>
        <>
          <div className={styles.title}>
            <Group />
            <span>Delete Group</span>
          </div>
          <div className={styles.deleteInfo}>
            <span>Are you sure you want to delete this group?</span>
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={() => appDispatch({ type: "hideModal" })}
            >
              <span>No</span>
            </button>
            <button
              type="button"
              className={styles.yesBtn}
              onClick={handleDelete}
            >
              <span>Yes, Delete!</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default DeleteGroup;
