import React, { FC } from "react";

import styles from "./index.module.scss";

interface Props {
  color?: string;
  text?: string;
}

export const Loading: FC<Props> = ({ color = "#fff", text = "" }) => (
  <span className={styles.loading} style={{ color }}>
    {text}
  </span>
);

export default Loading;
