import { FC, useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import validator from "validator";
import { IFormAdmin, Props } from "components/management/types";
import { AppContext } from "../../../../App";
import { ParticipantModal, Warning } from "../../../../constants/assets";
import styles from "./addEditAdmin.module.scss";
import UpdateAdmin from "../../../../apis/management/updateAdmin";
import AddAdmin from "../../../../apis/management/addAdmin";

type FieldsValidation = {
  name?: boolean;
  mobile?: boolean;
  email?: boolean;
};

const AddEditAdmin: FC<Props> = ({ mode, data, dispatch }) => {
  const { appDispatch } = useContext<any>(AppContext);
  const [readOnly] = useState(false);
  const [formData, setFormData] = useState<IFormAdmin>();
  const [isValid, setIsValid] = useState<FieldsValidation>({});
  const [emailMsg, setEmailMsg] = useState(
    "Please input a valid email address"
  );
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (mode === "Edit") {
      setIsValid({
        name: true,
        mobile: true,
        email: true,
      });
      setFormData(data);
    } else {
      setIsValid({
        name: false,
        mobile: false,
        email: false,
      });
      setFormData({ disabled: false });
    }
  }, []);

  const handleSave = () => {
    (async function admin() {
      if (Object.values(isValid).includes(false)) {
        setShowError(true);
        return;
      }

      const response: any = await (mode === "Edit"
        ? UpdateAdmin({
            data: formData,
          })
        : AddAdmin({
            data: formData,
          }));

      if (response.status === 200 || response.status === 201) {
        appDispatch({ type: "hideModal" });
        dispatch({ type: "refreshData", payload: "usersList" });
      } else if (response.response.data.errorKey === "20302") {
        setEmailMsg("The email already exists.");
        setIsValid((prev) => ({ ...prev, email: false }));
        setShowError(true);
        setFormData((prev) => ({ ...prev, email: "" }));
      }
    })();
  };

  const handleChangePhone = useCallback((e: NumberFormatValues) => {
    setFormData((prev) => ({ ...prev, mobile: e.value }));
    setIsValid((prev) => ({
      ...prev,
      mobile: e.value.length === 9,
    }));
  }, []);

  const handleInputChange = useCallback(
    (e: { target: { name: string; value: string } }) => {
      if (e.target.name === "name")
        setIsValid((prev) => ({
          ...prev,
          [e.target.name]: !!e.target.value.length,
        }));
      else if (e.target.name === "email")
        setIsValid((prev) => ({
          ...prev,
          [e.target.name]: validator.isEmail(e.target.value),
        }));

      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    []
  );

  return (
    <>
      <div className={styles.addEditBlock}>
        <>
          <div className={styles.title}>
            <ParticipantModal />
            <span>{`${mode}`} Admin</span>
          </div>
          <div className={styles.inputBlocks}>
            <form>
              <div className={styles.athleteRow}>
                <div className={clsx(styles.inputBlock, styles.subInput)}>
                  <div className={styles.inputWrapper}>
                    <input
                      readOnly={readOnly}
                      type="text"
                      name="name"
                      value={formData?.name}
                      placeholder="*Admin Name"
                      className={clsx(
                        styles.input,
                        !isValid.name && showError && styles.errorInput
                      )}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.infoRow}>
                <div className={styles.inputBlock}>
                  <div className={styles.inputWrapper}>
                    <NumberFormat
                      readOnly={readOnly}
                      className={clsx(
                        styles.input,
                        !isValid.mobile && showError && styles.errorInput
                      )}
                      id="mobile"
                      name="mobile"
                      format="+971 ## ### ####"
                      mask="_"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      value={formData?.mobile}
                      onValueChange={(e) => handleChangePhone(e)}
                    />
                  </div>
                  {showError && !isValid.mobile && (
                    <div className={styles.errorBlock}>
                      <div className={styles.warningIcon}>
                        <Warning />
                      </div>
                      <div className={styles.errorMessage}>
                        Please input a valid phone number
                      </div>
                    </div>
                  )}
                </div>

                <div className={styles.inputBlock}>
                  <div className={styles.inputWrapper}>
                    <input
                      readOnly={readOnly}
                      type="text"
                      name="email"
                      value={formData?.email}
                      placeholder="*Email"
                      className={clsx(
                        styles.input,
                        !isValid.email && showError && styles.errorInput
                      )}
                      onChange={handleInputChange}
                    />
                  </div>
                  {showError && !isValid.email && (
                    <div className={styles.errorBlock}>
                      <div className={styles.warningIcon}>
                        <Warning />
                      </div>
                      <div className={styles.errorMessage}>{emailMsg}</div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={() => appDispatch({ type: "hideModal" })}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className={clsx(
                !Object.values(isValid).includes(false)
                  ? styles.yesBtn
                  : styles.buttonDisabled
              )}
              onClick={handleSave}
            >
              <span>Save</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default AddEditAdmin;
