import { FC, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import validator from "validator";
import { AppContext } from "../../App";
import styles from "./login.module.scss";
import {
  SignInUser,
  SignInLock,
  PasswordInputEye,
  Warning,
  LeftArrow,
} from "../../constants/assets";
import Auth from "../../apis/auth/auth";

const Login: FC = ({ history }: any) => {
  const accountType =
    localStorage.getItem("accountType") || history.push("/home/accounts");
  const { appDispatch } = useContext<any>(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");

  const formInitialState = {
    login: "",
    password: "",
    rememberMe: true,
    accountType,
    isCleared: false,
  };

  const [form, setForm] = useState<any>(formInitialState);
  const validateLogin = () => validator.isEmail(form.login);

  const [isValid, setIsValid] = useState<any>({
    login: true,
    password: true,
  });

  useEffect(() => {
    setForm((prev: any) => ({
      ...prev,
      login: localStorage.getItem("rememberMe") || "",
    }));
  }, []);

  useEffect(() => {
    if (form.isCleared) {
      setForm(form);
      // Timeout is needed since it takes a few moment for Chrome to register the render
      setTimeout(() => history.push("/home/accounts"), 250);
    }
  }, [form]);

  const getID = (data: {
    accountType: string;
    userId?: number;
    athleteId?: string;
    coachId?: string;
  }) => {
    if (data.accountType === "ADMIN") return data.userId;
    if (data.accountType === "ATHLETE") return data.athleteId;
    if (data.accountType === "COACH") return data.coachId;

    return undefined;
  };
  const handleSignIn = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const isLoginValid = validateLogin();

    if (!form.password.length || !isLoginValid) {
      setIsValid({ login: isLoginValid, password: form.password.length });
      return;
    }

    if (validateLogin() && form.password.length) {
      const response: any = await Auth(form);
      if (response?.data) {
        appDispatch({
          type: "setAuthState",
          payload: {
            user: response.data.user,
            token: response.data.token,
            form,
            id: getID(response.data),
            accountType: response.data.accountType,
            superAdmin: response.data.superAdmin,
          },
        });
        history.push("/landing");
      } else {
        if (response.response.data.detail === "10304")
          setLoginErrorMsg(
            "The account is not authorized, please contact the admin."
          );
        else setLoginErrorMsg("Email or password is incorrect.");

        setError(true);
        setForm((prev: any) => ({ ...prev, password: "" }));
        setIsValid({ login: form.login, password: true });
        appDispatch({
          type: "setAuthState",
          payload: { user: null, token: null, form, id: "", admin: "" },
        });
      }
    }
  };

  const handleResetPassword = () => {
    history.push("/home/email");
  };
  const handleInputChange = (e: any) => {
    setForm((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleEmailBlur = (e: {
    target: { name: string; value: { length: number } };
  }) => {
    if (e.target.value.length)
      setIsValid((prev: any) => ({ ...prev, login: validateLogin() }));
    else setIsValid((prev: any) => ({ ...prev, login: true }));
  };

  const handlePasswordBlur = () => {
    setIsValid((prev: any) => ({ ...prev, password: true }));
  };

  const handlePasswordEyeMouseDown = () => {
    if ("ontouchend" in document) {
      return;
    }
    setShowPassword(true);
  };

  const handlePasswordEyeMouseUp = () => {
    if ("ontouchend" in document) {
      return;
    }
    setShowPassword(false);
  };

  const handlePasswordEyeTouchStart = () => {
    setShowPassword(true);
  };

  const handlePasswordEyeTouchEnd = () => {
    setShowPassword(false);
  };

  const handlePasswordEyeKeyDown = (e: { code: string }) => {
    if (e.code === "Space" || e.code === "Enter") {
      setShowPassword(true);
    }
  };

  const handlePasswordEyeKeyUp = (e: { code: string }) => {
    if (e.code === "Space" || e.code === "Enter") {
      setShowPassword(false);
    }
  };

  const handleRememberMe = (e: any) => {
    setForm((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleBack = () => {
    setForm({ ...formInitialState, isCleared: true });
    history.push("/home/accounts");
  };

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.title}>
          <div
            className={clsx(
              accountType === "admin" && styles.admin,
              accountType === "athlete" && styles.athlete,
              accountType === "coach" && styles.coach
            )}
          />
          <span>
            {accountType === "coach"
              ? "Junior Coach Sign In "
              : `${accountType} Sign In`}
          </span>
        </div>
        <div className={styles.subTitle}>
          <span>
            Please input your email registered in Heroes of Hope to proceed.
          </span>
        </div>
        <form className={styles.form} onSubmit={handleSignIn} noValidate={true}>
          <div>
            <div className={styles.inputsBlock}>
              <div className={styles.inputBlock}>
                <label htmlFor="login" className={styles.label}>
                  Email
                  <div className={styles.inputWrapper}>
                    <div
                      className={clsx(
                        styles.iconWrapper,
                        form.login.length > 0 && styles.activeIcon
                      )}
                    >
                      <SignInUser />
                    </div>

                    <input
                      type="email"
                      name="login"
                      autoComplete="username"
                      value={form.login}
                      placeholder="Type your Email"
                      onBlur={handleEmailBlur}
                      onChange={handleInputChange}
                      className={clsx(
                        styles.input,
                        !isValid.login && styles.errorInput
                      )}
                    />
                  </div>
                </label>
                {!isValid.login && (
                  <div className={styles.errorBlock}>
                    <div className={styles.warningIcon}>
                      <Warning />
                    </div>
                    <div className={styles.errorMessage}>
                      Please enter a valid email address.
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.inputBlock}>
                <label htmlFor="password" className={styles.label}>
                  Password
                  <div className={styles.inputWrapper}>
                    <div
                      className={clsx(
                        styles.iconWrapper,
                        form.password.length > 0 && styles.activeIcon
                      )}
                    >
                      <SignInLock />
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      autoComplete="current-password"
                      value={form.password}
                      placeholder="Type your password"
                      onBlur={handlePasswordBlur}
                      onChange={handleInputChange}
                      className={clsx(
                        styles.input,
                        !isValid.password && styles.errorInput
                      )}
                    />

                    <button
                      type="button"
                      className={clsx(
                        styles.iconWrapper,
                        styles.passwordEyeIcon,
                        showPassword && styles.activeIcon
                      )}
                      onMouseDown={handlePasswordEyeMouseDown}
                      onMouseUp={handlePasswordEyeMouseUp}
                      onTouchStart={handlePasswordEyeTouchStart}
                      onTouchEnd={handlePasswordEyeTouchEnd}
                      onKeyDown={handlePasswordEyeKeyDown}
                      onKeyUp={handlePasswordEyeKeyUp}
                    >
                      <PasswordInputEye />
                    </button>
                  </div>
                </label>
                {error && (
                  <div className={styles.errorBlock}>
                    <div className={styles.warningIcon}>
                      <Warning />
                    </div>
                    <div className={styles.errorMessage}>{loginErrorMsg}</div>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.blockFeatures}>
              <div className={styles.rememberMe}>
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  defaultChecked={true}
                  onClick={handleRememberMe}
                />
                <label htmlFor="rememberMe">Remember me</label>
              </div>

              <div
                role="button"
                aria-hidden="true"
                className={styles.forgotPassword}
                onClick={handleResetPassword}
              >
                <span>Forgot Password ?</span>
              </div>
            </div>
          </div>

          <div className={styles.buttonBlock}>
            <button
              type="button"
              className={styles.backButton}
              onClick={handleBack}
            >
              <LeftArrow />
              <span> Back </span>
            </button>
            <button
              type="submit"
              className={
                validateLogin() && form.password.length
                  ? styles.button
                  : styles.buttonDisabled
              }
              tabIndex={0}
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Login);
