export const COLOR_LIGHTER_BLUE = "#BBC0CF";
export const COLOR_LIGHT_BLUE = "#B6C2E3";
export const COLOR_BLUE = "#007AFF";
export const COLOR_BRIGHT_BLUE = "#4696ec";
export const COLOR_SALMON = "#FF616A";
export const COLOR_LIGHT_RED = "#FF7D7D";
export const COLOR_RED = "#FA582E";
export const COLOR_LIGHT_GREEN = "#8ECFA5";
export const COLOR_GREEN = "#0FBB59";
export const COLOR_DARK_GREEN = "#008B32";
export const COLOR_WHITE = "#f9fafd";
export const COLOR_LIGHT_GRAY = "#C5CDDE";
export const COLOR_GRAY = "#8C93A6";
export const COLOR_OTHERS_GRAY = "#C4CAD0";
export const COLOR_DARK_GRAY = "#2C303E";
export const COLOR_TOOLTIP_TEXT = "#414655";
export const COLOR_LIGHT_YELLOW = "#FFE37F";

export const GRADIENT_AREA_BLUE: [string, string] = [
  "rgba(2, 122, 253, 0.4)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_RED: [string, string] = [
  "rgba(255, 125, 125, 0.4)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_WHITE: [string, string] = [
  "rgba(212, 211, 211, 0.2)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_GREEN: [string, string] = [
  "rgba(15, 187, 89, 0.4)",
  "rgba(18, 190, 80, 0)",
];
