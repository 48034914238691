import { useContext, useReducer } from "react";
import EventCalendar from "./calendar/event-calendar";
import UpComingEvents from "./upcomingEvents/upcomingEvents";
import styles from "./events.module.scss";
import DispatchContext from "../../context/DispatchContext";
import StateContext from "../../context/StateContext";
import reducer, { initState } from "./reducer";
import { AppContext } from "../../App";

const Events = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { appState } = useContext<any>(AppContext);
  const CurrentComponent = () => {
    switch (appState.componentName) {
      case "calendar":
        return <EventCalendar />;
      case "upcomingEvents": {
        return <UpComingEvents />;
      }
      default:
        return <EventCalendar />;
    }
  };

  return (
    <>
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <div className={styles.eventsContainer}>{CurrentComponent()}</div>
        </DispatchContext.Provider>
      </StateContext.Provider>
    </>
  );
};

export default Events;
