import { FC, useContext } from "react";
import clsx from "clsx";
import { DispatchProps } from "components/management/types";
import styles from "./userStatus.module.scss";
import {
  ParticipantModal,
  ParticipantModalBlue,
  Whistle,
  WhistleBlue,
} from "../../../../constants/assets";
import { AppContext } from "../../../../App";
import DisableUser from "../../../../apis/management/disableUser";
import EnableUser from "../../../../apis/management/enableUser";

type Props = {
  userId?: string | number;
  state: { active?: string };
  disabled?: boolean;
  dispatch: React.Dispatch<DispatchProps>;
};

const UserStatus: FC<Props> = ({ userId, state, dispatch, disabled }) => {
  const { appDispatch } = useContext<any>(AppContext);
  const id = userId;
  const disabledLabel = disabled ? "Enable" : "Disable";

  const getUserLabel = () => {
    switch (state.active) {
      case "admin":
        return "admin";
      case "athlete":
        return "athlete";
      case "coach":
        return "junior coach";
      default:
        return "";
    }
  };

  const user = getUserLabel();

  const data = {
    id,
    active: state.active,
  };

  const handleToggle = () => {
    (async function status() {
      const response: any = await (disabled
        ? EnableUser(data)
        : DisableUser(data));
      if (response.status === 200 || response.status === 204) {
        appDispatch({ type: "hideModal" });
        dispatch({ type: "refreshData", payload: "usersList" });
      }
    })();
  };

  const modalIcon = () => {
    if (state.active !== "coach" && disabled) return <ParticipantModalBlue />;
    if (state.active !== "coach" && !disabled) return <ParticipantModal />;
    if (state.active === "coach" && disabled) return <WhistleBlue />;
    if (state.active === "coach" && !disabled) return <Whistle />;
    return <></>;
  };
  return (
    <>
      <div className={styles.deleteBlock}>
        <>
          <div className={styles.title}>
            {modalIcon()}

            <span>
              {disabledLabel} {user} account
            </span>
          </div>
          <div className={styles.deleteInfo}>
            <span>
              Are you sure you want to {disabledLabel.toLocaleLowerCase()} this{" "}
              {user}?
            </span>
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={() => appDispatch({ type: "hideModal" })}
            >
              <span>No</span>
            </button>
            <button
              type="button"
              className={clsx(disabled ? styles.yesBtnBlue : styles.yesBtn)}
              onClick={handleToggle}
            >
              <span>Yes, {disabledLabel}!</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default UserStatus;
