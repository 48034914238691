import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const AddResult = async (data: any) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "POST",
      baseURL: `${API_ENDPOINT}/coreservice/api/event-results`,
      data: { ...data },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e: any) {
    if (e.response) {
      return { error: e.response };
    }
    return e;
  }
};

export default AddResult;
