import { FC, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styles from "./landing.module.scss";
import Logout from "../../apis/auth/logout";

import { AppContext } from "../../App";
import {
  SignOutIcon,
  backgroundLanding,
  RightArrow,
  logoCircle,
  events,
  training,
  performance,
  EventsIcon,
  TrainingIcon,
  PerformanceIcon,
} from "../../constants/assets";

const Landing: FC = ({ history }: any) => {
  const { appDispatch } = useContext<any>(AppContext);

  useEffect(() => {
    appDispatch({ type: "hideModal" });
  }, []);

  const handleLogout = async () => {
    const response: any = await Logout();
    if (response?.data) {
      appDispatch({
        type: "setAuthState",
        payload: { user: response.data.user, token: response.data.token },
      });
      history.push("/home");
    } else {
      appDispatch({
        type: "setAuthState",
        payload: { user: null, token: null },
      });
    }
  };

  const handleClick = (e: any) => {
    history.push(`/main/${e.currentTarget.id}`);
  };

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={logoCircle} alt="" />
          <div />
        </div>

        <div className={styles.spacer}>
          <div>
            <div className={styles.sloganBlock}>
              <div className={styles.sloganTitle}>
                <span>Welcome to your profile</span>
              </div>

              <div className={styles.sloganContent}>
                <span>
                  Where would you like to navigate to?
                  <br /> Please select your preference below to get started!
                </span>
              </div>
            </div>

            <div className={styles.widgetContainer}>
              <div
                className={styles.widgetBlock}
                id="events"
                onClick={handleClick}
                role="button"
                aria-hidden="true"
              >
                <img src={events} alt="" />
                <div className={styles.widgetBlockTitle}>
                  <EventsIcon />
                  <span>Events</span>
                </div>
                <div className={styles.widgetInfo}>
                  <span>
                    Get an overview of all the planned events, register yourself
                    to them, and manage your registrations.{" "}
                  </span>
                </div>
                <button type="button" className={styles.continueBlock}>
                  <span>Continue</span>
                  <RightArrow />
                </button>
              </div>
              <div
                className={styles.widgetBlock}
                id="trainings"
                onClick={handleClick}
                role="button"
                aria-hidden="true"
              >
                <img src={training} alt="" />
                <div className={styles.widgetBlockTitle}>
                  <TrainingIcon />
                  <span>Training Activities</span>
                </div>
                <div className={styles.widgetInfo}>
                  <span>
                    Get an overview of your training activities, add goals, and
                    monitor your progress towards crushing them!{" "}
                  </span>
                </div>
                <button type="button" className={styles.continueBlock}>
                  <span>Continue</span>
                  <RightArrow />
                </button>
              </div>

              <div
                className={styles.widgetBlock}
                id="performance"
                onClick={handleClick}
                role="button"
                aria-hidden="true"
              >
                <img src={performance} alt="" />
                <div className={styles.widgetBlockTitle}>
                  <PerformanceIcon />
                  <span>Performance Dashboard</span>
                </div>
                <div className={styles.widgetInfo}>
                  <span>
                    Get an overview of your performance in trainings and key
                    events{" "}
                  </span>
                </div>
                <button type="button" className={styles.continueBlock}>
                  <span>Continue</span>
                  <RightArrow />
                </button>
              </div>
            </div>
          </div>

          <div className={styles.btnBlock}>
            <button
              type="button"
              className={styles.signOutBtn}
              onClick={handleLogout}
            >
              <SignOutIcon />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      </div>

      <div className={styles.g42} />
      <div className={styles.overlayBlackLinear} />
      <div className={styles.overlayBlackLinear} />
      <img className={styles.bg} src={backgroundLanding} alt="" />
    </div>
  );
};

export default withRouter(Landing);
