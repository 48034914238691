import { FC, ReactNode } from "react";

const Menu = (props: JSX.IntrinsicElements["div"]) => {
  return <div {...props} />;
};
const Blanket = (props: JSX.IntrinsicElements["div"]) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
interface DropdownProps {
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
  children?: ReactNode;
}
export const Dropdown: FC<DropdownProps> = ({
  children,
  isOpen,
  target,
  onClose,
}) => (
  <div>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
