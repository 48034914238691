import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";
import { formatDateAPI } from "../../utils/utils";

const TrainingActivities = async (data: {
  date: any;
  filter: string;
  toDate?: string;
}) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/coreservice/api/trainings`,
      params: {
        from: formatDateAPI(data.date),
        filter: data.filter,
        to: data.toDate,
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default TrainingActivities;
