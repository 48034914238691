import { FC, KeyboardEvent } from "react";
import clsx from "clsx";
import { Clock, CanceledEvent } from "../../../constants/assets";
import styles from "./calendar-event-item.module.scss";

type Props = {
  name: string;
  selected: boolean;
  className?: string;
  status?: string;
  time?: string;
  onClick: () => void;
};

export const CalendarEventItem: FC<Props> = ({
  name,
  selected,
  status,
  time,
  className,
  onClick,
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div
      role="button"
      onClick={onClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      className={clsx(className, styles.eventWrapper, {
        [styles.selected]: selected,
        [styles.upcoming]: status === "PENDING",
        [styles.past]: status === "DONE",
        [styles.canceled]: status === "CANCELLED",
      })}
    >
      <span title={name} className={styles.event}>
        {name}
      </span>
      <div className={styles.time}>
        {status === "CANCELLED" ? <CanceledEvent /> : <Clock />}
        <span className={styles.timeValue}>{time}</span>
      </div>
    </div>
  );
};
