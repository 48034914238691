import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../App";
import styles from "./menu.module.scss";
import {
  HomeIcon,
  EventsIcon,
  TrainingIcon,
  PerformanceIcon,
} from "../../constants/assets";

const Menu = () => {
  const { appDispatch } = useContext<any>(AppContext);
  return (
    <ul className={styles.ul}>
      <li>
        <NavLink to="/home/landing" activeClassName={styles.active}>
          <HomeIcon />
          <span> Home </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/main/events"
          activeClassName={styles.active}
          onClick={() =>
            appDispatch({ type: "globalRender", payload: "calendar" })
          }
        >
          <EventsIcon />

          <span>Events </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/main/activities" activeClassName={styles.active}>
          <TrainingIcon />

          <span> Training Activities </span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/main/performance" activeClassName={styles.active}>
          <PerformanceIcon />

          <span>Performance Dashboard </span>
        </NavLink>
      </li>
    </ul>
  );
};

export default Menu;
