// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
  FC,
} from "react";
import ReactSelect, { components } from "react-select";
import clsx from "clsx";
import styles from "./performance.module.scss";
import AthletesList from "../../apis/athletesList";
import CoachList from "../../apis/management/coachList";
import userProfilePersonal from "../../apis/userProfilePersonal";
import TrainingEventDashboard from "../../apis/trainings/trainingEventDashboard";
import CoachDashboard from "../../apis/trainings/coachDashboard";
import {
  formatDateAPI,
  getFormattedStartOfMonth,
  roundFloat,
} from "../../utils/utils";
import { CurrentDateCalendar } from "../current-date-calendar/current-date-calendar";
import { LineChart } from "../charts/line-chart/line-chart";
import { BarChart } from "../charts/bar-chart/bar-chart";
import { DonutChart } from "../charts/DonutChart";
import { COLOR_BLUE, COLOR_GREEN, COLOR_LIGHT_RED } from "../charts/colors";
import { Dropdown } from "../select/popout";
import { ArrowDown, Avatar, Search } from "../../constants/assets";

import { AppContext } from "../../App";
import "react-datepicker/dist/react-datepicker.css";
import customStyles from "./select.styles";

const Performance: FC = () => {
  const [athletes, setAthletes] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [select, setSelect] = useState();
  const [userProfile, setUserProfile] = useState<any>("");
  const [activity, setActivity] = useState([]);
  const [duration, setDuration] = useState({});
  const [caloriesBurnt, setCaloriesBurnt] = useState({});
  const [summary, setSummary] = useState({});
  const [minutesSpent, setMinutesSpent] = useState({});
  const [trainings, setTrainings] = useState({});
  const [weekRange, setWeekRange] = useState({});
  const [goals, setGoals] = useState({});
  const [events, setEvents] = useState({});
  const [distance, setDistance] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);

  enum FilterOption {
    athlete = "Athlete",
    coach = "Junior Coach",
  }

  const shadowConfig = { shadowBlur: 16, shadowOffsetY: 17 };

  const DEFAULT_ACTIVITY_COLORS = {
    Swimming: "#5AC086",
    Cycling: "#FD9175",
    Running: "#5699E1",
    Boxing: "#CC639C",
    Yoga: "#7863CC",
    Rugby: "#FD7575",
    Gymnastics: "#63B9CC",
    Basketball: "#5193B9",
    Others: "#A7B1B6",
  };

  const [startDate, setStartDate] = useState(
    getFormattedStartOfMonth(new Date())
  );
  const [active, setActive] = useState("training-dashboard");
  const { appState } = useContext(AppContext);
  const { appDispatch } = useContext(AppContext);
  const [filterType, setFilterType] = useState(
    (appState.currentFilter || appState.accountType) === "coach"
      ? "coach"
      : "athlete"
  );
  const [radioOption, setRadioOption] = useState(
    (appState.currentFilter || appState.accountType) === "coach"
      ? "coach"
      : "athlete"
  );
  const maxDate = new Date();

  const userPersonal = async (id: number) => {
    const personalResponse: any = await userProfilePersonal({
      id,
      type: radioOption === "coach" ? "coaches" : "athletes",
    });
    if (personalResponse?.data)
      setUserProfile((prev) => ({ ...prev, ...personalResponse.data }));
  };

  const userProfileData = async (id: number) => {
    const userProfileResponse = await userProfilePersonal({
      id: radioOption === "coach" ? `${id}?profileType=COACH` : id,
      type: "user-profile",
    });
    if (userProfileResponse?.data)
      setUserProfile({ ...userProfileResponse.data });
    userPersonal(id);
  };

  const filteredResponse = (trainingDashboardResponse) => {
    const filteredResponseData =
      trainingDashboardResponse.data?.activities?.map((element) => ({
        activity: element.category.name,
        count: element.count.count,
        maxSpeed: element.maxSpeed,
        maxDistance: element.maxDistance,
      }));
    setActivity(filteredResponseData);

    setSummary(trainingDashboardResponse.data?.summary);
    setMinutesSpent(trainingDashboardResponse.data?.minutesSpent);
    setCaloriesBurnt(trainingDashboardResponse.data?.caloriesBurnt);
    setDuration(trainingDashboardResponse.data?.countsDistribution);
    setGoals(trainingDashboardResponse.data?.goals);
    setTrainings(trainingDashboardResponse.data?.trainings);
    setDistance(trainingDashboardResponse.data?.distance);
    setEvents(trainingDashboardResponse.data?.events);
    setWeekRange(trainingDashboardResponse.data?.weekRange);
  };

  useEffect(() => {
    if (appState.accountType === "admin") {
      (async function list() {
        const response = await AthletesList();
        if (response?.data?.length) {
          const athlete = response.data.map((item) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          }));
          setAthletes(athlete);
          if (filterType === "athlete") {
            setDropDownOptions(athlete);
            const activeAthlete = appState.currentAthlete
              ? {
                  athlete: appState.currentAthlete,
                  id: appState.currentAthlete.value,
                }
              : {
                  athlete: athlete[0],
                  id: athlete[0].value,
                };

            setSelect(activeAthlete.athlete);
            appDispatch({
              type: "currentAthlete",
              payload: activeAthlete.athlete,
            });

            const trainingDashboardResponse = await TrainingEventDashboard({
              id: activeAthlete.id,
              date: startDate,
              active,
            });

            filteredResponse(trainingDashboardResponse);
            userProfileData(activeAthlete.id);
          }
        }
      })();
      (async function coachesList() {
        const response = await CoachList();
        if (response?.data?.length) {
          const coachList = response.data.map((item) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          }));
          setCoaches(coachList);
          if (filterType === "coach") {
            setDropDownOptions(coachList);
            const activeAthlete = appState.currentAthlete;
            setSelect(activeAthlete);
            const trainingDashboardResponse = await CoachDashboard({
              id: activeAthlete.value,
              date: startDate,
            });
            filteredResponse(trainingDashboardResponse);
            userProfileData(activeAthlete.value);
          }
        }
      })();
    } else if (appState.accountType === "athlete") {
      (async function dashboard() {
        const trainingDashboardResponse = await TrainingEventDashboard({
          id: appState.id,
          date: startDate,
          active,
        });
        filteredResponse(trainingDashboardResponse);
        userProfileData(appState.id);
      })();
    } else if (appState.accountType === "coach") {
      (async function dashboard() {
        const trainingDashboardResponse = await CoachDashboard({
          id: appState.id,
          date: startDate,
        });
        filteredResponse(trainingDashboardResponse);
        userProfileData(appState.id);
      })();
    }
  }, [appState.accountType, appState.id]);

  const sortObject = (obj: { [x: string]: any }) =>
    Object.keys(obj)
      .sort()
      .reduce((res: any, key) => {
        res[key] = obj[key];
        return res;
      }, {});

  const getKeys = (chartData: any) => {
    return chartData ? Object.keys(chartData).sort() : [];
  };

  const getValues = (chartData: any) => {
    return chartData ? Object.values(sortObject(chartData)) : [];
  };

  const trainingSeries = useMemo(
    () => [
      {
        name: "Value",
        color: filterType === "coach" ? COLOR_GREEN : COLOR_BLUE,
        shadowColor:
          filterType === "coach"
            ? "rgba(15, 187, 89, 0.4)"
            : "rgba(2, 122, 253, 0.4)",
        ...shadowConfig,
        values: getValues(trainings),
      },
    ],
    [trainings, filterType]
  );

  const eventSeries = useMemo(
    () => [
      {
        name: "Value",
        color: COLOR_BLUE,
        shadowColor: "rgba(2, 122, 253, 0.4)",
        ...shadowConfig,
        values: getValues(events),
      },
    ],
    [events]
  );

  const goalsSeries = useMemo(
    () => [
      {
        name: "Value",
        color: COLOR_LIGHT_RED,
        shadowColor: "rgba(255, 125, 125, 0.4)",
        ...shadowConfig,
        values: getValues(goals),
      },
    ],
    [goals]
  );

  const hoursSeries = useMemo(
    () => [
      {
        name: "Value",
        color: COLOR_LIGHT_RED,
        shadowColor: "rgba(255, 125, 125, 0.4)",
        ...shadowConfig,
        values: getValues(minutesSpent),
      },
    ],
    [minutesSpent]
  );

  const getDefaults = (activityType: string) => {
    return {
      name: activityType,
      color: DEFAULT_ACTIVITY_COLORS[activityType],
      shadowColor: DEFAULT_ACTIVITY_COLORS[activityType],
      ...shadowConfig,
      values: [],
    };
  };

  const getDistanceData: any = () => {
    const defaultActivity = {};

    if (distance) {
      Object.keys(distance)
        .sort()
        .forEach((key: string) => {
          const category = distance[key]?.values;
          category.forEach(
            (item: { category: { name: string }; value: any }) => {
              const { category: { name } = {} } = item;
              if (name in defaultActivity) {
                defaultActivity[name].values.push({
                  value: item?.value,
                });
              } else {
                const defaults = getDefaults(name);
                defaults.values.push({
                  value: item?.value,
                });
                defaultActivity[name] = defaults;
              }
            }
          );
        });
    }
    return Object.values(defaultActivity);
  };
  const distanceSeries = useMemo(() => [...getDistanceData()], [distance]);

  const getCaloriesSeries = () => [
    {
      name: "Value",
      color: COLOR_GREEN,
      shadowColor: "rgba(15, 187, 89, 0.4)",
      shadowBlur: 16,
      shadowOffsetY: 17,
      values: caloriesBurnt ? getValues(caloriesBurnt) : [],
    },
  ];

  const DEFAULT_DURATION_COLORS = {
    count30MDuration: { color: "#7459D9", name: "< 30 mins" },
    count60MDuration: { color: "#B3A6E5", name: "< 1 hour" },
    count60MMoreDuration: { color: "#DAD5EE", name: "> 1 hour" },
  };

  const createDurationSegmentsWithDefaults = () => {
    return getKeys(duration)?.map((element) => ({
      name: DEFAULT_DURATION_COLORS[element].name,
      value: duration[element].count,
      color: DEFAULT_DURATION_COLORS[element].color,
    }));
  };

  const maxDistanceCovered = useMemo(
    () =>
      activity?.map((element, index) => ({
        name: element?.activity,
        values: Array(activity.length)
          .fill(0)
          .fill(element?.maxDistance, index, index + 1),
        color: DEFAULT_ACTIVITY_COLORS[element.activity],
      })) || [],
    [activity]
  );

  const maxSpeedAchieved = useMemo(
    () =>
      activity?.map((element, index) => ({
        name: element?.activity,
        values: Array(activity.length)
          .fill(0)
          .fill(element?.maxSpeed, index, index + 1),
        color: DEFAULT_ACTIVITY_COLORS[element.activity],
      })) || [],
    [activity]
  );

  const activitySegmentsWithDefaults = useMemo(
    () =>
      activity?.map((element) => ({
        name: element?.activity,
        value: element?.count,
        color: DEFAULT_ACTIVITY_COLORS[element.activity],
      })) || [],
    [activity]
  );

  const activityLabels = useMemo(
    () => activity?.map((element) => element?.activity) || [],
    [activity]
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleTrainingDashboard = async (id, date, active) => {
    const dashboardResponse =
      radioOption === "coach"
        ? await CoachDashboard({
            id,
            date,
          })
        : await TrainingEventDashboard({
            id,
            date,
            active,
          });
    filteredResponse(dashboardResponse);
  };

  const renderTrainingEventChart = (charType, color) => {
    return (
      <>
        <span>{`${charType} Over Time`}</span>
        <LineChart
          name={`${charType} over time`}
          series={charType === "Trainings" ? trainingSeries : eventSeries}
          axisPointerColor={color}
          labels={getKeys(charType === "Trainings" ? trainings : events)}
          weekRange={weekRange}
        />
      </>
    );
  };

  const renderAthleteDashboard = () => {
    if (active === "training-dashboard") {
      return (
        <div className={styles.goals}>
          <span>Goals Met Over Time</span>
          <LineChart
            name="Goals met over time"
            series={goalsSeries}
            axisPointerColor={COLOR_LIGHT_RED}
            labels={getKeys(goals)}
            weekRange={weekRange}
          />
        </div>
      );
    }
    return (
      <div className={styles.distance}>
        <div className={styles.innerContent}>
          <span>Distance Covered Over Time</span>
          <LineChart
            name="distance met over time"
            series={distanceSeries}
            axisPointerColor={COLOR_LIGHT_RED}
            showLegend={true}
            labels={getKeys(distance)}
            weekRange={weekRange}
          />
        </div>
        <div className={styles.bottomLegend} />
      </div>
    );
  };
  const setSelectedAthlete = useCallback(
    (option) => {
      setSelect(option);
      userProfileData(option.value);
      handleTrainingDashboard(option.value, startDate, active);
      appDispatch({ type: "currentAthlete", payload: option });
    },
    [startDate, active, radioOption]
  );

  const handleTabClick = (e) => {
    setActive(e.target.name);
    handleTrainingDashboard(userProfile.id, startDate, e.target.name);
  };

  const onCalendarChange = (newDate) => {
    const formattedDate = formatDateAPI(newDate);
    setStartDate(formattedDate);
    handleTrainingDashboard(userProfile.id, formattedDate, active);
  };

  const DropdownIndicator = () => {
    return <></>;
  };

  const onChangeValue = (event: any) => {
    const { id } = event.currentTarget;
    setRadioOption(id);
    if (id === "athlete") setDropDownOptions(athletes);
    else setDropDownOptions(coaches);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onSelectChange = (value: OnChangeValue<StateOption, false>) => {
    toggleOpen();
    setFilterType(radioOption);
    appDispatch({ type: "currentFilter", payload: radioOption });
    setSelect(value);
    setSelectedAthlete(value);
  };

  const RadioButton = () => {
    return (
      <div className={styles.radioButton}>
        <label
          className={clsx(styles.wrapper, styles.default)}
          id="athlete"
          aria-hidden="true"
          onClick={onChangeValue}
        >
          <input
            type="radio"
            className={styles.input}
            checked={radioOption === "athlete"}
            value="athlete"
            name="option"
            readOnly={true}
          />
          <span className={styles.label}>Athlete</span>
        </label>
        <label
          className={clsx(styles.wrapper, styles.default)}
          id="coach"
          aria-hidden="true"
          onClick={onChangeValue}
        >
          <input
            type="radio"
            className={styles.input}
            checked={radioOption === "coach"}
            value="coach"
            name="option"
            readOnly={true}
          />
          <span className={styles.label}>Junior Coach</span>
        </label>
      </div>
    );
  };

  const Control = ({ children, ...props }) => {
    return (
      <>
        {RadioButton()}
        <components.Control {...props}>
          <Search />
          {children}
        </components.Control>
      </>
    );
  };

  return (
    <>
      <div className={styles.performanceContainer}>
        <div className={styles.profileBlock}>
          {appState.accountType === "admin" && (
            <Dropdown
              isOpen={isOpen}
              onClose={toggleOpen}
              target={
                <button
                  type="button"
                  aria-label="previuos"
                  className={clsx(styles.selectAthlete)}
                  onClick={toggleOpen}
                >
                  <span>
                    {select?.label ?? `Select ${FilterOption[radioOption]}`}
                  </span>
                  <ArrowDown />
                </button>
              }
            >
              <ReactSelect
                autoFocus={true}
                backspaceRemovesValue={false}
                components={{
                  DropdownIndicator,
                  Control,
                  IndicatorSeparator: null,
                }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen={true}
                isSearchable={true}
                styles={customStyles}
                onChange={onSelectChange}
                options={dropDownOptions}
                placeholder={`Search for ${FilterOption[radioOption]}`}
                tabSelectsValue={false}
                value={select}
              />
            </Dropdown>
          )}
          <div className={styles.profileContent}>
            <div className={styles.profilePersonal}>
              <div className={styles.profileImg}>
                <Avatar />
              </div>
              <div className={styles.profileInfoBlock}>
                <div className={styles.profileName}>
                  <span>
                    {appState.accountType === "admin"
                      ? select?.label
                      : `${appState.firstName} ${appState.lastName}`}
                  </span>
                </div>
                <div className={styles.profileLocation}>
                  <span>UAE</span>
                </div>
              </div>
            </div>

            <div className={styles.profileNumbersBlock}>
              <div className={styles.profileNumbers}>
                <span># of trainings performed</span>
                <span>{userProfile.noOfTrainings}</span>
              </div>
              <div className={styles.profileNumbers}>
                <span># of events</span>
                <span>{userProfile.noOfEvents}</span>
              </div>
              {filterType !== "coach" && (
                <div className={styles.profileNumbers}>
                  <span># of goals met</span>
                  <span>{userProfile.noOfGoalsMet}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.performanceBlock}>
          <div className={styles.filter}>
            {filterType !== "coach" && (
              <div className={styles.tabs}>
                <button
                  type="button"
                  name="training-dashboard"
                  className={`${
                    active === "training-dashboard" && styles.activeBtn
                  }  ${styles.tabsButtons}`}
                  onClick={handleTabClick}
                >
                  Trainings Overview
                </button>
                <button
                  type="button"
                  name="event-dashboard"
                  className={`${
                    active === "event-dashboard" && styles.activeBtn
                  }  ${styles.tabsButtons}`}
                  onClick={handleTabClick}
                >
                  Events Overview
                </button>
              </div>
            )}
            <div className={styles.dataPicker}>
              <CurrentDateCalendar
                value={startDate}
                onChange={onCalendarChange}
                className={styles.dataCalendar}
                direction="bottom"
                maxDetail="year"
                maxDate={maxDate}
              />
            </div>
          </div>
          {filterType === "coach" && (
            <div className={styles.performanceNumbers}>
              <div className={styles.detailsBlock}>
                <div>
                  <span> Number of events in the month </span>
                  <span>{summary?.eventsOfMonth}</span>
                </div>
                <div>
                  <span> Number of activities in the month </span>
                  <span>{summary?.activitiesOfMonth}</span>
                </div>
                <div>
                  <span> Number of hours spent in the month </span>
                  <span>
                    {summary?.minutesSpentOfMonth
                      ? roundFloat(summary?.minutesSpentOfMonth / 60)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className={styles.performanceCharts}>
            {filterType !== "coach" && (
              <div className={styles.chartsOne}>
                <div className={styles.distance}>
                  <span>
                    Maximum Distance Covered by
                    {active === "training-dashboard" ? " Training" : " Event"}
                  </span>
                  <BarChart
                    name="Maximum Distance​"
                    series={maxDistanceCovered}
                    labels={activityLabels}
                  />
                </div>
                <div className={styles.speed}>
                  <span>
                    Maximum Speed Achieved by
                    {active === "training-dashboard" ? " Training" : " Event"}
                  </span>
                  <BarChart
                    name="Maximum Speed"
                    series={maxSpeedAchieved}
                    labels={activityLabels}
                  />
                </div>
              </div>
            )}
            <div className={styles.chartsTwo}>
              <div className={styles.trainings}>
                {renderTrainingEventChart(
                  active === "event-dashboard" || filterType === "coach"
                    ? "Events"
                    : "Trainings",
                  COLOR_BLUE
                )}
              </div>
              <div className={styles.calories}>
                {filterType === "coach" ? (
                  renderTrainingEventChart("Trainings", COLOR_GREEN)
                ) : (
                  <>
                    <span>
                      Calories Burned/
                      {active === "training-dashboard" ? "Training" : "Event"}
                    </span>
                    <LineChart
                      name="Calories burnt/training"
                      series={getCaloriesSeries()}
                      axisPointerColor={COLOR_GREEN}
                      labels={getKeys(caloriesBurnt)}
                      weekRange={weekRange}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={styles.chartsThree}>
              {filterType === "coach" ? (
                <div className={styles.goals}>
                  <span>Hours Spent Over Time</span>
                  <LineChart
                    name="Hours spent over time"
                    series={hoursSeries}
                    axisPointerColor={COLOR_LIGHT_RED}
                    labels={getKeys(minutesSpent)}
                    isTime={true}
                    weekRange={weekRange}
                  />
                </div>
              ) : (
                renderAthleteDashboard()
              )}
              <div className={styles.pieCharts}>
                <div
                  className={clsx(styles.pieChartsActivity, {
                    [styles.distanceHeight]:
                      active === "event-dashboard" && filterType !== "coach",
                  })}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <span>Distribution by Activity</span>
                    <DonutChart
                      {...{
                        segments: activitySegmentsWithDefaults,
                        radia:
                          active === "event-dashboard"
                            ? ["35%", "60%"]
                            : ["38%", "65%"],
                      }}
                    />
                  </div>
                </div>
                <div
                  className={clsx(styles.pieChartsDuration, {
                    [styles.distanceHeight]:
                      active === "event-dashboard" && filterType !== "coach",
                  })}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <span>Distribution by Duration</span>
                    <DonutChart
                      {...{
                        segments: createDurationSegmentsWithDefaults() || [],
                        radia:
                          active === "event-dashboard"
                            ? ["35%", "60%"]
                            : ["40%", "70%"],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Performance;
