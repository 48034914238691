import { SetStateAction } from "react";

const accountType = (accountT: string) => {
  return accountT?.toLowerCase();
};

type Props = {
  athleteId: string;
  id: string;
  userId: string;
  token: string;
  coachId: string;

  superAdmin: string;
  accountType: string;
  email: string;
  selectedEvent: string;
  user: string;
  admin: boolean;
  form: {
    login: string;
    rememberMe: string;
  };
};
type ID = {
  athleteId: string;
  coachId: string;
  id: string;
  userId: string;
};
const idx = ({ athleteId, coachId, id, userId }: ID) => {
  return athleteId || coachId || userId || id;
};
const useAppReducer = (
  state: SetStateAction<Props>,
  action: {
    payload: Props;
    type: string;
  }
) => {
  switch (action.type) {
    case "trainingAthlete": {
      return {
        ...state,
        trainingAthlete: action.payload,
      };
    }
    case "currentAthlete": {
      return {
        ...state,
        currentAthlete: action.payload,
      };
    }
    case "currentFilter": {
      return {
        ...state,
        currentFilter: action.payload,
      };
    }
    case "globalRender": {
      return {
        ...state,
        componentName: action.payload,
      };
    }
    case "accountType":
      return {
        ...state,
        accountType: action.payload.accountType,
      };
    case "setAuthState":
      if (action.payload.token) {
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("superAdmin", action.payload.superAdmin);

        localStorage.setItem(
          "user",
          action.payload.form?.login || action.payload.user
        );
        localStorage.setItem("id", idx(action.payload));
        localStorage.setItem(
          "accountType",
          accountType(action.payload.accountType)
        );
        if (action.payload.form?.rememberMe)
          localStorage.setItem("rememberMe", "true");
        else if (action.payload.form) localStorage.removeItem("rememberMe");
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("user");
        localStorage.removeItem("id");
        localStorage.removeItem("superAdmin");
      }

      return {
        ...state,
        user: action.payload.user,
        id: idx(action.payload),
        accountType: accountType(action.payload.accountType),
        superAdmin: action.payload.superAdmin,
        userInfo: true,
      };
    case "setUserInfo": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "setSelectedEvent":
      return { ...state, selectedEvent: action.payload.selectedEvent };
    case "setEmail":
      return { ...state, email: action.payload.email };
    case "showModal":
      return { ...state, showModal: true, modalComponent: action.payload };
    case "hideModal":
      return { ...state, showModal: false, modalComponent: <></> };
    case "globalModeLast":
      return { ...state, globalModeLast: action.payload };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default useAppReducer;
