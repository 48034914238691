import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const DeleteRegister = async (data: { id: number; participantId: number }) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/coreservice/api/event-registrations/cancel`,
      params: {
        eventId: data.id,
        athleteId: data.participantId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default DeleteRegister;
