import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const GoalsList = async (data: { option: any; activeGoal: string }) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "POST",
      baseURL: `${API_ENDPOINT}/coreservice/api/goal/viewAll`,
      params: {
        athleteId: data.option.value,
        option: data.activeGoal,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default GoalsList;
