import React, { ChangeEvent } from "react";
import styles from "./toggleSwitch.module.scss";

interface Props {
  label: string;
  setCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  checkbox: boolean;
  state: any;
}

const ToggleSwitch = ({ label, setCheckbox, checkbox, state }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (state.detailsMode === "Add") setCheckbox(event.target.checked);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.toggle_switch}>
          <input
            type="checkbox"
            className={styles.checkbox}
            name={label}
            id={label}
            checked={checkbox}
            onChange={handleChange}
          />
          <label className={styles.label} htmlFor={label}>
            <span className={styles.inner} />
            <span className={styles.switch} />
          </label>
        </div>
      </div>
    </>
  );
};

export default ToggleSwitch;
