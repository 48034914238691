const convertArrayToObject = (array: string[], key: any) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export default convertArrayToObject;
