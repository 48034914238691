import { FC } from "react";
import clsx from "clsx";
import styles from "./toolbar.module.scss";
import { getLocalizedMonth } from "../utils";
import { YearCalendar } from "../../year-calendar/year-calendar";
import { ReactComponent as ArrowIcon } from "./arrow.svg";

type Props = {
  date: Date;
  className?: string;
  onPrevMonthClick: () => void;
  onNextMonthClick: () => void;
  onSelectSeason: (season: string) => void;
};

export const Toolbar: FC<Props> = ({
  className,
  date,
  onPrevMonthClick,
  onNextMonthClick,
  onSelectSeason,
}) => {
  return (
    <div className={clsx(className, styles.toolbar, styles.ltr)}>
      <div className={clsx(styles.controls, styles.monthControls)}>
        <button
          type="button"
          aria-label="previuos"
          className={clsx(styles.arrows, styles.prev)}
          onClick={onPrevMonthClick}
        >
          <ArrowIcon />
        </button>

        <h3 className={styles.month}>{getLocalizedMonth(date, "en")}</h3>

        <button
          type="button"
          aria-label="next"
          className={clsx(styles.arrows, styles.next)}
          onClick={onNextMonthClick}
        >
          <ArrowIcon />
        </button>
      </div>

      <div className={clsx(styles.controls, styles.seasonsControls)}>
        <YearCalendar
          valueDate={date}
          onChange={onSelectSeason}
          className={styles.dataCalendar}
          direction="bottom"
          maxDetail="decade"
        />
      </div>
    </div>
  );
};
