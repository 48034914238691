import { FC } from "react";
import { withRouter } from "react-router-dom";
import { Profile } from "../../constants/assets";
import styles from "./account.module.scss";

const Account: FC = ({ history }: any) => {
  const handleClick = () => {
    history.push("/main/management");
  };
  return (
    <div className={styles.account}>
      <button type="button" className={styles.btn} onClick={handleClick}>
        <Profile />
        <span> Account Management </span>
      </button>
    </div>
  );
};

export default withRouter(Account);
