import clsx from "clsx";
import { SetStateAction, useContext } from "react";
import styles from "./menu.module.scss";
import EventDetails from "../upcomingEventsWorkSpace/eventDetails/eventDetails";
import Participants from "../upcomingEventsWorkSpace/registration/participants";
import Involvements from "../upcomingEventsWorkSpace/involvements/involvements";
import Results from "../upcomingEventsWorkSpace/results/results";
import { AppContext } from "../../../../App";
import StateContext from "../../../../context/StateContext";
import {
  AddInvolvement,
  NoResults,
  Plus,
  TiTick,
  X,
} from "../../../../constants/assets";
import DispatchContext from "../../../../context/DispatchContext";
import { Spinner } from "../../../spinner/spinner";

const Menu = () => {
  const { appState } = useContext<any>(AppContext);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { accountType } = appState;
  // const isAthlete = accountType === "athlete";
  const isAdmin = accountType === "admin";
  const { active, showEventLoader } = state;

  const Component = () => {
    switch (active) {
      case "details": {
        if (showEventLoader) {
          return <Spinner className={styles.spinner} />;
        }
        return <EventDetails />;
      }
      case "registration": {
        return <Participants />;
      }
      case "involvements": {
        return <Involvements />;
      }
      default:
        return <Results />;
    }
  };

  const handleClick = (e: {
    currentTarget: { id: SetStateAction<string> };
  }) => {
    dispatch({ type: "setActive", payload: e.currentTarget.id });
    dispatch({ type: "involvementMode", payload: "View" });
    dispatch({ type: "resultsMode", payload: "View" });
  };

  const setAddMode = () => {
    dispatch({ type: "resultsMode", payload: "Add" });
  };

  const handleAddInvolvement = () => {
    dispatch({ type: "involvementMode", payload: "Add" });
  };

  return (
    <div className={styles.menuContainer}>
      <div
        className={clsx(
          styles.block,
          state.displayMenu ? styles.showMenu : styles.hideMenu
        )}
      >
        <div
          className={clsx(
            styles.layoutHeader,
            active === "registration" && styles.setHeight
          )}
        >
          <ul className={styles.ul}>
            <li>
              <div
                role="button"
                aria-hidden="true"
                id="details"
                className={clsx(active === "details" && styles.active)}
                onClick={handleClick}
              >
                <span>Event Details</span>
              </div>
            </li>
            {isAdmin &&
              (state.eventDetails.status !== "DONE" ||
                state.participantCoaches?.length > 0 ||
                state.participantAthletes?.length > 0) && (
                <li>
                  <div
                    role="button"
                    aria-hidden="true"
                    id="registration"
                    className={clsx(active === "registration" && styles.active)}
                    onClick={handleClick}
                  >
                    <span>Registration</span>
                  </div>
                </li>
              )}
            {state.eventDetails.status === "DONE" &&
              state.participantAthletes?.length > 0 && (
                <li>
                  <div
                    role="button"
                    aria-hidden="true"
                    id="results"
                    className={clsx(active === "results" && styles.active)}
                    onClick={handleClick}
                  >
                    <span> Event Result </span>
                  </div>
                </li>
              )}
            {isAdmin &&
              state.eventDetails.status === "DONE" &&
              state.participantCoaches?.length > 0 && (
                <li>
                  <div
                    role="button"
                    aria-hidden="true"
                    id="involvements"
                    className={clsx(active === "involvements" && styles.active)}
                    onClick={handleClick}
                  >
                    <span>Event Involvement</span>
                  </div>
                </li>
              )}
          </ul>

          {state.resultsMode === "View" &&
            active === "results" &&
            state.eventDetails.status === "DONE" && (
              <div
                className={clsx(
                  styles.actions,
                  !state.resultsDetails.length && styles.setElements
                )}
              >
                {!state.resultsDetails.length && <NoResults />}
                <button
                  type="button"
                  className={styles.addBtn}
                  onClick={() => setAddMode()}
                >
                  <Plus />
                  <span>Add Results </span>
                </button>
              </div>
            )}
          {state.eventDetails.status === "CANCELLED" && active === "details" && (
            <div className={styles.cancelRegistered}>
              <X />
              <span> Canceled</span>
            </div>
          )}

          {state.eventDetails.status === "DONE" && active === "details" && (
            <div className={styles.done}>
              <TiTick />
              <span> Done</span>
            </div>
          )}

          {state.involvementMode === "View" &&
            active === "involvements" &&
            state.participantCoaches?.length >
              state.involvementDetails.length &&
            state.eventDetails.status === "DONE" && (
              <div
                className={clsx(
                  styles.actions,
                  !state.involvementDetails.length && styles.setElements
                )}
              >
                {!state.involvementDetails.length && (
                  <AddInvolvement className={styles.placeHolder} />
                )}
                <button
                  type="button"
                  className={styles.addBtn}
                  onClick={handleAddInvolvement}
                >
                  <Plus />
                  <span>Add Involvement</span>
                </button>
              </div>
            )}
        </div>
        <div className={styles.component}>{Component()}</div>
      </div>
    </div>
  );
};

export default Menu;
