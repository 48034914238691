import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const DisableUser = async (data: {
  id: number | any;
  active: string | any;
}) => {
  const activeState = () => {
    if (data.active === "athlete") return "athletes";
    if (data.active === "admin") return "admin";
    if (data.active === "coach") return "coaches";
    return null;
  };

  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "DELETE",
      baseURL: `${API_ENDPOINT}/coreservice/api/${activeState()}/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default DisableUser;
