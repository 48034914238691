import { formatDefaultDate, startingDate } from "../../utils/utils";

export const initState = {
  eventDetails: {
    status: "PENDING",
    startDate: `${formatDefaultDate(startingDate(1))}T09:00:00.050`,
    endDate: `${formatDefaultDate(startingDate(1))}T10:00:00.050`,
    category: { name: "Select the category", value: "" },
    description: "",
    eventUrl: "",
    location: "",
    organizer: "",
    capacity: "",
    age: "All ages",
    ageLowerLimit: 1,
    ageUpperLimit: 99,
  },
  participantAthletes: [],
  involvementDetails: [],
  participantCoaches: [],
  resultsDetails: [],
  detailsMode: "Edit",
  involvementMode: "View",
  resultsMode: "View",
  readOnlyMode: true,
  showBlank: true,
  iniEventsList: false,
  refreshData: null,
  currentComponent: "calendar",
  participantRegistrationStatus: null,
  displayList: true,
  displayMenu: false,
  startingDate: startingDate(0),
  active: "details",
  showEventLoader: false,
};
const reducer = (
  state: { eventDetails: any },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "initTime": {
      return {
        ...state,
        startingDate: startingDate(0),
        eventDetails: {
          ...state.eventDetails,
          startDate: `${formatDefaultDate(startingDate(1))}T09:00:00.050`,
          endDate: `${formatDefaultDate(startingDate(1))}T10:00:00.050`,
        },
      };
    }
    case "involvementMode": {
      return {
        ...state,
        involvementMode: action.payload,
      };
    }
    case "eventDetails":
      return {
        ...state,
        eventDetails: action.payload,
      };
    case "participantAthletes":
      return {
        ...state,
        participantAthletes: action.payload,
      };
    case "participantCoaches":
      return {
        ...state,
        participantCoaches: action.payload,
      };
    case "involvementDetails":
      return {
        ...state,
        involvementDetails: action.payload,
      };
    case "participantRegistrationStatus":
      return {
        ...state,
        participantRegistrationStatus: action.payload,
      };
    case "wipeInvolvementData": {
      return {
        ...state,
        involvementDetails: [],
      };
    }
    case "resultsDetails":
      return {
        ...state,
        resultsDetails: action.payload,
      };
    case "refreshData":
      return {
        ...state,
        refreshData: action.payload,
      };

    case "resultsMode":
      return {
        ...state,
        resultsMode: action.payload,
      };
    case "setActive":
      return {
        ...state,
        active: action.payload,
      };
    case "detailsMode":
      return {
        ...state,
        detailsMode: action.payload,
      };

    case "readOnlyMode":
      return {
        ...state,
        readOnlyMode: action.payload,
      };

    case "resetDetails":
      return {
        ...initState,
      };
    case "iniEventsList":
      return {
        ...state,
        iniEventsList: action.payload,
      };
    case "eventStatus":
      return {
        ...state,
        eventDetails: {
          ...state.eventDetails,
          status: action.payload,
        },
      };

    case "displayList":
      return {
        ...state,
        displayList: action.payload,
      };
    case "showEventLoader":
      return {
        ...state,
        showEventLoader: action.payload,
      };
    case "displayMenu":
      return {
        ...state,
        displayMenu: action.payload,
      };

    default:
      return { ...state };
  }
};

export default reducer;
