import styles from "./pastGoal.module.scss";
import {
  Cup,
  Thumb,
  Medal,
  LargeTick,
  Calories,
  SpeedIcon,
  DistanceIcon,
  DurationIcon,
} from "../../../constants/assets";
import { formatDateSTD, lowerCase } from "../../../utils/utils";
import ActivityIcon from "./icons";

const PastGoal = ({ ...props }: any) => {
  const goal = { ...props.goal };

  const GoalIcon: any = () => {
    switch (goal.goalType) {
      case "CALORIES":
        return <Calories />;
      case "DISTANCE":
        return <DistanceIcon />;
      case "SPEED":
        return <SpeedIcon />;
      case "DURATION":
        return <DurationIcon />;
      default:
        return <></>;
    }
  };

  const LevelIcon: any = () => {
    switch (goal.achievedLevel) {
      case "PERFECT":
        return <Medal />;
      case "GOOD":
        return <Thumb />;
      case "EXCELLENT":
        return <Cup />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className={styles.block}>
        <div className={styles.activity}>
          <div className={styles.activityType}>
            {ActivityIcon(goal.category.name)}
            <span>{goal.category.name}</span>
          </div>
          {goal.status === "ACHIEVED" && (
            <div className={styles.status}>
              <>
                <LargeTick /> <span>Achieved</span>
              </>
            </div>
          )}
        </div>
        <div className={styles.goal}>
          <div className={styles.goalkcal}>
            <span>Goal</span>
            <span>
              {goal.goalValue} {goal.goalUnit}
            </span>
          </div>
          <div className={styles.goalIcon}>
            <GoalIcon />
            <span> {lowerCase(goal.goalType)} </span>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.infoData}>
            <span>Target by</span>
            <span> {formatDateSTD(new Date(goal.targetCompletionDate))}</span>
          </div>

          <div className={styles.infoData}>
            <span>Completed on</span>
            <span>
              {goal.endDate
                ? formatDateSTD(new Date(goal.endDate))
                : "In Progress"}
            </span>
          </div>

          <div className={styles.infoData}>
            <span>
              {goal.daysBeforeTarget}{" "}
              {goal.daysBeforeTarget ? "days before" : ""}{" "}
            </span>
            <div className={styles.cup}>
              {goal.achievedLevel && <LevelIcon />}
              <span> {goal.achievedLevel} </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PastGoal;
