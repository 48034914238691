import { FC } from "react";
import clsx from "clsx";

import styles from "./spinner.module.scss";

type Props = {
  className?: string;
  size?: "large" | "medium" | "small";
};

export const Spinner: FC<Props> = ({ className, size = "medium" }) => (
  <span className={clsx(className, styles.spinner, styles[size])} />
);
