import React, { FC, useRef, useState } from "react";
import Calendar from "react-calendar";
import clsx from "clsx";
import { formatDateYear } from "../../utils/utils";
import { useClickOutside } from "../../hooks/use-click-outside";
import { ArrowDownDefault } from "../../constants/assets";
import "react-calendar/dist/Calendar.css";
import "./year-calendar.overwrite.scss";
import styles from "./year-calendar.module.scss";

type Props = {
  valueDate: Date;
  onChange: (newValue: string) => void;
  className?: string;
  direction?: "left" | "bottom";
  maxDate?: Date;
  flex?: boolean;
  maxDetail?: "year" | "decade";
};

export const YearCalendar: FC<Props> = ({
  valueDate,
  onChange,
  className,
  direction = "bottom",
  maxDate,
  maxDetail = "year",
  flex = true,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const buttonRef = useRef(null);

  useClickOutside(calendarRef, (event) => {
    if (buttonRef.current && event.target !== buttonRef.current) {
      setShowCalendar(false);
    }
  });

  const handleToggleClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateChange = (newValue: Date) => {
    setShowCalendar(false);
    onChange(formatDateYear(newValue));
  };

  return (
    <div className={clsx(styles.wrapper, flex && styles.flex)}>
      <button
        type="button"
        className={clsx(styles.toggle, className)}
        onClick={handleToggleClick}
        ref={buttonRef}
      >
        {formatDateYear(valueDate)}
        <ArrowDownDefault className={styles.calendarIcon} />
      </button>
      {showCalendar && (
        <div
          ref={calendarRef}
          className={clsx(
            "month-calendar",
            styles.calendarWrapper,
            direction === "left" && styles.calendarWrapperLeft,
            direction === "bottom" && styles.calendarWrapperBottom
          )}
        >
          <Calendar
            value={valueDate || new Date()}
            onChange={handleDateChange}
            maxDetail={maxDetail}
            showFixedNumberOfWeeks={true}
            maxDate={maxDate || undefined}
            locale="en"
          />
        </div>
      )}
    </div>
  );
};
