import { SetStateAction, useContext, useEffect, useState } from "react";
import { components } from "react-select";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import styles from "./addGoal.module.scss";
import { AppContext } from "../../../App";
import { Select, SelectOption } from "../../select/select";
import { ArrowDown, GoalModal } from "../../../constants/assets";
import {
  formatDefaultDate,
  startingDate,
  durationToMin,
} from "../../../utils/utils";
import SetGoal from "../../../apis/goals/setGoal";
import EventsCategories from "../../../apis/eventsCategories";
import { CurrentDateCalendar } from "../../current-date-calendar/current-date-calendar";
import toastMsg from "../../../common/toast";

interface IForm {
  id?: number;
  category?: any;
  targetCompletionDate?: any;
  value?: any;
  goalType?: string;
}

const AddGoal = ({ dispatch }: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { appState } = useContext<any>(AppContext);
  const [eventsCategories, setEventsCategories] = useState([]);
  const [selectedGoalType, setSelectedGoalType] = useState<SelectOption>();
  const [selectedCategoryType, setSelectedCategoryType] =
    useState<SelectOption>();
  const [showError, setShowError] = useState(false);
  const [trackingType, setTrackingType] = useState("");
  const [trigger, setTrigger] = useState(true);

  const [form, setForm] = useState<IForm>({
    targetCompletionDate: startingDate(7).setHours(23, 59, 59),
  });

  const [isValid, setIsValid] = useState({
    category: false,
    value: false,
    goalType: false,
  });

  const [goalType] = useState([
    { value: "CALORIES", label: "Calories" },
    { value: "DISTANCE", label: "Distance" },
    { value: "SPEED", label: "Speed" },
    { value: "DURATION", label: "Duration" },
  ]);

  useEffect(() => {
    (async function events() {
      const response: any = await EventsCategories();
      if (response?.data) {
        const categories = response.data.map(
          (item: { id: any; name: any }) => ({
            value: item.id,
            label: `${item.name}`,
          })
        );

        setEventsCategories(categories);
      }
    })();
  }, []);

  const handleSave = () => {
    if (Object.values(isValid).includes(false)) {
      setShowError(true);
      toastMsg("Required fields must be filled in.");
      return;
    }

    const goalValue =
      selectedGoalType?.value === "DURATION"
        ? durationToMin(form.value)?.toString()
        : parseInt(form.value, 10);

    const data = {
      targetCompletionDate: new Date(form.targetCompletionDate).setHours(
        23,
        59,
        59
      ),
      category: {
        id: selectedCategoryType?.value,
      },
      goalType: selectedGoalType?.value,

      goalValue,
      athleteId: appState.id,
    };

    (async function goal() {
      setTrigger(false);
      const response: any = await SetGoal({ data });
      if (response?.data) {
        appDispatch({ type: "hideModal" });
        dispatch({ type: "refreshData", payload: "goals" });
      } else if (response?.response?.data.errorKey === "20043") {
        setShowError(true);
        toastMsg("Could not have more than 10 unfinished goals in total");
      }
      setTrigger(true);
    })();
  };
  const dropdownOption = (dropDownProps: any) => (
    <components.DropdownIndicator {...dropDownProps}>
      <ArrowDown />
    </components.DropdownIndicator>
  );

  const handleInputChange = (e: any) => {
    if (e.name === "targetCompletionDate" && e.value)
      setForm((prev) => ({ ...prev, [e.name]: e.value }));
    else if (e.target.name === "value")
      setForm((prev) => ({
        ...prev,
        [e.target.name]: e.target.value > 0 ? e.target.value : "",
      }));

    if (e.target?.name === "value")
      setIsValid((prev) => ({
        ...prev,
        [e.target.name]: !!(e.target.value.length && e.target.value > 0),
      }));
  };
  const handleChangeGoalType = (
    option: SetStateAction<SelectOption | undefined | any>
  ) => {
    setSelectedGoalType(option);
    setIsValid((prev) => ({ ...prev, goalType: true, value: false }));
    setForm((prev) => ({ ...prev, value: "" }));
    if (option.value === "DISTANCE") setTrackingType("km");
    else if (option.value === "SPEED") setTrackingType("km/h");
    else if (option.value === "DURATION") setTrackingType("h:m");
    else if (option.value === "CALORIES") setTrackingType("kcal");
  };

  const handleChangeCategoryType = (
    option: SetStateAction<SelectOption | undefined>
  ) => {
    setSelectedCategoryType(option);
    setIsValid((prev) => ({ ...prev, category: true }));
  };

  const handleChange = (e: NumberFormatValues) => {
    setForm((prev) => ({ ...prev, value: e.value }));
    setIsValid((prev) => ({
      ...prev,
      value: !!(e.value.length === 4 && parseInt(e.value, 10) > 0),
    }));
  };
  return (
    <>
      <div className={styles.addEditBlock}>
        <>
          <div className={styles.title}>
            <GoalModal />
            <span>Set a Goal</span>
          </div>
          <div className={styles.inputBlocks}>
            {appState.globalModeLast === "goalsAdd" && (
              <ToastContainer
                className={styles.toast}
                position="bottom-right"
              />
            )}
            <form>
              <label> Activity Category </label>

              <div className={clsx(styles.inputBlock, styles.inputActivity)}>
                <div className={styles.inputWrapper}>
                  <Select
                    isSearchable={true}
                    placeholder="Choose a category"
                    className={clsx(
                      styles.dropDown,
                      !isValid.category && showError && styles.errorInput
                    )}
                    name="category"
                    value={selectedCategoryType}
                    options={eventsCategories}
                    components={{ DropdownIndicator: dropdownOption }}
                    onChange={handleChangeCategoryType}
                  />
                </div>
              </div>

              <label> Tracking Type </label>
              <div className={styles.athleteRow}>
                <div className={styles.inputBlock}>
                  <div className={styles.inputWrapper}>
                    <Select
                      // TODO: Detect desktop and allow search for it?
                      isSearchable={false}
                      placeholder="Choose a type"
                      className={clsx(
                        styles.dropDown,
                        !isValid.goalType && showError && styles.errorInput
                      )}
                      name="goalType"
                      value={selectedGoalType}
                      options={goalType}
                      components={{ DropdownIndicator: dropdownOption }}
                      onChange={handleChangeGoalType}
                    />
                  </div>
                </div>

                <div className={clsx(styles.inputBlock, styles.subInput)}>
                  <div
                    className={clsx(
                      styles.inputWrapper,
                      styles.numbersRowInput,
                      !isValid.value && showError && styles.errorInput
                    )}
                  >
                    {selectedGoalType?.value === "DURATION" ? (
                      <NumberFormat
                        readOnly={!trackingType}
                        className={styles.input}
                        name="value"
                        format="## : ##"
                        allowNegative={false}
                        allowLeadingZeros={false}
                        allowEmptyFormatting={true}
                        mask="_"
                        prefix=""
                        value={form.value}
                        onValueChange={(e) => handleChange(e)}
                      />
                    ) : (
                      <NumberFormat
                        className={styles.input}
                        name="value"
                        value={form.value}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        allowEmptyFormatting={true}
                        placeholder="Insert value"
                        onChange={handleInputChange}
                      />
                    )}

                    <span className={styles.measure}>{trackingType}</span>
                  </div>
                </div>
              </div>

              <label> Target Date </label>

              <div className={styles.infoRow}>
                <div className={styles.inputBlock}>
                  <div className={styles.inputWrapper}>
                    <CurrentDateCalendar
                      minDate={startingDate(1)}
                      value={formatDefaultDate(
                        new Date(form.targetCompletionDate)
                      )}
                      onChange={(e) =>
                        handleInputChange({
                          name: "targetCompletionDate",
                          value: e,
                        })
                      }
                      className={clsx(styles.datePicker)}
                      direction="floatLeftTop"
                      type="datePicker"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.notes}>
                <span> *All fields are mandatory</span>
              </div>
            </form>
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={() => appDispatch({ type: "hideModal" })}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className={styles.yesBtn}
              onClick={handleSave}
              disabled={!trigger}
            >
              <span>Save</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default AddGoal;
