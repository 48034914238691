import React, { FC, useRef, useState } from "react";
import Calendar from "react-calendar";
import clsx from "clsx";
import { format } from "date-fns";
import { formatDate, formatDateSTD } from "../../utils/utils";
import { useClickOutside } from "../../hooks/use-click-outside";
import { CalendarIcon } from "../../constants/assets";
import "react-calendar/dist/Calendar.css";
import "./date-calendar.overwrite.scss";
import styles from "./date-calendar.module.scss";

type Props = {
  value: number | string;
  onChange: (newValue: number) => void;
  className?: string;
  direction?:
    | "left"
    | "bottom"
    | "floatLeft"
    | "floatLeftTop"
    | "rightCenter"
    | "leftHalf";
  maxDate?: Date;
  flex?: boolean;
  maxDetail?: "month" | "year";
  minDate?: Date;
  type?: string;
  readOnly?: boolean;
};

export const CurrentDateCalendar: FC<Props> = ({
  value,
  onChange,
  className,
  direction = "bottom",
  maxDate,
  maxDetail = "month",
  flex = true,
  type,
  readOnly = false,
  minDate,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const buttonRef = useRef(null);

  const valueDate = new Date(value);

  useClickOutside(calendarRef, (event) => {
    if (buttonRef.current && event.target !== buttonRef.current) {
      setShowCalendar(false);
    }
  });

  const handleToggleClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateChange = (newValue: Date) => {
    setShowCalendar(false);
    onChange(newValue.getTime());
  };

  return (
    <div className={clsx(styles.wrapper, flex && styles.flex)}>
      <button
        type="button"
        disabled={readOnly}
        className={clsx(
          type === "datePicker" ? styles.toggleDatePicker : styles.toggle,
          className
        )}
        onClick={handleToggleClick}
        ref={buttonRef}
      >
        {type === "datePicker" ? (
          <>
            {formatDateSTD(valueDate)}{" "}
            {!readOnly && (
              <CalendarIcon
                className={clsx(styles.calendarIcon, styles.iconSpacer)}
              />
            )}
          </>
        ) : (
          <>
            <CalendarIcon className={styles.calendarIcon} />{" "}
            {formatDate(valueDate)}
          </>
        )}
      </button>
      {showCalendar && (
        <div
          ref={calendarRef}
          className={clsx(
            "month-calendar",
            styles.calendarWrapper,
            styles[direction]
          )}
        >
          <Calendar
            next2Label={type === "datePicker" ? null : undefined}
            prev2Label={type === "datePicker" ? null : undefined}
            calendarType="US"
            formatShortWeekday={(locale, date: Date) => format(date, "EEEEEE")}
            value={valueDate || new Date()}
            onChange={handleDateChange}
            maxDetail={maxDetail}
            showFixedNumberOfWeeks={true}
            maxDate={maxDate || undefined}
            minDate={minDate || undefined}
            locale="en"
            className={clsx(type === "datePicker" && "datePickerStyle")}
          />
        </div>
      )}
    </div>
  );
};
