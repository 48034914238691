import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { SuccessToast, WarningToast } from "../constants/assets";

const toastMsg = (
  message = "Required fields must be filled in .",
  kind = "error"
) => {
  const toastOptions = {
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    transition: Zoom,
    closeButton: false,
    icon: false,
  };

  if (kind === "success")
    toast.success(
      <div className="successBody">
        <div className="color" />
        <SuccessToast />
        <span>{message}</span>
      </div>,

      {
        ...toastOptions,
        bodyClassName: "toastStyleSuccess",
        className: "toastStyleSuccessBorder",
      }
    );
  else if (kind === "error")
    toast.error(
      <div className="errorBody">
        <div className="color" />
        <WarningToast />
        <span>{message}</span>
      </div>,
      {
        ...toastOptions,
        bodyClassName: "toastStyleError",
        className: "toastStyleErrorBorder",
      }
    );
};

export default toastMsg;
