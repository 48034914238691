/* eslint-disable @typescript-eslint/no-shadow */
import { useContext, useEffect, useState, SetStateAction } from "react";
import DeleteInvolvement from "components/trainingActivities/components/deleteInvolvement";
import AddInvolvement from "apis/trainings/addInvolvement";
import UpdateInvolvement from "apis/trainings/updateInvolvement";
import CoachList from "apis/management/coachList";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import toastMsg from "common/toast";
import CoachInvolvement from "./coachInvolvement";
import StateContext from "../../../../context/StateContext";
import DispatchContext from "../../../../context/DispatchContext";
import { AppContext } from "../../../../App";
import {
  totalDurationToMin,
  totalMinToDuration,
} from "../../../../utils/utils";
import { FormMark, Plus } from "../../../../constants/assets";
import styles from "./involvements.module.scss";

interface IForm {
  id?: number;
  hours: string;
  minutes: string;
  name?: string;
  event?: { category: { name: string } };
}
export interface Option {
  value?: string;
  label?: string;
}

const AdminInvolvements = () => {
  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);

  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { accountType } = appState;
  const [readOnly, setReadOnly] = useState(true);
  const [actions] = useState(["Edit Involvement", "Delete Involvement"]);
  const isCoach = accountType === "coach";
  const isAdmin = accountType === "admin";

  const [showButtons, setShowButtons] = useState(false);
  const [showAddButton, setShowAddButton] = useState<boolean>();
  const [error, setError] = useState(false);
  const [rowID, setRowID] = useState(Number);
  const [selectCoach, setSelectCoach] = useState<Option | any>(null);
  const {
    involvementMode,
    involvementDetails,
    coachRegistersDetails,
    trainingDetails,
  } = state;
  const [coaches, setCoaches] = useState([]);

  const { hours, minutes } = totalMinToDuration(
    involvementDetails[0]?.duration
  );
  const [isValid, setIsValid] = useState({
    hours: false,
    minutes: false,
    athletes: false,
  });
  const [form, setForm] = useState<IForm>({ hours: "", minutes: "" });

  useEffect(() => {
    (async function list() {
      const response: any = await CoachList();
      if (response?.data) {
        const users = response.data.map(
          (item: { id: any; firstName: any; lastName: any }) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          })
        );
        const alreadyAddedCoaches = involvementDetails.map(
          (r: any) => r.coach.id
        );

        setCoaches(
          coachRegistersDetails
            ?.filter(
              (participant: { coach: { id: any }; id: any }) =>
                !alreadyAddedCoaches.includes(participant?.coach?.id)
            )
            .map((participant: { coach: { id: any }; id: any }) =>
              Object.assign(
                users.filter(
                  (coach: { value: any }) =>
                    coach?.value === participant?.coach?.id
                )[0],
                {
                  id: participant?.id,
                }
              )
            )
        );
      }
    })();
  }, [coachRegistersDetails, involvementDetails]);

  useEffect(() => {
    if (involvementMode === "Add") {
      setShowButtons(false);
      setRowID(-1);
      setForm({
        hours: "",
        minutes: "",
        event: { category: { name: trainingDetails.category.name } },
      });
      setIsValid({ hours: false, minutes: false, athletes: false });
      setError(false);
      setSelectCoach(null);
    } else if (involvementMode === "Edit")
      setIsValid({ hours: true, minutes: true, athletes: true });
  }, [involvementMode, involvementDetails]);

  useEffect(() => {
    if (state.involvementDetails.length) {
      dispatch({ type: "involvementMode", payload: "View" });
      setIsValid({ hours: true, minutes: true, athletes: true });
    }
    setShowAddButton(true);

    setForm((prev: any) => ({
      ...prev,
      ...involvementDetails[0],
      hours,
      minutes,
      training: { category: { name: trainingDetails.category.name } },
    }));
  }, [involvementDetails]);

  const handleSave = async () => {
    if (Object.values(isValid).includes(false)) {
      setError(true);
      if (isAdmin || involvementMode === "Add")
        toastMsg("Required fields must be filled in.");
      return;
    }

    const response: any =
      involvementMode === "Add"
        ? await AddInvolvement({
            duration: totalDurationToMin(form.hours, form.minutes),
            coach: {
              id: isCoach ? appState.id : selectCoach?.value,
            },
            training: {
              id: trainingDetails.id,
            },
          })
        : await UpdateInvolvement({
            id: form.id,
            duration: totalDurationToMin(form.hours, form.minutes),
            coach: {
              id: isCoach ? appState.id : selectCoach?.value || selectCoach?.id,
            },
            training: {
              id: trainingDetails.id,
            },
          });
    if (response?.data) {
      setReadOnly(true);
      setShowButtons(false);
      dispatch({ type: "refreshData", payload: null });
      dispatch({ type: "refreshData", payload: "involvements" });
      toastMsg(
        `Involvement is successfully ${
          involvementMode === "Add" ? "added" : "updated"
        }.`,
        "success"
      );
    } else if (response.response.data.errorKey === "20129") {
      setError(true);
    }
  };

  const handleRevert = () => {
    setRowID(-1);
    dispatch({ type: "involvementMode", payload: "View" });

    if (involvementMode === "Add") {
      setShowAddButton(true);
    } else {
      setReadOnly(true);
      setShowButtons(false);
      setForm((prev: any) => ({
        ...prev,
        hours,
        minutes,
      }));
      setIsValid((prev) => ({
        ...prev,
        hours: true,
        minutes: true,
        athletes: true,
      }));
    }
    setError(false);
  };

  useEffect(() => {
    if (
      appState.globalModeLast !== "trainingInvolvementsEdit" &&
      appState.globalModeLast !== "trainingInvolvementsAdd"
    )
      handleRevert();
  }, [appState.globalModeLast]);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setForm((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setIsValid((prev) => ({
      ...prev,
      [e.target.name]: !!e.target.value.length,
    }));
  };

  useEffect(() => {
    if (selectCoach)
      setIsValid((prev) => ({
        ...prev,
        athletes: true,
      }));
  }, [selectCoach]);

  const handleSelectedCoaches = (opt: SetStateAction<Option | null>) => {
    setSelectCoach(opt);
  };

  const handleAdd = () => {
    setReadOnly(false);
    dispatch({ type: "involvementMode", payload: "Add" });
    setShowButtons(true);
    setShowAddButton(false);
    setRowID(-1);
    appDispatch({ type: "globalModeLast", payload: "trainingInvolvementsAdd" });
  };

  const buildFormObject = (item: any) => {
    const { hours, minutes }: any = totalMinToDuration(item.duration);

    item.hours = hours;
    item.minutes = minutes;
    item.name = `${item.coach.firstName} ${item.coach.lastName}`;

    return item;
  };

  const onClick = (event: any, item?: any) => {
    if (event.value === "Edit Involvement") {
      setError(false);
      setReadOnly(false);
      setShowButtons(true);
      setForm(buildFormObject(item));
      setSelectCoach(item.coach);
      dispatch({ type: "involvementMode", payload: "Edit" });
      setRowID(item.id);
      appDispatch({
        type: "globalModeLast",
        payload: "trainingInvolvementsEdit",
      });
    } else if (event.value === "Delete Involvement") {
      const modalComponent = (
        <DeleteInvolvement
          state={item}
          dispatch={dispatch}
          setIsValid={setIsValid}
          setError={setError}
        />
      );
      appDispatch({ type: "showModal", payload: modalComponent });
    }
  };

  return (
    <div
      className={clsx(
        styles.block,
        !state.involvementDetails.length && styles.setHeight
      )}
    >
      <div className={styles.formHeader}>
        <div className={clsx(styles.title)}>
          <FormMark />
          <span>Activity Involvement</span>
        </div>

        {appState.globalModeLast === "trainingInvolvementsAdd" && (
          <ToastContainer className={styles.toast} position="top-left" />
        )}
      </div>
      {involvementMode !== "Add" && involvementDetails?.length === 0 && (
        <p className={styles.noData}>
          There isn&apos;t any involvement for this activity yet.
        </p>
      )}
      {showAddButton &&
        coachRegistersDetails?.length !== involvementDetails?.length && (
          <button
            type="button"
            className={clsx(
              styles.addButton,
              (involvementDetails?.length || involvementMode === "Add") &&
                styles.buttonCustom
            )}
            onClick={handleAdd}
          >
            <Plus />
            <span>Add Involvement</span>
          </button>
        )}
      {involvementMode === "Add" && (
        <>
          <CoachInvolvement
            readOnly={readOnly}
            showDropdown={!showButtons}
            showButtons={!showButtons}
            actions={actions}
            error={error}
            isValid={isValid}
            onSelectCoach={handleSelectedCoaches}
            coach={coaches}
            selectCoach={selectCoach}
            onChange={handleChange}
            onSave={handleSave}
            onRevert={handleRevert}
            onClick={onClick}
            data={form}
            parent="eventsAdmin"
          />
        </>
      )}

      {involvementDetails.map((item: any) => {
        return (
          <>
            <div
              className={clsx(
                rowID !== item.id &&
                  rowID !== -1 &&
                  involvementMode !== "View" &&
                  styles.inActiveBlock
              )}
            >
              {rowID === item.id &&
                appState.globalModeLast === "trainingInvolvementsEdit" && (
                  <>
                    <ToastContainer className={styles.toastPositionEdit} />
                  </>
                )}

              <CoachInvolvement
                readOnly={rowID !== item.id}
                showButtons={rowID === item.id && showButtons}
                actions={actions}
                error={rowID === item.id ? error : false}
                isValid={rowID === item.id ? isValid : {}}
                onChange={handleChange}
                onSave={handleSave}
                onRevert={handleRevert}
                onClick={(e) => onClick(e, item)}
                data={rowID !== item.id ? buildFormObject(item) : form}
                parent="eventsAdmin"
                buttonsBlock={
                  (involvementMode !== "Add" && rowID === item.id) ||
                  involvementMode === "View"
                }
              />
            </div>
          </>
        );
      })}
    </div>
  );
};
export default AdminInvolvements;
