/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useRef } from "react";
import ReactEcharts from "echarts-for-react";

import { roundFloat, toEChartsColor, toEChartsTextStyle } from "../utils";
import { ChartColor, ChartProps, SeriesItem, WeekRange } from "../types";
import { COLOR_TOOLTIP_TEXT, COLOR_WHITE, COLOR_DARK_GRAY } from "../colors";
import { ChartSize, FONT_SIZE_XSS, GradientDirection } from "../constants";
import {
  DEFAULT_CHART_X_AXIS_PHYS,
  DEFAULT_CHART_Y_AXIS_PHYS,
  DEFAULT_CHART_LEGEND,
} from "../defaults";
import { NoData } from "../no-data/no-data";
import {
  NoLegendSelected,
  NoLegendSelectedRef,
} from "../no-legend-selected/no-legend-selected";

import styles from "./line-chart.module.scss";

export type LineChartSeries = SeriesItem<{
  areaColor?: ChartColor;
  shadowColor?: ChartColor;
  shadowBlur?: number;
  shadowOffsetY?: number;
  width?: number;
  yAxisIndex?: number;
  dashed?: boolean;
  seriesName?: string;
}>;

export type LineChartProps = ChartProps<{
  type?: ChartSize;
  units?: string;
  markLineAt?: string;
  yAxis?: { name?: string }[];
  series: (LineChartSeries | undefined)[];
  axisPointerColor?: ChartColor;
  showLegend?: boolean;
  isTime?: boolean;
  titlePrefix?: string;
}>;

interface TooltipValue {
  $vars: string[];
  axisDim: string;
  axisId: string;
  axisIndex: number;
  axisType: string;
  axisValue: string;
  axisValueLabel: string;
  borderColor?: string;
  color: string;
  componentIndex: 0;
  componentSubType: string;
  componentType: string;
  data: string[];
  dataIndex: 1;
  dataType: undefined;
  dimensionNames: string[];
  encode: { x: number[]; y: number[] };
  marker: string;
  name: string;
  seriesId: string;
  seriesIndex: string;
  seriesName: string;
  seriesType: string;
  value: string[];
}

const getGridRightPadding = (type: ChartSize) =>
  type === ChartSize.Big ? 42 : 32;

const findDashedLegend = (name: string, filteredSeries: LineChartSeries[]) => {
  const data = filteredSeries.find((item) => item.name === name);
  if (data) {
    return !!data.dashed;
  }
  return false;
};

export const LineChart = React.forwardRef<ReactEcharts, LineChartProps>(
  (
    {
      type = ChartSize.Small,
      series,
      labels,
      showLegend = false,
      weekRange,
      yAxis,
      markLineAt,
      axisPointerColor,
      titlePrefix,
      isTime = false,
    },
    ref
  ) => {
    const noLegendSelectedRef = useRef<NoLegendSelectedRef | null>(null);
    const filteredSeries: LineChartSeries[] = series
      .filter((el) => el !== undefined)
      .map((el) => el as LineChartSeries);

    const hasData = useMemo(
      () =>
        filteredSeries.some((seriesItem) => {
          return seriesItem.values.filter((item) => item?.value).length > 0;
        }),
      [filteredSeries]
    );

    const onLegendSelectChanged = ({ selected }: any) => {
      const isAllLegendUnSelected = Object.keys(selected).every(
        (k) => !selected[k]
      );
      if (isAllLegendUnSelected && noLegendSelectedRef.current) {
        noLegendSelectedRef.current.show();
      }
      if (!isAllLegendUnSelected && noLegendSelectedRef.current) {
        noLegendSelectedRef.current.hide();
      }
    };

    const onEvents = {
      legendselectchanged: onLegendSelectChanged,
    };

    const height = showLegend ? "120%" : "100%";
    const customTooltipFormatter = (value: TooltipValue[]) => {
      return `
        ${value
          .map(
            (element: TooltipValue) => `
              <div class="${styles.tooltip}">
                ${element.seriesName}: ${parseFloat(element.data[1])}
              </div>`
          )
          .join("")}
          ${titlePrefix || ""} ${weekRange[value[0].axisValue]}`;
    };

    return (
      <div className={styles.wrapper}>
        <ReactEcharts
          ref={ref}
          opts={{ renderer: "canvas" }}
          style={{ width: "100%", height }}
          notMerge={true}
          onEvents={onEvents}
          option={{
            title: {
              show: false,
            },
            tooltip: {
              trigger: hasData && "axis",
              axisPointer: {
                type: "line",
                lineStyle: {
                  color: axisPointerColor,
                },
              },
              backgroundColor: COLOR_WHITE,
              borderColor: COLOR_WHITE,
              extraCssText: "box-shadow: 4px 4px 16px rgba(44, 48, 62, 0.1);",
              textStyle: {
                color: COLOR_TOOLTIP_TEXT,
                fontSize: 12,
                fontFamily: "Montserrat",
              },
              formatter: customTooltipFormatter,
            },
            legend: {
              ...DEFAULT_CHART_LEGEND,
              show: hasData && showLegend,
            },
            grid: {
              top: 22,
              left: 20,
              bottom: type === ChartSize.Big || showLegend ? 45 : 25,
              right: 20,
              containLabel: true,
            },
            xAxis: {
              ...DEFAULT_CHART_X_AXIS_PHYS,
              axisLine: {
                show: false,
              },
              boundaryGap: false,
              axisLabel: {
                ...toEChartsTextStyle(
                  {},
                  {
                    color: "#2C303E",
                    size: type === ChartSize.Big ? 12 : 10,
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }
                ),
                margin: 13,
                formatter(value: string) {
                  return value.charAt(0).toUpperCase() + value.slice(1);
                },
              },
              splitLine: {
                lineStyle: {
                  type: [7, 7],
                  width: 0.5,
                  dashOffset: 5,
                  color: "#C1C5CE",
                },
                show: hasData,
              },
              data: labels,
            },
            yAxis: (yAxis || [{}]).map((axis, index) => {
              return {
                type: "value",
                name: axis.name,
                nameTextStyle: {
                  ...toEChartsTextStyle(
                    {},
                    {
                      color: COLOR_DARK_GRAY,
                      fontWeight: "normal",
                      size: FONT_SIZE_XSS,
                      fontFamily: "Montserrat",
                    }
                  ),
                  align: index > 0 ? "right" : "left",
                  margin: -30,
                },
                axisLabel: {
                  fontWeight: 500,
                  size: 10,
                  fontFamily: "Montserrat",
                  formatter(value: string) {
                    if (axis.name === "Percentage" && parseFloat(value) <= 1) {
                      return `${parseFloat(value) * 100} `;
                    }
                    return `${value} `;
                  },
                },
                splitLine: {
                  ...DEFAULT_CHART_Y_AXIS_PHYS.splitLine,
                  lineStyle: {
                    type: "solid",
                    color: "#E1E1E1",
                  },
                  show: true,
                },
              };
            }),
            series: [
              ...filteredSeries.map((seriesItem) => ({
                markLine: markLineAt
                  ? {
                      silent: true,
                      animation: true,
                      label: {
                        show: false,
                      },
                      lineStyle: {
                        color: COLOR_WHITE,
                        type: "solid",
                      },
                      data: [
                        [
                          {
                            y: "85%",
                            xAxis: markLineAt,
                            symbol:
                              "path://M5.0913 1.5145L3 5L0.908698 1.5145C0.508783 0.847971 0.988896 0 1.76619 0H4.23381C5.0111 0 5.49122 0.847972 5.0913 1.5145Z",
                            symbolKeepAspect: true,
                          },
                          {
                            y: "5%",
                            xAxis: markLineAt,
                            symbol:
                              "path://M5.0913 1.5145L3 5L0.908698 1.5145C0.508783 0.847971 0.988896 0 1.76619 0H4.23381C5.0111 0 5.49122 0.847972 5.0913 1.5145Z",
                            symbolKeepAspect: true,
                          },
                        ],
                      ],
                    }
                  : undefined,
                yAxisIndex: seriesItem.yAxisIndex,
                type: "line",
                symbol: "circle",
                showSymbol: false,
                symbolSize: 10,
                showAllSymbol: "auto",
                itemStyle: {
                  color: seriesItem.color,
                },
                name: seriesItem.name,
                areaStyle: seriesItem.areaColor
                  ? {
                      color: toEChartsColor(
                        seriesItem.areaColor,
                        GradientDirection.TopToBottom
                      ),
                    }
                  : null,
                lineStyle: {
                  width: seriesItem.width ? seriesItem.width : 2,
                  type: seriesItem.dashed ? "dashed" : "solid",
                  shadowColor: seriesItem.shadowColor || null,
                  shadowBlur: seriesItem.shadowBlur || null,
                  shadowOffsetY: seriesItem.shadowOffsetY || null,
                },
                data: hasData
                  ? seriesItem.values.map((item, index) => [
                      labels ? labels[index] : index,
                      roundFloat(isTime ? item?.value / 60 : item?.value),
                    ])
                  : [0], // Just adding one data point to force axis rendering
              })),
            ],
          }}
        />
        {!hasData && <NoData chartSize={type} />}
        <NoLegendSelected chartSize={type} ref={noLegendSelectedRef} />
      </div>
    );
  }
);
