import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useCallback,
} from "react";
import clsx from "clsx";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import validator from "validator";
import { IFormAthlete, Props } from "components/management/types";
import { AppContext } from "../../../../App";
import { Warning, Whistle } from "../../../../constants/assets";
import styles from "./addEditCoach.module.scss";
import UpdateCoach from "../../../../apis/management/updateCoach";
import AddCoach from "../../../../apis/management/addCoach";
import { CurrentDateCalendar } from "../../../current-date-calendar/current-date-calendar";
import { formatDefaultDate } from "../../../../utils/utils";

interface FieldsValidation {
  firstName?: boolean;
  lastName?: boolean;
  parentName?: boolean;
  mobile?: boolean;
  email?: boolean;
}

const AddEditCoach: FC<Props> = ({ mode, data, dispatch }) => {
  const { appDispatch } = useContext<any>(AppContext);
  const [readOnly] = useState(false);
  const [formData, setFormData] = useState<IFormAthlete>({
    createDate: new Date(),
    deleted: false,
  });
  const [isValid, setIsValid] = useState<FieldsValidation>({});
  const [emailMsg, setEmailMsg] = useState(
    "Please input a valid email address"
  );
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (mode === "Edit") {
      setIsValid({
        firstName: true,
        lastName: true,
        parentName: true,
        mobile: true,
        email: true,
      });
      setFormData({
        ...data,
        createDate: !data?.createDate ? new Date() : data.createDate,
      });
    } else {
      setIsValid({
        firstName: false,
        lastName: false,
        parentName: false,
        mobile: false,
        email: false,
      });
    }
  }, []);

  const handleSave = async () => {
    if (Object.values(isValid).includes(false)) {
      setShowError(true);
      return;
    }
    const response: any = await (mode === "Edit"
      ? UpdateCoach({
          data: { ...formData, createDate: new Date(formData.createDate) },
        })
      : AddCoach({
          data: { ...formData, createDate: new Date(formData.createDate) },
        }));
    if (response.status === 200 || response.status === 201) {
      appDispatch({ type: "hideModal" });
      dispatch({ type: "refreshData", payload: "usersList" });
    } else if (
      response.response.data.errorKey === "20302" ||
      response.response.data.errorKey === "10102"
    ) {
      setEmailMsg("The email already exists.");
      setIsValid((prev) => ({ ...prev, email: false }));
      setShowError(true);
      setFormData((prev) => ({ ...prev, email: "" }));
    }
  };

  const handleChangePhone = useCallback((e: NumberFormatValues) => {
    setFormData((prev) => ({ ...prev, mobile: e.value }));
    setIsValid((prev) => ({
      ...prev,
      mobile: e.value.length === 9,
    }));
  }, []);

  const handleInputChange = useCallback(
    (e: ChangeEvent<{ name: string; value: string }>) => {
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      if (e.target.name === "email")
        setIsValid((prev) => ({
          ...prev,
          [e.target.name]: validator.isEmail(e.target.value),
        }));
      else
        setIsValid((prev) => ({
          ...prev,
          [e.target.name]: !!e.target.value.length,
        }));
    },
    []
  );

  const handleDateChange = (e: { name: string; value: number }) => {
    if (e.name === "createDate" && e.value)
      setFormData((prev) => ({ ...prev, [e.name]: e.value }));
  };

  return (
    <>
      <div className={styles.addEditBlock}>
        <>
          <div className={styles.title}>
            <Whistle />
            <span>{`${mode}`} Junior Coach</span>
          </div>
          <div className={styles.inputBlocks}>
            <form>
              <div className={styles.infoRow}>
                <div className={styles.inputBlock}>
                  <div className={styles.inputWrapper}>
                    <input
                      readOnly={readOnly}
                      type="text"
                      name="firstName"
                      value={formData?.firstName}
                      placeholder="*First Name"
                      className={clsx(
                        styles.input,
                        !isValid.firstName && showError && styles.errorInput
                      )}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className={clsx(styles.inputBlock, styles.rightElement)}>
                  <div className={styles.inputWrapper}>
                    <input
                      readOnly={readOnly}
                      type="text"
                      name="lastName"
                      value={formData?.lastName}
                      placeholder="*Last Name"
                      className={clsx(
                        styles.input,
                        !isValid.lastName && showError && styles.errorInput
                      )}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.infoRow}>
                <div className={styles.inputBlock}>
                  <div className={styles.inputWrapper}>
                    <input
                      readOnly={readOnly}
                      type="text"
                      name="parentName"
                      value={formData?.parentName}
                      placeholder="*Parent Name"
                      className={clsx(
                        styles.input,
                        !isValid.parentName && showError && styles.errorInput
                      )}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className={clsx(styles.inputBlock, styles.rightElement)}>
                  <div className={styles.inputWrapper}>
                    {formData.createDate && (
                      <CurrentDateCalendar
                        value={formatDefaultDate(new Date(formData.createDate))}
                        onChange={(e) =>
                          handleDateChange({
                            name: "createDate",
                            value: e,
                          })
                        }
                        className={clsx(styles.datePicker)}
                        direction="rightCenter"
                        type="datePicker"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.infoRow}>
                <div className={styles.inputBlock}>
                  <div className={styles.inputWrapper}>
                    <input
                      readOnly={readOnly}
                      type="text"
                      name="email"
                      value={formData?.email}
                      placeholder="*Email"
                      className={clsx(
                        styles.input,
                        !isValid.email && showError && styles.errorInput
                      )}
                      onChange={handleInputChange}
                    />
                  </div>
                  {showError && !isValid.email && (
                    <div className={styles.errorBlock}>
                      <div className={styles.warningIcon}>
                        <Warning />
                      </div>
                      <div className={styles.errorMessage}>{emailMsg}</div>
                    </div>
                  )}
                </div>

                <div className={clsx(styles.inputBlock, styles.rightElement)}>
                  <div className={styles.inputWrapper}>
                    <NumberFormat
                      readOnly={readOnly}
                      className={clsx(
                        styles.input,
                        !isValid.mobile && showError && styles.errorInput
                      )}
                      id="mobile"
                      name="mobile"
                      format="+971 ## ### ####"
                      mask="_"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      value={formData?.mobile}
                      onValueChange={(e) => handleChangePhone(e)}
                    />
                  </div>
                  {showError && !isValid.mobile && (
                    <div className={styles.errorBlock}>
                      <div className={styles.warningIcon}>
                        <Warning />
                      </div>
                      <div className={styles.errorMessage}>
                        Please input a valid phone number
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={() => appDispatch({ type: "hideModal" })}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className={clsx(
                !Object.values(isValid).includes(false)
                  ? styles.yesBtn
                  : styles.buttonDisabled
              )}
              onClick={handleSave}
            >
              <span>Save</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default AddEditCoach;
