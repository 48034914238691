export const LABEL_ROW_LETTERS_COUNT = 10;

export const FONT_SIZE_XSS = 12;
export const FONT_SIZE_XS = 14;
export const FONT_SIZE_M = 18;

export enum ChartSize {
  Small,
  Big,
}

export enum GradientDirection {
  LeftToRight,
  RightToLeft,
  TopToBottom,
  BottomToTop,
}

export const directionsMap = {
  [GradientDirection.LeftToRight]: [1, 0, 0, 0],
  [GradientDirection.RightToLeft]: [0, 0, 1, 0],
  [GradientDirection.BottomToTop]: [0, 1, 0, 0],
  [GradientDirection.TopToBottom]: [0, 0, 0, 1],
};
