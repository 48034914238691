/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useMemo, useRef, useState } from "react";
import DayPicker from "react-day-picker";
import { ClassNames } from "react-day-picker/types/ClassNames";
import clsx from "clsx";
import { CalendarEvent, CalendarEventsMap } from "./types";
import { Day } from "./day/day";
import { Toolbar } from "./toolbar/toolbar";
import styles from "./calendar.module.scss";
import dayPickerStyles from "./day-picker.module.scss";

type Props = {
  events: CalendarEventsMap;
  selectedMonth: Date;
  className?: string;
  selectedDays: Date[];
  onDayClick: (selectedDay: Date, selectedEvent: CalendarEvent) => void;
  onEventClick: (selectedEvent: CalendarEvent) => void;
  onPrevMonthClick: () => void;
  onNextMonthClick: () => void;
  onSelectSeason: (season: string) => void;
};

export const Calendar = memo<Props>(
  ({
    events,
    selectedMonth,
    className,
    selectedDays,
    onDayClick,
    onNextMonthClick,
    onPrevMonthClick,
    onSelectSeason,
    onEventClick,
  }) => {
    const CaptionElement = useRef(() => null);
    const weekdays = useMemo(
      () => ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      []
    );
    const [status, setStatus] = useState<string>();

    const onDayEventClick = (day: Date) => {
      const [date] = day.toISOString().split("T");
      const event = events[date];
      setStatus(event?.status ?? "NULL");
      onDayClick(day, event);
    };

    const dayPickerClassNames = {
      ...(dayPickerStyles as unknown as ClassNames),
      today: clsx(dayPickerStyles.today, styles.today),
      selected: clsx(dayPickerStyles.selected, styles.selected, {
        [styles.noEvent]: status === "NULL",
        [styles.upcoming]: status === "PENDING",
        [styles.past]: status === "DONE",
        [styles.canceled]: status === "CANCELLED",
      }),
    };

    const renderDay = useCallback(
      (day: Date) => {
        const [date] = day.toISOString().split("T");
        return (
          <div className={styles.cell}>
            <Day
              day={day}
              className={styles.date}
              event={events[date]}
              onEventClick={onEventClick}
            />
          </div>
        );
      },
      [events, onEventClick]
    );

    return (
      <div className={clsx(className, styles.container)}>
        <Toolbar
          date={selectedMonth}
          className={styles.toolbar}
          onPrevMonthClick={onPrevMonthClick}
          onNextMonthClick={onNextMonthClick}
          onSelectSeason={onSelectSeason}
        />

        <DayPicker
          classNames={dayPickerClassNames}
          month={selectedMonth}
          showOutsideDays={true}
          canChangeMonth={false}
          renderDay={renderDay}
          weekdaysShort={weekdays}
          onDayClick={onDayEventClick}
          selectedDays={selectedDays}
          captionElement={CaptionElement.current}
          fixedWeeks={true}
        />
      </div>
    );
  }
);
