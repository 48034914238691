import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const EventsList = async (data: {
  dateFrom: string;
  dateTo: string;
  filter?: string;
}) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/coreservice/api/events`,
      params: {
        from: data.dateFrom,
        to: data.dateTo,
        filter: data.filter,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default EventsList;
