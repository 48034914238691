import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const TrainingCapacity = async (data: { id: number }) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/coreservice/api/trainings/registration/capacity`,
      params: {
        trainingId: data.id,
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default TrainingCapacity;
