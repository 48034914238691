/* eslint-disable react-hooks/exhaustive-deps */
import { memo } from "react";
import clsx from "clsx";
import { useBreakpoints } from "../../../hooks/use-breakpoints";
import { CalendarEvent } from "../types";
import { CalendarEventItem } from "../calendar-event-item/calendar-event-item";
import { formatTime } from "../../../utils/utils";
import { Star } from "../../../constants/assets";
import styles from "./day.module.scss";

type Props = {
  day: Date;
  className?: string;
  event: CalendarEvent;
  onEventClick: (selectedEvent: CalendarEvent) => void;
};

export const Day = memo<Props>(({ day, event, className, onEventClick }) => {
  const { isMobile } = useBreakpoints();
  const dayNumber = day.getDate();
  const hasEvents = event;
  const dateClassName = clsx(className, styles.date);
  const startTime = hasEvents
    ? formatTime(new Date(event?.startDate?.toString().replace("Z", "")))
    : "";
  const endTime = hasEvents
    ? formatTime(new Date(event?.endDate?.toString().replace("Z", "")))
    : "";
  const time = `${startTime} - ${endTime}`;
  const getEventClickHandler = (selectedEvent: CalendarEvent) => () => {
    onEventClick(selectedEvent);
  };

  if (hasEvents && isMobile) {
    return (
      <>
        <div className={dateClassName}>
          {dayNumber}
          {event?.myEvent && <Star />}
        </div>
        <div
          className={clsx(styles.eventIndicator, {
            [styles.upcoming]: event.status === "PENDING",
            [styles.past]: event.status === "DONE",
            [styles.canceled]: event.status === "CANCELLED",
            [styles.visible]: !event.selected,
          })}
        />
      </>
    );
  }

  if (hasEvents && !isMobile) {
    return (
      <>
        {event?.myEvent && <Star className={styles.star} />}
        <div className={dateClassName}>{dayNumber}</div>
        <div className={styles.eventIndicator} />
        <CalendarEventItem
          className={styles.eventItem}
          name={event.eventName}
          time={time}
          status={event.status}
          selected={event.selected}
          onClick={getEventClickHandler(event)}
        />
      </>
    );
  }
  return (
    <>
      <div className={dateClassName}>{dayNumber}</div>
      <div className={styles.eventIndicator} />
    </>
  );
});
