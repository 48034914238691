/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import { SetStateAction, useContext, useEffect, useState } from "react";
import { components } from "react-select";
import clsx from "clsx";
import compareAsc from "date-fns/compareAsc";
import { ToastContainer } from "react-toastify";
import { Timer } from "interfaces/interfaces";
import NumberFormat from "react-number-format";
import { endOfDay, isBefore } from "date-fns";
// import ToggleSwitch from "common/toggleSwitch";
import ToggleSwitch from "common/toggleSwitch";
import MenuActions from "components/menuActions/menuActions";
import RegisterTrainingCoach from "apis/trainings/registerTrainingCoach";
import RegisterTrainingCancelCoach from "apis/trainings/registerTrainingCancelCoach";
import styles from "./details.module.scss";
import {
  FormMark,
  TiTick,
  X,
  Save,
  Edit,
  Cancel,
  Delete,
  ArrowGray,
  Warning,
  Join,
  Info,
} from "../../../constants/assets";
import StateContext from "../../../context/StateContext";
import {
  formatDefaultDate,
  formatTime24,
  removeTimezoneFromISODateString,
  textAreaMaxLength,
  timeProcessor,
} from "../../../utils/utils";
import EventsCategories from "../../../apis/eventsCategories";
import { Select, SelectOption } from "../../select/select";
import UpdateActivityDetails from "../../../apis/trainings/updateActivityDetails";
import InsertActivityDetails from "../../../apis/trainings/insertActivityDetails";
import DispatchContext from "../../../context/DispatchContext";
import { AppContext } from "../../../App";
import CancelDetails from "../components/cancelDetails";
import RegisterTraining from "../../../apis/trainings/registerTraining";
import RegisterTrainingCancel from "../../../apis/trainings/registerTrainingCancel";
import AgeSlider from "../../../common/ageSlider";
import { timeBrackets, dynamicTimeBrackets } from "../../../mocks/timeBrackets";
import { CurrentDateCalendar } from "../../current-date-calendar/current-date-calendar";
import toastMsg from "../../../common/toast";
import DeleteDetails from "../components/deleteDetails";

interface IForm {
  id?: number;
  name?: string;
  location?: string;
  organizer?: string;
  category?: {
    id: number;
    name: string;
  };
  capacity: number | string;
  ageLowerLimit?: number;
  ageUpperLimit?: number;
  startDate?: any;
  endDate?: any;
  description?: string;
  recurrenceEndDate?: any;
  recurrence?: string;
  recurrenceDescription?: string;
}

const REGISTRATION_STATUS_REGISTERED = "REGISTERED";
const REGISTRATION_STATUS_WAITING = "WAITING";

// TODO: Move to utils

const checkDateIncrement = (endTimeNewBracket: any[] | []) => {
  if (endTimeNewBracket.length === 0)
    return [{ value: "00:00", label: "12:00 AM" }];
  return endTimeNewBracket;
};

const dropdownOption = (dropDownProps: any) => (
  <components.DropdownIndicator {...dropDownProps}>
    <ArrowGray />
  </components.DropdownIndicator>
);

const Details = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [checkbox, setCheckbox] = useState(false);
  const { appDispatch } = useContext<any>(AppContext);
  const { appState } = useContext<any>(AppContext);
  const [actions, setActions] = useState([
    "Delete Activity",
    "Cancel Activity",
  ]);

  const initialFormState = JSON.parse(JSON.stringify(state.trainingDetails));
  const [form, setForm] = useState<IForm>(initialFormState);
  const {
    id,
    name,
    location,
    organizer,
    capacity,
    ageLowerLimit,
    ageUpperLimit,
    startDate,
    endDate,
    description,
    recurrence,
    recurrenceDescription,
  } = form;

  const [ageLabel, setAgeLabel] = useState<string>();
  const [showAgeSlider, setShowAgeSlider] = useState(false);

  const [eventsCategories, setEventsCategories] = useState([]);
  const [selectEventCategory, setSelectEventCategory] =
    useState<SelectOption>();

  const [selectStartTimeBracket, setSelectStartTimeBracket] =
    useState<SelectOption>();
  const [selectEndTimeBracket, setSelectEndTimeBracket] =
    useState<SelectOption>();

  const [time, setTime] = useState<Array<Timer>>([]);
  const [timeFilter, setTimeFilter] = useState(timeBrackets);

  const [athleteRegistrationStatus, setAthleteRegistrationStatus] =
    useState(Object);

  const [trainingStatus, setTrainingStatus] = useState(String);
  const [waitingInfo, setWaitingInfo] = useState(String);
  const [readOnly, setReadOnly] = useState(true);
  const [showError, setShowError] = useState(false);

  const [isValid, setIsValid] = useState<any>({
    name: false,
    capacity: false,
    location: false,
    category: false,
    startTime: true,
  });
  const processEndTimeBracket = (startTime: string) => {
    const valueIndex = time.findIndex((i: any) => i.value === startTime) + 1;
    const endTimeNewBracket = time.slice(valueIndex);
    const endDateObject = new Date(endDate);
    const validEndTimeBracket = checkDateIncrement(endTimeNewBracket);

    setTimeFilter(validEndTimeBracket);

    if (
      !validEndTimeBracket.some((t) => t.label === selectEndTimeBracket?.label)
    ) {
      setSelectEndTimeBracket(validEndTimeBracket[0]);
      setForm((prev) => ({
        ...prev,
        endDate: `${formatDefaultDate(
          new Date(
            validEndTimeBracket[0].value === "00:00"
              ? endDateObject.setDate(endDateObject.getDate() + 1)
              : endDateObject
          )
        )}T${validEndTimeBracket[0].value}:00.050`,
      }));
    }
  };

  useEffect(() => {
    switch (trainingStatus) {
      case "PENDING":
        return setActions(["Delete Activity", "Cancel Activity"]);
      case "DONE":
        return setActions([]);
      case "CANCELLED":
        return setActions(["Delete Activity"]);
      default:
        return setActions([]);
    }
  }, [trainingStatus]);

  useEffect(() => {
    if (checkbox) {
      let defaultEndDate = new Date(form.startDate);
      defaultEndDate = new Date(
        defaultEndDate.setDate(defaultEndDate.getDate() + 7)
      );

      setForm((prev) => ({
        ...prev,
        recurrence: checkbox ? "WEEKLY" : "NONE",
        recurrenceEndDate: defaultEndDate,
      }));
      setIsValid((prev: any) => ({
        ...prev,
        recurrenceEndDate: checkbox,
      }));
    }
  }, [checkbox]);

  useEffect(() => {
    (async () => {
      const response: any = await EventsCategories();
      if (response?.data) {
        const categories = response.data.map(
          (item: { id: any; name: any }) => ({
            value: item.id,
            label: `${item.name}`,
          })
        );
        setEventsCategories(categories);
        if (initialFormState?.category.name !== "")
          setSelectEventCategory({
            value: initialFormState?.category?.id,
            label: initialFormState?.category?.name,
          });
        else setSelectEventCategory({ label: "Choose a category" });
      }
    })();

    setForm({
      ...state.trainingDetails,
      startDate: state.trainingDetails.startDate.replace("Z", ""),
      endDate: state.trainingDetails.endDate.replace("Z", ""),
    });
    setTrainingStatus(state.trainingDetails?.status);
    const validateStatus = state.detailsMode !== "Add";
    setIsValid({
      name: validateStatus,
      capacity: validateStatus,
      location: validateStatus,
      category: validateStatus,
      startTime: true,
    });
    setShowError(false);

    setTime(
      dynamicTimeBrackets(
        state.trainingDetails.startDate.replace("Z", ""),
        0,
        state.detailsMode,
        readOnly
      )
    );
    setCheckbox(recurrence === "WEEKLY" && state.detailsMode !== "Add");
  }, [state.trainingDetails]);

  useEffect(() => {
    if (time?.length > 0 && state.detailsMode === "Edit") {
      const { startTimeOption, endTimeOption } = timeProcessor(
        startDate,
        endDate,
        state.detailsMode,
        readOnly
      );
      if (startTimeOption === undefined) {
        setSelectStartTimeBracket(time[0]);
        setSelectEndTimeBracket(endTimeOption);
        processEndTimeBracket(time[0].value);
      } else {
        setSelectStartTimeBracket(startTimeOption);
        setSelectEndTimeBracket(endTimeOption);
      }
    } else if (time?.length > 0 && state.detailsMode === "Add") {
      const addModeDate = time.filter(
        (item: { value: string }) =>
          item.value === formatTime24(new Date(startDate.replace("Z", "")))
      );
      const addModeEndDate = time.filter(
        (item: { value: string }) =>
          item.value === formatTime24(new Date(endDate.replace("Z", "")))
      );
      if (addModeDate.length === 0) {
        setSelectStartTimeBracket(time[0]);
        processEndTimeBracket(time[0].value);
      } else {
        setSelectStartTimeBracket(addModeDate[0]);
        setSelectEndTimeBracket(addModeEndDate[0]);
      }
    }
  }, [form.startDate, time]);

  useEffect(() => {
    if (selectStartTimeBracket !== undefined) {
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(form.startDate))}T${
          selectStartTimeBracket?.value || "00:00"
        }:00.050`,
      }));
    }
  }, [selectStartTimeBracket]);

  useEffect(() => {
    setReadOnly(state.readOnlyMode);
  }, [state.readOnlyMode]);

  useEffect(() => {
    if (state.athleteRegistrationStatus)
      setAthleteRegistrationStatus(state.athleteRegistrationStatus);
  }, [state.athleteRegistrationStatus]);

  useEffect(() => {
    if (state.athleteRegistrationStatus !== REGISTRATION_STATUS_REGISTERED) {
      let info = "";
      if (state.numOfPeopleAhead > 0)
        if (state.numOfPeopleAhead > 1)
          info = `Waiting list: ${state.numOfPeopleAhead} people ahead of you`;
        else
          info = `Waiting list: ${state.numOfPeopleAhead} person ahead of you`;
      else info = "You are next person";
      setWaitingInfo(info);
    }
  }, [state.numOfPeopleAhead]);

  const setSelectedEventCategory = (
    option: SetStateAction<SelectOption | undefined>
  ) => {
    setSelectEventCategory(option);
    setIsValid((prev: any) => ({
      ...prev,
      category: true,
    }));
  };

  const handleInputChange = (
    e: SetStateAction<SelectOption | undefined | any>
  ) => {
    if (e.name === "startTime" && e.value) {
      processEndTimeBracket(e.value);
      setSelectStartTimeBracket(e);
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(startDate))}T${
          e.value
        }:00.050`,
      }));
      setIsValid((prev: any) => ({
        ...prev,
        startTime: true,
      }));
    } else if (e.name === "endTime" && e.value) {
      setSelectEndTimeBracket(e);
      setForm((prev) => ({
        ...prev,
        endDate: `${formatDefaultDate(new Date(endDate))}T${e.value}:00.050`,
      }));
    } else if (e.name === "startDate" && e.value) {
      const startTime = formatTime24(new Date(startDate));
      const endTime = formatTime24(new Date(endDate));
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(e.value))}T${
          startTime || "00:00"
        }:00.050`,
        endDate: `${formatDefaultDate(new Date(e.value))}T${
          endTime || "00:00"
        }:00.050`,
      }));

      if (!isBefore(new Date(e.value), new Date(form.recurrenceEndDate))) {
        setForm((prev) => ({
          ...prev,
          recurrenceEndDate: new Date(e.value),
        }));
      }
      setTime(
        dynamicTimeBrackets(new Date(e.value), 0, state.detailsMode, readOnly)
      );
      setIsValid((prev: any) => ({
        ...prev,
        startTime: true,
      }));
    } else if (e.name === "recurrenceEndDate" && e.value) {
      setForm((prev) => ({
        ...prev,
        recurrenceEndDate: new Date(e.value),
      }));
    } else {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      if (isValid[e.target.name] !== undefined) {
        setIsValid((prev: any) => ({
          ...prev,
          [e.target.name]: !!e.target.value.length,
        }));
      }
    }
  };

  const handleEdit = () => {
    if (readOnly) dispatch({ type: "readOnlyMode", payload: false });
    else {
      (async function details() {
        if (capacity === "0") {
          setForm((prev) => ({ ...prev, capacity: "" }));
          setShowError(true);
          toastMsg("Required fields must be filled in.");
          return;
        }
        if (Object.values(isValid).includes(false)) {
          setShowError(true);
          if (!isValid.startTime)
            toastMsg("The activity time can't be in the past");
          else toastMsg("Required fields must be filled in.");
          return;
        }
        if (!isBefore(new Date(startDate), new Date(endDate))) {
          setShowError(true);
          toastMsg("Activity End Time should be after Start Time");
          return;
        }
        const response: any = await UpdateActivityDetails({
          form: {
            ...form,
            startDate: `${startDate.replace("Z", "")}Z`,
            endDate: `${endDate.replace("Z", "")}Z`,
            category: {
              id: selectEventCategory?.value,
              name: selectEventCategory?.label,
            },
          },
          id,
        });

        if (response?.response?.data?.errorKey === "20103") {
          setShowError(true);
          toastMsg("The activity time can't be in the past");
          setIsValid((prev: any) => ({
            ...prev,
            startTime: false,
          }));
        }

        if (response?.data) {
          dispatch({ type: "readOnlyMode", payload: true });
          dispatch({ type: "iniTrainingList", payload: true });
        }
      })();
    }
  };

  const handleSave = async () => {
    if (capacity === "0") {
      setForm((prev) => ({ ...prev, capacity: "" }));
      setShowError(true);
      toastMsg("Required fields must be filled in.");
      return;
    }
    if (Object.values(isValid).includes(false)) {
      setShowError(true);
      if (!isValid.startTime)
        toastMsg("The activity time can't be in the past");
      else toastMsg("Required fields must be filled in.");
      return;
    }

    if (!isBefore(new Date(startDate), new Date(endDate))) {
      setShowError(true);
      toastMsg("Activity End Time should be after Start Time");
      return;
    }

    const response: any = await InsertActivityDetails({
      ...form,
      startDate: removeTimezoneFromISODateString(startDate),
      endDate: removeTimezoneFromISODateString(endDate),
      recurrenceEndDate: `${formatDefaultDate(
        new Date(form.recurrenceEndDate)
      )}T${"23:59:59"}.050Z`,
      category: {
        id: selectEventCategory?.value,
        name: selectEventCategory?.label,
      },
    });

    if (response?.response?.data?.errorKey === "20103") {
      setShowError(true);
      toastMsg("The activity time can't be in the past");
      setIsValid((prev: any) => ({
        ...prev,
        startTime: false,
      }));
    }

    if (response?.data) {
      const { data } = response;
      dispatch({ type: "iniTrainingList", payload: { id: data.id } });
    }
  };

  const handleAgeChange = ({
    text,
    lower,
    upper,
  }: {
    text: string;
    lower: number;
    upper: number;
  }) => {
    setAgeLabel(text);
    setForm({ ...form, ageLowerLimit: lower, ageUpperLimit: upper });
  };

  const handleCancel = () => {
    const modalComponent = (
      <CancelDetails state={state.trainingDetails} dispatch={dispatch} />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleDelete = () => {
    const modalComponent = (
      <DeleteDetails state={state.trainingDetails} dispatch={dispatch} />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleCancelSave = () => {
    dispatch({ type: "iniTrainingList", payload: true });
  };

  const handleRevert = () => {
    setForm(initialFormState);
    const initialCategory = initialFormState.category;
    setSelectEventCategory({
      value: initialCategory.id,
      label: initialCategory.name,
    });
    dispatch({ type: "readOnlyMode", payload: true });
  };

  const handleRegister = () => {
    (async function register() {
      const response: any =
        appState.accountType === "athlete"
          ? await RegisterTraining({
              registrationStatus: state.trainingSummary.full
                ? "WAITING"
                : "REGISTER",
              athleteId: appState.id,
              trainingId: state.trainingDetails.id,
            })
          : await RegisterTrainingCoach({
              coach: { id: appState.id },
              training: { id: state.trainingDetails.id },
            });

      if (response?.data) {
        dispatch({
          type: "refreshData",
          payload:
            appState.accountType === "athlete" ? "statuses" : "statusesCoach",
        });
        dispatch({ type: "refreshData", payload: "trainingSummary" });
      }
    })();
  };

  const handleCancelRegistration = () => {
    (async function training() {
      const response: any =
        appState.accountType === "athlete"
          ? await RegisterTrainingCancel({
              registrationStatus: state.trainingSummary.full
                ? "WAITING"
                : "REGISTER",
              athleteId: appState.id,
              trainingId: state.trainingDetails.id,
            })
          : await RegisterTrainingCancelCoach({
              coachId: appState.id,
              trainingId: state.trainingDetails.id,
            });

      if (response?.data) {
        dispatch({
          type: "athleteRegistrationStatus",
          payload: "UN_REGISTERED",
        });
        dispatch({ type: "refreshData", payload: "trainingSummary" });
      }
    })();
  };
  const WaitingElements = () => {
    if (athleteRegistrationStatus === REGISTRATION_STATUS_WAITING) {
      return (
        <div className={styles.waitingList} data-tooltip={waitingInfo}>
          <Info />
          <label> {waitingInfo} </label>
        </div>
      );
    }
    return (
      <div className={styles.registered}>
        <TiTick />
        <label> Registered</label>
      </div>
    );
  };
  const handleActionsClick = (e: any) => {
    if (e.value === "Cancel Activity") handleCancel();
    else if (e.value === "Delete Activity") handleDelete();
  };

  const ReadOnlyElements = () => {
    if (readOnly) {
      return <></>;
    }
    return (
      <>
        <div
          role="button"
          aria-hidden="true"
          className={styles.revertBtnSmall}
          onMouseDown={handleRevert}
        >
          <Cancel />
        </div>
        <button
          type="button"
          className={styles.revertBtn}
          onMouseDown={handleRevert}
        >
          <span>Cancel</span>
        </button>
      </>
    );
  };

  const ActionBtnLabel = () => {
    return readOnly ? "Edit" : "Save";
  };

  const Actions = () => {
    if (trainingStatus !== "PENDING") {
      return (
        <>
          {trainingStatus === "CANCELLED" && (
            <div className={styles.cancelRegistered}>
              <X />
              <span> Canceled</span>
            </div>
          )}
          {trainingStatus === "DONE" &&
            athleteRegistrationStatus === REGISTRATION_STATUS_REGISTERED && (
              <div className={styles.registered}>
                <TiTick />
                <label> Registered</label>
              </div>
            )}
        </>
      );
    }
    if (appState.accountType !== "admin") {
      if (
        athleteRegistrationStatus === REGISTRATION_STATUS_REGISTERED ||
        athleteRegistrationStatus === REGISTRATION_STATUS_WAITING
      ) {
        return (
          <div className={styles.registerBlock}>
            <WaitingElements />
            <div
              role="button"
              aria-hidden="true"
              className={styles.cancelRegisterBtnSmall}
              onClick={handleCancelRegistration}
            >
              <Delete />
            </div>
            <button
              type="button"
              className={styles.cancelRegisterBtn}
              onClick={handleCancelRegistration}
            >
              <span>Cancel Registration</span>
            </button>
          </div>
        );
      }
      if (state.trainingSummary.full && appState.accountType === "athlete") {
        return (
          <>
            <div className={styles.warning}>
              <Warning />
              <span>Full Capacity</span>
            </div>
            <div
              role="button"
              aria-hidden="true"
              className={styles.joinBtnSmall}
              onClick={handleRegister}
            >
              <Join />
            </div>
            <button
              type="button"
              className={styles.joinBtn}
              onClick={handleRegister}
            >
              <span>Join Waiting List</span>
            </button>
          </>
        );
      }
      if (compareAsc(new Date(startDate), new Date()) === 1) {
        return (
          <button
            type="button"
            className={styles.registerBtn}
            onClick={handleRegister}
          >
            <span>Register</span>
          </button>
        );
      }
    } else if (state.detailsMode === "Edit") {
      return (
        <>
          <ReadOnlyElements />

          <div
            role="button"
            aria-hidden="true"
            className={clsx(styles.editBtnSmall)}
            onMouseDown={handleEdit}
          >
            {readOnly ? <Edit /> : <Save />}
          </div>

          <button
            type="button"
            className={clsx(styles.editBtn)}
            onMouseDown={handleEdit}
          >
            <span>{ActionBtnLabel()}</span>
          </button>
        </>
      );
    } else
      return (
        <>
          <div
            role="button"
            aria-hidden="true"
            className={styles.cancelBtnAddSmall}
            onMouseDown={handleCancelSave}
          >
            <Cancel />
          </div>

          <button
            type="button"
            className={styles.cancelBtnAdd}
            onMouseDown={handleCancelSave}
          >
            <span>Cancel</span>
          </button>

          <div
            role="button"
            aria-hidden="true"
            className={styles.saveBtnSmall}
            onMouseDown={handleSave}
          >
            <Save />
          </div>

          <button
            type="button"
            className={clsx(styles.saveBtn)}
            onMouseDown={handleSave}
          >
            <span>Save</span>
          </button>
        </>
      );

    return <></>;
  };

  return (
    <>
      <div className={styles.block}>
        <div className={styles.formHeader}>
          <div className={styles.title}>
            <FormMark />
            <span>Activity Details</span>
          </div>
          <div className={styles.actions}>
            {state.detailsMode === "Edit" &&
              appState.accountType === "admin" && (
                <MenuActions onClick={handleActionsClick} actions={actions} />
              )}
            <Actions />
          </div>
        </div>
        <form>
          <div className={styles.formContent}>
            {!readOnly && (
              <ToastContainer
                className={styles.toast}
                position="bottom-right"
              />
            )}

            <div className={styles.inputsBlock}>
              <div className={styles.inputBlock}>
                <label htmlFor="name" className={styles.label}>
                  {/* TODO: Add required attr/prop to inputs that are labeled as such */}
                  *Name
                </label>
                <div className={styles.inputWrapper}>
                  <input
                    readOnly={readOnly}
                    type="text"
                    name="name"
                    value={name}
                    placeholder="Type the activity name"
                    onChange={handleInputChange}
                    className={clsx(
                      styles.input,
                      !isValid.name && showError && styles.errorInput
                    )}
                  />
                </div>
              </div>

              <div className={styles.inputBlock}>
                <label htmlFor="location" className={styles.label}>
                  *Location
                </label>
                <div className={styles.inputWrapper}>
                  <input
                    readOnly={readOnly}
                    type="text"
                    name="location"
                    value={location}
                    placeholder="Type the location"
                    onChange={handleInputChange}
                    className={clsx(
                      styles.input,
                      !isValid.location && showError && styles.errorInput
                    )}
                  />
                </div>
              </div>

              <div className={styles.rowFieldsBlock}>
                <div className={styles.inputBlock}>
                  <label htmlFor="organizer" className={styles.label}>
                    Organizer
                  </label>
                  <div className={styles.inputWrapper}>
                    <input
                      readOnly={readOnly}
                      type="text"
                      name="organizer"
                      placeholder={!readOnly ? "Type the organizer name" : ""}
                      value={organizer}
                      onChange={handleInputChange}
                      className={styles.input}
                    />
                  </div>
                </div>

                <div className={styles.inputBlock}>
                  <label htmlFor="category" className={styles.label}>
                    *Category
                  </label>
                  <div className={styles.inputWrapper}>
                    <Select
                      // TODO: Detect desktop and allow search for it?
                      isSearchable={false}
                      isDisabled={readOnly}
                      className={clsx(
                        styles.selectAthlete,
                        readOnly && [styles.hideArrow, styles.disabledItem],
                        !isValid.category && showError && styles.errorInput
                      )}
                      name="category"
                      value={selectEventCategory}
                      options={eventsCategories}
                      components={{ DropdownIndicator: dropdownOption }}
                      onChange={setSelectedEventCategory}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.rowFields}>
                <div className={styles.inputBlock}>
                  <label htmlFor="capacity" className={styles.label}>
                    *Capacity
                  </label>
                  <div className={styles.inputWrapper}>
                    <NumberFormat
                      className={clsx(
                        styles.input,
                        !isValid.capacity && showError && styles.errorInput
                      )}
                      id="capacity"
                      name="capacity"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      placeholder="Number"
                      value={capacity}
                      readOnly={readOnly}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className={styles.inputBlock}>
                  <label htmlFor="age" className={styles.label}>
                    *Age
                  </label>

                  <div
                    className={clsx(styles.inputWrapper, styles.agePosition)}
                  >
                    <input
                      inputMode="none"
                      readOnly={readOnly}
                      type="text"
                      name="age"
                      value={ageLabel}
                      className={styles.input}
                      onFocus={() => !readOnly && setShowAgeSlider(true)}
                    />
                    <AgeSlider
                      lower={ageLowerLimit}
                      upper={ageUpperLimit}
                      onChange={handleAgeChange}
                      isVisible={showAgeSlider}
                      onClickOutside={() => setShowAgeSlider(false)}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.inputBlock}>
                <label htmlFor="startDate" className={styles.label}>
                  *Start Date
                </label>
                <div className={styles.inputWrapper}>
                  <CurrentDateCalendar
                    minDate={state.startingDate}
                    value={formatDefaultDate(new Date(startDate))}
                    onChange={(e) =>
                      handleInputChange({ name: "startDate", value: e })
                    }
                    className={clsx(styles.input, styles.datePicker)}
                    direction="floatLeft"
                    type="datePicker"
                    readOnly={readOnly}
                  />
                </div>
              </div>

              <div className={clsx(styles.rowFields, styles.rowsFieldsTablet)}>
                <div className={styles.inputBlock}>
                  <label htmlFor="startTime" className={styles.label}>
                    *Start Time
                  </label>
                  <div
                    className={clsx(
                      styles.inputWrapper,
                      styles.inputWrapperTablet,
                      styles.timeField
                    )}
                  >
                    <Select
                      isSearchable={false}
                      isDisabled={readOnly}
                      className={clsx(
                        styles.selectAthlete,
                        readOnly && styles.hideArrow,
                        !isValid.startTime && showError && styles.errorInput
                      )}
                      value={selectStartTimeBracket}
                      name="startTime"
                      options={time}
                      onChange={handleInputChange}
                      components={{ DropdownIndicator: dropdownOption }}
                    />
                  </div>
                </div>

                <div className={styles.inputBlock}>
                  <label
                    htmlFor="endTime"
                    className={clsx(styles.label, styles.labelTablet)}
                  >
                    *End Time
                  </label>
                  <div
                    className={clsx(
                      styles.inputWrapper,
                      styles.inputWrapperTablet,
                      styles.timeField
                    )}
                  >
                    <Select
                      // TODO: Detect desktop and allow search for it?
                      isSearchable={false}
                      isDisabled={readOnly}
                      className={clsx(
                        styles.selectAthlete,
                        readOnly && styles.hideArrow
                      )}
                      value={selectEndTimeBracket}
                      name="endTime"
                      options={timeFilter}
                      onChange={handleInputChange}
                      components={{ DropdownIndicator: dropdownOption }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inputsBlock}>
              {checkbox &&
                state.detailsMode === "Edit" &&
                appState.accountType === "admin" && (
                  <>
                    <div className={styles.recurrenceDescription}>
                      <div>Recurrence</div>
                      <div>
                        <textarea
                          readOnly={readOnly}
                          maxLength={textAreaMaxLength}
                          required={true}
                          name="recurrenceDescription"
                          value={recurrenceDescription}
                          className={clsx(
                            styles.input,
                            readOnly && styles.dimFont
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>

            {state.detailsMode === "Add" && (
              <>
                <div className={clsx(styles.toggle, styles.inputBlock)}>
                  <label className={styles.label}>Recurring</label>
                  <div>
                    <ToggleSwitch
                      label="toggle"
                      setCheckbox={setCheckbox}
                      checkbox={checkbox}
                      state={state}
                    />
                  </div>
                </div>
                <br />
              </>
            )}

            <div className={styles.inputsBlock}>
              {checkbox && state.detailsMode === "Add" && (
                <>
                  <div
                    className={clsx(
                      styles.rowFields,
                      styles.rowsFieldsTablet,
                      styles.customLayout
                    )}
                  >
                    <div className={styles.inputBlock}>
                      <div
                        className={clsx(
                          styles.inputWrapper,
                          styles.inputWrapperTablet,
                          styles.reoccuring
                        )}
                      >
                        <span>Recur every week</span>
                      </div>
                    </div>

                    <div className={styles.inputBlock}>
                      <label
                        htmlFor="recurrenceEndDate"
                        className={styles.label}
                      >
                        End By
                      </label>
                      <div className={styles.inputWrapper}>
                        <CurrentDateCalendar
                          minDate={new Date(form.startDate)}
                          value={formatDefaultDate(
                            new Date(form.recurrenceEndDate)
                          )}
                          onChange={(e) =>
                            handleInputChange({
                              name: "recurrenceEndDate",
                              value: e,
                            })
                          }
                          className={clsx(styles.input, styles.datePicker)}
                          direction="leftHalf"
                          type="datePicker"
                          readOnly={readOnly}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>

        <div className={styles.description}>
          <div>Description</div>
          <div>
            <textarea
              readOnly={readOnly}
              maxLength={textAreaMaxLength}
              required={true}
              name="description"
              placeholder={
                state.detailsMode === "Add" ? "Enter activity description" : ""
              }
              value={description}
              className={clsx(styles.input, readOnly && styles.dimFont)}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
