import {
  differenceInYears,
  format,
  parse,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import { dynamicTimeBrackets } from "mocks/timeBrackets";

const apiDateFormat = "yyyy-MM-dd";
const localDateFormat = "MMM yyyy";
const customDateFormat = "d MMM, yyyy";
const customDateFormatByMonth = "MMM d, yyyy";
const monthDayDateFormat = "d MMMM,";
const yearDateFormat = "yyyy";
const timeFormat = "hh:mm aa";
const defaultDateFormat = "yyyy-MM-dd";
const timeFormat24 = "HH:mm";
const stdDateFormat = "dd/MM/yyyy";
const fullMonthNameYear = "MMMM";
const dateFormatUS = "MM/dd/yyyy";

export const formatFullMonthName: (date: Date) => string = (date) =>
  format(date, fullMonthNameYear);

export const formatDefaultDate: (date: Date) => string = (date) =>
  format(date, defaultDateFormat);

export const formatTime24: (date: Date) => string = (date) =>
  format(date, timeFormat24);

export const formatTime: (date: Date) => string = (date) =>
  format(date, timeFormat);

export const formatDateYear: (date: Date) => string = (date) =>
  format(date, yearDateFormat);

export const formatDateMonthDayByMonth: (date: Date) => string = (date) =>
  format(date, customDateFormatByMonth);

export const formatDateMonthDay: (date: Date) => string = (date) =>
  format(date, monthDayDateFormat);

export const formatDateCustom: (date: Date) => string = (date) =>
  format(date, customDateFormat);

export const formatDate: (date: Date) => string = (date) =>
  format(date, localDateFormat);

export const formatDateAPI: (date: Date) => string = (date) =>
  format(date, apiDateFormat);

export const formatDateUS: (date: Date) => string = (date) =>
  format(date, dateFormatUS);

export const getFormattedStartOfMonth: (date: Date) => string = (date) =>
  format(startOfMonth(date), apiDateFormat);

export const formatDateSTD: (date: Date) => string = (date) =>
  format(date, stdDateFormat);

export const parseDate: (input: string) => Date = (input) =>
  parse(input, apiDateFormat, new Date());

export const getAge: (date: Date) => number = (date) =>
  differenceInYears(new Date(), date);
export type Id = string | number;

export const roundFloat = (
  input?: number,
  decimalPoints = 1
): number | undefined => {
  const multiplier = 10 ** decimalPoints;
  return input !== undefined
    ? Math.round(input * multiplier) / multiplier
    : undefined;
};

export const durationToMin: (input: any) => any = (input) => {
  if (input?.length === 4) {
    const hrs = input.slice(0, 2);
    const min = input.slice(2, 4);

    return parseInt(hrs, 10) * 60 + parseInt(min, 10);
  }
  return 0;
};

export const minToDuration: (input: string | any) => string = (input) => {
  const remMin = parseInt(input, 10) % 60;
  const min = remMin < 10 ? `0${remMin}` : remMin;
  const remHrs = Math.floor(parseInt(input, 10) / 60);
  const hrs = remHrs < 10 ? `0${remHrs}` : remHrs;
  return `${hrs}h : ${min}m`;
};

export const totalDurationToMin: (hours: string, minutes: string) => any = (
  hours,
  minutes
) => {
  const hrs = hours;
  const min = minutes;

  return parseInt(hrs, 10) * 60 + parseInt(min, 10);
};

export const totalMinToDuration: (duration: string) => {
  hours: string | number;
  minutes: string | number;
} = (duration) => {
  const remMin = parseInt(duration, 10) % 60;
  const min = remMin < 10 ? `0${remMin}` : remMin;
  const remHrs = Math.floor(parseInt(duration, 10) / 60);
  const hrs = remHrs < 10 ? `0${remHrs}` : remHrs;
  return { hours: hrs, minutes: min };
};

export function getFormattedEdgeDaysOfMonth(
  date: Date,
  dateFormat = apiDateFormat
) {
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);

  return {
    dateFrom: format(startDate, dateFormat),
    dateTo: format(endDate, dateFormat),
  };
}
export const startingDate: (input: number | any) => Date = (input) => {
  let minDate = new Date();
  minDate = new Date(minDate.setDate(minDate.getDate() + input));
  return minDate;
};

export const startEndDate: (
  input: number | any,
  incHours: number,
  incMin: number
) => Date = (input, incHours, incMin) => {
  let minDate = new Date();
  minDate = new Date(minDate.setDate(minDate.getDate() + input));

  minDate.setHours(minDate.getHours() + incHours);
  minDate.setMinutes(minDate.getMinutes() + incMin);

  return minDate;
};

export const getEndOfMonth: (date: Date) => string = (date) => {
  const endDate = endOfMonth(date);
  return formatDateAPI(endDate);
};

export const getStartOfMonth: (date: Date) => Date = (date) => {
  return startOfMonth(date);
};

export const lowerCase: (string: string) => string = (string) => {
  return string.replace(/\S*/g, (word: string) => {
    return word.charAt(0) + word.slice(1).toLowerCase();
  });
};

export const roundTimeQuarterHour = (time: Date) => {
  const timeToReturn = new Date(time);
  timeToReturn.setMilliseconds(
    Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000
  );
  timeToReturn.setSeconds(Math.ceil(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
  timeToReturn.setHours(Math.ceil(timeToReturn.getHours()));

  return timeToReturn;
};

export const removeTimezoneFromISODateString = (dateString: string) =>
  `${dateString.replace("Z", "")}Z`;

export const textAreaMaxLength = 255;

export const timeProcessor = (
  startDate: string,
  endDate: string,
  detailsMode: string,
  readOnly: boolean
) => {
  const tempDateStart = new Date(startDate.replace("Z", ""));
  const tempDateEnd = new Date(endDate.replace("Z", ""));
  const startTimeOption = dynamicTimeBrackets(
    tempDateStart,
    0,
    detailsMode,
    readOnly
  ).filter(
    (item) => item.value === formatTime24(new Date(startDate.replace("Z", "")))
  )[0];
  const endTimeOption = dynamicTimeBrackets(
    tempDateEnd,
    0,
    detailsMode,
    readOnly
  ).filter(
    (item) => item.value === formatTime24(new Date(endDate.replace("Z", "")))
  )[0];

  return { startTimeOption, endTimeOption };
};
