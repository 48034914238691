const initialValues = {
  user: "",
  accountType: "",
  rememberMe: "",
  id: "",
  email: "",
  showModal: false,
  userInfo: false,
};

export default initialValues;
