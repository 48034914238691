import axios from "axios";
import { API_ENDPOINT } from "../../constants/const";

const Logout = async () => {
  const token = localStorage.getItem("token");

  try {
    const user = localStorage.getItem("rememberMe");
    return await axios({
      method: "POST",
      baseURL: `${API_ENDPOINT}/authservice/api/user/logout`,
      params: { email: user },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default Logout;
