import { FC, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import { IFormAdmin } from "components/management/types";
import styles from "./dataGrid.module.scss";
import { User, PencilIcon, UserAdd } from "../../../../../constants/assets";
import { AppContext } from "../../../../../App";
import StateContext from "../../../../../context/StateContext";
import AddEditAdmin from "../../addEditAdmin/addEditAdmin";
import AthletesList from "../../../../../apis/athletesList";
import DispatchContext from "../../../../../context/DispatchContext";
import AdminList from "../../../../../apis/management/adminList";
import CoachList from "../../../../../apis/management/coachList";

import UserStatus from "../../userStatus/userStatus";
import AddEditAthlete from "../../addEditAthlete/addEditAthlete";
import { formatDateSTD } from "../../../../../utils/utils";
import AddEditCoach from "../../addEditCoach/addEditCoach";

const DataGrid: FC = () => {
  const { appDispatch } = useContext<any>(AppContext);

  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [results, setResults] = useState([]);

  const currentAPI = () => {
    if (state.active === "admin") return AdminList();
    if (state.active === "athlete") return AthletesList();
    if (state.active === "coach") return CoachList();
    return null;
  };

  const getData = async () => {
    const response: any = await currentAPI();
    if (response.status === 200) {
      setResults(
        state.active === "admin" ? response.data.content : response.data
      );
      dispatch({ type: "refreshData", payload: null });
    }
  };
  useEffect(() => {
    setResults([]);
    getData();
  }, [state.active]);

  useEffect(() => {
    getData();
  }, [state.refreshData]);

  const handleToggleDelete = (id: number, disabled: boolean) => {
    const modalComponent = (
      <UserStatus
        userId={id}
        disabled={disabled}
        state={state}
        dispatch={dispatch}
      />
    );

    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const modal = (data: IFormAdmin | undefined) => {
    if (state.active === "admin")
      return <AddEditAdmin data={data} mode="Edit" dispatch={dispatch} />;
    if (state.active === "athlete")
      return <AddEditAthlete data={data} mode="Edit" dispatch={dispatch} />;
    return <AddEditCoach data={data} mode="Edit" dispatch={dispatch} />;
  };
  const handleEdit = (data: any) => {
    const modalComponent = modal(data);

    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const disabledDeleted = (disabled: boolean, deleted: boolean) =>
    disabled || deleted;

  return (
    <>
      <div className={styles.block}>
        <div
          className={clsx(
            styles.formHeader,
            results.length && styles.setHeight
          )}
        >
          {results && results.length > 0 ? (
            <>
              <div className={styles.gridHeader}>
                {state[state.active]?.header.map((item: any) => {
                  return <span key={item.id}>{item}</span>;
                })}
              </div>

              {results.map((result: any, index) => {
                return (
                  <div
                    className={clsx(
                      styles.athleteDetailsBlock,
                      disabledDeleted(result.disabled, result.deleted) &&
                        styles.dimRowBg
                    )}
                    key={result.id}
                  >
                    <div className={styles.athleteInfo}>
                      <div className={clsx(styles.avatarName)}>
                        <div
                          className={clsx(
                            disabledDeleted(result.disabled, result.deleted) &&
                              styles.dimRowId
                          )}
                        >
                          <span> {index + 1} </span>
                        </div>
                      </div>

                      <div className={styles.athleteActions}>
                        {!disabledDeleted(result.disabled, result.deleted) && (
                          <div
                            role="button"
                            aria-hidden="true"
                            className={styles.edit}
                            onClick={() => handleEdit(result)}
                          >
                            <PencilIcon />
                          </div>
                        )}

                        <div
                          role="button"
                          aria-hidden="true"
                          className={styles.delete}
                          onClick={() =>
                            handleToggleDelete(
                              result.id,
                              disabledDeleted(result.disabled, result.deleted)
                            )
                          }
                        >
                          {disabledDeleted(result.disabled, result.deleted) ? (
                            <UserAdd />
                          ) : (
                            <User />
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        styles.athleteDetails,
                        disabledDeleted(result.disabled, result.deleted) &&
                          styles.dimRow
                      )}
                    >
                      <div />
                      {state.active === "admin" ? (
                        <div>
                          <span> Coach Name</span>
                          <span>{result.name}</span>
                        </div>
                      ) : (
                        <>
                          <div>
                            <span>
                              {" "}
                              {state.active === "athlete"
                                ? "Athlete"
                                : "Coach"}{" "}
                              Name
                            </span>
                            <span>
                              {" "}
                              {result.firstName} {result.lastName}
                            </span>
                          </div>
                          <div>
                            <span> Parent Name</span>
                            <span> {result.parentName}</span>
                          </div>
                        </>
                      )}

                      <div>
                        <span> Email</span>
                        <span> {result.email}</span>
                      </div>
                      <div>
                        <span> Phone Number</span>
                        <span>
                          <NumberFormat
                            disabled={true}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            format="+971 ## ### ####"
                            value={result.mobile}
                          />
                        </span>
                      </div>

                      {(state.active === "athlete" ||
                        state.active === "coach") && (
                        <div>
                          <span> Date Submitted</span>
                          {result.createDate && (
                            <span>
                              {" "}
                              {formatDateSTD(new Date(result.createDate))}
                            </span>
                          )}
                        </div>
                      )}

                      <div />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default DataGrid;
