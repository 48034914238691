import { addMonths, setYear, subMonths, isSameDay } from "date-fns";
import { useContext, useCallback, useEffect, useState } from "react";
import { components, DropdownIndicatorProps, OptionProps } from "react-select";
import { useDidMount } from "rooks";
import DispatchContext from "../../../context/DispatchContext";
import styles from "./event-calendar.module.scss";
import { AppContext } from "../../../App";
import {
  Plus,
  ArrowDownDefault,
  Star,
  DoneIcon,
} from "../../../constants/assets";
import { Calendar } from "../../calendar/calendar";
import { EventInfo } from "../components/event-info/event-info";
import { EventInfoPlaceholder } from "../components/event-info-placeholder/event-info-placeholder";
import { Select, SelectOption } from "../../select/select";
import { CalendarEvent, CalendarEventsMap } from "../../calendar/types";
import { getFormattedEdgeDaysOfMonth } from "../../../utils/utils";
import EventDetails from "../components/addEventDetails";
import StateContext from "../../../context/StateContext";
import EventsList from "../../../apis/events/eventsList";
import GetCoachRegisterStatus from "../../../apis/events/getCoachRegisterStatus";
import GetRegisterStatus from "../../../apis/events/getRegisterStatus";
import EventRegistration from "../components/eventRegistration";
import CancelRegistration from "../components/cancelRegistration";

const EventCalendar = () => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { appState, appDispatch } = useContext<any>(AppContext);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const [events, setEvents] = useState<CalendarEvent[]>();
  const [eventsType] = useState([
    { value: "ALL_ACTIVITIES", label: "All Events" },
    { value: "MY_ACTIVITIES", label: "My Events" },
  ]);
  const [selectedEventType, setSelectedEventType] = useState<SelectOption>(
    eventsType[0]
  );

  const getEventsMap = () => {
    if (!events) {
      return Object.create(null) as CalendarEventsMap;
    }
    const initialValue = {};
    return events.reduce<CalendarEventsMap>((obj, item: CalendarEvent) => {
      const { id, startDate } = item;
      const day = new Date(startDate);
      const [date] = day.toISOString().split("T");
      item.selected = selectedEvent ? selectedEvent.id === id : false;
      obj[date] = item;
      return obj;
    }, initialValue);
  };

  const handleEvents = () => {
    appDispatch({
      type: "setSelectedEvent",
      payload: { selectedEvent },
    });
    appDispatch({ type: "globalRender", payload: "upcomingEvents" });
  };

  const getEventsList = async (
    setTodaysDate = false,
    date: Date = currentMonth
  ) => {
    const { dateFrom, dateTo } = getFormattedEdgeDaysOfMonth(date);
    const response: any = await EventsList({
      dateFrom,
      dateTo,
      filter: selectedEventType.value,
    });
    if (response?.data) {
      setEvents(response.data);
      if (setTodaysDate) {
        const todaysEvent = response.data.find((event: CalendarEvent) =>
          isSameDay(new Date(event.startDate), new Date())
        );
        setSelectedEvent(todaysEvent);
      }
    }
  };

  const getRegistrationStatus = async (id: number) => {
    const response: any =
      appState.accountType === "athlete"
        ? await GetRegisterStatus({
            id,
            participantId: appState.id,
          })
        : await GetCoachRegisterStatus({
            id,
            participantId: appState.id,
          });
    if (response?.data) {
      dispatch({
        type: "participantRegistrationStatus",
        payload: response.data.status,
      });
    }
    dispatch({ type: "refreshData", payload: null });
  };

  useEffect(() => {
    if (selectedEvent) getRegistrationStatus(selectedEvent.id);
  }, [selectedEvent]);

  const handleClickAddNewEvent = () => {
    const modalComponent = (
      <EventDetails
        state={state}
        dispatch={dispatch}
        position="rightFullHeight"
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const addRegistration = () => {
    const modalComponent = (
      <EventRegistration
        state={selectedEvent}
        participantId={appState.id}
        participantType={appState.accountType}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const cancelRegistration = () => {
    const modalComponent = (
      <CancelRegistration
        state={selectedEvent}
        participantId={appState.id}
        participantType={appState.accountType}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const renderRegistration = () => {
    if (
      selectedEvent?.status === "CANCELLED" ||
      (selectedEvent?.status === "DONE" &&
        state.participantRegistrationStatus !== "REGISTERED")
    )
      return null;

    if (state.participantRegistrationStatus === "REGISTERED") {
      return (
        <div className={styles.cancelRegistration}>
          <div className={styles.registered}>
            <DoneIcon />
            Registered
          </div>
          {selectedEvent?.status === "PENDING" && (
            <button type="button" onClick={cancelRegistration}>
              Cancel Registration
            </button>
          )}
        </div>
      );
    }
    return (
      <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        className={styles.registerBtn}
        onClick={addRegistration}
      >
        <button type="button">Register</button>
      </div>
    );
  };

  const handleEventClick = useCallback((event: CalendarEvent) => {
    if (event.selected) {
      setSelectedEvent(null);
      return;
    }

    setSelectedEvent(event);
  }, []);

  const handlePrevMonthClick = () => {
    setCurrentMonth((date) => subMonths(date, 1));
  };

  const handleNextMonthClick = () => {
    setCurrentMonth((date) => addMonths(date, 1));
  };

  const handleSelectSeason = (season: string) => {
    setCurrentMonth((date) => setYear(date, Number(season)));
  };

  const handleDayClick = useCallback(
    (selectedDay: Date, event: CalendarEvent) => {
      setSelectedDays([selectedDay]);
      setSelectedEvent(event);
    },
    [selectedEvent]
  );

  useDidMount(() => {
    if (selectedEvent) {
      setSelectedDays([new Date(selectedEvent.startDate)]);
    }
  });

  const handleDateChange = useCallback(
    (date: Date) => {
      getEventsList(true, date);
    },
    [selectedEventType]
  );

  useEffect(() => {
    getEventsList();
  }, [state.participantRegistrationStatus]);

  useEffect(() => {
    if (!appState.showModal) handleDateChange(currentMonth);
  }, [currentMonth, handleDateChange, selectedEventType]);

  useEffect(() => {
    if (!appState.showModal && appState.accountType === "admin")
      handleDateChange(currentMonth);
  }, [appState.showModal]);

  const renderEventsContent = () => {
    if (!selectedEvent) {
      return <EventInfoPlaceholder />;
    }

    if (selectedEvent) {
      return (
        <>
          <EventInfo event={selectedEvent} />
          {appState.accountType === "admin" ? (
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className={styles.eventManagement}
              onClick={handleEvents}
            >
              <button type="button">Event Management</button>
            </div>
          ) : (
            renderRegistration()
          )}
        </>
      );
    }
    return false;
  };

  const dropdownOption = (dropDownProps: DropdownIndicatorProps<any>) => (
    <components.DropdownIndicator {...dropDownProps}>
      <ArrowDownDefault />
    </components.DropdownIndicator>
  );

  const EventCustomOption = ({ children, ...props }: OptionProps<any>) => (
    <components.Option {...props}>
      {children}
      {props.label === "My Events" && <Star className={styles.star} />}
    </components.Option>
  );

  const handleChangeEventType = (option: SelectOption) => {
    if (option.value !== selectedEventType.value) setSelectedEventType(option);
  };

  return (
    <div className={styles.container}>
      <div className={styles.events}>
        <div className={styles.function}>
          {appState.accountType === "admin" ? (
            <button type="button" onClick={handleClickAddNewEvent}>
              Add New Event <Plus />
            </button>
          ) : (
            <div className={styles.selectActivity}>
              <Select
                className={styles.selectActivity}
                name="activities"
                placeholder=""
                value={selectedEventType}
                options={eventsType}
                components={{
                  DropdownIndicator: dropdownOption,
                  Option: EventCustomOption,
                }}
                onChange={handleChangeEventType}
              />
            </div>
          )}
        </div>
        <div className={styles.separator} />
        {renderEventsContent()}
      </div>
      <div className={styles.calendar}>
        <Calendar
          events={getEventsMap()}
          selectedDays={selectedDays}
          selectedMonth={currentMonth}
          onEventClick={handleEventClick}
          onPrevMonthClick={handlePrevMonthClick}
          onNextMonthClick={handleNextMonthClick}
          onSelectSeason={handleSelectSeason}
          onDayClick={handleDayClick}
        />
      </div>
    </div>
  );
};

export default EventCalendar;
