import {
  Cycling,
  Running,
  Swimming,
  Boxing,
  Yoga,
  Rugby,
  Gymnastics,
  Basketball,
  OthersIcon,
} from "../../../constants/assets";

const ActivityIcon = (type: string) => {
  switch (type) {
    case "Running":
      return <Running />;
    case "Swimming":
      return <Swimming />;
    case "Cycling":
      return <Cycling />;
    case "Boxing":
      return <Boxing />;
    case "Yoga":
      return <Yoga />;
    case "Rugby":
      return <Rugby />;
    case "Gymnastics":
      return <Gymnastics />;
    case "Basketball":
      return <Basketball />;
    case "Others":
      return <OthersIcon />;
    default:
      return <OthersIcon />;
  }
};

export default ActivityIcon;
