import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./upcomingEventsList.module.scss";
import {
  LeftArrow,
  RightOpenArrow,
  CalendarIcon,
} from "../../../../constants/assets";
import {
  formatFullMonthName,
  formatDateYear,
  formatDateCustom,
  formatTime,
  getFormattedEdgeDaysOfMonth,
} from "../../../../utils/utils";
import { CalendarEvent } from "../../../calendar/types";
import EventsList from "../../../../apis/events/eventsList";
import GetEvent from "../../../../apis/events/getEvent";
import DispatchContext from "../../../../context/DispatchContext";
import StateContext from "../../../../context/StateContext";
import GetRegisters from "../../../../apis/events/getRegisters";
import GetCoachRegisters from "../../../../apis/events/getCoachRegisters";
import { AppContext } from "../../../../App";
import GetResults from "../../../../apis/events/getResults";
import GetInvolvement from "../../../../apis/events/getInvolvement";

const UpcomingEventsList = () => {
  const [selectedEvent, setSelectedEvent] = useState<number>();
  const [eventsList, setEventsList] = useState<CalendarEvent[]>([]);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);
  const selectedEventDate = new Date(appState.selectedEvent?.startDate);
  const refs = eventsList.reduce((obj: any, value) => {
    obj[value.id] = React.createRef() as React.RefObject<HTMLDivElement>;
    return obj;
  }, {});
  const getEvent = async (id: number) => {
    const response: any = await GetEvent({ id });
    if (response?.data) {
      dispatch({ type: "eventDetails", payload: response.data });
    }

    dispatch({ type: "refreshData", payload: null });
  };

  const getRegisters = async (id: number) => {
    const response: any = await GetRegisters({
      id,
      status: appState.accountType === "admin" ? "REGISTER" : "WAITING",
    });
    if (response?.data) {
      dispatch({ type: "participantAthletes", payload: response.data });
    }
    dispatch({ type: "refreshData", payload: null });
  };

  const getResults = async (id: number) => {
    const response: any = await GetResults({ id });
    if (response?.data) {
      dispatch({ type: "resultsDetails", payload: response.data });
    }
    dispatch({ type: "refreshData", payload: null });
  };

  const getCoachRegisters = async (id: number) => {
    const response: any = await GetCoachRegisters({ id });
    if (response?.data) {
      dispatch({ type: "participantCoaches", payload: response.data });
    }
    dispatch({ type: "refreshData", payload: null });
  };

  const getInvolvement = async (id: number) => {
    const response: any = await GetInvolvement(id);
    if (response?.data) {
      dispatch({ type: "involvementDetails", payload: response.data });
    }
    dispatch({ type: "refreshData", payload: null });
  };

  const workSpaceData = async (id: any) => {
    dispatch({ type: "showEventLoader", payload: true });
    await Promise.all([
      getEvent(id),
      getRegisters(id),
      getCoachRegisters(id),
      getResults(id),
      getInvolvement(id),
    ]);
    dispatch({ type: "showEventLoader", payload: false });
  };

  const handleClick = (e: { currentTarget: { id: string } }) => {
    setSelectedEvent(parseInt(e.currentTarget.id, 10));
    dispatch({ type: "detailsMode", payload: "Edit" });
    dispatch({ type: "resultsMode", payload: "View" });
    dispatch({ type: "readOnlyMode", payload: true });
    dispatch({ type: "displayList", payload: false });
    dispatch({ type: "displayMenu", payload: true });
    dispatch({ type: "setActive", payload: "details" });
    workSpaceData(e.currentTarget.id);
  };

  const getEventsList = async (flag = "noDelete") => {
    const { dateFrom, dateTo } = getFormattedEdgeDaysOfMonth(selectedEventDate);
    const response: any = await EventsList({ dateFrom, dateTo });
    if (response?.data?.length > 0) {
      const selectedEventId =
        flag === "noDelete"
          ? selectedEvent ?? appState.selectedEvent?.id
          : response.data[0].id;
      setEventsList(response.data);
      setSelectedEvent(selectedEventId);
      workSpaceData(selectedEventId);
      dispatch({ type: "detailsMode", payload: "Edit" });
      dispatch({ type: "readOnlyMode", payload: true });
      dispatch({ type: "resultsMode", payload: "View" });
      dispatch({ type: "iniEventsList", payload: false });
    } else {
      setEventsList([]);
      appDispatch({ type: "globalRender", payload: "calendar" });
    }
  };
  useEffect(() => {
    getEventsList();
  }, []);
  useEffect((): any => {
    switch (state.refreshData) {
      case "event":
        return getEvent(state.eventDetails.id);
      case "participants": {
        getRegisters(state.eventDetails.id);
        return getCoachRegisters(state.eventDetails.id);
      }
      case "results":
        return getResults(state.eventDetails.id);
      case "involvements":
        return getInvolvement(state.eventDetails.id);
      case "eventsList":
        return getEventsList();
      case "eventsListAfterDelete":
        return getEventsList("Delete");

      default:
        return <></>;
    }
  }, [state.refreshData]);

  useEffect(() => {
    if (state.iniEventsList) getEventsList();
  }, [state.iniEventsList]);

  useEffect(() => {
    if (selectedEvent) {
      const selectedRef = refs[selectedEvent];
      selectedRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    return () => dispatch({ type: "resetDetails" });
  }, [eventsList, selectedEvent]);

  const handleCalendarView = () => {
    dispatch({ type: "resetDetails" });
    appDispatch({ type: "globalRender", payload: "calendar" });
  };
  return (
    <>
      <div
        className={clsx(
          styles.upcomingEventsListContainer,
          !state.displayList && styles.hideList
        )}
      >
        <div className={styles.block}>
          <div className={styles.header}>
            <div className={styles.title}>
              <LeftArrow onClick={handleCalendarView} />
              <span>Events</span>
            </div>
            <div className={styles.date}>
              <span> {formatFullMonthName(selectedEventDate)} </span>
              <span> {formatDateYear(selectedEventDate)} </span>
            </div>
          </div>

          <div className={styles.items}>
            {eventsList && eventsList.length > 0 && (
              <>
                {eventsList.map((event: any) => {
                  return (
                    <div
                      role="button"
                      aria-hidden="true"
                      ref={refs[event.id]}
                      key={event.id}
                      id={event.id}
                      className={clsx(
                        styles.item,
                        selectedEvent === event.id && styles.itemSelected
                      )}
                      onClick={handleClick}
                    >
                      <div className={styles.itemInfo}>
                        <div className={styles.itemTitle}>
                          <span>{event.eventName}</span>

                          <div
                            className={clsx(
                              styles.rightArrow,
                              selectedEvent === event.id &&
                                styles.rightArrowSelected
                            )}
                          >
                            <RightOpenArrow />
                          </div>
                        </div>

                        <div className={styles.itemDateTime}>
                          <CalendarIcon />
                          <span>
                            {formatDateCustom(
                              new Date(event.startDate.replace("Z", ""))
                            )}
                          </span>

                          <span>
                            {formatTime(
                              new Date(event.startDate.replace("Z", ""))
                            )}{" "}
                            -{" "}
                            {formatTime(
                              new Date(event.endDate.replace("Z", ""))
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingEventsList;
