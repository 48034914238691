/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useRef } from "react";
import ReactEcharts from "echarts-for-react";

import { toEChartsTextStyle } from "../utils";
import { ChartColor, ChartProps, SeriesItem } from "../types";
import { COLOR_TOOLTIP_TEXT, COLOR_WHITE, COLOR_DARK_GRAY } from "../colors";
import { ChartSize, FONT_SIZE_XSS } from "../constants";
import {
  DEFAULT_CHART_X_AXIS_PHYS,
  DEFAULT_CHART_Y_AXIS_PHYS,
} from "../defaults";
import { NoData } from "../no-data/no-data";
import {
  NoLegendSelected,
  NoLegendSelectedRef,
} from "../no-legend-selected/no-legend-selected";

import styles from "./bar-chart.module.scss";

export type BarChartSeries = {
  color?: ChartColor;
  name?: string;
  shadowColor?: ChartColor;
  shadowBlur?: number;
  shadowOffsetY?: number;
  values: number[];
};

export type BarChartProps = ChartProps<{
  type?: ChartSize;
  units?: string;
  markLineAt?: string;
  yAxis?: { name?: string }[];
  series: (BarChartSeries | undefined)[];
  axisPointerColor?: ChartColor;
  showLegend?: boolean;
  titlePrefix?: string;
}>;

interface TooltipValue {
  $vars: string[];
  axisDim: string;
  axisId: string;
  axisIndex: number;
  axisType: string;
  axisValue: string;
  axisValueLabel: string;
  borderColor?: string;
  color: string;
  componentIndex: 0;
  componentSubType: string;
  componentType: string;
  data: number;
  dataIndex: 1;
  dataType: undefined;
  dimensionNames: string[];
  encode: { x: number[]; y: number[] };
  marker: string;
  name: string;
  seriesId: string;
  seriesIndex: string;
  seriesName: string;
  seriesType: string;
  value: number;
}

export const BarChart = React.forwardRef<ReactEcharts, BarChartProps>(
  (
    {
      type = ChartSize.Small,
      series,
      labels,
      showLegend = true,
      yAxis,
      axisPointerColor,
    },
    ref
  ) => {
    const noLegendSelectedRef = useRef<NoLegendSelectedRef | null>(null);
    const filteredSeries: BarChartSeries[] = series
      .filter((el) => el !== undefined)
      .map((el) => el as BarChartSeries);

    const hasData = useMemo(
      () =>
        filteredSeries.some((seriesItem) => {
          return seriesItem.values.length > 0;
        }),
      [filteredSeries]
    );

    const onLegendSelectChanged = ({ selected }: any) => {
      const isAllLegendUnSelected = Object.keys(selected).every(
        (k) => !selected[k]
      );
      if (isAllLegendUnSelected && noLegendSelectedRef.current) {
        noLegendSelectedRef.current.show();
      }
      if (!isAllLegendUnSelected && noLegendSelectedRef.current) {
        noLegendSelectedRef.current.hide();
      }
    };

    const onEvents = {
      legendselectchanged: onLegendSelectChanged,
    };

    const height = "110%";
    const customTooltipFormatter = (value: TooltipValue[]) => {
      return `
        ${value
          .map((element: TooltipValue) =>
            element.data > 0
              ? `
              <div class="${styles.tooltip}">
                ${element.seriesName}: ${parseFloat(element.data.toString())}
              </div>`
              : ""
          )
          .join("")}`;
    };

    return (
      <div className={styles.wrapper}>
        <ReactEcharts
          ref={ref}
          opts={{ renderer: "svg" }}
          style={{ width: "100%", height }}
          notMerge={true}
          onEvents={onEvents}
          option={{
            title: {
              show: false,
            },
            tooltip: {
              trigger: hasData && "axis",
              axisPointer: {
                type: "line",
                lineStyle: {
                  color: axisPointerColor,
                },
              },
              backgroundColor: COLOR_WHITE,
              borderColor: COLOR_WHITE,
              extraCssText: "box-shadow: 4px 4px 16px rgba(44, 48, 62, 0.1);",
              textStyle: {
                color: COLOR_TOOLTIP_TEXT,
                fontSize: 12,
                fontFamily: "Montserrat",
              },
              formatter: customTooltipFormatter,
            },
            legend: {
              padding: 0,
              itemGap: 12,
              itemWidth: 8,
              icon: "circle",
              bottom: 0,
              left: "center",
              textStyle: {
                lineHeight: 12,
                fontFamily: "Montserrat",
                color: "#222222",
                fontSize: 10,
                padding: [0, 0, 0, 0],
              },
              show: hasData && showLegend,
            },
            grid: {
              top: 22,
              left: 0,
              bottom: 65,
              right: 0,
              containLabel: true,
            },
            xAxis: {
              ...DEFAULT_CHART_X_AXIS_PHYS,
              show: true,
              axisLine: {
                show: false,
              },
              boundaryGap: true,
              axisLabel: {
                show: false,
              },
              splitLine: {
                lineStyle: {
                  type: [7, 7],
                  width: 0.5,
                  dashOffset: 5,
                  color: "#C1C5CE",
                },
                show: hasData,
              },
              data: labels,
            },
            yAxis: (yAxis || [{}]).map((axis, index) => {
              return {
                type: "value",
                name: axis.name,
                nameTextStyle: {
                  ...toEChartsTextStyle(
                    {},
                    {
                      color: COLOR_DARK_GRAY,
                      fontWeight: "normal",
                      size: FONT_SIZE_XSS,
                      fontFamily: "Montserrat",
                    }
                  ),
                  align: index > 0 ? "right" : "left",
                  margin: -30,
                },
                axisLabel: {
                  fontWeight: 500,
                  size: 10,
                  fontFamily: "Montserrat",
                  formatter(value: string) {
                    if (axis.name === "Percentage" && parseFloat(value) <= 1) {
                      return `${parseFloat(value) * 100} `;
                    }
                    return `${value} `;
                  },
                },
                splitLine: {
                  ...DEFAULT_CHART_Y_AXIS_PHYS.splitLine,
                  lineStyle: {
                    type: "solid",
                    color: "#E1E1E1",
                  },
                  show: true,
                },
              };
            }),
            series: [
              ...filteredSeries.map((seriesItem) => ({
                type: "bar",
                avoidLabelOverlap: false,
                barWidth: 12,
                barCategoryGap: 0,
                barGap: "-100%",
                itemStyle: {
                  color: seriesItem.color,
                  borderRadius: [12, 12, 0, 0],
                },
                name: seriesItem.name,
                data: hasData ? seriesItem.values : [0], // Just adding one data point to force axis rendering
              })),
            ],
          }}
        />
        {!hasData && <NoData chartSize={type} type="bar" />}
        <NoLegendSelected chartSize={type} ref={noLegendSelectedRef} />
      </div>
    );
  }
);
