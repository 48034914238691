import React, { useContext, useEffect, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { components } from "react-select";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import {
  Speed,
  Duration,
  Distance,
  CaloriesResults,
  Avatar,
  PencilIcon,
  TrashIcon,
  ArrowDownDefault,
} from "../../../../../constants/assets";
import styles from "./results.module.scss";
import StateContext from "../../../../../context/StateContext";
import DispatchContext from "../../../../../context/DispatchContext";
import { AppContext } from "../../../../../App";
import { durationToMin, minToDuration } from "../../../../../utils/utils";
import DeleteResult from "../../../../../apis/events/deleteResult";
import AddResult from "../../../../../apis/events/addResult";
import AthletesList from "../../../../../apis/athletesList";
import { Option } from "../../addEditParticipant";
import { Select } from "../../../../select/select";
import UpdateResult from "../../../../../apis/events/updateResult";
import toastMsg from "../../../../../common/toast";

interface IForm {
  id?: number;
  distance?: string;
  duration?: string;
  calories?: string;
  speed?: string;
}

interface Fields {
  speed?: boolean;
  distance?: boolean;
  duration?: boolean;
  calories?: boolean;
  athletes?: boolean;
}

const Results = () => {
  const { appState } = useContext<any>(AppContext);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { accountType, id: accountId, firstName, lastName } = appState;

  const isAthlete = accountType === "athlete";
  const isAdmin = accountType === "admin";

  const { resultsDetails, resultsMode, eventDetails, participantAthletes } =
    state;

  const [results, setResults] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [addMode, setAddMode] = useState(Boolean);
  const [formData, setFormData] = useState<IForm>({});
  const [saveDone, setSaveDone] = useState(true);
  const [rowID, setRowID] = useState(Number);
  const [selectAthlete, setSelectAthlete] = useState<Option | null>(null);
  const [showError, setShowError] = useState(false);
  const [isValid, setIsValid] = useState<Fields>({});
  useEffect(() => {
    (async function list() {
      const response: any = await AthletesList();
      if (response?.data) {
        const users = response.data.map(
          (item: {
            id: any;
            firstName: any;
            lastName: any;
            email: any;
            mobile: any;
            parentName: any;
          }) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
            email: item.email,
            mobile: item.mobile,
            parentName: item.parentName,
          })
        );
        const alreadyAddedAthletes = resultsDetails.map(
          (r: any) => r.athlete.id
        );

        if (participantAthletes?.length)
          setAthletes(
            participantAthletes
              .filter(
                (participant: { athlete: { id: any }; id: any }) =>
                  !alreadyAddedAthletes.includes(participant.athlete.id)
              )
              .map((participant: { athlete: { id: any }; id: any }) =>
                Object.assign(
                  users.filter(
                    (athlete: { value: any }) =>
                      athlete.value === participant.athlete.id
                  )[0],
                  {
                    id: participant.id,
                  }
                )
              )
          );
        setRowID(-1);
      }
    })();
  }, [participantAthletes, resultsDetails]);
  useEffect(() => {
    setResults(resultsDetails);
    setAddMode(resultsMode !== "View");
    if (resultsMode === "Add") {
      setSaveDone(false);
      setFormData({});
      setRowID(-1);
      setIsValid({
        speed: false,
        distance: false,
        duration: false,
        calories: false,
        athletes: isAthlete,
      });
    } else
      setIsValid({
        speed: true,
        distance: true,
        duration: true,
        calories: true,
        athletes: true,
      });

    setShowError(false);
  }, [resultsDetails, resultsMode]);

  const handleRevert = () => {
    setRowID(-1);
    setShowError(false);
  };

  const handleCancel = () => {
    dispatch({ type: "resultsMode", payload: "View" });
    setSelectAthlete(null);
    dispatch({ type: "refreshData", payload: "results" });
  };

  const handleSave = async () => {
    if (Object.values(isValid).includes(false)) {
      setShowError(true);

      toastMsg("Required fields must be filled in.");
      return;
    }
    const response: any = await AddResult({
      ...formData,
      duration: durationToMin(formData.duration).toString(),
      event: {
        id: eventDetails.id,
      },
      athlete: {
        id: isAthlete ? accountId : selectAthlete?.value,
      },
      date: eventDetails.startDate,
    });
    if (response?.error) {
      const { data } = response.error;
      if (data?.detail) {
        toastMsg(data.detail, "error");
        return;
      }
      toastMsg("Something went wrong", "error");
      return;
    }
    if (response?.data) {
      setSelectAthlete(null);
      setSaveDone(true);
      dispatch({ type: "refreshData", payload: "results" });
      dispatch({ type: "resultsMode", payload: "View" });
      toastMsg("Results are successfully added.", "success");
      setShowError(false);
    }
  };

  const handleSaveEdit = async (result: {
    duration: any;
    athlete: { id: any };
  }) => {
    if (Object.values(isValid).includes(false)) {
      setShowError(true);
      toastMsg("Required fields must be filled in.");
      return;
    }
    const duration = durationToMin(formData.duration);
    const response: any = await UpdateResult({
      form: {
        ...formData,
        duration: duration ? duration.toString() : result.duration,
        event: {
          id: eventDetails.id,
        },
        athlete: {
          id: result.athlete.id,
        },

        date: eventDetails.startDate,
      },
      id: rowID,
    });
    if (response?.data) {
      setRowID(-1);
      setSelectAthlete(null);
      setSaveDone(true);
      dispatch({ type: "refreshData", payload: "results" });
      dispatch({ type: "resultsMode", payload: "View" });
      toastMsg("Results are successfully updated.", "success");
      setShowError(false);
    }
  };

  const handleInputChange = (e: NumberFormatValues, name: string) => {
    setFormData((prev) => ({ ...prev, [name]: e.value }));
    setIsValid({
      ...isValid,
      [name]: name === "duration" ? e.value.length === 4 : !!e.value.length,
    });
  };

  const handleDelete = async (id: number) => {
    const response: any = await DeleteResult({ id });

    if (response.status === 204) {
      setSaveDone(true);
      dispatch({ type: "refreshData", payload: "results" });
      dispatch({ type: "resultsMode", payload: "View" });
      setRowID(-1);
    }
  };

  const handleEdit = (athleteInfo: React.SetStateAction<IForm> | any) => {
    setFormData({
      ...athleteInfo,
      duration: minToDuration(athleteInfo.duration),
    });
    setRowID(athleteInfo.id);
    dispatch({ type: "resultsMode", payload: "View" });
    setIsValid({
      speed: true,
      distance: true,
      duration: true,
      calories: true,
      athletes: true,
    });
    setShowError(false);
  };

  const dropdownOption = (dropDownProps: any) => (
    <components.DropdownIndicator {...dropDownProps}>
      <ArrowDownDefault />
    </components.DropdownIndicator>
  );

  const setSelectedAthlete = (option: React.SetStateAction<Option | null>) => {
    setSelectAthlete(option);
    setIsValid((prev) => ({ ...prev, athletes: true }));
  };

  return (
    <>
      <div className={styles.block}>
        <div className={styles.toast}>
          {resultsMode === "Add" && (
            <ToastContainer className={styles.toastPosition} />
          )}
        </div>
        <div className={styles.formHeader}>
          <>
            {addMode && !saveDone && (
              <div
                className={clsx(
                  styles.resultsDetailsBlock,
                  !state.resultsDetails.length && styles.setHeight
                )}
              >
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className={clsx(rowID === -1 && styles.extendHeight)}
                >
                  <div className={styles.athleteInfo}>
                    <div className={styles.avatar}>
                      <Avatar />
                    </div>

                    <div className={styles.athleteName}>
                      {isAdmin ? (
                        <Select
                          placeholder="Attendee"
                          isSearchable={true}
                          className={clsx(
                            styles.selectAthlete,
                            !isValid.athletes && showError && styles.errorInput
                          )}
                          name="athletes"
                          value={selectAthlete}
                          options={athletes}
                          components={{ DropdownIndicator: dropdownOption }}
                          onChange={setSelectedAthlete}
                        />
                      ) : (
                        <span>{`${firstName} ${lastName}`}</span>
                      )}
                    </div>

                    <div className={styles.actionBlock}>
                      <div className={styles.category}>
                        <span className={styles.categoryName}>Category</span>
                        <div className={styles.categoryDropDown}>
                          <span className={styles.categoryValue}>
                            <span> {eventDetails?.category?.name}</span>
                          </span>
                        </div>
                      </div>
                      {addMode && !saveDone && (
                        <div
                          className={clsx(styles.actions, styles.alignActions)}
                        >
                          <>
                            <button
                              type="button"
                              className={styles.cancelBtnAdd}
                              onClick={handleCancel}
                            >
                              <span>Cancel</span>
                            </button>
                            <button
                              type="button"
                              className={styles.saveBtn}
                              onClick={handleSave}
                            >
                              <span>Save</span>
                            </button>
                          </>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.bottomSection}>
                    <div className={styles.results}>
                      <div className={styles.distance}>
                        <Distance />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.distance && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="distance"
                            name="distance"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.distance}
                            onValueChange={(e) =>
                              handleInputChange(e, "distance")
                            }
                            readOnly={saveDone}
                          />
                          {/* TODO: Fix click on span should focus the input */}
                          <span className={styles.measure}>km</span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.results}>
                      <div className={styles.speed}>
                        <Speed />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.speed && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="speed"
                            name="speed"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.speed}
                            onValueChange={(e) => handleInputChange(e, "speed")}
                            readOnly={saveDone}
                          />
                          <span className={styles.measure}>km/h</span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.results}>
                      <div className={styles.duration}>
                        <Duration />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.duration && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="duration"
                            name="duration"
                            readOnly={saveDone}
                            format="##h : ##m"
                            mask="_"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            prefix=""
                            value={formData.duration}
                            onValueChange={(e) =>
                              handleInputChange(e, "duration")
                            }
                          />{" "}
                        </div>
                      </div>
                    </div>

                    <div className={styles.results}>
                      <div className={styles.calories}>
                        <CaloriesResults />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.calories && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="calories"
                            name="calories"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.calories}
                            onValueChange={(e) =>
                              handleInputChange(e, "calories")
                            }
                            readOnly={saveDone}
                          />
                          <span className={styles.measure}>kcal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </>

          {results && results.length > 0 && (
            <>
              {results.map((result: any) => {
                return (
                  <React.Fragment key={result.id}>
                    <div
                      className={clsx(
                        styles.resultsDetailsBlock,
                        rowID !== result.id &&
                          rowID !== -1 &&
                          styles.inActiveBlock
                      )}
                    >
                      {rowID === result.id && (
                        <>
                          <ToastContainer
                            className={styles.toastPositionEdit}
                          />
                        </>
                      )}

                      <form
                        onSubmit={(e) => e.preventDefault()}
                        className={clsx(
                          rowID !== -1 &&
                            rowID === result.id &&
                            styles.extendHeight
                        )}
                      >
                        <div className={styles.athleteInfo}>
                          <div className={styles.avatar}>
                            <Avatar />
                          </div>
                          <div className={styles.athleteName}>
                            <span className={styles.name}>
                              {`${result.athlete.firstName} ${result.athlete.lastName}`}
                            </span>
                          </div>
                          <div className={styles.category}>
                            <span className={styles.categoryName}>
                              Category
                            </span>
                            <div className={styles.categoryDropDown}>
                              <span className={styles.categoryValue}>
                                <span> {result.event.category.name}</span>
                              </span>
                            </div>

                            {rowID === result.id ? (
                              <div className={styles.actions}>
                                <>
                                  <button
                                    type="button"
                                    className={styles.cancelBtnAdd}
                                    onClick={handleRevert}
                                  >
                                    <span>Cancel</span>
                                  </button>
                                  <button
                                    type="button"
                                    className={styles.saveBtn}
                                    onClick={() => handleSaveEdit(result)}
                                  >
                                    <span>Save</span>
                                  </button>
                                </>
                              </div>
                            ) : (
                              eventDetails.status === "DONE" && (
                                <div className={styles.athleteActions}>
                                  <div
                                    role="button"
                                    aria-hidden="true"
                                    className={styles.edit}
                                    onClick={() => handleEdit(result)}
                                  >
                                    <PencilIcon />
                                  </div>
                                  <div
                                    role="button"
                                    aria-hidden="true"
                                    className={styles.delete}
                                    onClick={() => handleDelete(result.id)}
                                  >
                                    <TrashIcon />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>

                        <div className={styles.bottomSection}>
                          <div className={styles.results}>
                            <div className={styles.distance}>
                              <Distance />
                              {rowID === result.id ? (
                                <div
                                  className={clsx(
                                    styles.numbersRowInput,
                                    !isValid.distance &&
                                      showError &&
                                      styles.errorInput
                                  )}
                                >
                                  <NumberFormat
                                    className={styles.input}
                                    id="distance"
                                    name="distance"
                                    readOnly={rowID !== result.id}
                                    prefix=""
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    allowEmptyFormatting={true}
                                    value={formData.distance}
                                    onValueChange={(e) =>
                                      rowID === result.id &&
                                      handleInputChange(e, "distance")
                                    }
                                  />
                                  <span className={styles.measure}>km</span>
                                </div>
                              ) : (
                                <>
                                  <div className={styles.numbersRow}>
                                    <span> {result.distance} </span>
                                    <span className={styles.measure}>km</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className={styles.results}>
                            <div className={styles.speed}>
                              <Speed />

                              {rowID === result.id ? (
                                <div
                                  className={clsx(
                                    styles.numbersRowInput,
                                    !isValid.speed &&
                                      showError &&
                                      styles.errorInput
                                  )}
                                >
                                  <NumberFormat
                                    className={styles.input}
                                    id="speed"
                                    name="speed"
                                    readOnly={rowID !== result.id}
                                    prefix=""
                                    value={formData.speed}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    allowEmptyFormatting={true}
                                    onValueChange={(e) =>
                                      rowID === result.id &&
                                      handleInputChange(e, "speed")
                                    }
                                  />
                                  <span className={styles.measure}>km/h</span>
                                </div>
                              ) : (
                                <>
                                  <div className={styles.numbersRow}>
                                    <span> {result.speed} </span>
                                    <span className={styles.measure}>km/h</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className={styles.results}>
                            <div className={styles.duration}>
                              <Duration />

                              {rowID === result.id ? (
                                <div
                                  className={clsx(
                                    styles.numbersRowInput,
                                    !isValid.duration &&
                                      showError &&
                                      styles.errorInput
                                  )}
                                >
                                  {" "}
                                  <NumberFormat
                                    className={styles.input}
                                    id="duration"
                                    name="duration"
                                    readOnly={rowID !== result.id}
                                    format="##h : ##m"
                                    mask="_"
                                    prefix=""
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    allowEmptyFormatting={true}
                                    value={formData.duration}
                                    onValueChange={(e) =>
                                      rowID === result.id &&
                                      handleInputChange(e, "duration")
                                    }
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className={styles.numbersRow}>
                                    <span>
                                      {" "}
                                      {minToDuration(result.duration)}{" "}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className={styles.results}>
                            <div className={styles.calories}>
                              <CaloriesResults />

                              {rowID === result.id ? (
                                <div
                                  className={clsx(
                                    styles.numbersRowInput,
                                    !isValid.calories &&
                                      showError &&
                                      styles.errorInput
                                  )}
                                >
                                  <NumberFormat
                                    className={styles.input}
                                    id="calories"
                                    name="calories"
                                    readOnly={rowID !== result.id}
                                    prefix=""
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    allowEmptyFormatting={true}
                                    value={formData.calories}
                                    onValueChange={(e) =>
                                      rowID === result.id &&
                                      handleInputChange(e, "calories")
                                    }
                                  />
                                  <span className={styles.measure}>kcal</span>
                                </div>
                              ) : (
                                <>
                                  <div className={styles.numbersRow}>
                                    <span> {result.calories} </span>
                                    <span className={styles.measure}>kcal</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Results;
