import { useContext, useState } from "react";
import { ParticipantModal, SuccessModal } from "../../../constants/assets";
import { AppContext } from "../../../App";
import AddRegister from "../../../apis/events/addRegister";
import AddCoachRegister from "../../../apis/events/addCoachRegister";
import styles from "./eventRegistration.module.scss";

const EventRegistration = ({
  state,
  dispatch,
  participantId,
  participantType,
}: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { id } = state;
  const [showResponse, setShowResponse] = useState(false);

  const handleRegister = async () => {
    const params =
      participantType === "coach"
        ? {
            event: { id },
            coach: { id: participantId },
            registrationStatus: "REGISTER",
          }
        : {
            event: { id },
            athlete: { id: participantId },
            registrationStatus: "REGISTER",
          };
    const response: any =
      participantType === "coach"
        ? await AddCoachRegister(params)
        : await AddRegister(params);
    if (response.status === 200 || response.status === 201) {
      setShowResponse(true);
      dispatch({
        type: "participantRegistrationStatus",
        payload: "REGISTERED",
      });
    }
  };

  return (
    <>
      <div className={styles.registrationBlock}>
        {!showResponse && (
          <>
            <div className={styles.title}>
              <ParticipantModal />
              <span>Confirmation</span>
            </div>
            <div className={styles.registrationInfo}>
              <span>Are you sure you want to register to this event?</span>
            </div>
            <div className={styles.actions}>
              <button
                type="button"
                className={styles.noBtn}
                onClick={() => appDispatch({ type: "hideModal" })}
              >
                <span>No</span>
              </button>
              <button
                type="button"
                className={styles.yesBtn}
                onClick={handleRegister}
              >
                <span>Yes</span>
              </button>
            </div>
          </>
        )}

        {showResponse && (
          <>
            <div className={styles.title}>
              <SuccessModal />
              <span>Success!</span>
            </div>
            <div className={styles.successInfoResponse}>
              <span>
                You successfully registered for “{state.eventName}” event.
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EventRegistration;
