import {
  setMinutes,
  format,
  differenceInMinutes,
  addMinutes,
  isAfter,
  startOfTomorrow,
} from "date-fns";
import { roundTimeQuarterHour, startingDate } from "utils/utils";

const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;

const intervalInMinutes = 15;
const interval = intervalInMinutes * minute;
const timeOptionsAmount = day / interval;

const valueFormat = "HH:mm";
const labelFormat = `hh:mm a`;

const timeBrackets = Array.from({ length: timeOptionsAmount }).map(
  (item, index) => {
    const date = setMinutes(new Date(0, 0, 0, 0), index * intervalInMinutes);
    const value = format(date, valueFormat);
    const label = format(date, labelFormat);
    return { value, label };
  }
);

const dynamicTimeBrackets = (
  defaultDate: Date = startingDate(0),
  lastQuater = 0,
  mode = "Add",
  readonly = true
) => {
  let comingDate = defaultDate;
  const initDay = new Date();
  initDay.setHours(0, 0, 0, 0);
  const proposedDate = new Date(roundTimeQuarterHour(comingDate));
  proposedDate.setHours(0, 0, 0, 0);

  const diffInDays = isAfter(proposedDate, initDay);
  if (diffInDays) {
    return timeBrackets;
  }

  if (mode === "Edit") comingDate = startingDate(0);

  const options =
    differenceInMinutes(
      startOfTomorrow(),
      new Date(
        roundTimeQuarterHour(new Date(comingDate.toString().replace("Z", "")))
      )
    ) / intervalInMinutes;

  const arrayValues = Array.from({ length: options + lastQuater }).map(
    (item, index) => {
      const date = addMinutes(
        new Date(
          roundTimeQuarterHour(new Date(comingDate.toString().replace("Z", "")))
        ),
        index * intervalInMinutes
      );

      const value = format(date, valueFormat);
      const label = format(date, labelFormat);
      return { value, label };
    }
  );

  if (mode === "Add") return arrayValues;

  if (readonly) {
    const value = format(new Date(defaultDate), valueFormat);
    const label = format(new Date(defaultDate), labelFormat);
    arrayValues.push({ value, label });
  }

  return arrayValues;
};
export { dynamicTimeBrackets, timeBrackets };
