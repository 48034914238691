import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { FC, useContext, useState } from "react";
import validator from "validator";
import styles from "./email.module.scss";
import { SignInUser, LeftArrow, Warning } from "../../../constants/assets";
import ResetSendEmail from "../../../apis/auth/resetSendEmail";
import { AppContext } from "../../../App";

const Email: FC = ({ history }: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const [error, setError] = useState(false);

  const [form, setForm] = useState({
    email: "",
  });

  const validateLogin = () => validator.isEmail(form.email);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!validateLogin()) {
      setError(true);
      return;
    }
    const response: any = await ResetSendEmail(form);

    if (response?.data) {
      history.push("/home/checkEmail");
      appDispatch({ type: "setEmail", payload: { email: form.email } });
    }
  };
  const handleInputChange = (e: any) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleEmailBlur = (e: any) => {
    if (e.target.value.length) {
      if (!validateLogin()) setError(true);
      else setError(false);
    } else {
      setError(false);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>Forgot Password</span>
        </div>
        <div className={styles.subTitle}>
          <span>
            The reset link will be sent to your registered email address.
          </span>
        </div>
        <form className={styles.form} onSubmit={handleSubmit} noValidate={true}>
          <div className={styles.inputsBlock}>
            <div className={styles.inputBlock}>
              <label htmlFor="email" className={styles.label}>
                Email
                <div className={styles.inputWrapper}>
                  <div
                    className={clsx(
                      styles.iconWrapper,
                      form.email.length > 0 && styles.activeIcon
                    )}
                  >
                    <SignInUser />
                  </div>

                  <input
                    onBlur={handleEmailBlur}
                    type="email"
                    name="email"
                    value={form.email}
                    placeholder="Type your Email"
                    onChange={handleInputChange}
                    className={clsx(styles.input, error && styles.errorInput)}
                  />
                </div>
              </label>
              {error && (
                <div className={styles.errorBlock}>
                  <div className={styles.warningIcon}>
                    <Warning />
                  </div>
                  <div className={styles.errorMessage}>
                    Please enter a valid email address.
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.buttonBlock}>
            <button
              type="button"
              className={styles.backButton}
              onClick={() => history.push("/home/login")}
            >
              <LeftArrow />
              <span> Back </span>
            </button>
            <button
              type="submit"
              tabIndex={0}
              className={
                validateLogin() ? styles.button : styles.buttonDisabled
              }
            >
              Send Link
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Email);
