import { withRouter } from "react-router-dom";
import { FC, useContext } from "react";
import styles from "./checkEmail.module.scss";
import ResetSendEmail from "../../../apis/auth/resetSendEmail";
import { AppContext } from "../../../App";

const CheckEmail: FC = ({ history }: any) => {
  const { appState } = useContext<any>(AppContext);
  const handleRoute = () => {
    history.push("/home/login");
  };

  const handleResend = async () => {
    await ResetSendEmail({ email: appState.email });
  };

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div>
          <div className={styles.title}>
            <span>Check your email</span>
          </div>
          <div className={styles.subTitle}>
            <span>
              The recovery link was sent to your email. Please check your email.
            </span>
          </div>
        </div>

        <div className={styles.buttonBlock}>
          <button
            onClick={handleRoute}
            type="button"
            className={styles.button}
            tabIndex={0}
          >
            Back to sign in
          </button>
          <div role="button" aria-hidden="true" className={styles.actions}>
            <span>Didn&apos;t receive link? </span>
            <span role="button" aria-hidden="true" onClick={handleResend}>
              Resend
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CheckEmail);
