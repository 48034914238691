import { useContext, useState } from "react";
import { ParticipantModal, SuccessModal } from "../../../constants/assets";
import { AppContext } from "../../../App";
import DeleteRegister from "../../../apis/events/deleteRegister";
import DeleteCoachRegister from "../../../apis/events/deleteCoachRegister";
import styles from "./eventRegistration.module.scss";

const CancelRegistration = ({
  state,
  dispatch,
  participantId,
  participantType,
}: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { id } = state;
  const [showResponse, setShowResponse] = useState(false);

  const handleCancel = async () => {
    const params = {
      id,
      participantId,
    };
    const response: any =
      participantType === "coach"
        ? await DeleteCoachRegister(params)
        : await DeleteRegister(params);
    if (response.status === 200) {
      setShowResponse(true);
      dispatch({
        type: "participantRegistrationStatus",
        payload: null,
      });
    }
  };

  return (
    <>
      <div className={styles.registrationBlock}>
        {!showResponse && (
          <>
            <div className={styles.title}>
              <ParticipantModal />
              <span>Confirmation</span>
            </div>
            <div className={styles.registrationInfo}>
              <span>Are you sure you want to cancel your registration?</span>
            </div>
            <div className={styles.actions}>
              <button
                type="button"
                className={styles.noBtn}
                onClick={() => appDispatch({ type: "hideModal" })}
              >
                <span>No</span>
              </button>
              <button
                type="button"
                className={styles.yesBtn}
                onClick={handleCancel}
              >
                <span>Yes</span>
              </button>
            </div>
          </>
        )}

        {showResponse && (
          <>
            <div className={styles.title}>
              <SuccessModal />
              <span>Success!</span>
            </div>
            <div className={styles.successInfoResponse}>
              <span>
                You successfully canceled your registration for “
                {state.eventName}” event.
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CancelRegistration;
