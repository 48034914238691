// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  createRef,
  useLayoutEffect,
} from "react";
import clsx from "clsx";
import ReactSelect, {
  components,
  MenuListProps,
  OptionProps,
  ActionMeta,
} from "react-select";
import { AppContext } from "../../../../App";
import { Group, X, Expand, Warning } from "../../../../constants/assets";
import styles from "./addEditGroup.module.scss";
import UpdateGroup from "../../../../apis/management/updateGroup";
import AddGroup from "../../../../apis/management/addGroup";
import AthletesList from "../../../../apis/athletesList";

export interface Option {
  value: number;
  label: string;
}
interface IForm {
  id?: number;
  name?: string;
  athleteIds?: number[];
}

interface Fields {
  name?: boolean;
  selected?: boolean;
}

const AddEditGroup = ({ mode, data, dispatch }: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const [readOnly] = useState(false);
  const [formData, setFormData] = useState<IForm>({});
  const [isValid, setIsValid] = useState<Fields>({});
  const [optionSelected, setOptionSelected] = useState<readonly Option[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [error, setError] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [isExpand, setIsExpand] = useState(true);
  const [displayOptions, setDisplayOptions] = useState<readonly Option[]>([]);
  const [lastSelected, setLastSelected] = useState<Option | undefined>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [duplicateError, setDuplicateError] = useState(false);
  const [showExpand, setShowExpand] = useState<boolean>();

  const blockRef = createRef<any>();
  const selectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mode === "Edit") {
      setIsValid({
        name: true,
        selected: true,
      });
      setShowMembers(true);
      setFormData(data);
    } else {
      setIsValid({
        name: false,
        selected: false,
      });
    }
  }, []);

  useEffect(() => {
    (async function list() {
      const response: any = await AthletesList(false);
      if (response?.data) {
        const mappedAthletes = response.data.map(
          (item: {
            id: any;
            firstName: any;
            lastName: any;
            parentName: any;
            mobile: any;
            email: any;
          }) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          })
        );
        setAthletes(mappedAthletes);
        if (mode === "Edit" && data?.athleteIds?.length) {
          const selectedOption = mappedAthletes.filter(
            (element: { value: any }) =>
              data.athleteIds.find((item: any) => item === element.value)
          );
          setDisplayOptions(selectedOption);
          setOptionSelected([]);
        }
      }
    })();
  }, []);

  const handleSave = () => {
    (async function admin() {
      if (Object.values(isValid).includes(false)) {
        setShowError(true);
        return;
      }
      const response: any = await (mode === "Edit"
        ? UpdateGroup({
            data: formData,
          })
        : AddGroup({
            data: formData,
          }));
      if (response.status === 200 || response.status === 201) {
        appDispatch({ type: "hideModal" });
        dispatch({ type: "refreshData", payload: "usersList" });
      } else if (response.response.data.errorKey === "20302") {
        setIsValid((prev) => ({ ...prev, email: false }));
        setShowError(true);
        setFormData((prev) => ({ ...prev, email: "" }));
      } else if (response.response.data.errorKey === "20131") {
        setDuplicateError(true);
      }
    })();
  };

  const customStyles = {
    container: (base: any) => ({
      ...base,
      background: "#fff",
      fontSize: "14px",
      lineHeight: "20px",
      borderRadius: "8px",
      color: "#000",
      padding: "1px 0px",
    }),
    valueContainer: (base: any) => ({
      ...base,
      margin: "0px",
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: "14px",
      borderBottom: "1px solid #c1c5ce",
      color: "#222222",
      backgroundColor: "transparent",
      height: "36px",
      "&:click": {
        backgroundColor: "transparent",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      paddingLeft: "10px",
      paddingRight: "10px",
    }),
    control: (provided: any) => ({
      ...provided,
      height: "50px",
      borderRadius: "8px",
      border:
        !isValid.selected && showError
          ? "1px solid #d93731"
          : "1px solid #cccccc",
      boxShadow: "none",
      "&:hover": {
        border:
          !isValid.name && showError
            ? "1px solid #d93731"
            : "1px solid #cccccc",
      },
    }),
  };

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    if (e.target.name === "name")
      setIsValid((prev) => ({
        ...prev,
        [e.target.name]: !!e.target.value.length,
      }));

    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const MultiValue = () => {
    return <></>;
  };

  const handleChange = (
    option: readonly Option[],
    actionMeta: ActionMeta<Option>
  ) => {
    setLastSelected(actionMeta?.option);
    if (option.length <= 20) {
      setOptionSelected(option);
    }
    setError(option.length >= 20);
  };

  const expand = () => {
    setIsExpand(!isExpand);
  };

  const handleCancelSelect = () => {
    setIsMenuOpen(false);
    setShowMembers(true);
    setError(false);
    setOptionSelected([]);
    selectRef?.current?.blur();
  };

  const handleSaveSelect = () => {
    setIsMenuOpen(false);
    setShowMembers(true);
    const selectedIds = optionSelected.map((a: any) => a.value);
    setFormData((prev) => ({ ...prev, athleteIds: selectedIds }));
    setIsValid((prev) => ({ ...prev, selected: optionSelected.length > 0 }));
    setDisplayOptions(optionSelected);
    setOptionSelected([]);
    selectRef?.current?.blur();
  };
  const handleFocus = () => {
    setIsMenuOpen(true);
    setShowMembers(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      setOptionSelected(displayOptions);
    }
  }, [isMenuOpen]);

  const handleDelete = (value: any) => {
    const options = displayOptions.filter(
      (item: { value: any }) => item.value !== value
    );
    setError(options.length >= 20);
    setDisplayOptions(options);
    setIsValid((prev) => ({ ...prev, selected: options.length > 0 }));
    const selectedIds = options.map((a: any) => a.value);
    setFormData((prev) => ({ ...prev, athleteIds: selectedIds }));
  };

  const Option = ({ isSelected, ...props }: OptionProps<any>) => {
    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (lastSelected?.value === props?.data?.value) {
        ref?.current?.parentElement?.parentElement?.scrollTo({
          left: 0,
          top: scrollPosition,
        });
      }
    }, [isSelected]);

    return (
      <div>
        <components.Option {...props} innerRef={ref}>
          <input
            className={styles.input}
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MenuList = useCallback(
    (props: MenuListProps<any>) => {
      const { children, innerProps } = props;
      const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        setScrollPosition(e?.currentTarget?.scrollTop);
      };
      return (
        <>
          <components.MenuList
            {...props}
            innerProps={{ ...innerProps, onScroll: handleScroll }}
          >
            {children}
          </components.MenuList>
          <div className={styles.actionsStyle}>
            {error ? (
              <div className={styles.actionsLabelRed}>
                <span>
                  You&apos;ve reached the max <br />
                  number of participants
                </span>
              </div>
            ) : (
              <div className={styles.actionsLabel}>
                <span>
                  *Max number of <br /> participants is <b>20</b>
                </span>
              </div>
            )}
            <div className={styles.actionsList}>
              <button
                type="button"
                className={styles.noBtn}
                onClick={handleCancelSelect}
              >
                <span>Cancel</span>
              </button>
              <button
                onClick={handleSaveSelect}
                type="button"
                className={clsx(
                  optionSelected.length > 0
                    ? styles.yesBtn
                    : styles.buttonDisabled
                )}
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </>
      );
    },
    [error, optionSelected]
  );

  useLayoutEffect(() => {
    if (blockRef.current?.clientHeight > 96) {
      setShowExpand(true);
    } else setShowExpand(false);
  }, [blockRef]);
  return (
    <>
      <div className={styles.addEditBlock}>
        <>
          <div className={styles.title}>
            <Group />
            <span>{`${mode === "Add" ? "Create" : "Edit"}`} Group</span>
          </div>
          <div className={styles.inputBlocks}>
            <form>
              <div className={styles.infoRow}>
                <div className={styles.athleteRow}>
                  <div className={styles.inputBlock}>
                    <div className={styles.inputWrapper}>
                      <input
                        readOnly={readOnly}
                        type="text"
                        name="name"
                        value={formData?.name}
                        placeholder="*Group Name"
                        className={clsx(
                          styles.input,
                          !isValid.name && showError && styles.errorInput
                        )}
                        onChange={handleInputChange}
                      />
                    </div>
                    {showError && !isValid.name && (
                      <div className={styles.errorBlock}>
                        <div className={styles.warningIcon}>
                          <Warning />
                        </div>
                        <div className={styles.errorMessage}>
                          The group name is mandatory
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.dropDown}>
                  <ReactSelect
                    ref={selectRef}
                    options={athletes}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    openMenuOnClick={true}
                    openMenuOnFocus={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    components={{
                      Option,
                      MultiValue,
                      MenuList,
                    }}
                    onChange={handleChange}
                    value={optionSelected}
                    styles={customStyles}
                    menuIsOpen={isMenuOpen}
                    placeholder="Choose Athletes"
                    onFocus={handleFocus}
                    controlShouldRenderValue={false}
                  />
                  {showError && !isValid.selected && (
                    <div className={styles.errorBlock}>
                      <div className={styles.warningIcon}>
                        <Warning />
                      </div>
                      <div className={styles.errorMessage}>
                        Please select the athlete
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={clsx(
                  styles.membersBlock,
                  !isExpand && styles.fullHeight
                )}
              >
                <div
                  ref={blockRef}
                  className={clsx(
                    styles.membersName,
                    !showMembers && styles.hideMembers
                  )}
                >
                  {displayOptions.map((item: any) => (
                    <>
                      <div className={styles.memberBlock} key={item.value}>
                        <span>{item.label}</span>
                        <X onClick={() => handleDelete(item.value)} />
                      </div>
                    </>
                  ))}
                  {showExpand && (
                    <div
                      className={clsx(
                        styles.expandBlock,
                        !isExpand && styles.changePosition
                      )}
                    >
                      <Expand className={styles.expand} onClick={expand} />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>

          {duplicateError && (
            <div className={styles.errorBlock}>
              <div className={styles.warningIcon}>
                <Warning />
              </div>
              <div className={styles.errorMessage}>
                Group name is already taken. Try a different one
              </div>
            </div>
          )}
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={() => appDispatch({ type: "hideModal" })}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className={clsx(
                !Object.values(isValid).includes(false)
                  ? styles.yesBtn
                  : styles.buttonDisabled
              )}
              onClick={handleSave}
            >
              <span> {mode === "Add" ? "Create" : "Save"}</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default AddEditGroup;
