import { useContext, useEffect } from "react";
import { AppContext } from "App";
import styles from "./upcomingEvents.module.scss";
import UpcomingEventsList from "../components/upcomingEventsList/upcomingEventsList";
import Menu from "../components/upcomingEventsMenu/menu";
import StateContext from "../../../context/StateContext";
import { LeftArrow } from "../../../constants/assets";
import DispatchContext from "../../../context/DispatchContext";

const UpComingEvents = () => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { appDispatch } = useContext<any>(AppContext);
  const handleSwitchView = () => {
    dispatch({ type: "displayList", payload: true });
    dispatch({ type: "displayMenu", payload: false });
  };

  useEffect(() => {
    return () => appDispatch({ type: "globalRender", payload: "calendar" });
  }, []);
  return (
    <>
      <div className={styles.upcomingEventsContainer}>
        <UpcomingEventsList />
        {state.displayMenu && (
          <div className={styles.backList}>
            <div className={styles.title}>
              <LeftArrow onClick={handleSwitchView} />
              <span>Events List</span>
            </div>
          </div>
        )}
        <Menu />
      </div>
    </>
  );
};

export default UpComingEvents;
