import { useMediaMatch } from "rooks";

export function useBreakpoints() {
  const isMobile = useMediaMatch("(max-width: 768px)");
  const isTablet = useMediaMatch("(max-width: 1024px)");
  const isLaptopSmall = useMediaMatch("(max-width: 1366px)");
  const isLaptop = useMediaMatch("(max-width: 1919px)");

  const isDesktop = !(isLaptop || isTablet || isMobile);

  return {
    isMobile,
    isLaptopSmall,
    isLaptop,
    isDesktop,
  };
}
