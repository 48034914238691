/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useState } from "react";
import DeleteResult from "apis/trainings/deleteResult";
import styles from "./deleteResults.module.scss";
import { Results } from "../../../constants/assets";
import { AppContext } from "../../../App";

const DeleteResults = ({ id, athleteId, dispatch }: any) => {
  const { appDispatch } = useContext<any>(AppContext);

  const [showResponse] = useState(false);

  const handleCancel = () => {
    (async function cancel() {
      const response: any = await DeleteResult({ id, athleteId });

      if (response.status === 204) {
        appDispatch({ type: "hideModal" });
        dispatch({ type: "refreshData", payload: "results" });
      }
    })();
  };

  return (
    <>
      <div className={styles.cancelBlock}>
        {!showResponse && (
          <>
            <div className={styles.title}>
              <Results />
              <span>Delete Result</span>
            </div>
            <div className={styles.cancelInfo}>
              <span>Are you sure you want to delete the result?</span>
            </div>
            <div className={styles.actions}>
              <button
                type="button"
                className={styles.noBtn}
                onClick={() => appDispatch({ type: "hideModal" })}
              >
                <span>No</span>
              </button>
              <button
                type="button"
                className={styles.yesBtn}
                onClick={handleCancel}
              >
                <span>Yes, Delete!</span>
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DeleteResults;
