/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RefObject, useEffect } from "react";

export function useClickOutside(
  ref: RefObject<HTMLElement>,
  handler: ((event: MouseEvent) => void) | (() => void)
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(<Node>event.target)) {
        handler(event);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref, handler]);
}
