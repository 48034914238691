import { useContext, useState } from "react";
import styles from "./cancelDetails.module.scss";
import { CancelModal, SuccessModal } from "../../../constants/assets";
import { AppContext } from "../../../App";
import CancelTrainingDetails from "../../../apis/trainings/cancelTrainingDetails";
import { formatDateCustom, formatTime } from "../../../utils/utils";

const CancelDetails = ({ state, dispatch }: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { id } = state;
  const [showResponse, setShowResponse] = useState(false);

  const handleCancel = () => {
    (async function cancel() {
      const response: any = await CancelTrainingDetails({ id });
      if (response?.data?.status === "CANCELLED") {
        setShowResponse(true);
        dispatch({ type: "detailsMode", payload: "Edit" });

        dispatch({
          type: "trainingStatus",
          payload: response?.data.status,
        });
      }
    })();
  };

  return (
    <>
      <div className={styles.cancelBlock}>
        {!showResponse && (
          <>
            <div className={styles.title}>
              <CancelModal />
              <span>Cancel Activity</span>
            </div>
            <div className={styles.cancelInfo}>
              <span>Are you sure you want to cancel the activity?</span>
            </div>
            <div className={styles.actions}>
              <button
                type="button"
                className={styles.noBtn}
                onClick={() => appDispatch({ type: "hideModal" })}
              >
                <span>No</span>
              </button>
              <button
                type="button"
                className={styles.yesBtn}
                onClick={handleCancel}
              >
                <span>Yes, Cancel!</span>
              </button>
            </div>
          </>
        )}

        {showResponse && (
          <>
            <div className={styles.title}>
              <SuccessModal />
              <span>Success</span>
            </div>

            <div className={styles.dateTime}>
              <div className={styles.date}>
                {formatDateCustom(new Date(state.startDate.replace("Z", "")))}
              </div>
              <div className={styles.time}>
                {formatTime(new Date(state.startDate.replace("Z", "")))}
              </div>
            </div>
            <div className={styles.cancelInfoResponse}>
              <span>
                The activity has been cancelled successfully and <br /> all
                participants were informed via email
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CancelDetails;
