// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  useContext,
  useEffect,
  useState,
  useReducer,
  useRef,
  RefObject,
  SetStateAction,
} from "react";
import { RouteComponentProps } from "react-router-dom";
import { components } from "react-select";
import clsx from "clsx";

import RegisterTrainingStatusCoach from "apis/trainings/registerTrainingStatusCoach";
import ParticipantCoachesDetails from "apis/trainings/participantCoachesDetails";
import GetInvolvement from "apis/trainings/getInvolvement";
import {
  formatDateCustom,
  formatDateMonthDay,
  formatDateYear,
  formatTime,
  getStartOfMonth,
  getEndOfMonth,
} from "../../utils/utils";

import { AppContext } from "../../App";
import reducer, { initState } from "./reducer";
import DispatchContext from "../../context/DispatchContext";
import StateContext from "../../context/StateContext";

import AthletesData from "../../apis/athletesList";
import ActivityData from "../../apis/trainings/trainingDetails";
import TrainingResultsData from "../../apis/trainings/resultsDetails";
import ParticipantsData from "../../apis/participants/participantsDetails";
import TrainingActivitiesData from "../../apis/trainings/trainingActivities";
import GoalsData from "../../apis/goals/goalsList";
import TrainingCapacityData from "../../apis/trainings/trainingCapacity";
import RegisterTrainingStatus from "../../apis/trainings/registerTrainingStatus";

import { TrainingActivityInfo } from "../../interfaces/interfaces";
import { Select, SelectOption } from "../select/select";
import { Spinner } from "../spinner/spinner";
import { DateCalendar } from "../date-calendar/date-calendar";

import CurrentGoal from "./components/currentGoal";
import AddGoal from "./components/addGoal";
import PastGoal from "./components/pastGoal";
import EmptyBlock from "./components/emptyBlock";
import Details from "./details/details";
import Participants from "./participants/participants";
import Results from "./results/results";

import {
  Plus,
  CalendarIcon,
  Goals,
  ArrowDownDefault,
} from "../../constants/assets";

import styles from "./training.module.scss";
import Involvements from "./involvments/involvements";
import AdminInvolvements from "./components/involvements/involvements";

const basePath = "/main/activities";

export const TrainingActivities = ({
  match,
  history,
}: RouteComponentProps<{ id?: string }>) => {
  const activityIdUrlParam = match?.params?.id;
  // I would rather keep this as string, in case we change int ID to some hash,
  // but alas...
  const activityIdFromUrl = activityIdUrlParam
    ? Number(activityIdUrlParam)
    : undefined;

  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);

  const [state, dispatch] = useReducer(reducer, initState);

  const [lastSelectedDate, setLastSelectedDate] = useState<Date | undefined>(
    undefined
  );
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    activityIdFromUrl ? undefined : new Date()
  );

  const [athletes, setAthletes] = useState([]);
  const [selectedAthlete, setSelectedAthlete] = useState<SelectOption>();

  const [goals, setGoals] = useState([]);
  const [activeGoalType, setActiveGoalType] = useState("CURRENT");
  const [isGoalLoading, setIsGoalLoading] = useState(true);

  const [currentMonthActivities, setCurrentMonthActivities] = useState({});
  const [activities, setActivities] = useState([]);

  const [activitiesTypes] = useState([
    { value: "ALL_ACTIVITIES", label: "All Activities" },
    { value: "MY_ACTIVITIES", label: "My Activities" },
  ]);
  const [selectedActivityType, setSelectedActivityType] = useState(
    activitiesTypes[0]
  );

  const [lastSelectedActivityId, setLastSelectedActivityId] = useState<
    number | undefined
  >();
  const [selectedActivityId, setSelectedActivityId] = useState<
    number | undefined
  >(activityIdFromUrl);
  const [isActivityLoading, setIsActivityLoading] = useState(false);

  const [showBlank, setShowBlank] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCoachRegistered, setIsCoachRegistered] = useState(true);

  const workSpaceRef = useRef<null | HTMLDivElement>(null);
  const selectedActivityButtonRef = useRef<null | HTMLDivElement>(null);

  const noGoalText =
    activeGoalType === "PAST"
      ? "There are no settled goals yet"
      : "There are no current goals yet";

  const trainingDetails = async (id: number) => {
    const response: any = await ActivityData({ id });
    if (response?.data) {
      dispatch({ type: "trainingDetails", payload: response.data });
    }

    dispatch({ type: "refreshData", payload: null });
    return response?.data;
  };

  const resultsDetails = async (id: number) => {
    const response: any = await TrainingResultsData({ id });
    if (response?.data) {
      dispatch({ type: "resultsDetails", payload: response.data });
    }

    dispatch({ type: "refreshData", payload: null });
  };

  const participantsDetails = async (id: number) => {
    if (appState.accountType === "admin") {
      const response: any = await ParticipantsData({
        id,
        filter: appState.accountType === "admin" ? "ALL" : "WAITING",
      });

      if (response?.data) {
        dispatch({ type: "participantsDetails", payload: response.data });
      }
    }

    dispatch({ type: "refreshData", payload: null });
  };

  const coachRegistersDetails = async (id: number) => {
    if (appState.accountType === "admin") {
      const response: any = await ParticipantCoachesDetails({
        id,
      });

      if (response?.data) {
        setIsCoachRegistered(response.data.length > 0);
        dispatch({ type: "coachRegistersDetails", payload: response.data });
      }
    }

    dispatch({ type: "refreshData", payload: null });
  };

  const athleteRegistrationStatus = async (id: number) => {
    if (appState.accountType === "athlete") {
      const response: any = await RegisterTrainingStatus({
        trainingId: id,
        athleteId: appState.id,
      });
      if (response?.data) {
        dispatch({
          type: "athleteRegistrationStatus",
          payload: response.data.status,
        });
        dispatch({
          type: "numOfPeopleAhead",
          payload: response.data.numOfPeopleAhead,
        });
      }
    }
    dispatch({ type: "refreshData", payload: null });
  };

  const coachRegistrationStatus = async (id: number) => {
    if (appState.accountType === "coach") {
      const response: any = await RegisterTrainingStatusCoach({
        trainingId: id,
        coachId: appState.id,
      });
      if (response?.data) {
        dispatch({
          type: "athleteRegistrationStatus",
          payload: response.data.status,
        });
      }
    }
    dispatch({ type: "refreshData", payload: null });
  };

  const trainingSummary = async (id: number) => {
    const response: any = await TrainingCapacityData({ id });
    if (response?.data) {
      dispatch({ type: "trainingSummary", payload: response.data });
    }

    dispatch({ type: "refreshData", payload: null });
  };
  const involvementDetails = async (id: number) => {
    const response: any = await GetInvolvement(id);
    if (!response?.data) {
      return;
    }
    dispatch({ type: "involvementDetails", payload: response.data });
  };
  const workSpaceData = async (id: number) => {
    const [detailsData] = await Promise.all([
      trainingDetails(id),
      resultsDetails(id),
      participantsDetails(id),
      coachRegistersDetails(id),
      athleteRegistrationStatus(id),
      coachRegistrationStatus(id),
      trainingSummary(id),
      involvementDetails(id),
    ]);

    return detailsData;
  };

  const fetchTrainingActivity = async (id: number) => {
    setIsActivityLoading(true);
    try {
      const data = await workSpaceData(id);
      const { startDate }: any = data;
      if (!selectedDate) {
        setSelectedDate(new Date(startDate.split("T")[0]));
      }
      setShowBlank(false);
      dispatch({ type: "detailsMode", payload: "Edit" });
      dispatch({ type: "readOnlyMode", payload: true });
      dispatch({ type: "resultsMode", payload: "View" });
    } catch {
      history.replace(basePath);
    }
    setIsActivityLoading(false);
  };

  const fetchTrainingActivities = async () => {
    setIsLoading(true);
    setShowBlank(false);
    dispatch({ type: "iniTrainingList", payload: false });
    const response: any = await TrainingActivitiesData({
      date: selectedDate,
      filter: selectedActivityType.value,
    });
    setIsLoading(false);
    if (response?.data?.length > 0) {
      if (
        response.data
          .map(({ id }: { id: number }) => id)
          .includes(selectedActivityId)
      ) {
        setIsActivityLoading(false);
      } else {
        const { id } = response.data[0];
        setSelectedActivityId(id);
      }
      setActivities(response.data);
      return;
    }
    setShowBlank(true);
    setActivities([]);
    setSelectedActivityId(undefined);
  };

  const fetchAllTrainingActivities = async (fromDate: Date) => {
    const response: any = await TrainingActivitiesData({
      date: getStartOfMonth(fromDate),
      filter: selectedActivityType.value,
      toDate: getEndOfMonth(fromDate),
    });
    if (response?.data?.length > 0) {
      const initialValue = {};
      const filteredObj = response.data.reduce(
        (obj: any, item: TrainingActivityInfo) => {
          const day = new Date(item.startDate);
          const dayMonthKey = `${day.getUTCDate()}-${day.getUTCMonth()}`;
          obj[dayMonthKey] = true;
          return obj;
        },
        initialValue
      );
      setCurrentMonthActivities(filteredObj);
    } else {
      setCurrentMonthActivities({});
    }
  };

  const fetchGoals = async (
    option:
      | boolean
      | SelectOption
      | ((prevState: SelectOption | undefined) => SelectOption | undefined)
      | { value: any }
      | undefined,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    activeGoal: string
  ) => {
    setIsGoalLoading(true);
    const response: any = await GoalsData({ option, activeGoal });
    if (response?.data) {
      setGoals(response.data);
      dispatch({ type: "refreshData", payload: null });
    }
    setIsGoalLoading(false);
  };

  const fetchAthletes = async () => {
    if (appState.accountType !== "admin") {
      return;
    }
    const response: any = await AthletesData();
    if (!response?.data) {
      return;
    }
    const athletesData = response.data.map(
      (item: {
        // TODO: Create Athlete type
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        mobile: string;
        parentName: string;
      }) => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
        email: item.email,
        mobile: item.mobile,
        parentName: item.parentName,
      })
    );
    setAthletes(athletesData);
    setSelectedAthlete(appState.trainingAthlete || athletesData[0]);
  };

  useEffect(() => {
    if (appState.accountType === "athlete") {
      fetchGoals({ value: appState.id }, activeGoalType);
      return;
    }
    fetchGoals(appState.trainingAthlete || selectedAthlete, activeGoalType);
  }, [appState.trainingAthlete, selectedAthlete]);

  useEffect(() => {
    if (!selectedDate) {
      if (!state.iniTrainingList) {
        return;
      }
      if (selectedActivityId) {
        fetchTrainingActivity(selectedActivityId);
        return;
      }
      const { id } = state.iniTrainingList;
      if (id) {
        setSelectedActivityId(id);
        return;
      }
      if (lastSelectedActivityId) {
        setSelectedActivityId(lastSelectedActivityId);
        return;
      }
      setSelectedDate(lastSelectedDate);
      return;
    }
    setLastSelectedDate(undefined);
    setIsLoading(true);
    setIsActivityLoading(true);
    fetchAthletes();
    fetchTrainingActivities();
    fetchAllTrainingActivities(selectedDate);
  }, [selectedDate, selectedActivityType]);

  useEffect(() => {
    if (!state.iniTrainingList) {
      return;
    }
    setSelectedDate(undefined);
  }, [state.iniTrainingList]);

  useEffect(() => {
    if (!selectedActivityId) {
      history.replace(basePath);
      return;
    }
    setLastSelectedActivityId(undefined);
    history.replace(`${basePath}/${selectedActivityId}`);
    fetchTrainingActivity(selectedActivityId);
  }, [selectedActivityId]);

  const getActivityButtonScroll = (
    ref: RefObject<HTMLDivElement>,
    retryCount = 0
  ) => {
    if (!selectedActivityId) {
      return;
    }
    const maxRetries = 3;
    if (retryCount > maxRetries) {
      return;
    }
    const { current } = ref;
    if (current === null) {
      setTimeout(() => {
        getActivityButtonScroll(ref, retryCount + 1);
      }, 500);
      return;
    }
    const { offsetParent, offsetTop } = current;
    if (offsetParent === null) {
      return;
    }
    // Let's nicely center the selected activity button
    const parentHeight = offsetParent.getBoundingClientRect().height;
    const margin = 10 * 2; // As per styles
    if (offsetTop >= parentHeight) {
      const { height } = current.getBoundingClientRect();
      offsetParent.scrollTop = offsetTop - (height + margin);
    }
  };

  useEffect(() => {
    if (isLoading === true) {
      return;
    }
    getActivityButtonScroll(selectedActivityButtonRef);
  }, [isLoading]);

  useEffect((): any => {
    switch (state.refreshData) {
      case "training":
        return trainingDetails(state.trainingDetails.id);
      case "trainingsList":
        return fetchTrainingActivities();
      case "involvements":
        return involvementDetails(state.trainingDetails.id);
      case "participants": {
        participantsDetails(state.trainingDetails.id);
        return coachRegistersDetails(state.trainingDetails.id);
      }
      case "results":
        return resultsDetails(state.trainingDetails.id);
      case "statuses":
        return athleteRegistrationStatus(state.trainingDetails.id);
      case "statusesCoach":
        return coachRegistrationStatus(state.trainingDetails.id);
      case "goals":
        return fetchGoals(
          appState.accountType === "athlete" && { value: appState.id },
          activeGoalType
        );
      case "goalsAdmin":
        return fetchGoals(
          appState.accountType === "admin" && { value: selectedAthlete?.value },
          activeGoalType
        );
      case "trainingSummary":
        return trainingSummary(state.trainingDetails.id);
      default:
        return false;
    }
  }, [state.refreshData]);

  const handleClickGoal = (e: any) => {
    setActiveGoalType(e.target.name);
    fetchGoals(
      appState.accountType === "admin"
        ? selectedAthlete
        : { value: appState.id },
      e.target.name
    );
  };

  const handleAthleteSelect = (
    option: SetStateAction<SelectOption | undefined>
  ) => {
    setSelectedAthlete(option);
    appDispatch({ type: "trainingAthlete", payload: option });

    fetchGoals(option, activeGoalType);
  };

  const handleMonthChange = (activeStartDate: Date) => {
    fetchAllTrainingActivities(activeStartDate);
  };

  const dropdownOption = (dropDownProps: any) => (
    <components.DropdownIndicator {...dropDownProps}>
      <ArrowDownDefault />
    </components.DropdownIndicator>
  );
  const handleStartDateChange = (value: Date) => {
    setSelectedDate(value);
    setActivities([]);
  };

  const handleClickShowForm = ({
    currentTarget,
  }: {
    currentTarget: { id: string };
  }) => {
    const { id } = currentTarget;
    dispatch({ type: "detailsMode", payload: "Edit" });
    dispatch({ type: "resultsMode", payload: "View" });
    dispatch({ type: "readOnlyMode", payload: true });

    setSelectedActivityId(Number(id));
  };

  const handleClickAddNew = () => {
    dispatch({ type: "resetDetails", payload: "" });
    dispatch({ type: "detailsMode", payload: "Add" });
    dispatch({ type: "resultsMode", payload: "View" });
    dispatch({ type: "readOnlyMode", payload: false });

    setIsActivityLoading(false);
    setShowBlank(false);
    setLastSelectedActivityId(selectedActivityId);
    setLastSelectedDate(selectedDate);
    setSelectedActivityId(undefined);

    const workSpaceOffset = workSpaceRef?.current?.getBoundingClientRect() || {
      y: 0,
    };
    if (workSpaceOffset.y > 110)
      workSpaceRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
  };

  const handleClickAddGoal = () => {
    appDispatch({ type: "globalModeLast", payload: "goalsAdd" });

    const modalComponent = (
      <AddGoal state={state.trainingDetails} dispatch={dispatch} />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  // TODO extract to its own component
  const renderTrainingActivity = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (activities.length > 0) {
      return (
        <div className={styles.listItems}>
          {activities.map((trainingActivity: any) => {
            return (
              <div
                role="button"
                aria-hidden="true"
                key={trainingActivity.id}
                className={clsx(
                  styles.item,
                  selectedActivityId === trainingActivity.id &&
                    styles.itemSelected
                )}
                ref={
                  selectedActivityId === trainingActivity.id
                    ? selectedActivityButtonRef
                    : undefined
                }
                id={trainingActivity.id}
                onClick={handleClickShowForm}
              >
                <div className={styles.itemTitle}>
                  <span>{trainingActivity.name}</span>
                </div>
                <div className={styles.itemDate}>
                  <span>
                    <CalendarIcon />{" "}
                    {formatDateCustom(
                      new Date(trainingActivity.startDate.replace("Z", ""))
                    )}
                  </span>
                </div>
                <div className={styles.itemTime}>
                  {formatTime(
                    new Date(trainingActivity.startDate.replace("Z", ""))
                  )}{" "}
                  -{" "}
                  {formatTime(
                    new Date(trainingActivity.endDate.replace("Z", ""))
                  )}
                  <span />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return <EmptyBlock type="activitiesList" text="No Activities" />;
  };

  // TODO extract to its own component
  const renderGoals = () => {
    if (isGoalLoading) {
      return <Spinner />;
    }
    if (goals.length > 0) {
      return (
        <div
          className={clsx(
            styles.goalsList,
            appState.accountType === "athlete" && styles.listHeight
          )}
        >
          {goals.map((goal: any) => (
            <div key={goal.id}>
              {activeGoalType === "PAST" ? (
                <PastGoal goal={goal} />
              ) : (
                <CurrentGoal goal={goal} />
              )}
            </div>
          ))}
        </div>
      );
    }
    return <EmptyBlock type="goals" text={noGoalText} />;
  };

  // TODO extract to its own component
  const renderActivityDetails = () => {
    if (isActivityLoading) {
      return <Spinner />;
    }
    return (
      <div
        ref={workSpaceRef}
        className={clsx(
          styles.workSpaceBlock,
          appState.accountType === "admin" &&
            !state.readOnlyMode &&
            styles.redBorder
        )}
      >
        {state.trainingDetails && <Details />}
        {state.participantsDetails &&
          state.detailsMode === "Edit" &&
          appState.accountType === "admin" &&
          state.trainingDetails.status !== "CANCELLED" && <Participants />}
        {state.resultsDetails &&
          state.trainingDetails.status === "DONE" &&
          ((appState.accountType === "athlete" &&
            state.athleteRegistrationStatus === "REGISTERED") ||
            (state.participantsDetails &&
              state.participantsDetails.length > 0)) && (
            <Results athletesData={athletes} />
          )}
        {state.trainingDetails.status === "DONE" &&
          appState.accountType === "coach" &&
          state.athleteRegistrationStatus !== "UN_REGISTERED" && (
            <Involvements />
          )}
        {state.trainingDetails.status === "DONE" &&
          appState.accountType === "admin" &&
          isCoachRegistered && <AdminInvolvements />}
      </div>
    );
  };

  const handleChangeActivity = (option: any) => {
    if (option.value !== selectedActivityType.value)
      setSelectedActivityType(option);
  };

  return (
    <>
      <div className={styles.trainingContainer}>
        <div className={styles.activitiesBlock}>
          <div className={styles.activitiesContent}>
            <div className={styles.function}>
              {appState.accountType === "admin" ? (
                <button type="button" onClick={handleClickAddNew}>
                  Add New Activity <Plus />
                </button>
              ) : (
                <div className={styles.selectActivity}>
                  <Select
                    className={styles.selectActivity}
                    name="activities"
                    placeholder=""
                    value={selectedActivityType}
                    options={activitiesTypes}
                    components={{ DropdownIndicator: dropdownOption }}
                    onChange={handleChangeActivity}
                  />
                </div>
              )}
            </div>

            <div className={styles.activitiesList}>
              <div className={styles.title}>
                <span>Training Activities</span>
              </div>
              <div className={styles.date}>
                {selectedDate && (
                  <>
                    <span>{formatDateMonthDay(selectedDate)} </span>
                    <span>{formatDateYear(selectedDate)}</span>
                  </>
                )}
              </div>
              {renderTrainingActivity()}
            </div>
          </div>
          <div className={styles.activitiesCalendar}>
            <DateCalendar
              value={selectedDate || null}
              onChange={handleStartDateChange}
              datesToAddContentTo={currentMonthActivities}
              onActiveStartDateChange={handleMonthChange}
            />
          </div>
        </div>

        {showBlank ? (
          <div className={styles.workSpaceBlock}>
            <EmptyBlock type="workSpace" text="No Activities" />
          </div>
        ) : (
          <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
              {renderActivityDetails()}
            </DispatchContext.Provider>
          </StateContext.Provider>
        )}

        {appState.accountType !== "coach" && (
          <div className={styles.goalsBlock}>
            {appState.accountType === "admin" && (
              <div className={styles.selectAthlete}>
                <Select
                  className={styles.selectAthlete}
                  name="athletes"
                  isSearchable={true}
                  placeholder="Choose the athlete"
                  value={selectedAthlete}
                  options={athletes}
                  components={{ DropdownIndicator: dropdownOption }}
                  onChange={handleAthleteSelect}
                />
              </div>
            )}

            <div className={styles.goals}>
              <div className={styles.goalsIcon}>
                <Goals />
                <span>Goals</span>
              </div>
              {appState.accountType === "athlete" && (
                <div
                  role="button"
                  aria-hidden="true"
                  className={styles.goalsButton}
                  onClick={handleClickAddGoal}
                >
                  <button type="button">
                    Set a Goal <Plus />
                  </button>
                </div>
              )}
            </div>

            <div className={styles.filter}>
              <div className={styles.tabs}>
                <button
                  type="button"
                  name="CURRENT"
                  className={`${
                    activeGoalType === "CURRENT" && styles.activeBtn
                  }  ${styles.tabsButtons}`}
                  onClick={handleClickGoal}
                >
                  Current Goals
                </button>
                <button
                  type="button"
                  name="PAST"
                  className={`${
                    activeGoalType === "PAST" && styles.activeBtn
                  }  ${styles.tabsButtons}`}
                  onClick={handleClickGoal}
                >
                  Past Goals
                </button>
              </div>
            </div>
            {renderGoals()}
          </div>
        )}
      </div>
    </>
  );
};

export default TrainingActivities;
