/* eslint-disable react-hooks/exhaustive-deps */

import clsx from "clsx";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { components } from "react-select";
import { ToastContainer } from "react-toastify";
import NumberFormat from "react-number-format";
import { isBefore } from "date-fns";
import { Timer } from "interfaces/interfaces";
import styles from "./addEventDetails.module.scss";
import {
  FormMark,
  ArrowDownDefault,
  Schedule,
} from "../../../constants/assets";
import EventsCategories from "../../../apis/eventsCategories";
import { Select, SelectOption } from "../../select/select";
import { AppContext } from "../../../App";
import {
  formatDefaultDate,
  formatTime24,
  textAreaMaxLength,
} from "../../../utils/utils";
import AgeSlider from "../../../common/ageSlider";
import { dynamicTimeBrackets, timeBrackets } from "../../../mocks/timeBrackets";
import AddEvent from "../../../apis/events/addEvent";
import { CurrentDateCalendar } from "../../current-date-calendar/current-date-calendar";
import toastMsg from "../../../common/toast";

interface IForm {
  location?: string;
  organizer?: string;
  category?: {
    id: number;
    name: string;
  };
  capacity: number | string;
  ageLowerLimit?: number;
  ageUpperLimit?: number;
  description?: string;
  startDate?: any;
  endDate?: any;
  eventName?: any;
  eventUrl?: string;
}

const EventDetails = ({ state, dispatch }: any) => {
  const { appDispatch } = useContext<any>(AppContext);

  const initialFormState = JSON.parse(JSON.stringify(state.eventDetails));
  const [form, setForm] = useState<IForm>(initialFormState);
  const {
    eventName,
    location,
    organizer,
    capacity,
    ageLowerLimit,
    ageUpperLimit,
    startDate,
    endDate,
    description,
    eventUrl,
  } = form;

  const [ageLabel, setAgeLabel] = useState<string>();
  const [showAgeSlider, setShowAgeSlider] = useState(false);

  const [eventsCategories, setEventsCategories] = useState([]);
  const [selectEventCategory, setSelectEventCategory] =
    useState<SelectOption>();

  const [time, setTime] = useState<Array<Timer>>([]);
  const [selectStartTimeBracket, setSelectStartTimeBracket] =
    useState<SelectOption>();
  const [selectEndTimeBracket, setSelectEndTimeBracket] =
    useState<SelectOption>();

  const [timeFilter, setTimeFilter] = useState(timeBrackets);

  const [readOnly] = useState(false);
  const [showError, setShowError] = useState(false);

  const [isValid, setIsValid] = useState<any>({
    eventName: false,
    capacity: false,
    location: false,
    category: false,
    startTime: true,
  });

  const setSelectedEventCategory = (
    option: SetStateAction<SelectOption | undefined>
  ) => {
    setSelectEventCategory(option);
    setIsValid((prev: any) => ({
      ...prev,
      category: true,
    }));
  };

  const dropdownOption = (dropDownProps: any) => (
    <components.DropdownIndicator {...dropDownProps}>
      <ArrowDownDefault />
    </components.DropdownIndicator>
  );
  const checkDateIncrement = (endTimeNewBracket: any[] | []) => {
    if (endTimeNewBracket.length === 0)
      return [{ value: "00:00", label: "12:00 AM" }];
    return endTimeNewBracket;
  };
  const processEndTimeBracket = (startTime: string) => {
    const valueIndex =
      time.findIndex((i: { value: string }) => i.value === startTime) + 1;
    const endTimeNewBracket = time.slice(valueIndex);
    const endDateObject = new Date(endDate);
    const validEndTimeBracket = checkDateIncrement(endTimeNewBracket);

    setTimeFilter(validEndTimeBracket);
    if (
      !validEndTimeBracket.some((t) => t.label === selectEndTimeBracket?.label)
    ) {
      setSelectEndTimeBracket(validEndTimeBracket[0]);
      setForm((prev) => ({
        ...prev,
        endDate: `${formatDefaultDate(
          new Date(
            validEndTimeBracket[0].value === "00:00"
              ? endDateObject.setDate(endDateObject.getDate() + 1)
              : endDateObject
          )
        )}T${validEndTimeBracket[0].value}:00.050`,
      }));
    }
  };
  const handleInputChange = (e: SetStateAction<SelectOption | any>) => {
    if (e.name === "startTime" && e.value) {
      processEndTimeBracket(e.value);

      setSelectStartTimeBracket(e);
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(startDate))}T${
          e.value
        }:00.050`,
      }));
      setIsValid((prev: any) => ({
        ...prev,
        startTime: true,
      }));
    } else if (e.name === "endTime" && e.value) {
      setSelectEndTimeBracket(e);
      setForm((prev) => ({
        ...prev,
        endDate: `${formatDefaultDate(new Date(endDate))}T${e.value}:00.050`,
      }));
    } else if (e.name === "startDate" && e.value) {
      const startTime = formatTime24(new Date(startDate));
      const endTime = formatTime24(new Date(endDate));
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(e.value))}T${
          startTime || "00:00"
        }:00.050`,
        endDate: `${formatDefaultDate(new Date(e.value))}T${
          endTime || "00:00"
        }:00.050`,
      }));
      setTime(dynamicTimeBrackets(new Date(e.value), 0, "Add"));
      setIsValid((prev: any) => ({
        ...prev,
        startTime: true,
      }));
    } else {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      if (isValid[e.target.name] !== undefined) {
        setIsValid((prev: any) => ({
          ...prev,
          [e.target.name]: !!e.target.value.length,
        }));
      }
    }
  };

  useEffect(() => {
    (async function events() {
      const response: any = await EventsCategories();
      if (response?.data) {
        const categories = response.data.map(
          (item: { id: any; name: any }) => ({
            value: item.id,
            label: `${item.name}`,
          })
        );
        setEventsCategories(categories);
      }
    })();

    // console.log("ss", state.eventDetails.startDate);

    setForm({
      ...state.eventDetails,
      startDate: state.eventDetails.startDate.replace("Z", ""),
      endDate: state.eventDetails.endDate.replace("Z", ""),
    });

    setTime(
      dynamicTimeBrackets(
        state.eventDetails.startDate.replace("Z", ""),
        0,
        "Add",
        readOnly
      )
    );
  }, [state.eventDetails]);

  useEffect(() => {
    if (time?.length > 0) {
      const addModeDate = time.filter(
        (item: any) =>
          item.value === formatTime24(new Date(startDate.replace("Z", "")))
      );
      const addModeEndDate = time.filter(
        (item: { value: string }) =>
          item.value === formatTime24(new Date(endDate.replace("Z", "")))
      );
      // console.log(addModeDate, startDate, time);
      if (addModeDate.length === 0) {
        setSelectStartTimeBracket(time[0]);
        processEndTimeBracket(time[0].value);
        // console.log("a");
      } else {
        setSelectStartTimeBracket(addModeDate[0]);
        setSelectEndTimeBracket(addModeEndDate[0]);
      }
    }
  }, [time]);

  useEffect(() => {
    if (selectStartTimeBracket !== undefined) {
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(form.startDate))}T${
          selectStartTimeBracket?.value || "00:00"
        }:00.050`,
      }));
    }
  }, [selectStartTimeBracket]);

  const handleAgeChange = ({
    text,
    lower,
    upper,
  }: {
    text: string;
    lower: number;
    upper: number;
  }) => {
    setAgeLabel(text);
    setForm({ ...form, ageLowerLimit: lower, ageUpperLimit: upper });
  };

  const handleAdd = () => {
    (async function add() {
      if (capacity === "0") {
        setForm((prev) => ({ ...prev, capacity: "" }));
        setIsValid((prev: any) => ({
          ...prev,
          capacity: false,
        }));

        setShowError(true);
        toastMsg("Required fields must be filled in.");
        return;
      }
      if (Object.values(isValid).includes(false)) {
        setShowError(true);
        if (!isValid.startTime) toastMsg("The event time can't be in the past");
        else toastMsg("Required fields must be filled in.");
        return;
      }

      if (!isBefore(new Date(startDate), new Date(endDate))) {
        setShowError(true);
        toastMsg("Event End Time should be after Start Time");
        return;
      }

      const response: any = await AddEvent({
        form: {
          ...form,
          startDate: `${startDate.replace("Z", "")}Z`,
          endDate: `${endDate.replace("Z", "")}Z`,
          category: {
            id: selectEventCategory?.value,
            name: selectEventCategory?.label,
          },
        },
      });
      if (response?.response?.data?.errorKey === "20123") {
        setShowError(true);
        toastMsg("The event time can't be in the past");
        setIsValid((prev: any) => ({
          ...prev,
          startTime: false,
        }));
      }

      if (response?.data) {
        appDispatch({ type: "hideModal" });
        dispatch({ type: "resetDetails" });
      }
    })();
  };

  return (
    <>
      <div className={styles.upcomingEventsWSContainer}>
        <div className={styles.block}>
          <div className={styles.form}>
            <ToastContainer className={styles.toast} position="bottom-right" />

            <div className={styles.formHeader}>
              <div className={styles.title}>
                <span>Add New Event</span>
              </div>
            </div>

            <form>
              <div className={styles.content}>
                <div className={styles.contentBlocks}>
                  <div className={styles.marker}>
                    <FormMark />
                    <span>Key Information</span>
                  </div>

                  <div className={styles.formContent}>
                    <div className={styles.inputsBlock}>
                      <div className={styles.rowFieldsBlock}>
                        <div className={styles.inputBlock}>
                          <label htmlFor="eventName" className={styles.label}>
                            *Event Name
                          </label>
                          <div className={styles.inputWrapper}>
                            <input
                              placeholder="Type the event name"
                              readOnly={readOnly}
                              required={true}
                              type="text"
                              name="eventName"
                              value={eventName}
                              className={clsx(
                                styles.input,
                                !isValid.eventName &&
                                  showError &&
                                  styles.errorInput
                              )}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles.rowFieldsBlock}>
                        <div className={styles.inputBlock}>
                          <label htmlFor="age" className={styles.label}>
                            *Age
                          </label>

                          <div
                            className={clsx(
                              styles.inputWrapper,
                              styles.agePosition
                            )}
                          >
                            <input
                              inputMode="none"
                              readOnly={readOnly}
                              type="text"
                              name="age"
                              value={ageLabel}
                              className={styles.input}
                              onFocus={() =>
                                !readOnly && setShowAgeSlider(true)
                              }
                            />
                            <AgeSlider
                              lower={ageLowerLimit}
                              upper={ageUpperLimit}
                              onChange={handleAgeChange}
                              isVisible={showAgeSlider}
                              onClickOutside={() => setShowAgeSlider(false)}
                            />
                          </div>
                        </div>
                        <div className={styles.inputBlock}>
                          <label htmlFor="eventUrl" className={styles.label}>
                            Event URL
                          </label>
                          <div
                            className={clsx(
                              styles.inputWrapper,
                              readOnly
                                ? styles.setPositionColorDisabled
                                : styles.setPositionColorEnabled
                            )}
                          >
                            <input
                              placeholder="www.example.com"
                              readOnly={readOnly}
                              required={true}
                              type="text"
                              value={eventUrl}
                              name="eventUrl"
                              className={styles.input}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.rowFieldsBlock}>
                        <div className={styles.inputBlock}>
                          <label htmlFor="catergory" className={styles.label}>
                            *Event Category
                          </label>
                          <div className={styles.inputWrapper}>
                            <Select
                              // TODO: Detect desktop and allow search for it?
                              isSearchable={false}
                              isDisabled={readOnly}
                              className={clsx(
                                styles.selectAthlete,
                                readOnly && styles.hideArrow,
                                !isValid.category &&
                                  showError &&
                                  styles.errorInput
                              )}
                              name="category"
                              placeholder="Choose a category"
                              value={selectEventCategory}
                              options={eventsCategories}
                              components={{ DropdownIndicator: dropdownOption }}
                              onChange={setSelectedEventCategory}
                            />
                          </div>
                        </div>
                        <div className={styles.inputBlock}>
                          <label htmlFor="location" className={styles.label}>
                            *Location
                          </label>
                          <div className={styles.inputWrapper}>
                            <input
                              placeholder="Type the location"
                              readOnly={readOnly}
                              required={true}
                              type="text"
                              name="location"
                              value={location}
                              className={clsx(
                                styles.input,
                                !isValid.location &&
                                  showError &&
                                  styles.errorInput
                              )}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles.rowFieldsBlock}>
                        <div className={styles.inputBlock}>
                          <label htmlFor="organizer" className={styles.label}>
                            Organizer
                          </label>
                          <div className={styles.inputWrapper}>
                            <input
                              placeholder="Type the organizer name"
                              readOnly={readOnly}
                              required={true}
                              type="text"
                              name="organizer"
                              value={organizer}
                              className={styles.input}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className={styles.inputBlock}>
                          <label htmlFor="capacity" className={styles.label}>
                            *Capacity
                          </label>
                          <div className={styles.inputWrapper}>
                            <NumberFormat
                              className={clsx(
                                styles.input,
                                !isValid.capacity &&
                                  showError &&
                                  styles.errorInput
                              )}
                              id="capacity"
                              name="capacity"
                              allowEmptyFormatting={true}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              value={capacity}
                              placeholder="Number"
                              readOnly={readOnly}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.description}>
                    <div>Description</div>
                    <div>
                      <textarea
                        readOnly={readOnly}
                        maxLength={textAreaMaxLength}
                        required={true}
                        name="description"
                        placeholder="Enter event description"
                        value={description}
                        className={styles.input}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.contentBlocks}>
                  <div className={styles.marker}>
                    <Schedule />
                    <span>Schedule</span>
                  </div>

                  <div className={styles.formContent}>
                    <div className={styles.inputsBlock}>
                      <div className={styles.rowFieldsBlock}>
                        <div className={styles.inputBlock}>
                          <label htmlFor="startTime" className={styles.label}>
                            *Start Date
                          </label>
                          <div className={styles.inputWrapper}>
                            <CurrentDateCalendar
                              minDate={state.startingDate}
                              value={formatDefaultDate(new Date(startDate))}
                              onChange={(e) =>
                                handleInputChange({
                                  name: "startDate",
                                  value: e,
                                })
                              }
                              className={clsx(styles.input, styles.datePicker)}
                              direction="floatLeftTop"
                              type="datePicker"
                              readOnly={readOnly}
                            />
                          </div>
                        </div>
                        <div className={styles.inputBlock}>
                          <label htmlFor="endTime" className={styles.label}>
                            *Start & End Time
                          </label>

                          <div className={styles.timeRow}>
                            <div
                              className={clsx(
                                styles.inputWrapper,
                                styles.inputWrapperTablet,
                                styles.timeField
                              )}
                            >
                              <Select
                                // TODO: Detect desktop and allow search for it?
                                menuPlacement="top"
                                isSearchable={false}
                                isDisabled={readOnly}
                                className={clsx(
                                  styles.selectAthlete,
                                  readOnly && styles.hideArrow,
                                  !isValid.startTime &&
                                    showError &&
                                    styles.errorInput
                                )}
                                value={selectStartTimeBracket}
                                name="startTime"
                                options={time}
                                onChange={handleInputChange}
                                components={{
                                  DropdownIndicator: dropdownOption,
                                }}
                              />
                            </div>
                            <div className={styles.splitter} />
                            <div
                              className={clsx(
                                styles.inputWrapper,
                                styles.inputWrapperTablet,
                                styles.timeField
                              )}
                            >
                              <Select
                                // TODO: Detect desktop and allow search for it?
                                menuPlacement="top"
                                isSearchable={false}
                                isDisabled={readOnly}
                                className={clsx(
                                  styles.selectAthlete,
                                  readOnly && styles.hideArrow
                                )}
                                value={selectEndTimeBracket}
                                name="endTime"
                                options={timeFilter}
                                onChange={handleInputChange}
                                components={{
                                  DropdownIndicator: dropdownOption,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={styles.actions}>
              <button
                type="button"
                className={styles.noBtn}
                onClick={() => appDispatch({ type: "hideModal" })}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className={styles.yesBtn}
                onClick={handleAdd}
              >
                <span>Create</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetails;
