import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const TrainingDashboard = async (data: {
  active: string;
  date: string;
  id: number;
}) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/coreservice/api/${data.active}`,
      params: {
        from: data.date,
        athleteId: data.id,
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default TrainingDashboard;
