import { FC, useContext } from "react";
import clsx from "clsx";
import { AppContext } from "../App";
import styles from "./modal.module.scss";
import { CloseModal } from "../constants/assets";

const Modal: FC<{ showModal: string }> = (props) => {
  const { showModal } = props;
  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);
  const position = appState.modalComponent?.props?.position || null;

  if (!showModal) {
    return <></>;
  }

  const handleClose = () => {
    appDispatch({ type: "hideModal" });
  };

  return (
    <>
      <div
        className={clsx(
          position === "rightFullHeight"
            ? styles.modalBlockCustom
            : styles.modalBlock
        )}
        {...props}
      >
        <div className={styles.spacing}>
          <div className={styles.modalBox}>
            <div
              role="button"
              aria-hidden="true"
              className={styles.closeModal}
              onClick={handleClose}
            >
              <CloseModal />
            </div>

            {appState.modalComponent}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
