/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useMediaMatch } from "rooks";
import clsx from "clsx";
import { ReactEcharts } from "../LazyReactEcharts";
import { ChartColor, GradientDirection } from "./color";
import { toEChartsColor } from "../utils";
import { ChartSize } from "../constants";
import { NoData } from "../no-data/no-data";
import styles from "./styles.module.scss";

const DEFAULT_LEGEND_LABEL_TEXT_STYLE = {
  color: "#f9fafd",
  size: "18",
};
const DEFAULT_LEGEND_VALUE_TEXT_STYLE = {
  color: "#f9fafd",
  size: "18",
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DONT_SHOW = {
  show: false,
};

type TextStyle = {
  color?: string;
  size?: number | string;
};

export interface ChartSegment {
  value: number;
  color: ChartColor;
  name?: string;
}

export interface DonutChartProps {
  // title?: string;
  radia?: [string, string];
  // hideValueFromLabel?: boolean;
  segments: Array<ChartSegment>;
  text: string;
  totalsTextStyle?: TextStyle;
  labelTextStyle?: TextStyle;
  hoverOffset?: number;
  titleStyle?: {
    left?: string;
    top?: string;
  };
}

export const DonutChart = ({
  radia,
  segments,
  text,
  hoverOffset = 10,
  titleStyle = {},
  totalsTextStyle = DEFAULT_LEGEND_VALUE_TEXT_STYLE,
  labelTextStyle = DEFAULT_LEGEND_LABEL_TEXT_STYLE,
}: DonutChartProps) => {
  const isSmallTablet = useMediaMatch(
    "(min-width: 768px) and (max-width: 930px)"
  );
  const isTablet = useMediaMatch("(min-width: 930px) and (max-width: 1024px)");
  const top = isSmallTablet ? "25%" : "35%";
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {!segments?.length ? (
        <NoData chartSize={ChartSize.Small} />
      ) : (
        <ReactEcharts
          className={clsx(styles.donutChart, {
            [styles.chartHeight]: segments?.length > 3 && isTablet,
          })}
          option={{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              padding: 0,
              itemGap: 4,
              itemWidth: 8,
              icon: "circle",
              bottom: 0,
              left: "center",
              textStyle: {
                fontFamily: "Montserrat",
                lineHeight: 16,
                color: "#222222",
                fontSize: 10,
                padding: [0, 0, 0, 0],
              },
            },
            series: [
              {
                type: "pie",
                selectedMode: "single",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                center: segments?.length > 3 ? ["50%", top] : ["50%", "50%"],
                radius: radia,
                avoidLabelOverlap: false,
                data: segments.map((segment) => ({
                  ...segment,
                  itemStyle: {
                    color: toEChartsColor(
                      segment.color,
                      GradientDirection.RightToLeft
                    ),
                  },
                })),
                itemStyle: {
                  borderColor: "white",
                  borderWidth: 10,
                  borderRadius: 50,
                },
                label: {
                  show: false,
                  fontSize: "12",
                  position: "center",
                  formatter(d: { name: any }) {
                    return d.name;
                  },
                },
                labelLine: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    color: "black",
                  },
                },
                emphasis: {
                  scale: true,
                  labelLine: {
                    show: true,
                    lineStyle: {
                      width: 2,
                      color: "black",
                    },
                  },
                  label: {
                    show: true,
                    formatter: ["{a|{d}%}", "{b|{b}}"].join("\n"),
                    rich: {
                      a: {
                        fontSize: 20,
                        fontWeight: "bold",
                        fontFamily: "Montserrat",
                        color: "#222222",
                        lineHeight: 28,
                      },
                      b: {
                        fontSize: 10,
                        fontFamily: "Montserrat",
                        color: "#222222",
                        lineHeight: 16,
                      },
                    },
                  },
                },
              },
            ],

            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  alignTo: "connectors",
                  enabled: true,
                  formatter: "{b} {d}%",
                },
              },
            },
          }}
          notMerge={true}
          lazyUpdate={true}
        />
      )}
    </div>
  );
};
