import { useContext } from "react";
import Menu from "../menu/menu";
import Account from "../account/account";
import styles from "./header.module.scss";
import { logoCircle } from "../../constants/assets";
import { AppContext } from "../../App";

const Header = () => {
  const { appState } = useContext<any>(AppContext);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={logoCircle} alt="" />
          <div />
        </div>
        <Menu />

        {appState.superAdmin && <Account />}
      </div>
    </>
  );
};

export default Header;
