import React, { FC } from "react";
import Calendar from "react-calendar";
import { format } from "date-fns";

import "./date-calendar.overwrite.scss";
import styles from "./date-calendar.module.scss";

type Props = {
  className?: string;
  datesToAddContentTo: any;
  onChange: (value: Date) => void;
  onActiveStartDateChange: (activeStartDate: Date) => void;
  value: Date | null;
  minDate?: Date;
};

export const DateCalendar: FC<Props> = ({
  value,
  onChange,
  onActiveStartDateChange,
  datesToAddContentTo,
  className,
  minDate,
}) => {
  const tileContent = ({ date }: any) => {
    const dayMonthKey = `${date.getDate()}-${date.getMonth()}`;
    if (datesToAddContentTo && datesToAddContentTo[dayMonthKey])
      return (
        <div className={styles.outer}>
          <div className={styles.inner} />
        </div>
      );
    return null;
  };

  const onMonthChange = ({ activeStartDate }: any) => {
    onActiveStartDateChange(activeStartDate);
  };
  return (
    <div className="current-date-calendar">
      <Calendar
        key={value && value.getTime()}
        calendarType="US"
        formatShortWeekday={(locale, date: Date) => format(date, "EEEEEE")}
        value={value}
        onChange={onChange}
        onActiveStartDateChange={onMonthChange}
        next2Label={null}
        prev2Label={null}
        className={className}
        locale="en"
        tileContent={tileContent}
        minDate={minDate}
        showFixedNumberOfWeeks={true}
      />
    </div>
  );
};
