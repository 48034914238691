import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const Reset = async (
  data: { password: string; confirmPassword: string },
  token: string | null
) => {
  try {
    return await axios({
      method: "POST",
      baseURL: `${API_ENDPOINT}/authservice/password/resetPassword`,
      data: {
        password: data.password,
        confirmPassword: data.confirmPassword,
        token,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default Reset;
