import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const AthletesGroup = async (id: number) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/coreservice/api/athlete-groups/groups//${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default AthletesGroup;
