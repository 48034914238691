import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import GetRegisters from "apis/events/getRegisters";
import GetCoachRegisters from "apis/events/getCoachRegisters";
import CoachList from "apis/management/coachList";
import styles from "./participants.module.scss";
import { Plus, TrashIcon, PencilIcon } from "../../../../../constants/assets";
import { AppContext } from "../../../../../App";
import StateContext from "../../../../../context/StateContext";
import AddEditParticipant from "../../addEditParticipant";
import DeleteParticipants from "../../deleteParticipant";
import DispatchContext from "../../../../../context/DispatchContext";
import { useBreakpoints } from "../../../../../hooks/use-breakpoints";

const Participants = () => {
  const { appDispatch, appState } = useContext<any>(AppContext);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [results, setResults] = useState([]);
  const [registeredCoaches, setRegisteredCoaches] = useState([]);
  const [coachList, setCoachList] = useState([]);
  const [eventStatus, setEventStatus] = useState(String);
  const { participantCoaches, participantAthletes, eventDetails } = state;
  const { isMobile } = useBreakpoints();

  const fetchParticipant = async (id: number) => {
    const response: any = await GetRegisters({
      id,
      status: appState.accountType === "admin" ? "REGISTER" : "WAITING",
    });
    if (response?.data) {
      const athletes = response.data.map(
        (item: {
          id: any;
          athlete: {
            id: any;
            firstName: any;
            lastName: any;
            email: any;
            mobile: any;
            parentName: any;
          };
        }) => ({
          id: item.id,
          value: item.athlete.id,
          label: `${item.athlete.firstName} ${item.athlete.lastName}`,
          email: item.athlete.email,
          mobile: item.athlete.mobile,
          parentName: item.athlete.parentName,
        })
      );

      setResults(athletes);
    }
  };

  const fetchCoaches = async () => {
    const response: any = await CoachList();
    if (response?.data) {
      setCoachList(response.data);
    }
  };

  const fetchRegisteredCoach = async (id: number) => {
    const response: any = await GetCoachRegisters({ id });
    if (response?.data) {
      const coaches = response.data.map(
        (item: {
          id: any;
          coach: {
            id: any;
            firstName: any;
            lastName: any;
            email: any;
            mobile: any;
            parentName: any;
          };
        }) => ({
          id: item.id,
          value: item.coach.id,
          label: `${item.coach.firstName} ${item.coach.lastName}`,
          email: item.coach.email,
          mobile: item.coach.mobile,
          parentName: item.coach.parentName,
        })
      );
      setRegisteredCoaches(coaches);
    }
  };

  useEffect(() => {
    fetchParticipant(eventDetails.id);
  }, [participantAthletes]);

  useEffect(() => {
    fetchCoaches();
    fetchRegisteredCoach(eventDetails.id);
  }, [participantCoaches]);

  useEffect(() => {
    setEventStatus(eventDetails?.status);
  }, [eventDetails]);

  const handleAdd = (participantType: string) => {
    const modalComponent = (
      <AddEditParticipant
        mode="Add"
        participantType={participantType}
        selectedParticipants={
          participantType === "coach" ? registeredCoaches : results
        }
        state={eventDetails}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleDelete = (id: number, participantType: string) => {
    const modalComponent = (
      <DeleteParticipants
        participantId={id}
        participantType={participantType}
        state={eventDetails}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleEdit = (participantInfo: any, participantType: string) => {
    const modalComponent = (
      <AddEditParticipant
        participantInfo={participantInfo}
        selectedParticipants={
          participantType === "coach" ? registeredCoaches : results
        }
        participantType={participantType}
        mode="Edit"
        state={eventDetails}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const renderAdd = (addText: string, participantType: string) => {
    return (
      <div className={styles.actions}>
        <button
          type="button"
          className={styles.addBtn}
          onClick={() => handleAdd(participantType)}
        >
          <Plus />
          <span>{addText}</span>
        </button>
      </div>
    );
  };

  const renderParticipants = (participant: any, participantType: string) => {
    return (
      <div className={styles.athleteDetailsBlock} key={participant.value}>
        <div className={styles.athleteInfo}>
          <div className={styles.avatarName}>
            <span>{participant.label} </span>
          </div>

          {eventStatus === "PENDING" && (
            <div className={styles.athleteActions}>
              <div
                role="button"
                aria-hidden="true"
                className={styles.edit}
                onClick={() => handleEdit(participant, participantType)}
              >
                <PencilIcon />
              </div>
              <div
                role="button"
                aria-hidden="true"
                className={styles.delete}
                onClick={() => handleDelete(participant.value, participantType)}
              >
                <TrashIcon />
              </div>
            </div>
          )}
        </div>

        <div className={styles.athleteDetails}>
          <div>
            <span> Parent Name</span>
            <span> {participant.parentName}</span>
          </div>
          <div className={styles.groupRow}>
            <div>
              <span> Mobile</span>
              <span> {participant.mobile}</span>
            </div>
            <div>
              <span> Email</span>
              <span> {participant.email}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={clsx(
        styles.block,
        results?.length === 0 &&
          registeredCoaches?.length === 0 &&
          eventStatus !== "PENDING" &&
          isMobile &&
          styles.hideBlock,
        results?.length === 0 &&
          registeredCoaches?.length === 0 &&
          styles.fullHeight
      )}
    >
      {results?.length === 0 &&
      registeredCoaches?.length === 0 &&
      eventDetails.id ? (
        <div className={styles.noDataBlock}>
          {renderAdd("Add Athlete", "athlete")}
          {renderAdd("Add Junior Coach", "coach")}
        </div>
      ) : (
        <>
          {(eventStatus === "PENDING" || results?.length > 0) && (
            <div className={styles.categoryTitle}>Athlete Registration</div>
          )}
          <div
            className={clsx(
              styles.formHeader,
              results?.length && styles.setHeight
            )}
          >
            {results?.length > 0 ? (
              <>
                {results?.map((result: any) => {
                  return renderParticipants(result, "athlete");
                })}
                {eventStatus === "PENDING" &&
                  eventDetails.capacity !== results?.length &&
                  renderAdd("Add Athlete", "athlete")}
              </>
            ) : (
              eventStatus === "PENDING" &&
              eventDetails.id &&
              renderAdd("Add Athlete", "athlete")
            )}
          </div>
          {(eventStatus === "PENDING" || registeredCoaches?.length > 0) && (
            <div className={styles.categoryTitle}>
              Junior Coach Registration
            </div>
          )}
          <div
            className={clsx(
              styles.formHeader,
              registeredCoaches?.length && styles.setHeight
            )}
          >
            {registeredCoaches?.length > 0 ? (
              <>
                {registeredCoaches?.map((participantCoach: any) => {
                  return renderParticipants(participantCoach, "coach");
                })}
                {eventStatus === "PENDING" &&
                  coachList?.length !== registeredCoaches.length &&
                  renderAdd("Add Junior Coach", "coach")}
              </>
            ) : (
              eventStatus === "PENDING" &&
              eventDetails.id &&
              coachList?.length > 0 &&
              renderAdd("Add Junior Coach", "coach")
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Participants;
