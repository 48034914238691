import { FC } from "react";
import styles from "./managementBoard.module.scss";
import Menu from "../managementMenu/menu";

const ManagementBoard: FC = () => {
  return (
    <>
      <div className={styles.management}>
        <div className={styles.backList}>
          <div className={styles.title}>
            <span>Account Management</span>
          </div>
        </div>
        <Menu />
      </div>
    </>
  );
};

export default ManagementBoard;
