import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const ValidateToken: any = async () => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/authservice/api/users/refreshToken`,
      params: { rememberMe: localStorage.getItem("rememberMe") },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default ValidateToken;
