import ProgressBar from "react-customizable-progressbar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DashedProgress } from "react-dashed-progress";
import { differenceInDays, intervalToDuration } from "date-fns";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { formatDateSTD, lowerCase } from "../../../utils/utils";
import { LargeTick } from "../../../constants/assets";
import styles from "./currentGoal.module.scss";
import ActivityIcon from "./icons";

const CurrentGoal = ({ ...props }: any) => {
  const goal = { ...props.goal };
  const [goalAchieved, setGoalAchieved] = useState(Number);
  const [overtime, setOvertime] = useState(false);
  const [differenceDays, setDifferenceDays] = useState(Number);
  const [finalResultAfterConversion, setFinalResultAfterConversion] =
    useState(Number);
  const [remDays, setRemDays] = useState(Object);

  useEffect(() => {
    setGoalAchieved((goal.actualValue * 100) / goal.goalValue);
    const diff = differenceInDays(
      new Date(goal.targetCompletionDate.replace("Z", "")),
      new Date()
    );
    setDifferenceDays(Math.abs(diff));
    setOvertime(diff < 0);
    const diffDurationTargetCurrent: any = intervalToDuration({
      start: new Date(),
      end: new Date(goal.targetCompletionDate.replace("Z", "")),
    });
    const diffDurationTargetStart: any = intervalToDuration({
      start: new Date(goal.targetCompletionDate.replace("Z", "")),
      end: new Date(goal.startDate),
    });
    const resultInPercentage =
      100 -
      (diffDurationTargetCurrent.days * 100) / diffDurationTargetStart.days;
    setRemDays(diffDurationTargetCurrent);
    const resultPercentage = diff < 0 ? 100 : (resultInPercentage * 40) / 100;
    setFinalResultAfterConversion(resultPercentage);
  }, []);

  return (
    <>
      <div className={styles.block}>
        <div className={styles.activity}>
          <div className={styles.activityType}>
            {ActivityIcon(goal.category.name)}
            <span>{goal.category.name}</span>
          </div>
          {goal.status === "ACHIEVED" && (
            <div className={styles.status}>
              <>
                <LargeTick /> <span>Achieved</span>
              </>
            </div>
          )}
        </div>

        <div className={styles.verticalBoxes}>
          <div className={styles.goal}>
            <div className={styles.goalIcon}>
              <span> {lowerCase(goal.goalType)} </span>
            </div>

            <div className={styles.goalkcal}>
              <div className={styles.element}>
                <ProgressBar
                  radius={40}
                  className={styles.RCP__progress}
                  progress={goalAchieved > 100 ? 100 : goalAchieved}
                  strokeWidth={4}
                  strokeColor="#fdac97"
                  trackStrokeWidth={9}
                  trackStrokeColor="#f9f9f9"
                  pointerRadius={6}
                  pointerStrokeWidth={4}
                  pointerStrokeColor="#fa582e"
                  pointerFillColor="#fa582e"
                />

                <span className={styles.unit}>{goal.goalUnit} </span>

                <span className={styles.unitNumber}>{goal.goalValue}</span>
              </div>
            </div>

            <div className={styles.info}>
              <div className={styles.infoData}>
                <span>
                  {formatDateSTD(
                    new Date(goal.targetCompletionDate.replace("Z", ""))
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.goal}>
            <div className={styles.goalIcon}>
              <span> Days to target completion date</span>
            </div>

            <div className={styles.goalkcal}>
              <div className={styles.element}>
                <div className={styles.dashedCircle}>
                  <DashedProgress
                    value={finalResultAfterConversion}
                    countBars={40}
                    strokeThickness={2}
                    trailThickness={2}
                    trailColor="#E7E7E7"
                    strokeColor={overtime ? "#DE3636" : "#007AFF"}
                    showTooltip={false}
                  />
                </div>
                <span
                  className={clsx(styles.unitRight, overtime && styles.setRed)}
                >
                  {overtime ? differenceDays : remDays.days} <span>d</span>
                </span>

                <span className={styles.unitNumberRight}>
                  {overtime
                    ? "Overtime"
                    : `${remDays.hours}h : ${remDays.minutes}m`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentGoal;
