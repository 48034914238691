import { useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { durationToMin, minToDuration } from "utils/utils";
import AddResult from "apis/events/addResult";
import UpdateResult from "apis/events/updateResult";
import styles from "./addEditResult.module.scss";
import { AppContext } from "../../../App";
import {
  CaloriesResults,
  Distance,
  Duration,
  Results,
  Speed,
} from "../../../constants/assets";

interface IForm {
  id?: number;
  distance?: string;
  duration?: string;
  calories?: string;
  speed?: string;
}

interface Fields {
  id?: boolean;
  speed?: boolean;
  distance?: boolean;
  duration?: boolean;
  calories?: boolean;
}

const AddEditResult = ({
  data,
  mode,
  setUpdateResults,
  setInsertResults,
}: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { appState } = useContext<any>(AppContext);
  const [formData, setFormData] = useState<IForm>({
    ...data,
    duration: minToDuration(data.duration),
  });
  const [showError, setShowError] = useState(false);
  const [saveDone] = useState(false);
  const [isValid, setIsValid] = useState<Fields>({});

  const handleSave = () => {
    if (Object.values(isValid).includes(false)) {
      setShowError(true);
      return;
    }

    (async function update() {
      if (mode === "Edit") {
        const duration = durationToMin(
          formData.duration?.replace(/[hm: ]/g, "")
        );

        const response: any = await UpdateResult({
          form: {
            distance: formData.distance,
            speed: formData.speed,
            calories: formData.calories,
            duration: duration ? duration.toString() : formData.duration,
            event: {
              id: data.event.id,
            },
            athlete: {
              id: appState.id,
            },
          },
          id: data.id,
        });
        if (response.status === 200) {
          appDispatch({ type: "hideModal" });
          setUpdateResults(response.data);
        }
      } else {
        const response: any = await AddResult({
          distance: formData.distance,
          speed: formData.speed,
          calories: formData.calories,
          duration: durationToMin(formData.duration).toString(),
          event: {
            id: data.id,
          },
          athlete: {
            id: appState.id,
          },
        });
        if (response?.data) {
          appDispatch({ type: "hideModal" });
          setInsertResults(response.data);
        }
      }
    })();
  };

  const handleCloseModal = useCallback(() => {
    appDispatch({ type: "hideModal" });
  }, []);

  const handleInputChange = (e: any) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setIsValid((prev) => ({
      ...prev,
      [e.target.name]: !!e.target.value.length,
    }));
  };

  const handleChange = (e: NumberFormatValues) => {
    setFormData((prev) => ({ ...prev, duration: e.value }));
    setIsValid((prev) => ({
      ...prev,
      duration: e.value.length === 4,
    }));
  };

  useEffect(() => {
    if (mode === "Add") {
      setIsValid({
        speed: false,
        distance: false,
        duration: false,
        calories: false,
      });
    } else {
      setIsValid({
        speed: true,
        distance: true,
        duration: true,
        calories: true,
      });
    }
    setShowError(false);
  }, [mode]);

  return (
    <>
      <div className={styles.addEditBlock}>
        <>
          <div className={styles.title}>
            <Results />
            <span>{mode} Your Results</span>
          </div>
          <div className={styles.addNewResults}>
            <div className={styles.resultsDetailsBlock}>
              <form>
                <div className={clsx(styles.inputBlock, styles.subInput)}>
                  <div className={styles.inputWrapper}>
                    <input
                      type="text"
                      name="parentName"
                      className={styles.input}
                      readOnly={true}
                      value={`${appState.firstName} ${appState.lastName}`}
                    />
                  </div>
                </div>

                <div className={styles.bottomSection}>
                  <div className={styles.bottomDetails}>
                    <div className={styles.results}>
                      <div className={styles.distance}>
                        <Distance />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.distance && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="distance"
                            name="distance"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.distance}
                            onChange={handleInputChange}
                            placeholder="0"
                          />
                          <span className={styles.measure}>km</span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.results}>
                      <div className={styles.speed}>
                        <Speed />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.speed && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="speed"
                            name="speed"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.speed}
                            onChange={handleInputChange}
                            placeholder="0"
                          />
                          <span className={styles.measure}>km/h</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.bottomDetails}>
                    <div className={styles.results}>
                      <div className={styles.duration}>
                        <Duration />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.duration && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="duration"
                            name="duration"
                            readOnly={saveDone}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            format="##h : ##m"
                            mask="_"
                            prefix=""
                            value={formData.duration}
                            onValueChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.results}>
                      <div className={styles.calories}>
                        <CaloriesResults />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.calories && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="calories"
                            name="calories"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.calories}
                            onChange={handleInputChange}
                            placeholder="0"
                          />

                          <span className={styles.measure}>kcal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={handleCloseModal}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className={clsx(
                styles.yesBtn,
                Object.values(isValid).includes(false) && styles.buttonDisabled
              )}
              onClick={handleSave}
            >
              <span>Save</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default AddEditResult;
