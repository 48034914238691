import {
  startEndDate,
  roundTimeQuarterHour,
  startingDate,
  formatDefaultDate,
} from "../../utils/utils";

export const initState = {
  trainingDetails: {
    status: "PENDING",
    eventName: "",
    startDate: `${formatDefaultDate(startingDate(1))}T09:00:00.050`,
    endDate: `${formatDefaultDate(startingDate(1))}T10:00:00.050`,
    name: "",
    category: { name: "", value: "" },
    location: "",
    organizer: "",
    capacity: "",
    age: "All ages",
    ageLowerLimit: 1,
    ageUpperLimit: 99,
    description: "",
    recurrence: "NONE",
    recurrenceEndDate: startEndDate(8, 0, 0),
    recurrenceDescription: "",
  },
  participantsDetails: [],
  coachRegistersDetails: [],
  coachesDetails: [],
  resultsDetails: [],
  involvementDetails: [],
  detailsMode: "Edit",
  resultsMode: "View",
  involvmentMode: "Hide",
  readOnlyMode: true,
  showBlank: true,
  iniTrainingList: false,
  athleteRegistrationStatus: null,
  refreshData: null,
  startingDate: startingDate(0),
  trainingSummary: { full: null },
  numOfPeopleAhead: null,
  rowID: -1,
};
const reducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case "initTime": {
      return {
        ...state,
        startingDate: startingDate(0),
        trainingDetails: {
          ...state.trainingDetails,
          startDate: `${roundTimeQuarterHour(
            startEndDate(1, 4, 0)
          ).toISOString()}`,
          endDate: `${roundTimeQuarterHour(
            startEndDate(1, 4, 15)
          ).toISOString()}`,
        },
      };
    }

    case "involvementDetails":
      return {
        ...state,
        involvementDetails: action.payload,
      };

    case "setRowID":
      return {
        ...state,
        rowID: action.payload,
      };

    case "wipeInvolvementData": {
      return {
        ...state,
        involvementDetails: [],
      };
    }
    case "trainingDetails":
      return {
        ...state,
        trainingDetails: action.payload,
      };
    case "trainingSummary":
      return {
        ...state,
        trainingSummary: action.payload,
      };
    case "resultsDetails":
      return {
        ...state,
        resultsDetails: action.payload,
      };
    case "participantsDetails":
      return {
        ...state,
        participantsDetails: action.payload,
      };
    case "coachRegistersDetails":
      return {
        ...state,
        coachRegistersDetails: action.payload,
      };
    case "refreshData": {
      return {
        ...state,
        refreshData: action.payload,
      };
    }
    case "detailsMode": {
      return {
        ...state,
        detailsMode: action.payload,
      };
    }
    case "involvementMode": {
      return {
        ...state,
        involvementMode: action.payload,
      };
    }
    case "readOnlyMode": {
      return {
        ...state,
        readOnlyMode: action.payload,
      };
    }
    case "resultsMode": {
      return {
        ...state,
        resultsMode: action.payload,
      };
    }
    case "resetDetails":
      return {
        ...initState,
      };
    case "iniTrainingList":
      return {
        ...state,
        iniTrainingList: action.payload,
      };
    case "athleteRegistrationStatus":
      return {
        ...state,
        athleteRegistrationStatus: action.payload,
      };
    case "numOfPeopleAhead":
      return {
        ...state,
        numOfPeopleAhead: action.payload,
      };

    case "trainingStatus": {
      return {
        ...state,
        trainingDetails: {
          ...state.trainingDetails,
          status: action.payload,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default reducer;
