import { FC } from "react";
import { withRouter } from "react-router-dom";
import { Congrat } from "../../../constants/assets";
import styles from "./congratsResetPassword.module.scss";

const CongratsResetPassword: FC = ({ history }: any) => {
  const handleProceed = () => {
    history.push("/home/login/");
  };
  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.congratsIcon}>
          <Congrat />
        </div>
        <span className={styles.congrats}>Success!</span>
        <span className={styles.done}>
          Your password has been changed <br />
          successfully!
        </span>
        <div className={styles.buttonBlock}>
          <button
            type="button"
            className={styles.button}
            onClick={handleProceed}
          >
            Back to sign in
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CongratsResetPassword);
