import axios from "axios";
import { API_ENDPOINT } from "../../constants/const";

const UpdateActivityDetails = async (data: { id: any; form: any }) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "PUT",
      baseURL: `${API_ENDPOINT}/coreservice/api/trainings/${data.id}`,
      data: { ...data.form },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default UpdateActivityDetails;
