import { MenuButton, MenuItem, Menu } from "@szhsin/react-menu";
import clsx from "clsx";
import { DotsBtn } from "constants/assets";
import { FC, useCallback } from "react";
import "./menuActions.css";

type Props = {
  onClick: (val: any) => void;
  actions: string[];
  offsetY?: number;
  offsetX?: number;
  className?: string;
};

const MenuActions: FC<Props> = ({
  onClick,
  actions,
  offsetY = 5,
  offsetX = 0,
  className = "className",
}) => {
  const handleClick = useCallback(
    (event: any) => {
      onClick(event);
    },
    [onClick]
  );

  return (
    <>
      {actions.length ? (
        <Menu
          className={clsx(className)}
          menuButton={
            <MenuButton>
              <DotsBtn />
            </MenuButton>
          }
          key="bottom"
          direction="bottom"
          align="end"
          offsetY={offsetY}
          offsetX={offsetX}
        >
          {actions.map((action) => (
            <MenuItem
              key={action}
              value={action}
              className={clsx(actions.length === 1 && "oneItem")}
              onClick={handleClick}
            >
              {action}
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <></>
      )}
    </>
  );
};

export default MenuActions;
