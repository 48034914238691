import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import validator from "validator";
import styles from "./resetPassword.module.scss";
import {
  SignInLock,
  PasswordInputEye,
  TiTick,
  Warning,
} from "../../../constants/assets";
import Reset from "../../../apis/auth/reset";
import useQuery from "../../../utils/queryParams";
import VerifyResetPasswordLink from "../../../apis/auth/verifyResetPasswordLink";

const ResetPassword: FC = ({ history }: any) => {
  const query = useQuery();
  const token = query.get("token");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrengthBar, setPasswordStrengthBar] =
    useState<{ width: string }>();
  const [strength, setStrength] = useState("Weak");
  const [hintError, setHintError] = useState(false);
  const [alertMsg, setAlertMsg] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);

  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  const [isValid, setIsValid] = useState({
    password: true,
    confirmPassword: true,
  });

  useEffect(() => {
    (async function verify() {
      const response: any = await VerifyResetPasswordLink(token);
      if (response.status !== 200) {
        history.push("/home/accounts");
      }
    })();
  }, []);

  useEffect(() => {
    if (
      !isValid.confirmPassword &&
      form.password.length > 0 &&
      form.confirmPassword.length > 0
    )
      setIsValid((prev) => ({
        ...prev,
        confirmPassword:
          form.password === form.confirmPassword && form.password.length > 0,
      }));
  }, [form]);

  const passwordStrength = (password: string, returnScore = true) => {
    return validator.isStrongPassword(password, {
      minLength: 8,
      minLowercase: 0,
      minUppercase: 1,
      minNumbers: 0,
      minSymbols: 1,
      returnScore,
      pointsPerUnique: 0,
      pointsPerRepeat: 0,
      pointsForContainingLower: 5,
      pointsForContainingUpper: 10,
      pointsForContainingNumber: 5,
      pointsForContainingSymbol: 10,
    });
  };
  const handleInputChange = (e: any) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name === "password") {
      const password = {
        strength: passwordStrength(e.target.value),
        strong: passwordStrength(e.target.value, false),
      };
      if (!password.strong) {
        setPasswordStrengthBar({
          width: e.target.value.length === 0 ? "0%" : "33%",
        });
        setStrength("Weak");
      } else if (password.strong && e.target.value.length < 16) {
        setPasswordStrengthBar({ width: "66%" });
        setStrength("Medium");
      } else if (password.strong && e.target.value.length >= 16) {
        setPasswordStrengthBar({ width: "100%" });
        setStrength("Strong");
      }

      setIsValid((prev) => ({ ...prev, password: password.strong }));
    } else if (
      e.target.name === "confirmPassword" &&
      form.password.length === 0
    ) {
      setIsValid((prev) => ({ ...prev, password: false }));
    }
  };

  const showPasswordHandler = (target: string, bool: boolean) => {
    if (target === "passwordEye") setShowPassword(bool);
    else if (target === "confirmPasswordEye") setShowConfirmPassword(bool);
  };

  const handlePasswordEyeMouseDown = (e: {
    currentTarget: { name: string };
  }) => {
    if ("ontouchend" in document) {
      return;
    }
    showPasswordHandler(e.currentTarget.name, true);
  };
  const handlePasswordEyeMouseUp = (e: { currentTarget: { name: string } }) => {
    if ("ontouchend" in document) {
      return;
    }
    showPasswordHandler(e.currentTarget.name, false);
  };

  const handlePasswordEyeTouchStart = (e: {
    currentTarget: { name: string };
  }) => {
    showPasswordHandler(e.currentTarget.name, true);
  };

  const handlePasswordEyeTouchEnd = (e: {
    currentTarget: { name: string };
  }) => {
    showPasswordHandler(e.currentTarget.name, false);
  };

  const handlePasswordEyeKeyDown = (e: {
    code: string;
    currentTarget: { name: string };
  }) => {
    if (e.code === "Space" || e.code === "Enter") {
      showPasswordHandler(e.currentTarget.name, true);
    }
  };

  const handlePasswordEyeKeyUp = (e: {
    code: string;
    currentTarget: { name: string };
  }) => {
    if (e.code === "Space" || e.code === "Enter") {
      showPasswordHandler(e.currentTarget.name, false);
    }
  };

  const validatePassword = () => passwordStrength(form.password, false);
  const validateConfirmPassword = () =>
    form.password === form.confirmPassword && form.password.length > 0;

  const validateForm = () => {
    setIsValid({
      password: validatePassword(),
      confirmPassword: validateConfirmPassword(),
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (validatePassword() && validateConfirmPassword()) {
      e.preventDefault();
      setHintError(false);

      const response: any = await Reset(form, token);
      if (response?.data) history.push("/home/congratsResetPassword");
      else if (response.response.data.errorKey === "10305") {
        setAlertMsg("Token expired.");
        setShowAlert(true);
      } else if (response.response.data.errorKey === "10304") {
        setAlertMsg("Token is invalid.");
        setShowAlert(true);
      }
    } else {
      if (!validatePassword() || !validateConfirmPassword()) setHintError(true);
      validateForm();
    }
  };

  const handlePasswordFocus = () => {
    setIsValid((prev) => ({ ...prev, password: true }));
  };

  const handlePasswordBlur = () => {
    setIsValid((prev) => ({ ...prev, password: true }));
  };

  const handleConfirmPasswordFocus = () => {
    setIsValid((prev) => ({ ...prev, confirmPassword: true }));
  };

  const handleConfirmPasswordBlur = () => {
    setIsValid((prev) => ({ ...prev, confirmPassword: true }));
  };

  const classFunction = () => {
    if (form.password.length === 0) {
      return styles.buttonDisabled;
    }
    if (
      isValid.password &&
      isValid.confirmPassword &&
      form.password.length >= 8 &&
      form.confirmPassword.length >= 8 &&
      form.password.length === form.confirmPassword.length
    ) {
      return styles.button;
    }
    return styles.buttonDisabled;
  };
  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>Change Password</span>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputsBlock}>
            <div className={styles.inputBlock}>
              <label htmlFor="password" className={styles.label}>
                New Password
                <div className={styles.inputWrapper}>
                  <div
                    className={clsx(
                      styles.iconWrapper,
                      form.password.length > 0 && styles.activeIcon
                    )}
                  >
                    <SignInLock />
                  </div>
                  <input
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={form.password}
                    placeholder="Create a password"
                    onChange={handleInputChange}
                    className={clsx(
                      styles.input,
                      !isValid.password && styles.errorInput
                    )}
                  />
                  <button
                    type="button"
                    name="passwordEye"
                    className={clsx(
                      styles.iconWrapper,
                      styles.passwordEyeIcon,
                      showPassword && styles.activeIcon
                    )}
                    onMouseDown={handlePasswordEyeMouseDown}
                    onMouseUp={handlePasswordEyeMouseUp}
                    onTouchStart={handlePasswordEyeTouchStart}
                    onTouchEnd={handlePasswordEyeTouchEnd}
                    onKeyDown={handlePasswordEyeKeyDown}
                    onKeyUp={handlePasswordEyeKeyUp}
                  >
                    <PasswordInputEye />
                  </button>
                </div>
              </label>

              {form.password.length >= 8 &&
                passwordStrength(form.password, false) && (
                  <TiTick className={styles.tickMark} />
                )}
            </div>

            <div className={styles.inputBlock}>
              <label htmlFor="password" className={styles.label}>
                Confirm Password
                <div className={styles.inputWrapper}>
                  <div
                    className={clsx(
                      styles.iconWrapper,
                      form.password.length > 0 && styles.activeIcon
                    )}
                  >
                    <SignInLock />
                  </div>
                  <input
                    onFocus={handleConfirmPasswordFocus}
                    onBlur={handleConfirmPasswordBlur}
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={form.confirmPassword}
                    placeholder="Confirm a New Password"
                    onChange={handleInputChange}
                    className={clsx(
                      styles.input,
                      !isValid.confirmPassword && styles.errorInput
                    )}
                  />
                  <button
                    type="button"
                    name="confirmPasswordEye"
                    className={clsx(
                      styles.iconWrapper,
                      styles.passwordEyeIcon,
                      showConfirmPassword && styles.activeIcon
                    )}
                    onMouseDown={handlePasswordEyeMouseDown}
                    onMouseUp={handlePasswordEyeMouseUp}
                    onTouchStart={handlePasswordEyeTouchStart}
                    onTouchEnd={handlePasswordEyeTouchEnd}
                    onKeyDown={handlePasswordEyeKeyDown}
                    onKeyUp={handlePasswordEyeKeyUp}
                  >
                    <PasswordInputEye />
                  </button>
                </div>
              </label>
              {form.confirmPassword === form.password &&
                form.confirmPassword.length >= 8 && (
                  <TiTick className={styles.tickMark} />
                )}
            </div>
          </div>

          <div className={styles.blockFeatures}>
            <div
              className={clsx(
                hintError && styles.passwordHintError,
                styles.passwordHint
              )}
            >
              <span>
                *Password should contain: Min. 8 characters, at least one
                uppercase letter and one symbol.
              </span>
            </div>
          </div>

          {form.password.length > 0 && (
            <div className={styles.bar}>
              <div className={styles.barLabels}>
                <span>Strength</span>
                <span>{strength}</span>
              </div>
              <div className={styles.animateBarContainer}>
                <div
                  className={clsx(styles.animateBar, styles[strength])}
                  style={{ width: passwordStrengthBar?.width }}
                />
              </div>
            </div>
          )}

          {showAlert && (
            <div className={styles.errorBlock}>
              <div className={styles.warningIcon}>
                <Warning />
              </div>
              <div className={styles.errorMessage}>{alertMsg}</div>
            </div>
          )}
          <div className={styles.buttonBlock}>
            <button type="submit" tabIndex={0} className={classFunction()}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
