import { FC, useReducer } from "react";
import ManagementBoard from "./components/managementBoard/managementBoard";
import styles from "./management.module.scss";
import DispatchContext from "../../context/DispatchContext";
import StateContext from "../../context/StateContext";
import reducer, { initState } from "./reducer";

const Management: FC = () => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <>
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <div className={styles.container}>
            <ManagementBoard />
          </div>
        </DispatchContext.Provider>
      </StateContext.Provider>
    </>
  );
};

export default Management;
