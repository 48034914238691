import axios from "axios";
import { API_ENDPOINT } from "../constants/const";

const UserProfile = async (data: { type: string; id: string }) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/coreservice/api/${data.type}/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default UserProfile;
