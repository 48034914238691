import { NoEvent } from "constants/assets";
import { FC } from "react";

import styles from "./event-info-placeholder.module.scss";

export const EventInfoPlaceholder: FC = () => {
  return (
    <div className={styles.placeholder}>
      <NoEvent />
      <div className={styles.text}>
        Event Information will be displayed here
      </div>
    </div>
  );
};
