import { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import clsx from "clsx";
import CoachInvolvement from "common/coachInvolvement";
import AddInvolvement from "apis/trainings/addInvolvement";
import UpdateInvolvement from "apis/trainings/updateInvolvement";
import toastMsg from "common/toast";
import StateContext from "../../../context/StateContext";
import DispatchContext from "../../../context/DispatchContext";
import { AppContext } from "../../../App";
import { totalDurationToMin, totalMinToDuration } from "../../../utils/utils";
import { FormMark, Plus } from "../../../constants/assets";
import styles from "./involvements.module.scss";
import DeleteInvolvement from "../components/deleteInvolvement";

interface IForm {
  id?: number;
  hours: string;
  minutes: string;
}

const Involvements = () => {
  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);

  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { accountType, firstName, lastName } = appState;
  const [readOnly, setReadOnly] = useState(true);
  const [actions] = useState(["Edit Involvement", "Delete Involvement"]);
  const isCoach = accountType === "coach";
  const isAdmin = accountType === "admin";
  const { involvementMode, trainingDetails, athleteRegistrationStatus } = state;
  const [showButtons, setShowButtons] = useState(false);
  const [showAddButton, setShowAddButton] = useState<boolean>();
  const [error, setError] = useState(false);

  const { hours, minutes } = totalMinToDuration(
    state.involvementDetails[0]?.duration
  );
  const [isValid, setIsValid] = useState({ hours: false, minutes: false });
  const [form, setForm] = useState<IForm>({ hours: "", minutes: "" });

  useEffect(() => {
    setForm((prev: any) => ({
      ...prev,
      name: `${firstName} ${lastName}`,
    }));
  }, [appState]);

  useEffect(() => {
    if (state.involvementDetails.length) {
      dispatch({ type: "involvementMode", payload: "Edit" });
      setIsValid({ hours: true, minutes: true });
    } else dispatch({ type: "involvementMode", payload: "Add" });
    setShowAddButton(state.involvementDetails.length === 0);

    setForm((prev: any) => ({
      ...prev,
      ...state.involvementDetails[0],
      hours,
      minutes,
      training: { category: { name: trainingDetails.category.name } },
    }));
  }, [state.involvementDetails]);

  const handleAdd = () => {
    setIsValid({ hours: false, minutes: false });
    dispatch({ type: "involvementMode", payload: "Add" });
    setShowButtons(true);
    setShowAddButton(false);
    setReadOnly(false);
    setError(false);
    appDispatch({ type: "globalModeLast", payload: "trainingInvolvementsAdd" });
  };

  const handleSave = async () => {
    if (Object.values(isValid).includes(false)) {
      setError(true);
      if (isAdmin || involvementMode === "Add")
        toastMsg("Required fields must be filled in.");
      return;
    }

    const response: any =
      involvementMode === "Add"
        ? await AddInvolvement({
            duration: totalDurationToMin(form.hours, form.minutes),
            coach: {
              id: appState.id,
            },
            training: {
              id: trainingDetails.id,
            },
          })
        : await UpdateInvolvement({
            id: form.id,
            duration: totalDurationToMin(form.hours, form.minutes),
            coach: {
              id: appState.id,
            },
            training: {
              id: trainingDetails.id,
            },
          });
    if (response?.data) {
      setReadOnly(true);
      setShowButtons(false);
      dispatch({ type: "refreshData", payload: null });
      dispatch({ type: "refreshData", payload: "involvements" });
    } else if (response.response.data.errorKey === "20129") {
      setError(true);
    }
  };

  const handleRevert = () => {
    if (involvementMode === "Add") {
      setShowAddButton(true);
      setForm((prev: any) => ({
        ...prev,
        hours: "",
        minutes: "",
      }));
    } else {
      setReadOnly(true);
      setShowButtons(false);
      setForm((prev: any) => ({
        ...prev,
        hours,
        minutes,
      }));
      setIsValid((prev) => ({
        ...prev,
        hours: true,
        minutes: true,
      }));
    }
    setError(false);
  };

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setForm((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setIsValid((prev) => ({
      ...prev,
      [e.target.name]: !!e.target.value.length,
    }));
  };

  const onClick = (event: any) => {
    if (event.value === "Edit Involvement") {
      setReadOnly(false);
      setShowButtons(true);
      dispatch({ type: "refreshData", payload: null });
      setIsValid({ hours: true, minutes: true });
    } else if (event.value === "Delete Involvement") {
      const modalComponent = (
        <DeleteInvolvement
          state={state.involvementDetails[0]}
          dispatch={dispatch}
        />
      );
      dispatch({ type: "refreshData", payload: null });

      appDispatch({ type: "showModal", payload: modalComponent });
    }
  };
  return (
    <div className={styles.block}>
      <div className={styles.formHeader}>
        <div className={clsx(styles.title)}>
          <FormMark />
          <span>Activity Involvement</span>
        </div>

        {appState.globalModeLast === "trainingInvolvementsAdd" && (
          <ToastContainer className={styles.toast} position="top-left" />
        )}
      </div>

      {showAddButton && trainingDetails.status === "DONE" && (
        <p className={styles.noData}>
          There isn&apos;t any involvement for this activity yet.
        </p>
      )}

      {showAddButton &&
        trainingDetails.status === "DONE" &&
        ((isCoach && athleteRegistrationStatus === "REGISTERED") ||
          isAdmin) && (
          <button
            type="button"
            className={styles.addButton}
            onClick={handleAdd}
          >
            <Plus />
            <span>Add Involvement</span>
          </button>
        )}

      {(state.involvementDetails.length || involvementMode === "Add") &&
        !showAddButton && (
          <CoachInvolvement
            readOnly={readOnly}
            showButtons={showButtons}
            actions={actions}
            error={error}
            isValid={isValid}
            onChange={handleChange}
            onSave={handleSave}
            onRevert={handleRevert}
            onClick={onClick}
            data={form}
          />
        )}
    </div>
  );
};
export default Involvements;
