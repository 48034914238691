import { FC } from "react";
import { withRouter } from "react-router-dom";
import { Congrat } from "../../../constants/assets";
import styles from "./congrats.module.scss";

const CongratsRegister: FC = ({ history }: any) => {
  const accountType = localStorage.getItem("accountType");
  const handleProceed = () => {
    history.push("/home/accounts");
  };
  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.congratsIcon}>
          <Congrat />
        </div>
        <span className={styles.congrats}>Congrats!</span>
        <span className={styles.done}>
          You are now successfully <br /> registered as{" "}
          {accountType === "coach" ? "a Junior Coach" : `an ${accountType}`}.
        </span>
        <div className={styles.buttonBlock}>
          <button
            type="button"
            className={styles.button}
            onClick={handleProceed}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CongratsRegister);
