import { StylesConfig } from "react-select";

const styles: StylesConfig = {
  container: (base) => ({
    ...base,
    background: "#fff",
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "8px",
    color: "#000",
    padding: "1px 0px",
  }),
  control: (base) => ({
    ...base,
    width: "100%",
    backgroundColor: "transparent",
    borderColor: "transparent",
    boxShadow: "none",
    outline: "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "14px",
    color: "#2B2F3D",
  }),
  valueContainer: (base) => ({
    ...base,
    margin: "0px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    marginInlineEnd: "14px",
    color: "#000",
    background: "transparent",
    borderRadius: "8px",
    padding: "6px",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 8px 24px rgba(81, 81, 81, 0.15)",
    color: "#222222",
  }),
  menuList: (base) => ({
    ...base,
    borderRadius: "8px",
    "::-webkit-scrollbar": {
      width: "0px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#fff",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#fff",
    },
    padding: 0,
  }),
  option: (base, state) => ({
    ...base,
    borderBottom: "0.5px solid #CCCCCC",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: state.isSelected ? "bold" : "normal",
    paddingTop: "16px",
    height: "50px",
    "&:hover": {
      color: "#222222",
      fontWeight: "bold",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  }),
};

export default styles;
