/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./participants.module.scss";
import {
  FormMark,
  Plus,
  AvatarRed,
  TrashIcon,
  PencilIcon,
  Info,
} from "../../../constants/assets";
import { AppContext } from "../../../App";
import StateContext from "../../../context/StateContext";
import AddEditParticipant from "../components/addEditParticipant";
import DeleteParticipants from "../components/deleteParticipant";
import AthletesList from "../../../apis/athletesList";
import CoachList from "../../../apis/management/coachList";
import DispatchContext from "../../../context/DispatchContext";

const Participants = () => {
  const { appDispatch } = useContext<any>(AppContext);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [results, setResults] = useState([]);
  const [registeredCoaches, setRegisteredCoaches] = useState([]);
  const [trainingStatus, setTrainingStatus] = useState(String);
  const participants = state.participantsDetails;
  const participantCoaches = state.coachRegistersDetails;

  const fetchParticipant = async () => {
    const response: any = await AthletesList();
    if (response?.data) {
      const athletes = response.data.map(
        (item: {
          id: any;
          firstName: any;
          lastName: any;
          email: any;
          mobile: any;
          parentName: any;
        }) => ({
          value: item.id,
          label: `${item.firstName} ${item.lastName}`,
          email: item.email,
          mobile: item.mobile,
          parentName: item.parentName,
        })
      );

      const filteredParticipant = participants.map(
        (participant: {
          athleteId: any;
          order: any;
          registrationStatus: any;
          id: number;
        }) => {
          const filteredAthlete = athletes.filter(
            (athlete: { value: any }) => athlete.value === participant.athleteId
          );
          return {
            ...filteredAthlete?.[0],
            registrationId: participant?.order,
            status: participant.registrationStatus,
            id: participant.id,
          };
        }
      );
      setResults(filteredParticipant);
    }
  };

  const fetchRegisteredCoach = async () => {
    const response: any = await CoachList();
    if (response?.data) {
      const coaches = response.data.map(
        (item: {
          id: any;
          firstName: any;
          lastName: any;
          email: any;
          mobile: any;
          parentName: any;
        }) => ({
          value: item.id,
          label: `${item.firstName} ${item.lastName}`,
          email: item.email,
          mobile: item.mobile,
          parentName: item.parentName,
        })
      );
      setRegisteredCoaches(
        participantCoaches?.map(
          (participantCoach: { coach: { id: any }; id: any }) =>
            Object.assign(
              coaches.filter(
                (coach: { value: any }) =>
                  coach.value === participantCoach.coach.id
              )[0],
              { id: participantCoach.id }
            )
        )
      );
    }
  };

  useEffect(() => {
    fetchParticipant();
    fetchRegisteredCoach();
  }, [participants, participantCoaches]);

  useEffect(() => {
    setTrainingStatus(state.trainingDetails?.status);
  }, [state.trainingDetails]);

  const handleAdd = () => {
    const modalComponent = (
      <AddEditParticipant
        mode="Add"
        selectedAthlete={results}
        selectedCoach={registeredCoaches}
        state={state.trainingDetails}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleDelete = (id: number, participantType: string) => {
    const modalComponent = (
      <DeleteParticipants
        participantId={id}
        participantType={participantType}
        state={state.trainingDetails}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleEdit = (participantInfo: any, participantType: string) => {
    const modalComponent = (
      <AddEditParticipant
        participantInfo={participantInfo}
        participantType={participantType}
        selectedAthlete={results}
        selectedCoach={registeredCoaches}
        mode="Edit"
        state={state.trainingDetails}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const renderParticipants = (
    participant: any,
    participantType: string,
    index?: number
  ) => {
    return (
      <div className={styles.athleteDetailsBlock} key={participant.value}>
        <div className={styles.athleteInfo}>
          <div className={styles.athlete}>
            <div className={styles.avatarName}>
              <div className={styles.idStyle}>
                {participant.registrationId ?? index}
              </div>
              <span>{participant.label} </span>
            </div>

            {participant.status === "WAITING" && (
              <div className={styles.waitingList}>
                <Info />
                <label> Waiting list </label>
              </div>
            )}
          </div>

          {trainingStatus === "PENDING" && (
            <div className={styles.athleteActions}>
              <div
                role="button"
                aria-hidden="true"
                className={styles.edit}
                onClick={() => handleEdit(participant, participantType)}
              >
                <PencilIcon />
              </div>
              <div
                role="button"
                aria-hidden="true"
                className={styles.delete}
                onClick={() => handleDelete(participant.value, participantType)}
              >
                <TrashIcon />
              </div>
            </div>
          )}
        </div>

        <div className={styles.athleteDetails}>
          <div>
            <span> Parent Name</span>
            <span> {participant.parentName}</span>
          </div>
          <div className={styles.groupRow}>
            <div>
              <span> Mobile</span>
              <span> {participant.mobile}</span>
            </div>
            <div>
              <span> Email</span>
              <span> {participant.email}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.block}>
        <>
          <div className={styles.formHeader}>
            <div
              className={clsx(
                styles.title,
                trainingStatus === "DONE" && styles.titlePadding
              )}
            >
              {(results?.length > 0 || registeredCoaches?.length > 0) && (
                <>
                  <FormMark />
                  <span>Activity Participants</span>
                </>
              )}
            </div>
            {(results?.length > 0 || registeredCoaches?.length > 0) &&
              trainingStatus === "PENDING" && (
                <div className={styles.actions}>
                  <button
                    type="button"
                    className={styles.addBtn}
                    onClick={handleAdd}
                  >
                    <Plus />
                    <span>Add Participants</span>
                  </button>
                </div>
              )}
          </div>
        </>

        {results?.length > 0 || registeredCoaches?.length > 0 ? (
          <>
            {results?.length > 0 && (
              <div className={styles.categoryTitle}>Athletes</div>
            )}
            {results?.map((result: any) => {
              return renderParticipants(result, "athlete");
            })}
            {registeredCoaches?.length > 0 && (
              <div className={styles.categoryTitle}>Junior Coaches</div>
            )}
            {registeredCoaches?.map((participantCoach: any, index: number) => {
              return renderParticipants(participantCoach, "coach", index + 1);
            })}
          </>
        ) : (
          trainingStatus === "PENDING" &&
          state.trainingDetails.id && (
            <>
              <div className={styles.noDataBlock}>
                <div className={styles.avatar}>
                  <AvatarRed />
                </div>
                <div className={styles.text}>
                  <span>
                    There isn&apos;t any participant for this activity yet.{" "}
                  </span>
                </div>

                <div className={styles.actions}>
                  <button
                    type="button"
                    className={styles.addBtn}
                    onClick={handleAdd}
                  >
                    <Plus />
                    <span>Add Participants</span>
                  </button>
                </div>
              </div>
            </>
          )
        )}
      </div>
    </>
  );
};

export default Participants;
