import { FC } from "react";
import ReactSelect, {
  GroupBase,
  Props as ReactSelectProps,
} from "react-select";

import styles from "./select.styles";

export type SelectOption = {
  value?: string;
  label?: string;
  name?: string;
};

export type SelectChangeOption = {
  value: string;
  name: string;
};

type Props<
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false | true,
  Group extends GroupBase<Option> = GroupBase<Option>
> = ReactSelectProps<Option, IsMulti, Group>;

type OverridedProps = Omit<Props, "onChange"> & {
  name: string;
  onChange: (option: SelectOption & { name: string }) => void;
  isSearchable?: boolean;
  className?: string;
};

export const Select: FC<OverridedProps> = ({
  isSearchable = false,
  onChange,
  name,
  ...props
}) => {
  const handleChange: Props["onChange"] = (option) => {
    onChange({ ...option, name });
  };

  return (
    <ReactSelect
      {...props}
      onChange={handleChange}
      isSearchable={isSearchable}
      styles={styles as typeof props.styles}
    />
  );
};
