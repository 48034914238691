import { FC, useContext, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AppContext } from "App";
import DispatchContext from "context/DispatchContext";
import { minToDuration } from "utils/utils";
import { CalendarEvent } from "../../../calendar/types";
import {
  Avatar,
  ClockCoach,
  PencilIcon,
  TrashIcon,
} from "../../../../constants/assets";

import styles from "./coach-info.module.scss";
import AddEditInvolve from "../addEditInvolve";
import DeleteInvolvement from "../deleteInvolvement";

type Props = {
  event: CalendarEvent;
  setInvolvementInfo: any;
};

export const CoachInfo: FC<Props> = ({ event, setInvolvementInfo }) => {
  const dispatch = useContext(DispatchContext);

  const { appDispatch } = useContext<any>(AppContext);
  const [updateInvolve, setUpdateInvolve] = useState({ duration: "" });

  useEffect(() => {
    if (updateInvolve.duration !== "")
      setInvolvementInfo((prev: any) => ({
        ...prev,
        duration: updateInvolve.duration,
      }));
  }, [updateInvolve]);

  const handleEdit = () => {
    dispatch({ type: "coachMode", payload: "Edit" });
    const modalComponent = (
      <AddEditInvolve
        data={event}
        mode="Edit"
        setUpdateInvolve={setUpdateInvolve}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleDelete = () => {
    const modalComponent = (
      <DeleteInvolvement
        state={event}
        dispatch={dispatch}
        setInvolvementInfo={setInvolvementInfo}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  return (
    <div className={styles.container}>
      <div className={styles.profileInfo}>
        <Avatar className={styles.avatar} />
        <div className={styles.athleteActions}>
          <div
            role="button"
            aria-hidden="true"
            className={styles.edit}
            onClick={handleEdit}
          >
            <PencilIcon />
          </div>
          <div
            role="button"
            aria-hidden="true"
            className={styles.delete}
            onClick={() => handleDelete()}
          >
            <TrashIcon />
          </div>
        </div>
        <span className={styles.profileName}>
          {event.coach?.firstName} {event.coach?.lastName}
        </span>
      </div>

      <div className={styles.divider} />

      <div className={styles.lowerBlock}>
        <ClockCoach className={styles.clockIcon} />
        <span className={styles.label}>Time Spent</span>
        <span className={styles.time}>
          {minToDuration(event.duration).replace(":", "")}
        </span>
      </div>
    </div>
  );
};
