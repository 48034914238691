import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const Auth: any = async (data: {
  password: string;
  accountType: string;
  login: string;
  rememberMe: boolean;
}) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "POST",
      baseURL: `${API_ENDPOINT}/authservice/api/user/login`,
      params: {
        email: data.login,
        password: data.password,
        accountType: data.accountType?.toUpperCase(),
        rememberMe: data.rememberMe,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default Auth;
