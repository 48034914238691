import { toEChartsTextStyle } from "./utils";
import { COLOR_DARK_GRAY, COLOR_WHITE } from "./colors";
import { FONT_SIZE_XS, FONT_SIZE_XSS, FONT_SIZE_M } from "./constants";

export const DEFAULT_CHART_X_AXIS_PHYS = {
  axisLabel: {
    ...toEChartsTextStyle(
      {},
      {
        color: "#2C303E",
        size: FONT_SIZE_XSS,
        fontFamily: "Montserrat",
      }
    ),
    margin: 13,
  },
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
  axisLine: {
    lineStyle: {
      color: "rgba(84, 93, 118, 0.8)",
    },
  },
  type: "category",
};

export const DEFAULT_CHART_Y_AXIS_PHYS = {
  axisLabel: {
    ...toEChartsTextStyle(
      {},
      {
        color: "#878A93",
        size: FONT_SIZE_XSS,
        fontFamily: "Montserrat",
      }
    ),
    formatter: "{value}",
  },
  nameTextStyle: {
    fontSize: FONT_SIZE_M,
    textTransform: "upper-case",
  },
  axisTick: {
    show: false,
  },
  axisLine: {
    lineStyle: {
      color: "#878A93",
    },
  },
  splitLine: {
    lineStyle: {
      color: COLOR_DARK_GRAY,
    },
  },
  type: "value",
};

export const DEFAULT_CHART_TITLE_PHYS = {
  show: true,
  top: 0,
  left: 0,
  textStyle: {
    color: COLOR_WHITE,
    size: FONT_SIZE_M,
    fontFamily: "Montserrat",
    rich: {
      a: {
        fontSize: FONT_SIZE_XS,
        fontWeight: "bold",
        fontFamily: "Montserrat",
      },
      b: {
        fontSize: FONT_SIZE_XS,
        fontWeight: "normal",
        fontFamily: "Montserrat",
      },
    },
  },
};

export const DEFAULT_CHART_LEGEND = {
  show: true,
  bottom: 0,
  type: "scroll",
  itemGap: 10,
  width: "100%",
  itemHeight: 8,
  itemWidth: 8,
  left: "center",
  icon: "circle",
  textStyle: {
    fontFamily: "Montserrat",
    fontSize: 10,
  },
};
