import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

type Props = {
  form?: any;
  id?: number;
};
const UpdateCoachRegister = async ({ form, id }: Props) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "PUT",
      baseURL: `${API_ENDPOINT}/coreservice/api/event-coaches/${id}`,
      data: { ...form, id },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default UpdateCoachRegister;
