import { FC, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "../header/header";
import styles from "./main.module.scss";
import Performance from "../../components/performance/performance";

import Events from "../../components/events/events";
import Management from "../../components/management/management";
import { AppContext } from "../../App";

const Main: FC = ({ match, history, children }: any) => {
  const { component } = match.params;
  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);

  useEffect(() => {
    appDispatch({ type: "hideModal" });
  }, [component]);

  const CurrentComponent = () => {
    switch (component) {
      case "performance":
        return <Performance />;
      case "events":
        return <Events />;
      case "management":
        return appState.superAdmin ? (
          <Management />
        ) : (
          history.push("/home/landing")
        );
      default:
        return <Performance />;
    }
  };
  return (
    <>
      <div className={styles.mainContainer}>
        <Header />
        <div className={styles.currentComponent}>
          {children || CurrentComponent()}
        </div>
      </div>
    </>
  );
};

export default withRouter(Main);
