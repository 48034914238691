import axios from "axios";
import { format } from "date-fns";

import { API_ENDPOINT } from "../../constants/const";

interface IForm {
  data: {
    id?: number;
    category?: string;
    targetCompletionDate: string;
    value?: string;
    goalType?: string;
  };
}
const SetGoal = async ({ data }: IForm | any) => {
  const targetDateStr = format(
    data.targetCompletionDate,
    "yyyy-MM-dd'T'HH:mm:ss.SSS"
  );
  data.targetCompletionDate = `${targetDateStr}Z`;

  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "POST",
      baseURL: `${API_ENDPOINT}/coreservice/api/goal/set`,
      data: { ...data },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default SetGoal;
