/* eslint-disable prettier/prettier */
import React, { FC, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import AthleteList from "apis/management/athleteList";
import styles from "./groupsGrid.module.scss";
import {
  TrashIcon,
  PencilIcon,
  NoGroup,
} from "../../../../../constants/assets";
import { AppContext } from "../../../../../App";
import StateContext from "../../../../../context/StateContext";
import DispatchContext from "../../../../../context/DispatchContext";
import GroupList from "../../../../../apis/management/groupList";
import DeleteGroup from "../../deleteGroup/deleteGroup";
import AddEditGroup from "../../addEditGroup/addEditGroup";
import { Group } from "../../../../../interfaces/interfaces";
import { Athlete } from "../../../../calendar/types";
import { Spinner } from "../../../../spinner/spinner";
import convertArrayToObject from "../../../../../utils/arrayToObject";


const GroupsGrid :FC = () => {
  const { appDispatch } = useContext<any>(AppContext);

  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [results, setResults] = useState<Group[]>();
  const [athletes, setAthletes] = useState<Record<number, Athlete>>({});
  const [isLoadingResult, setIsLoadingResult] = useState(true);

  const getData = async () => {
    setIsLoadingResult(true);
    const response: any = await GroupList();
    if (response.status === 200) {
      setResults(
        state.active === "admin" ? response.data.content : response.data
      );
      dispatch({ type: "refreshData", payload: null });
    }
    setIsLoadingResult(false);
  };
  const getAthlete = async () => {
    const response: any =  await AthleteList();
    if (response?.data?.length) {
      const athlete = convertArrayToObject(response.data, "id");
      setAthletes(athlete);
    }
  };
  useEffect(() => {
    getAthlete();
  }, []);

  useEffect(() => {
    setResults([]);
    getData();
  }, [state.active]);

  useEffect(() => {
    getData();
  }, [state.refreshData]);

  const handleToggleDelete = (id: number) => {
    const modalComponent = (
      <DeleteGroup id={id} dispatch={dispatch} />
    );

    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleEdit = (data: any) => {
    const modalComponent = (
      <AddEditGroup data={data} mode="Edit" dispatch={dispatch}  />
    );

    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const renderResult = () => {
    if (isLoadingResult) {
      return <Spinner />;
    }
    if (results?.length) {
      return (
        <>
          {results.map((result: Group) => (
            <div className={clsx(styles.athleteDetailsBlock)} key={result.id}>
              <div className={styles.athleteInfo}>
                <span className={styles.groupName}>{result.name}</span>

                <div className={styles.athleteActions}>
                  <div
                    role="button"
                    aria-hidden="true"
                    className={styles.edit}
                    onClick={() => handleEdit(result)}
                  >
                    <PencilIcon />
                  </div>

                  <div
                    role="button"
                    aria-hidden="true"
                    className={styles.delete}
                    onClick={() => handleToggleDelete(result.id)}
                  >
                    <TrashIcon />
                  </div>
                </div>
              </div>

              <div className={styles.membersBlock}>
                <span className={styles.membersLabel}> Members</span>
                <div className={styles.membersName}>
                  {result?.athleteIds?.length &&
                    result?.athleteIds.map((id) => (
                      <div className={styles.memberBlock} key={id}>
                        <span>
                          {athletes[id]?.firstName} {athletes[id]?.lastName}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </>
      );
    }
    return (
      <div className={styles.placeholder}>
        <NoGroup />
        <div className={styles.text}>No groups created</div>
      </div>
    );
  };
  return (
    <>
      <div className={clsx(styles.block, !results?.length && styles.setHeight)}>
        <div
          className={clsx(
            styles.formHeader,
            results?.length && styles.unSetHeight
          )}
        >
          {renderResult()}
        </div>
      </div>
    </>
  );
};

export default GroupsGrid;
