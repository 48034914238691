import { useContext, useEffect, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { ToastContainer } from "react-toastify";
import clsx from "clsx";

import { Select, SelectOption } from "components/select/select";
import UpdateResult from "apis/trainings/updateResult";
import toastMsg from "../../../common/toast";

import StateContext from "../../../context/StateContext";
import DispatchContext from "../../../context/DispatchContext";
import { AppContext } from "../../../App";

import AddTrainingResults from "../../../apis/trainings/addTrainingResults";
import { durationToMin, minToDuration } from "../../../utils/utils";

import {
  FormMark,
  Speed,
  Duration,
  Plus,
  Distance,
  CaloriesResults,
  Avatar,
  PencilIcon,
  TrashIcon,
  Cancel,
  Save,
} from "../../../constants/assets";

import styles from "./results.module.scss";
import DeleteResults from "../components/deleteResults";

interface IForm {
  athlete?: any;
  id?: number;
  distance?: string;
  duration?: string;
  calories?: string;
  speed?: string;
}

interface Fields {
  id?: boolean;
  speed?: boolean;
  distance?: boolean;
  duration?: boolean;
  calories?: boolean;
}

type ParticipantData = {
  registrationStatus: string; // TODO: Use enum
  athleteId: number;
};

type ResultData = {
  athlete: {
    id: number;
  };
};

type Props = {
  athletesData?: { value: number; label: string }[];
};

const Results = ({ athletesData }: Props) => {
  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);

  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [showButtons, setShowButtons] = useState(false);

  const { accountType, id, firstName, lastName } = appState;

  const {
    resultsDetails,
    resultsMode,
    athleteRegistrationStatus,
    trainingDetails,
    participantsDetails,
    rowID,
  } = state;

  const [formData, setFormData] = useState<IForm>({});
  const [isValid, setIsValid] = useState<Fields>({});

  const [results, setResults] = useState([]);

  const [saveDone, setSaveDone] = useState(false);
  const [showError, setShowError] = useState(false);

  const [selectedAthlete, setSelectedAthlete] = useState<SelectOption>();

  const isAthlete = accountType === "athlete";
  const isAdmin = accountType === "admin";

  const registeredAthletesIdsList = isAdmin
    ? participantsDetails.reduce(
        (acc: number[], { athleteId, registrationStatus }: ParticipantData) => {
          // TODO: Use enum
          if (registrationStatus === "REGISTER") {
            return [...acc, athleteId];
          }
          return acc;
        },
        []
      )
    : [];

  const athletesWithResultsIdsList = resultsDetails.map(
    ({ athlete }: ResultData) => athlete.id
  );

  const hideAddButton = isAthlete
    ? athletesWithResultsIdsList.includes(id)
    : athletesWithResultsIdsList.length === registeredAthletesIdsList.length;

  const athletesOptions =
    !hideAddButton && isAdmin
      ? athletesData?.reduce((acc, { value, label }) => {
          if (
            registeredAthletesIdsList.includes(value) &&
            !athletesWithResultsIdsList.includes(value)
          ) {
            return [...acc, { value, label }];
          }
          return acc;
        }, [] as any)
      : []; // FIXME: TS for some reason doesn't like SelectOption[]

  useEffect(() => {
    if (resultsMode === "Add") {
      setShowButtons(true);
      setIsValid({
        id: isAthlete,
        speed: false,
        distance: false,
        duration: false,
        calories: false,
      });
    } else {
      setResults(resultsDetails);
      setIsValid({
        id: isAthlete || resultsMode === "Edit",
        speed: true,
        distance: true,
        duration: true,
        calories: true,
      });
    }

    setShowError(false);
  }, [resultsDetails, resultsMode]);

  useEffect(() => {
    dispatch({ type: "resultsMode", payload: "View" });
    if (accountType === "admin")
      dispatch({ type: "refreshData", payload: "goalsAdmin" });
    else if (accountType === "athlete")
      dispatch({ type: "refreshData", payload: "goals" });
  }, [state.resultsDetails]);

  const handleAdd = () => {
    setSaveDone(false);
    setFormData({});
    setSelectedAthlete(undefined);
    dispatch({ type: "resultsMode", payload: "Add" });
    dispatch({ type: "setRowID", payload: -1 });
    appDispatch({ type: "globalModeLast", payload: "trainingResultsAdd" });
  };

  const handleSave = async () => {
    if (Object.values(isValid).includes(false)) {
      setShowError(true);
      if (isAdmin || resultsMode === "Add")
        toastMsg("Required fields must be filled in.");
      return;
    }

    if (resultsMode === "Edit") {
      const duration = durationToMin(formData.duration?.replace(/[hm: ]/g, ""));

      const response: any = await UpdateResult({
        form: {
          distance: formData.distance,
          speed: formData.speed,
          calories: formData.calories,
          duration: duration ? duration.toString() : formData.duration,
          date: new Date(),
          training: {
            id: trainingDetails.id,
          },
          athlete: {
            id: isAthlete ? appState.id : formData.athlete.id,
          },
        },
        id: formData.id,
      });

      if (response?.data) {
        toastMsg("Results are successfully updated.", "success");
        setSaveDone(true);
        dispatch({ type: "refreshData", payload: "results" });
        dispatch({ type: "refreshData", payload: "goals" });
        dispatch({ type: "resultsMode", payload: "View" });

        setShowButtons(false);
      }
    } else {
      const response: any = await AddTrainingResults({
        ...formData,
        duration: durationToMin(formData.duration).toString(),
        training: {
          id: trainingDetails.id,
        },
        athlete: {
          id: isAthlete ? id : selectedAthlete?.value,
        },
        date: new Date(),
      });
      if (response?.data) {
        toastMsg("Results are successfully added.", "success");
        setSaveDone(true);
        dispatch({ type: "refreshData", payload: "results" });
        dispatch({ type: "refreshData", payload: "goals" });
        dispatch({ type: "resultsMode", payload: "View" });

        setShowButtons(false);
      }
    }

    dispatch({ type: "refreshData", payload: "goalsAdmin" });
  };

  const handleRevert = () => {
    if (resultsMode === "Add") {
      setFormData({});
      dispatch({ type: "resultsMode", payload: "View" });
      setSelectedAthlete(undefined);
      dispatch({ type: "refreshData", payload: "results" });

      if (isAdmin) setShowButtons(false);
    } else {
      dispatch({ type: "resultsMode", payload: "View" });
      setShowButtons(false);
    }

    dispatch({ type: "setRowID", payload: -1 });
  };

  useEffect(() => {
    if (
      appState.globalModeLast !== "trainingResultsEdit" &&
      appState.globalModeLast !== "trainingResultsAdd"
    )
      handleRevert();
  }, [appState.globalModeLast]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name !== "duration")
      setIsValid({
        ...isValid,
        [e.target.name]: !!e.target.value.length,
      });
  };

  const handleInputChangeEditMode = (e: NumberFormatValues, name: string) => {
    setFormData((prev) => ({ ...prev, [name]: e.value }));
    setIsValid({
      ...isValid,
      [name]: name === "duration" ? e.value.length === 4 : !!e.value.length,
    });
  };

  const handleChange = (e: NumberFormatValues) => {
    setFormData((prev) => ({ ...prev, duration: e.value }));
    setIsValid((prev) => ({
      ...prev,
      duration: e.value.length === 4,
    }));
  };

  const Buttons = () => {
    return (
      <>
        <div role="button" aria-hidden="true" onMouseDown={handleRevert}>
          <Cancel />
        </div>
        <div role="button" aria-hidden="true" onMouseDown={handleSave}>
          <Save />
        </div>
      </>
    );
  };

  const handleAthleteSelect = (option: SelectOption) => {
    setSelectedAthlete(option);
    setIsValid((prev) => ({
      ...prev,
      id: true,
    }));
  };

  const handleEdit = (athleteInfo: React.SetStateAction<IForm> | any) => {
    dispatch({ type: "setRowID", payload: athleteInfo.id });
    appDispatch({ type: "globalModeLast", payload: "trainingResultsEdit" });

    dispatch({ type: "resultsMode", payload: "Edit" });
    setFormData({
      ...athleteInfo,
      duration: minToDuration(athleteInfo.duration),
    });
    setShowButtons(true);
    setSaveDone(false);
    setShowError(false);
  };

  const handleDelete = (athleteInfo: React.SetStateAction<IForm> | any) => {
    const modalComponent = (
      <DeleteResults
        id={athleteInfo.id}
        athleteId={athleteInfo.athlete.id}
        dispatch={dispatch}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const displayLowerSection =
    (results && resultsMode === "View" && results.length > 0 && isAthlete) ||
    isAdmin;

  const displayUpperSection =
    (results.length === 0 &&
      resultsMode === "View" &&
      trainingDetails.status === "DONE" &&
      isAthlete &&
      athleteRegistrationStatus === "REGISTERED") ||
    isAdmin;

  const showAddResultsButton = results.length === participantsDetails.length;
  return (
    <div className={styles.block}>
      <div className={styles.formHeader}>
        <div className={clsx(styles.title)}>
          <FormMark />
          <span>Activity Results</span>
        </div>

        {appState.globalModeLast === "trainingResultsAdd" && (
          <>
            {" "}
            <ToastContainer className={styles.toast} position="top-left" />
          </>
        )}
      </div>

      {resultsMode !== "Add" &&
        results.length === 0 &&
        trainingDetails.status === "DONE" && (
          <p className={styles.noData}>
            There isn&apos;t any results for this activity yet.
          </p>
        )}

      {((displayUpperSection && !showAddResultsButton) ||
        (isAthlete && !results.length)) &&
        resultsMode !== "Add" && (
          <button
            type="button"
            className={clsx(
              styles.addButton,
              (results.length || resultsMode === "Add") && styles.buttonCustom
            )}
            onClick={handleAdd}
          >
            <Plus />
            <span>Add Results</span>
          </button>
        )}

      {(resultsMode === "Add" || (resultsMode === "Edit" && isAthlete)) &&
        !saveDone && (
          <div className={styles.addNewResults}>
            <div className={styles.resultsDetailsBlock}>
              <form>
                <div className={styles.athleteInfo}>
                  <div className={styles.avatar}>
                    <Avatar />
                  </div>
                  <div className={styles.athleteName}>
                    {isAdmin ? (
                      <Select
                        placeholder="Choose a participant"
                        className={clsx(
                          styles.select,
                          !isValid.id && showError && styles.errorInput
                        )}
                        name="participant"
                        value={selectedAthlete}
                        options={athletesOptions}
                        onChange={handleAthleteSelect}
                      />
                    ) : (
                      <span>{`${firstName} ${lastName}`}</span>
                    )}
                  </div>

                  {showButtons && (
                    <div className={styles.buttons}>
                      <Buttons />
                    </div>
                  )}
                </div>

                <div className={styles.category}>
                  <span className={styles.categoryName}>Category</span>
                  <div className={styles.categoryDropDown}>
                    <span className={styles.categoryValue}>
                      <span> {trainingDetails?.category?.name}</span>
                    </span>
                  </div>
                </div>

                <div className={styles.bottomSection}>
                  <div className={styles.bottomDetails}>
                    <div className={styles.results}>
                      <div className={styles.distance}>
                        <Distance />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.distance && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="distance"
                            name="distance"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.distance}
                            onChange={handleInputChange}
                            placeholder="0"
                          />
                          {/* TODO: Fix click on span should focus the input */}
                          <span className={styles.measure}>km</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsx(styles.results, styles.bottomDetailsSub)}
                    >
                      <div className={styles.speed}>
                        <Speed />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.speed && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="speed"
                            name="speed"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.speed}
                            onChange={handleInputChange}
                            placeholder="0"
                          />
                          <span className={styles.measure}>km/h</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.bottomDetails}>
                    <div className={styles.results}>
                      <div className={styles.duration}>
                        <Duration />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.duration && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="duration"
                            name="duration"
                            readOnly={saveDone}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            format="##h : ##m"
                            mask="_"
                            prefix=""
                            value={formData.duration}
                            onValueChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsx(styles.results, styles.bottomDetailsSub)}
                    >
                      <div className={styles.calories}>
                        <CaloriesResults />
                        <div
                          className={clsx(
                            styles.numbersRowInput,
                            !isValid.calories && showError && styles.errorInput
                          )}
                        >
                          <NumberFormat
                            className={styles.input}
                            id="calories"
                            name="calories"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={formData.calories}
                            onChange={handleInputChange}
                            placeholder="0"
                          />

                          <span className={styles.measure}>kcal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

      {displayLowerSection &&
        results.map((result: any) => {
          return (
            <div
              key={result.id}
              className={clsx(
                styles.resultsDetailsBlock,
                rowID !== result.id &&
                  rowID !== -1 &&
                  resultsMode !== "View" &&
                  styles.inActiveBlock
              )}
            >
              {rowID === result.id &&
                appState.globalModeLast === "trainingResultsEdit" && (
                  <>
                    <ToastContainer className={styles.toastPositionEdit} />
                  </>
                )}

              <form
                onSubmit={(e) => e.preventDefault()}
                className={clsx(rowID !== -1)}
              >
                <div className={styles.athleteInfo}>
                  <div className={styles.avatar}>
                    <Avatar />
                  </div>
                  <div className={styles.athleteName}>
                    <span>
                      {`${result.athlete.firstName} ${result.athlete.lastName}`}
                    </span>
                  </div>

                  {!showButtons && (
                    <div className={styles.athleteActions}>
                      <div
                        role="button"
                        aria-hidden="true"
                        className={styles.edit}
                        onClick={() => handleEdit(result)}
                      >
                        <PencilIcon />
                      </div>
                      <div
                        role="button"
                        aria-hidden="true"
                        className={styles.delete}
                        onClick={() => handleDelete(result)}
                      >
                        <TrashIcon />
                      </div>
                    </div>
                  )}

                  {showButtons &&
                    resultsMode === "Edit" &&
                    rowID === result.id && (
                      <div className={styles.buttons}>
                        <Buttons />
                      </div>
                    )}
                </div>

                <div className={styles.category}>
                  <span className={styles.categoryName}>Category</span>
                  <div className={styles.categoryDropDown}>
                    <span className={styles.categoryValue}>
                      {result.training.category.name}
                    </span>
                  </div>
                </div>

                <div className={styles.bottomSection}>
                  <div className={styles.bottomDetails}>
                    <div className={styles.results}>
                      <div className={styles.distance}>
                        <Distance />
                        {rowID === result.id ? (
                          <div
                            className={clsx(
                              styles.numbersRowInput,
                              !isValid.distance &&
                                showError &&
                                styles.errorInput
                            )}
                          >
                            <NumberFormat
                              className={styles.input}
                              id="distance"
                              name="distance"
                              readOnly={rowID !== result.id}
                              prefix=""
                              allowNegative={false}
                              allowLeadingZeros={false}
                              allowEmptyFormatting={true}
                              value={formData.distance}
                              onValueChange={(e) =>
                                rowID === result.id &&
                                handleInputChangeEditMode(e, "distance")
                              }
                            />
                            <span className={styles.measure}>km</span>
                          </div>
                        ) : (
                          <div className={styles.numbersRow}>
                            <span> {result.distance} </span>
                            <span className={styles.measure}>km</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={clsx(styles.results, styles.bottomDetailsSub)}
                    >
                      <div className={styles.speed}>
                        <Speed />
                        {rowID === result.id ? (
                          <div
                            className={clsx(
                              styles.numbersRowInput,
                              !isValid.speed && showError && styles.errorInput
                            )}
                          >
                            <NumberFormat
                              className={styles.input}
                              id="speed"
                              name="speed"
                              readOnly={rowID !== result.id}
                              prefix=""
                              value={formData.speed}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              allowEmptyFormatting={true}
                              onValueChange={(e) =>
                                rowID === result.id &&
                                handleInputChangeEditMode(e, "speed")
                              }
                            />
                            <span className={styles.measure}>km/h</span>
                          </div>
                        ) : (
                          <>
                            <div className={styles.numbersRow}>
                              <span> {result.speed} </span>{" "}
                              <span className={styles.measure}>km/h</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.bottomDetails}>
                    <div className={styles.results}>
                      <div className={styles.duration}>
                        <Duration />
                        {rowID === result.id ? (
                          <div
                            className={clsx(
                              styles.numbersRowInput,
                              !isValid.duration &&
                                showError &&
                                styles.errorInput
                            )}
                          >
                            {" "}
                            <NumberFormat
                              className={styles.input}
                              id="duration"
                              name="duration"
                              readOnly={rowID !== result.id}
                              format="##h : ##m"
                              mask="_"
                              prefix=""
                              allowNegative={false}
                              allowLeadingZeros={false}
                              allowEmptyFormatting={true}
                              value={formData.duration}
                              onValueChange={(e) =>
                                rowID === result.id &&
                                handleInputChangeEditMode(e, "duration")
                              }
                            />
                          </div>
                        ) : (
                          <>
                            <div className={styles.numbersRow}>
                              <span> {minToDuration(result.duration)} </span>{" "}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div
                      className={clsx(styles.results, styles.bottomDetailsSub)}
                    >
                      <div className={styles.calories}>
                        <CaloriesResults />
                        {rowID === result.id ? (
                          <div
                            className={clsx(
                              styles.numbersRowInput,
                              !isValid.calories &&
                                showError &&
                                styles.errorInput
                            )}
                          >
                            <NumberFormat
                              className={styles.input}
                              id="calories"
                              name="calories"
                              readOnly={rowID !== result.id}
                              prefix=""
                              allowNegative={false}
                              allowLeadingZeros={false}
                              allowEmptyFormatting={true}
                              value={formData.calories}
                              onValueChange={(e) =>
                                rowID === result.id &&
                                handleInputChangeEditMode(e, "calories")
                              }
                            />
                            <span className={styles.measure}>kcal</span>
                          </div>
                        ) : (
                          <>
                            <div className={styles.numbersRow}>
                              <span> {result.calories} </span>
                              <span className={styles.measure}>kcal</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          );
        })}
    </div>
  );
};

export default Results;
