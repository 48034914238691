import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const UpdateInvolvement = async (data: any) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "PUT",
      baseURL: `${API_ENDPOINT}/coreservice/api/training-involvements/${data.id}`,
      data: { ...data },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default UpdateInvolvement;
