import { useContext, useState } from "react";
import styles from "./cancelEvent.module.scss";
import { CancelModal, SuccessModal } from "../../../constants/assets";
import { AppContext } from "../../../App";
import { formatTime, formatDateCustom } from "../../../utils/utils";
import CancelEvent from "../../../apis/events/cancelEvent";

const CancelEvents = ({ state, dispatch }: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { id } = state;
  const [showResponse, setShowResponse] = useState(false);

  const handleCancel = async () => {
    const response: any = await CancelEvent({ id });
    if (response?.data?.status === "CANCELLED") {
      setShowResponse(true);
      dispatch({ type: "detailsMode", payload: "Edit" });
      dispatch({ type: "eventStatus", payload: response?.data.status });
    }
  };

  return (
    <>
      <div className={styles.cancelBlock}>
        {!showResponse && (
          <>
            <div className={styles.title}>
              <CancelModal />
              <span>Cancel Event</span>
            </div>
            <div className={styles.cancelInfo}>
              <span>Are you sure you want to cancel the event?</span>
            </div>
            <div className={styles.actions}>
              <button
                type="button"
                className={styles.noBtn}
                onClick={() => appDispatch({ type: "hideModal" })}
              >
                <span>No</span>
              </button>
              <button
                type="button"
                className={styles.yesBtn}
                onClick={handleCancel}
              >
                <span>Yes, Cancel!</span>
              </button>
            </div>
          </>
        )}

        {showResponse && (
          <>
            <div className={styles.title}>
              <SuccessModal />
              <span>Success</span>
            </div>

            <div className={styles.dateTime}>
              <div className={styles.date}>
                {formatDateCustom(new Date(state.startDate))}
              </div>
              <div className={styles.time}>
                {formatTime(new Date(state.startDate.replace("Z", "")))}
              </div>
            </div>
            <div className={styles.cancelInfoResponse}>
              <span>
                The event has been cancelled successfully and all participants
                were informed via email
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CancelEvents;
