import { useContext, useState } from "react";
import DeleteInvolvements from "apis/events/deletelInvolvements";
import styles from "./deleteInvolvement.module.scss";
import { Whistle } from "../../../constants/assets";
import { AppContext } from "../../../App";

const DeleteInvolvement = ({
  state,
  dispatch,
  setInvolvementInfo,
  isEvent,
}: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { id } = state;
  const [showResponse] = useState(false);

  const handleCancel = () => {
    (async function cancel() {
      const response: any = await DeleteInvolvements({ id });
      if (response.status === 204) {
        appDispatch({ type: "hideModal" });
        dispatch({ type: "refreshData", payload: null });
        if (isEvent) dispatch({ type: "refreshData", payload: "involvements" });
        else dispatch({ type: "wipeInvolvementData" });
        if (setInvolvementInfo) setInvolvementInfo(undefined);
      }
    })();
  };

  return (
    <>
      <div className={styles.cancelBlock}>
        {!showResponse && (
          <>
            <div className={styles.title}>
              <Whistle />
              <span>Delete Involvement</span>
            </div>
            <div className={styles.cancelInfo}>
              <span>Are you sure you want to delete the involvement?</span>
            </div>
            <div className={styles.actions}>
              <button
                type="button"
                className={styles.noBtn}
                onClick={() => appDispatch({ type: "hideModal" })}
              >
                <span>No</span>
              </button>
              <button
                type="button"
                className={styles.yesBtn}
                onClick={handleCancel}
              >
                <span>Yes, Delete!</span>
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DeleteInvolvement;
