import clsx from "clsx";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { components } from "react-select";
import { ToastContainer } from "react-toastify";
import { isBefore } from "date-fns";
import { Timer } from "interfaces/interfaces";
import NumberFormat from "react-number-format";
import MenuActions from "components/menuActions/menuActions";
import styles from "./eventDetails.module.scss";
import {
  FormMark,
  Cancel,
  Edit,
  Save,
  ArrowDownDefault,
  SignInLock,
  Schedule,
} from "../../../../../constants/assets";
import EventsCategories from "../../../../../apis/eventsCategories";
import { Select, SelectOption } from "../../../../select/select";
import DispatchContext from "../../../../../context/DispatchContext";
import StateContext from "../../../../../context/StateContext";
import { AppContext } from "../../../../../App";
import {
  formatDefaultDate,
  formatTime24,
  textAreaMaxLength,
  timeProcessor,
} from "../../../../../utils/utils";
import UpdateEvent from "../../../../../apis/events/updateEvent";
import CancelEvents from "../../cancelEvent";
import AgeSlider from "../../../../../common/ageSlider";
import {
  dynamicTimeBrackets,
  timeBrackets,
} from "../../../../../mocks/timeBrackets";
import { CurrentDateCalendar } from "../../../../current-date-calendar/current-date-calendar";
import toastMsg from "../../../../../common/toast";
import DeleteDetails from "../../deleteEvent";

interface IForm {
  id?: number;
  location?: string;
  organizer?: string;
  category?: {
    id: number;
    name: string;
  };
  capacity: number | string;
  ageLowerLimit?: number;
  ageUpperLimit?: number;
  description?: string;
  startDate?: any;
  endDate?: any;
  eventName?: any;
  eventUrl?: string;
}

interface Fields {
  location?: boolean;
  capacity?: boolean;
  eventName?: boolean;
  starttime?: boolean;
}

const EventDetails = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { appDispatch } = useContext<any>(AppContext);
  const { appState } = useContext<any>(AppContext);

  const initialFormState = JSON.parse(JSON.stringify(state.eventDetails));
  const [form, setForm] = useState<IForm>(initialFormState);
  const {
    id,
    eventName,
    location,
    organizer,
    capacity,
    ageLowerLimit,
    ageUpperLimit,
    startDate,
    endDate,
    description,
    eventUrl,
  } = form;

  const [ageLabel, setAgeLabel] = useState<string>();
  const [showAgeSlider, setShowAgeSlider] = useState(false);

  const [eventsCategories, setEventsCategories] = useState([]);
  const [selectEventCategory, setSelectEventCategory] =
    useState<SelectOption>();

  const [selectStartTimeBracket, setSelectStartTimeBracket] =
    useState<SelectOption>();
  const [selectEndTimeBracket, setSelectEndTimeBracket] =
    useState<SelectOption>();

  const [time, setTime] = useState<Array<Timer>>([]);
  const [timeFilter, setTimeFilter] = useState(timeBrackets);
  const [actions, setActions] = useState(["Delete Event", "Cancel Event"]);
  const [eventStatus, setEventStatus] = useState(String);

  const [readOnly, setReadOnly] = useState(true);
  const [showError, setShowError] = useState(false);

  const [isValid, setIsValid] = useState<Fields | any>({});

  const setSelectedEventCategory = (
    option: SetStateAction<SelectOption | undefined>
  ) => {
    setSelectEventCategory(option);
  };

  useEffect(() => {
    setReadOnly(state.readOnlyMode);
  }, [state.readOnlyMode]);

  useEffect(() => {
    switch (eventStatus) {
      case "PENDING":
        return setActions(["Delete Event", "Cancel Event"]);
      case "DONE":
        return setActions([]);
      case "CANCELLED":
        return setActions(["Delete Event"]);
      default:
        return setActions([]);
    }
  }, [eventStatus]);

  const handleDelete = () => {
    const modalComponent = (
      <DeleteDetails state={state.eventDetails} dispatch={dispatch} />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const dropdownOption = (dropDownProps: any) => (
    <components.DropdownIndicator {...dropDownProps}>
      <ArrowDownDefault />
    </components.DropdownIndicator>
  );
  const checkDateIncrement = (endTimeNewBracket: any[] | []) => {
    if (endTimeNewBracket.length === 0)
      return [{ value: "00:00", label: "12:00 AM" }];
    return endTimeNewBracket;
  };
  const processEndTimeBracket = (startTime: string) => {
    const valueIndex =
      time.findIndex((i: { value: string }) => i.value === startTime) + 1;
    const endTimeNewBracket = time.slice(valueIndex);
    const endDateObject = new Date(endDate);
    const validEndTimeBracket = checkDateIncrement(endTimeNewBracket);

    setTimeFilter(validEndTimeBracket);
    if (
      !validEndTimeBracket.some((t) => t.label === selectEndTimeBracket?.label)
    ) {
      setSelectEndTimeBracket(validEndTimeBracket[0]);
      setForm((prev) => ({
        ...prev,
        endDate: `${formatDefaultDate(
          new Date(
            validEndTimeBracket[0].value === "00:00"
              ? endDateObject.setDate(endDateObject.getDate() + 1)
              : endDateObject
          )
        )}T${validEndTimeBracket[0].value}:00.050`,
      }));
    }
  };
  const handleInputChange = (
    e: SetStateAction<SelectOption | Fields | any>
  ) => {
    if (e.name === "startTime" && e.value) {
      processEndTimeBracket(e.value);

      setSelectStartTimeBracket(e);
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(startDate))}T${
          e.value
        }:00.050`,
      }));
      setIsValid((prev: any) => ({
        ...prev,
        startTime: true,
      }));
    } else if (e.name === "endTime" && e.value) {
      setSelectEndTimeBracket(e);
      setForm((prev) => ({
        ...prev,
        endDate: `${formatDefaultDate(new Date(endDate))}T${e.value}:00.050`,
      }));
    } else if (e.name === "startDate" && e.value) {
      const startTime = formatTime24(new Date(startDate));
      const endTime = formatTime24(new Date(endDate));
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(e.value))}T${
          startTime || "00:00"
        }:00.050`,
        endDate: `${formatDefaultDate(new Date(e.value))}T${
          endTime || "00:00"
        }:00.050`,
      }));
      setTime(dynamicTimeBrackets(new Date(e.value), 0, "Edit"));
      setIsValid((prev: any) => ({
        ...prev,
        startTime: true,
      }));
    } else {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      if (isValid[e?.target?.name] !== undefined) {
        setIsValid((prev: any) => ({
          ...prev,
          [e.target.name]: !!e.target.value.length,
        }));
      }
    }
  };

  useEffect(() => {
    (async function events() {
      const response: any = await EventsCategories();
      if (response?.data) {
        const categories = response.data.map(
          (item: { id: any; name: any }) => ({
            value: item.id,
            label: `${item.name}`,
          })
        );
        setEventsCategories(categories);
        setSelectEventCategory({
          value: initialFormState?.category?.id,
          label: initialFormState?.category?.name,
        });
      }
    })();

    setForm({
      ...state.eventDetails,
      startDate: state.eventDetails.startDate.replace("Z", ""),
      endDate: state.eventDetails.endDate.replace("Z", ""),
    });
    setEventStatus(state.eventDetails?.status);
    setShowError(false);
    setIsValid({
      location: true,
      capacity: true,
      eventName: true,
      startTime: true,
    });
    setTime(
      dynamicTimeBrackets(
        state.eventDetails.startDate.replace("Z", ""),
        0,
        "Edit"
      )
    );
  }, [state.eventDetails]);

  useEffect(() => {
    if (time?.length > 0) {
      const { startTimeOption, endTimeOption } = timeProcessor(
        startDate,
        endDate,
        state.detailsMode,
        readOnly
      );

      if (startTimeOption === undefined) {
        setSelectStartTimeBracket(time[0]);
        setSelectEndTimeBracket(endTimeOption);

        processEndTimeBracket(time[0].value);
      } else {
        setSelectStartTimeBracket(startTimeOption);
        setSelectEndTimeBracket(endTimeOption);
      }
    }
  }, [form.startDate]);

  useEffect(() => {
    if (selectStartTimeBracket !== undefined) {
      setForm((prev) => ({
        ...prev,
        startDate: `${formatDefaultDate(new Date(form.startDate))}T${
          selectStartTimeBracket?.value || "00:00"
        }:00.050`,
      }));
    }
  }, [selectStartTimeBracket]);

  const handleAgeChange = ({
    text,
    lower,
    upper,
  }: {
    text: string;
    lower: number;
    upper: number;
  }) => {
    setAgeLabel(text);
    setForm({ ...form, ageLowerLimit: lower, ageUpperLimit: upper });
  };

  const handleEdit = () => {
    if (readOnly) dispatch({ type: "readOnlyMode", payload: false });
    else {
      (async function update() {
        if (capacity === "0") {
          setForm((prev) => ({ ...prev, capacity: "" }));
          setIsValid((prev: any) => ({
            ...prev,
            capacity: false,
          }));
          setShowError(true);
          toastMsg("Required fields must be filled in.");
          return;
        }

        if (Object.values(isValid).includes(false)) {
          setShowError(true);
          if (!isValid.startTime)
            toastMsg("The activity time can't be in the past");
          else toastMsg("Required fields must be filled in.");
          return;
        }

        if (!isBefore(new Date(startDate), new Date(endDate))) {
          setShowError(true);
          toastMsg("Event End Time should be after Start Time");
          return;
        }

        const response: any = await UpdateEvent({
          form: {
            ...form,
            startDate: `${startDate.replace("Z", "")}Z`,
            endDate: `${endDate.replace("Z", "")}Z`,
            category: {
              id: selectEventCategory?.value,
              name: selectEventCategory?.label,
            },
          },
          id,
        });

        if (response?.response?.data?.errorKey === "20123") {
          setShowError(true);
          toastMsg("The event time can't be in the past");
          setIsValid((prev: any) => ({
            ...prev,
            startTime: false,
          }));
        }

        if (response?.data) {
          dispatch({ type: "readOnlyMode", payload: true });
          dispatch({ type: "iniEventsList", payload: true });
        } else if (response.response.data.errorKey === "20130") {
          setShowError(true);
          toastMsg(
            "The capacity couldn't be smaller than current participants"
          );
        }
      })();
    }
  };

  const handleCancel = () => {
    const modalComponent = (
      <CancelEvents state={state.eventDetails} dispatch={dispatch} />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleRevert = () => {
    setForm(initialFormState);
    const initialCategory = initialFormState.category;
    setSelectEventCategory({
      value: initialCategory.id,
      label: initialCategory.name,
    });
    dispatch({ type: "readOnlyMode", payload: true });
  };

  const Actions = () => {
    const Comp = () => (
      <>
        <div
          role="button"
          aria-hidden="true"
          className={clsx(styles.editBtnSmall)}
          onMouseDown={handleEdit}
        >
          {readOnly ? <Edit /> : <Save />}
        </div>

        <button
          type="button"
          className={clsx(styles.editBtn, !readOnly && styles.updateBtn)}
          onMouseDown={handleEdit}
        >
          <span>{readOnly ? "Edit" : "Save"}</span>
        </button>
      </>
    );

    if (
      eventStatus === "PENDING" &&
      appState.accountType === "admin" &&
      state.detailsMode === "Edit"
    ) {
      if (readOnly)
        return (
          <>
            <Comp />
          </>
        );
      return (
        <>
          <div
            role="button"
            aria-hidden="true"
            className={styles.revertBtnSmall}
            onMouseDown={handleRevert}
          >
            <Cancel />
          </div>
          <button
            type="button"
            className={styles.revertBtn}
            onMouseDown={handleRevert}
          >
            <span>Cancel</span>
          </button>
          <Comp />
        </>
      );
    }
    return <></>;
  };
  const handleActionsClick = (e: any) => {
    if (e.value === "Cancel Event") handleCancel();
    else if (e.value === "Delete Event") handleDelete();
  };
  return (
    <div className={styles.upcomingEventsWSContainer}>
      <div className={styles.block}>
        <div className={styles.form}>
          <ToastContainer className={styles.toast} position="bottom-right" />

          <div className={styles.formHeader}>
            <div className={styles.title}>
              {readOnly ? (
                <span>{state.eventDetails.eventName}</span>
              ) : (
                <input
                  required={true}
                  type="text"
                  value={eventName}
                  name="eventName"
                  onChange={handleInputChange}
                  className={clsx(
                    styles.input,
                    !isValid.eventName && showError && styles.errorInput
                  )}
                />
              )}
            </div>
            <div className={styles.actions}>
              {appState.accountType === "admin" && (
                <MenuActions onClick={handleActionsClick} actions={actions} />
              )}

              <Actions />
            </div>
          </div>

          <form>
            <div className={styles.content}>
              <div className={styles.contentBlocks}>
                <div className={styles.marker}>
                  <FormMark />
                  <span>Key Information</span>
                </div>

                <div className={styles.formContent}>
                  <div className={styles.inputsBlock}>
                    <div className={styles.rowFieldsBlock}>
                      <div className={styles.inputBlock}>
                        <label htmlFor="startTime" className={styles.label}>
                          *Age
                        </label>
                        <div
                          className={clsx(
                            styles.inputWrapper,
                            styles.agePosition
                          )}
                        >
                          <input
                            inputMode="none"
                            readOnly={readOnly}
                            type="text"
                            name="age"
                            value={ageLabel}
                            className={styles.input}
                            onFocus={() => !readOnly && setShowAgeSlider(true)}
                          />
                          <AgeSlider
                            lower={ageLowerLimit}
                            upper={ageUpperLimit}
                            onChange={handleAgeChange}
                            isVisible={showAgeSlider}
                            onClickOutside={() => setShowAgeSlider(false)}
                          />
                        </div>
                      </div>
                      <div className={styles.inputBlock}>
                        <label htmlFor="endTime" className={styles.label}>
                          Event URL
                        </label>
                        <div
                          className={clsx(
                            styles.inputWrapper,
                            readOnly
                              ? styles.setPositionColorDisabled
                              : styles.setPositionColorEnabled
                          )}
                        >
                          <input
                            readOnly={readOnly}
                            required={true}
                            type="text"
                            value={eventUrl}
                            name="eventUrl"
                            className={styles.input}
                            onChange={handleInputChange}
                          />
                          <div className={styles.iconWrapper}>
                            <SignInLock />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.rowFieldsBlock}>
                      <div className={styles.inputBlock}>
                        <label htmlFor="catergory" className={styles.label}>
                          *Event Category
                        </label>
                        <div className={styles.inputWrapper}>
                          <Select
                            // TODO: Detect desktop and allow search for it?
                            isSearchable={false}
                            isDisabled={readOnly}
                            className={clsx(
                              styles.selectAthlete,
                              readOnly && styles.hideArrow
                            )}
                            name="category"
                            value={selectEventCategory}
                            options={eventsCategories}
                            components={{ DropdownIndicator: dropdownOption }}
                            onChange={setSelectedEventCategory}
                          />
                        </div>
                      </div>
                      <div className={styles.inputBlock}>
                        <label htmlFor="endTime" className={styles.label}>
                          *Location
                        </label>
                        <div className={styles.inputWrapper}>
                          <input
                            readOnly={readOnly}
                            required={true}
                            type="text"
                            name="location"
                            value={location}
                            className={clsx(
                              styles.input,
                              !isValid.location &&
                                showError &&
                                styles.errorInput
                            )}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.rowFieldsBlock}>
                      <div className={styles.inputBlock}>
                        <label htmlFor="startTime" className={styles.label}>
                          Organizer
                        </label>
                        <div className={styles.inputWrapper}>
                          <input
                            readOnly={readOnly}
                            required={true}
                            type="text"
                            name="organizer"
                            value={organizer}
                            className={styles.input}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className={styles.inputBlock}>
                        <label htmlFor="endTime" className={styles.label}>
                          *Capacity
                        </label>
                        <div className={styles.inputWrapper}>
                          <NumberFormat
                            className={clsx(
                              styles.input,
                              !isValid.capacity &&
                                showError &&
                                styles.errorInput
                            )}
                            id="capacity"
                            name="capacity"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={true}
                            value={capacity}
                            readOnly={readOnly}
                            placeholder="Number"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.description}>
                  <div>Description</div>
                  <div>
                    <textarea
                      readOnly={readOnly}
                      maxLength={textAreaMaxLength}
                      required={true}
                      name="description"
                      value={description}
                      className={clsx(styles.input, readOnly && styles.dimFont)}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.contentBlocks}>
                <div className={styles.marker}>
                  <Schedule />
                  <span>Schedule</span>
                </div>

                <div className={styles.formContent}>
                  <div className={styles.inputsBlock}>
                    <div className={styles.rowFieldsBlock}>
                      <div className={styles.inputBlock}>
                        <label htmlFor="startTime" className={styles.label}>
                          *Start Date
                        </label>
                        <div className={styles.inputWrapper}>
                          <CurrentDateCalendar
                            minDate={state.startingDate}
                            value={formatDefaultDate(new Date(startDate))}
                            onChange={(e) =>
                              handleInputChange({ name: "startDate", value: e })
                            }
                            className={clsx(styles.input, styles.datePicker)}
                            direction="floatLeftTop"
                            type="datePicker"
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className={styles.inputBlock}>
                        <label htmlFor="endTime" className={styles.label}>
                          *Start Time
                        </label>
                        <div
                          className={clsx(
                            styles.inputWrapper,
                            styles.inputWrapperTablet,
                            styles.timeField
                          )}
                        >
                          <Select
                            // TODO: Detect desktop and allow search for it?
                            menuPlacement="top"
                            isSearchable={false}
                            isDisabled={readOnly}
                            className={clsx(
                              styles.selectAthlete,
                              readOnly && styles.hideArrow,
                              !isValid.startTime &&
                                showError &&
                                styles.errorInput
                            )}
                            value={selectStartTimeBracket}
                            name="startTime"
                            options={time}
                            onChange={handleInputChange}
                            components={{ DropdownIndicator: dropdownOption }}
                          />
                        </div>
                      </div>
                      <div className={styles.inputBlock}>
                        <label htmlFor="endTime" className={styles.label}>
                          *End Time
                        </label>
                        <div
                          className={clsx(
                            styles.inputWrapper,
                            styles.inputWrapperTablet,
                            styles.timeField
                          )}
                        >
                          <Select
                            // TODO: Detect desktop and allow search for it?
                            menuPlacement="top"
                            isSearchable={false}
                            isDisabled={readOnly}
                            className={clsx(
                              styles.selectAthlete,
                              readOnly && styles.hideArrow
                            )}
                            value={selectEndTimeBracket}
                            name="endTime"
                            options={timeFilter}
                            onChange={handleInputChange}
                            components={{ DropdownIndicator: dropdownOption }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
