import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const VerifyResetPasswordLink = async (token: string | null) => {
  try {
    return await axios({
      method: "GET",
      baseURL: `${API_ENDPOINT}/authservice/password/verifyResetPasswordLink`,
      params: { token },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default VerifyResetPasswordLink;
