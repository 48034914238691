export const initState = {
  admin: {
    data: [],
    header: ["#", "Coach Name", "Email", "Phone Number", ""],
  },
  athlete: {
    data: [],
    header: [
      "#",
      "Athlete Name",
      "Parent's Name",
      "Email",
      "Phone Number",
      "Date Submitted",
      "",
    ],
  },
  coach: {
    data: [],
    header: [
      "#",
      "Junior Coach Name",
      "Parent's Name",
      "Email",
      "Phone Number",
      "Date Submitted",
      "",
    ],
  },
  active: "admin",
  refreshData: null,
};
const reducer = (
  state: { [key: string]: string },
  action: { type: string; payload: string }
) => {
  switch (action.type) {
    case "setActive":
      return {
        ...state,
        active: action.payload,
      };
    case "refreshData":
      return {
        ...state,
        refreshData: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
