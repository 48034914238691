import React, { useState, forwardRef, useImperativeHandle } from "react";
import clsx from "clsx";

import { ChartSize } from "../constants";

import { InfoNoLegend } from "../../../constants/assets";

import styles from "./no-legend-selected.module.scss";

type Props = {
  type?: "line" | "bar";
  chartSize?: ChartSize;
  positionStatic?: boolean;
};

export type NoLegendSelectedRef = {
  show: () => void;
  hide: () => void;
};

export const NoLegendSelected = forwardRef<NoLegendSelectedRef, Props>(
  (
    { chartSize, type = "line", positionStatic = false },
    noLegendSelectedRef
  ) => {
    const [visible, setVisible] = useState(false);

    const show = () => {
      if (!visible) {
        setVisible(true);
      }
    };

    const hide = () => {
      if (visible) {
        setVisible(false);
      }
    };

    useImperativeHandle(noLegendSelectedRef, () => ({
      show,
      hide,
    }));
    return (
      <div
        className={clsx(
          styles.container,
          chartSize === ChartSize.Small && styles.small,
          type === "bar" && styles.bar,
          positionStatic && styles.static,
          !visible && styles.hide
        )}
      >
        <InfoNoLegend />
        <div className={styles.caption}>
          You’ve just deselected all chart values!
        </div>
        <div className={styles.subTitle}>
          Please, reselect them to see the results.
        </div>
      </div>
    );
  }
);
