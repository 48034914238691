import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const DeleteResult = async (data: { id: number; athleteId: number }) => {
  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "DELETE",
      baseURL: `${API_ENDPOINT}/coreservice/api/training-results/${data.id}?athleteId=${data.athleteId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default DeleteResult;
