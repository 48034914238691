import { useContext } from "react";
import RegisterTrainingCancelCoach from "apis/trainings/registerTrainingCancelCoach";
import styles from "./deleteParticipants.module.scss";
import { ParticipantModal } from "../../../constants/assets";
import { AppContext } from "../../../App";
import DeleteParticipant from "../../../apis/participants/deleteParticipant";

const DeleteParticipants = ({
  state,
  dispatch,
  participantId,
  participantType,
}: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { id } = state;

  const handleDelete = async () => {
    const response: any =
      participantType === "coach"
        ? await RegisterTrainingCancelCoach({
            coachId: participantId,
            trainingId: id,
          })
        : await DeleteParticipant({
            id,
            athleteId: participantId,
          });
    if (response.status === 200) {
      appDispatch({ type: "hideModal" });
      dispatch({ type: "refreshData", payload: "participants" });
    }
  };

  return (
    <>
      <div className={styles.deleteBlock}>
        <>
          <div className={styles.title}>
            <ParticipantModal />
            <span>Delete Participant</span>
          </div>
          <div className={styles.deleteInfo}>
            <span>Are you sure you want to delete this participant?</span>
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={() => appDispatch({ type: "hideModal" })}
            >
              <span>No</span>
            </button>
            <button
              type="button"
              className={styles.yesBtn}
              onClick={handleDelete}
            >
              <span>Yes, Delete!</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default DeleteParticipants;
