import axios from "axios";

import { API_ENDPOINT } from "../../constants/const";

const AddRegister = async (data: any) => {
  if (data.option === "group") delete data.athlete;
  else delete data.group;
  delete data.option;

  const token = localStorage.getItem("token");
  try {
    return await axios({
      method: "POST",
      baseURL: `${API_ENDPOINT}/coreservice/api/event-registrations`,
      data: { ...data },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export default AddRegister;
