/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useContext, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ShowMoreText from "react-show-more-text";
import GetInvolvement from "apis/events/getInvolvement";
import { CalendarEvent, Result, EventSummary } from "../../../calendar/types";
import {
  TiTick,
  X,
  Ellipsis,
  DistanceResult,
  DurationResult,
  SpeedResult,
  CalorieResult,
  Avatar,
  Plus,
  PencilIcon,
  TrashIcon,
} from "../../../../constants/assets";
import { formatTime, minToDuration } from "../../../../utils/utils";
import EmptyBlock from "../../../trainingActivities/components/emptyBlock";
import GetResults from "../../../../apis/events/getResults";
import getEventSummary from "../../../../apis/events/getEventSummary";
import { AppContext } from "../../../../App";
import { Spinner } from "../../../spinner/spinner";
import styles from "./event-info.module.scss";
import AddEditInvolve from "../addEditInvolve";
import { CoachInfo } from "../coachInfo/coach-info";
import DeleteResults from "../deleteResults";
import AddEditResult from "../addEditResult";

type Props = {
  event: CalendarEvent;
};

const minAge = 1;
const maxAge = 99;

export const EventInfo: FC<Props> = ({ event }) => {
  const [result, setResult] = useState<Result>();
  const [registrations, setRegistrations] = useState<EventSummary>();

  const [isLoadingResult, setIsLoadingResult] = useState(true);
  const [isLoadingRegistrations, setIsLoadingRegistrations] = useState(true);
  const [isLoadingInvolvement, setIsLoadingInvolvement] = useState(true);

  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);
  const [involvementInfo, setInvolvementInfo] = useState();
  const [insertInvolve, setInsertInvolve] = useState();
  const [insertResults, setInsertResults] = useState({ event: { id: -1 } });
  const [updateResults, setUpdateResults] = useState({ event: { id: -1 } });

  const [eventID, setEventID] = useState<number>();

  const isLoading =
    event.id !== eventID || // This fixes visible loaded fields text flashing
    isLoadingResult ||
    isLoadingRegistrations ||
    isLoadingInvolvement;

  const { accountType } = appState;
  const getResults = async (id: number) => {
    setIsLoadingResult(true);
    const response: any = await GetResults({ id });
    if (response?.data) {
      setResult(response.data[0]);
    }
    // TODO: Handle errors
    setIsLoadingResult(false);
  };

  useEffect(() => {
    if (insertResults?.event?.id !== -1) {
      const { id } = insertResults.event;
      if (id) getResults(id);
    }
  }, [insertResults]);

  useEffect(() => {
    if (updateResults?.event?.id !== -1) {
      const { id } = updateResults.event;
      if (id) getResults(id);
    }
  }, [updateResults]);

  const getInvolvement = async (id: number) => {
    setIsLoadingInvolvement(true);
    const response: any = await GetInvolvement(id);
    if (response?.data) {
      setInvolvementInfo(response.data[0]);
    }
    // TODO: Handle errors
    setIsLoadingInvolvement(false);
  };

  const getRegistrations = async (id: number) => {
    setIsLoadingRegistrations(true);
    const response: any = await getEventSummary({ id });
    if (response?.data) {
      setRegistrations(response.data);
    }
    // TODO: Handle errors
    setIsLoadingRegistrations(false);
  };

  const formatDuration = (duration = 0) => {
    const [hh, mm] = minToDuration(duration).split(":");
    return `${hh} : ${mm}`;
  };
  const startTime = event
    ? formatTime(new Date(event?.startDate?.toString().replace("Z", "")))
    : "";
  const endTime = event
    ? formatTime(new Date(event?.endDate?.toString().replace("Z", "")))
    : "";

  const handleEditResult = () => {
    const modalComponent = (
      <AddEditResult
        data={result}
        mode="Edit"
        setUpdateResults={setUpdateResults}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleDeleteResult = () => {
    const modalComponent = (
      <DeleteResults data={result} setResult={setResult} />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const isAthlete = accountType === "athlete";
  const isCoach = accountType === "coach";

  const renderResult = () => {
    if (isLoadingResult) {
      return <Spinner />;
    }
    if (result) {
      return (
        <div className={styles.resultContainer}>
          <div className={styles.profileInfo}>
            <Avatar className={styles.avatar} />
            <div className={styles.athleteActions}>
              <div
                role="button"
                aria-hidden="true"
                className={styles.edit}
                onClick={handleEditResult}
              >
                <PencilIcon />
              </div>
              <div
                role="button"
                aria-hidden="true"
                className={styles.delete}
                onClick={() => handleDeleteResult()}
              >
                <TrashIcon />
              </div>
            </div>
            <span className={styles.profileName}>
              {`${appState.firstName} ${appState.lastName}`}
            </span>
          </div>
          <div className={styles.divider} />
          <div className={styles.resultRow}>
            <DistanceResult />
            <span className={styles.resultTitle}>Distance</span>
            <span className={styles.resultValue}>
              {result?.distance ? `${result?.distance} km` : "-"}
            </span>
          </div>
          <div className={styles.resultRow}>
            <SpeedResult />
            <span className={styles.resultTitle}>Speed</span>
            <span className={styles.resultValue}>
              {result?.speed ? `${result?.speed} km/h` : "-"}
            </span>
          </div>
          <div className={styles.resultRow}>
            <DurationResult />
            <span className={styles.resultTitle}>Duration</span>
            <span className={styles.resultValue}>
              {result?.duration ? `${formatDuration(result?.duration)}` : "-"}
            </span>
          </div>
          <div className={styles.resultRow}>
            <CalorieResult />
            <span className={styles.resultTitle}>Calories</span>
            <span className={styles.resultValue}>
              {result?.calories ? `${result?.calories} kCal` : "-"}
            </span>
          </div>
        </div>
      );
    }
    return (
      <EmptyBlock
        type="resultComingSoon"
        text="Event results are coming soon."
      />
    );
  };

  const renderAthleteStatus = () => {
    return (
      <>
        <span className={styles.category}>Status</span>
        <div className={styles.cancelRegistered}>
          <X />
          <span> Canceled</span>
        </div>
      </>
    );
  };

  const getAgeText = (ageLowerLimit: number, ageUpperLimit: number) => {
    let text = "";
    if (ageLowerLimit === minAge && ageUpperLimit === maxAge) text = "All ages";
    else if (
      ageLowerLimit === minAge &&
      ageUpperLimit !== maxAge &&
      ageUpperLimit !== minAge
    )
      text = `Under ${ageUpperLimit} yrs`;
    else if (
      ageLowerLimit !== minAge &&
      ageUpperLimit === maxAge &&
      ageLowerLimit !== maxAge
    )
      text = `Above ${ageLowerLimit} yrs`;
    else if (ageLowerLimit === ageUpperLimit) text = `${ageLowerLimit} yrs`;
    else if (ageLowerLimit !== minAge && ageUpperLimit !== maxAge)
      text = `${ageLowerLimit} yrs - ${ageUpperLimit} yrs`;
    return text;
  };

  useEffect(() => {
    getResults(event.id);
    getRegistrations(event.id);
    getInvolvement(event.id);
    setEventID(event.id);
  }, [event.id]);

  useEffect(() => {
    if (insertInvolve !== "") {
      setInvolvementInfo(insertInvolve);
    }
  }, [insertInvolve]);

  const handleInvolve = () => {
    const modalComponent = (
      <AddEditInvolve
        data={event}
        mode="Add"
        setInsertInvolve={setInsertInvolve}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleResults = () => {
    const modalComponent = (
      <AddEditResult
        data={event}
        mode="Add"
        setInsertResults={setInsertResults}
      />
    );
    appDispatch({ type: "showModal", payload: modalComponent });
  };

  const handleExpanded = () => {
    setEventID(event?.id);
  };

  const renderEventDetails = () => (
    <>
      <span className={styles.category}>Category</span>
      <span className={styles.details}>{event.category.name}</span>
      <span className={styles.category}>Age</span>
      <span className={styles.details}>
        {getAgeText(event.ageLowerLimit, event.ageUpperLimit)}
      </span>
      <span className={styles.category}>Location</span>
      <span className={styles.details}>{event.location}</span>
      <span className={styles.category}>Organizer</span>
      <span className={styles.details}>
        {event.organizer?.length > 0 ? event.organizer : "-"}
      </span>
      <span className={styles.category}>Capacity</span>
      <span className={styles.details}>{event.capacity}</span>
      {!isAthlete && (
        <>
          <span className={styles.category}>Registrations</span>
          <span className={styles.details}>
            {registrations?.registrationsCount}
          </span>
        </>
      )}
      {event?.eventUrl?.length > 0 && (
        <>
          <span className={styles.category}>Event URL</span>
          <div className={styles.details}>
            <div className={styles.url}>{event.eventUrl}</div>
          </div>
        </>
      )}
      {event?.description?.length > 0 && (
        <>
          <span className={styles.category}>Description</span>
          {eventID === event.id && (
            <ShowMoreText
              lines={2}
              more="Show more"
              less="Show less"
              className={styles.details}
              anchorClass={styles.showMore}
              onClick={handleExpanded}
              expanded={false}
              width={0}
              truncatedEndingComponent={" "}
            >
              {event.description}
            </ShowMoreText>
          )}
        </>
      )}
      {!isAthlete && (
        <>
          <span className={styles.statusHeading}>Status</span>
          {event.status === "CANCELLED" && (
            <div className={styles.cancelRegistered}>
              <X />
              <span> Canceled</span>
            </div>
          )}
          {event.status === "PENDING" && (
            <div className={styles.pending}>
              <Ellipsis />
              <span> Pending</span>
            </div>
          )}
          {event.status === "DONE" && (
            <div className={styles.done}>
              <TiTick />
              <span> Done</span>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{event.eventName}</h3>
      {(isAthlete || isCoach) && (
        <div className={styles.timeBlock}>
          <div className={styles.time}>
            <span className={styles.timeLabel}>Start Time</span>
            <span className={styles.timeValue}>{startTime}</span>
          </div>
          <div className={styles.divider} />
          <div className={styles.time}>
            <span className={styles.timeLabel}>End Time</span>
            <span className={styles.timeValue}>{endTime}</span>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className={styles.loader}>
          <Spinner />
        </div>
      ) : (
        renderEventDetails()
      )}

      {isAthlete && event.status === "CANCELLED" && renderAthleteStatus()}

      {isCoach && event.myEvent && event.status === "DONE" && (
        <>
          {!involvementInfo ? (
            <button
              className={styles.involveBTN}
              type="button"
              onClick={handleInvolve}
            >
              Add Involvement <Plus />
            </button>
          ) : (
            <CoachInfo
              event={involvementInfo}
              setInvolvementInfo={setInvolvementInfo}
            />
          )}
        </>
      )}

      {isAthlete && event.myEvent && event.status === "DONE" && (
        <>
          {!result ? (
            <button
              className={styles.involveBTN}
              type="button"
              onClick={handleResults}
            >
              Add Results <Plus />
            </button>
          ) : (
            renderResult()
          )}
        </>
      )}
    </div>
  );
};
