import { useCallback, useContext, useState } from "react";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import NumberFormat from "react-number-format";
import AddInvolvement from "apis/events/addInvolvement";
import { totalDurationToMin, totalMinToDuration } from "utils/utils";
import UpdateInvolvement from "apis/events/updateInvolvement";
import styles from "./addEditInvolve.module.scss";
import { AppContext } from "../../../App";
import { Whistle } from "../../../constants/assets";

interface IForm {
  hours: string;
  minutes: string;
}

const AddEditInvolve = ({
  data,
  mode,
  setUpdateInvolve,
  setInsertInvolve,
}: any) => {
  const { appDispatch } = useContext<any>(AppContext);
  const { appState } = useContext<any>(AppContext);
  const [error, setError] = useState(false);

  const { hours, minutes } = totalMinToDuration(data.duration);

  const [form, setForm] = useState<IForm>({ ...data, hours, minutes });

  const [isValid, setIsValid] = useState({
    hours: mode === "Edit" || false,
    minutes: mode === "Edit" || false,
  });

  const handleSave = () => {
    if (Object.values(isValid).includes(false)) {
      setError(true);
      return;
    }

    (async function update() {
      if (mode === "Edit") {
        const response: any = await UpdateInvolvement({
          id: data.id,
          duration: totalDurationToMin(form.hours, form.minutes),
          coach: {
            id: appState.id,
          },
          event: {
            id: data.event.id,
          },
        });
        if (response.status === 200) {
          appDispatch({ type: "hideModal" });
          setUpdateInvolve(response.data);
        } else if (response.response.data.errorKey === "20129") {
          setError(true);
        }
      } else {
        const response: any = await AddInvolvement({
          duration: totalDurationToMin(form.hours, form.minutes),
          coach: {
            id: appState.id,
          },
          event: {
            id: data.id,
          },
        });
        if (response?.data) {
          appDispatch({ type: "hideModal" });
          setInsertInvolve(response.data);
        } else if (response.response.data.errorKey === "20129") {
          setError(true);
        }
      }
    })();
  };

  const handleCloseModal = useCallback(() => {
    appDispatch({ type: "hideModal" });
  }, []);

  const handleInputChange = (e: any) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    setIsValid((prev) => ({
      ...prev,
      [e.target.name]: !!e.target.value.length,
    }));
  };

  return (
    <>
      <div className={styles.addEditBlock}>
        <>
          <div className={styles.title}>
            <Whistle />
            <span>{mode} Your Involvement</span>
          </div>
          <div className={styles.inputBlocks}>
            <form>
              <label className={styles.modalTitle}> Time Spent </label>
              <div className={styles.athleteRow}>
                <div className={clsx(styles.inputBlock, styles.subInput)}>
                  <div
                    className={clsx(
                      styles.numbersRowInput,
                      !isValid.hours && error && styles.errorInput
                    )}
                  >
                    <NumberFormat
                      className={styles.input}
                      id="hours"
                      name="hours"
                      maxLength={2}
                      placeholder="00"
                      value={form.hours}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      onChange={handleInputChange}
                    />
                    <span className={styles.measure}>Hours</span>
                  </div>
                </div>

                <div className={clsx(styles.inputBlock, styles.subInput)}>
                  <div
                    className={clsx(
                      styles.numbersRowInput,
                      styles.minutesBlock,
                      !isValid.minutes && error && styles.errorInput
                    )}
                  >
                    <NumberFormat
                      className={styles.input}
                      id="minutes"
                      name="minutes"
                      allowNegative={false}
                      placeholder="00"
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      maxLength={2}
                      value={form.minutes}
                      onChange={handleInputChange}
                    />

                    <span className={styles.measure}>Minutes</span>
                  </div>
                </div>
              </div>
            </form>
            <ToastContainer className={styles.toast} position="bottom-right" />
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={handleCloseModal}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className={clsx(
                styles.yesBtn,
                Object.values(isValid).includes(false) && styles.buttonDisabled
              )}
              onClick={handleSave}
            >
              <span>Save</span>
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default AddEditInvolve;
