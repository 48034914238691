/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-unused-vars */
export type ChartColor = string | [string, string];

export enum GradientDirection {
  LeftToRight,
  RightToLeft,
  TopToBottom,
  BottomToTop,
}

const directionsMap = {
  [GradientDirection.LeftToRight]: [1, 0, 0, 0],
  [GradientDirection.RightToLeft]: [0, 0, 1, 0],
  [GradientDirection.BottomToTop]: [0, 1, 0, 0],
  [GradientDirection.TopToBottom]: [0, 0, 0, 1],
};

const hexRange = (val: number) => Math.min(255, Math.max(0, val));

export const createExpMap =
  (k: number, from = 0, to = 100) =>
  (num: number) => {
    const a = 100 / (Math.pow(to, k) - Math.pow(from, k));
    const b = -a * Math.pow(from, k);

    return a * Math.pow(num, k) + b;
  };

export const lightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  const r = hexRange((num >> 16) + amt);
  const b = hexRange(((num >> 8) & 0x00ff) + amt);
  const g = hexRange((num & 0x0000ff) + amt);

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export const createColorGetter =
  (k: number, minValue = 0, maxValue = 100) =>
  (baseColour: string, value: number) => {
    const expMap = createExpMap(k, minValue, maxValue);
    const num = Math.floor(expMap(value));

    return `${lightenDarkenColor(baseColour, num)}`;
  };
