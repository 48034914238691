import React, { Suspense } from "react";
import { ECharts } from "echarts";
import { Loading } from "../../Loading";

export type ReactEchartsInstance = {
  getEchartsInstance(): ECharts;
};

const LazyReactEcharts = React.lazy(() => import("echarts-for-react"));

export const ReactEcharts = (props: any) => (
  <Suspense fallback={<Loading />}>
    <LazyReactEcharts {...props} />
  </Suspense>
);

export default ReactEcharts;
