import clsx from "clsx";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { useEffect, useState, useRef } from "react";
import "./ageSlider.css";
import { useClickOutside } from "../hooks/use-click-outside";

const min = 1;
const max = 99;

const AgeSlider = ({
  lower = min,
  upper = max,
  onChange,
  isVisible,
  onClickOutside,
}: any) => {
  const ageRef = useRef(null);

  const [marks, setMarks] = useState({});
  const [value, setValue] = useState<number[]>([]);

  useClickOutside(ageRef, (event: any) => {
    if (event?.target?.name === "age") {
      return;
    }
    onClickOutside(event);
  });

  const onSliderAfterChange = ([low, high]: number[]) => {
    if (low === min && high === max)
      onChange({
        text: "All ages",
        lower: low,
        upper: high,
      });
    else if (low === min && high !== max && high !== min)
      onChange({
        text: `Under ${high} yrs`,
        lower: low,
        upper: high,
      });
    else if (low !== min && high === max && low !== max)
      onChange({
        text: `Above ${low} yrs`,
        lower: low,
        upper: high,
      });
    else if (low === high)
      onChange({
        text: `${low} yrs`,
        lower: low,
        upper: high,
      });
    else if (low !== min && high !== max)
      onChange({
        text: `${low} yrs - ${high} yrs`,
        lower: low,
        upper: high,
      });
  };

  useEffect(() => {
    setMarks({
      [min]: min,
      [max]: max,
      [lower]: lower,
      [upper]: upper,
    });
    setValue([lower, upper]);
    onSliderAfterChange([lower, upper]);
  }, [lower, upper]);

  const onSliderChange = ([low, high]: number[]) => {
    setMarks({
      [min]: min,
      [max]: max,
      [low]: low,
      [high]: high,
    });
    setValue([low, high]);
  };

  return (
    <div
      // TODO: Visibility should be controlled outside the component
      className={clsx("sliderBlock", !isVisible && "hideRange")}
      ref={ageRef}
    >
      <Range
        min={min}
        max={max}
        onChange={onSliderChange}
        onAfterChange={onSliderAfterChange}
        marks={marks}
        value={value}
      />
    </div>
  );
};

export default AgeSlider;
