/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { FC, useContext } from "react";
import styles from "./menu.module.scss";
import DataGrid from "../users/dataGrid/dataGrid";
import GroupsGrid from "../users/groupsGrid/groupsGrid";
import { Plus } from "../../../../constants/assets";
import DispatchContext from "../../../../context/DispatchContext";
import StateContext from "../../../../context/StateContext";
import AddEditAdmin from "../addEditAdmin/addEditAdmin";
import { AppContext } from "../../../../App";
import AddEditAthlete from "../addEditAthlete/addEditAthlete";
import AddEditGroup from "../addEditGroup/addEditGroup";
import AddEditCoach from "../addEditCoach/addEditCoach";

const Menu: FC = () => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { appDispatch } = useContext<any>(AppContext);

  const handleClick = (e: { currentTarget: { id: string } }) => {
    dispatch({ type: "setActive", payload: e.currentTarget.id });
  };

  const setAddMode = () => {
    if (state.active === "admin")
      appDispatch({
        type: "showModal",
        payload: <AddEditAdmin mode="Add" data={state} dispatch={dispatch} />,
      });
    else if (state.active === "athlete")
      appDispatch({
        type: "showModal",
        payload: <AddEditAthlete mode="Add" data={state} dispatch={dispatch} />,
      });
    else if (state.active === "coach")
      appDispatch({
        type: "showModal",
        payload: <AddEditCoach mode="Add" data={state} dispatch={dispatch} />,
      });
    else if (state.active === "group")
      appDispatch({
        type: "showModal",
        payload: <AddEditGroup mode="Add" data={state} dispatch={dispatch} />,
      });
  };

  const userLabel = () => {
    if (state.active === "group") return "group";
    if (state.active === "coach") return `junior ${state.active} `;
    return `${state.active} user`;
  };
  return (
    <div className={styles.menuContainer}>
      <div className={clsx(styles.block)}>
        <div className={styles.layoutHeader}>
          <ul className={styles.ul}>
            <li>
              <div
                role="button"
                aria-hidden="true"
                id="admin"
                className={clsx(state.active === "admin" && styles.active)}
                onClick={handleClick}
              >
                <span>Admin</span>
              </div>
            </li>
            <li>
              <div
                role="button"
                aria-hidden="true"
                id="athlete"
                className={clsx(state.active === "athlete" && styles.active)}
                onClick={handleClick}
              >
                <span> Athlete </span>
              </div>
            </li>
            <li>
              <div
                role="button"
                aria-hidden="true"
                id="coach"
                className={clsx(state.active === "coach" && styles.active)}
                onClick={handleClick}
              >
                <span> Junior Coach </span>
              </div>
            </li>
            <li>
              <div
                role="button"
                aria-hidden="true"
                id="group"
                className={clsx(state.active === "group" && styles.active)}
                onClick={handleClick}
              >
                <span> Group </span>
              </div>
            </li>
          </ul>

          <div className={clsx(styles.actions)}>
            <button
              type="button"
              className={styles.addBtn}
              onClick={() => setAddMode()}
            >
              <Plus />
              <span>Add new {userLabel()} </span>
            </button>
          </div>
        </div>
        <div className={styles.component}>
          {state.active === "group" ? <GroupsGrid /> : <DataGrid />}
        </div>
      </div>
    </div>
  );
};

export default Menu;
