/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import styles from "./accounts.module.scss";
import { AppContext } from "../../App";
import { Admin, Athlete, WhistleAccount } from "../../constants/assets";

const Accounts: FC<any> = ({ history }) => {
  const [accountType, setAccountType] = useState<string | null>(null);
  const [moduleType, setModuleType] = useState<string>("login");
  const [disabled, setDisabled] = useState(true);
  const { appState } = useContext<any>(AppContext);
  const { appDispatch } = useContext<any>(AppContext);
  useEffect(() => {
    appDispatch({ type: "accountType", payload: { accountType: null } });
  }, []);

  const handleClick = (e: any) => {
    appDispatch({
      type: "accountType",
      payload: { accountType: e.target.id },
    });
    localStorage.setItem("accountType", e.target.id);

    setAccountType(e.target.id);
    setDisabled(false);
  };
  const swapModule = () => {
    if (moduleType === "login") setModuleType("register");
    else setModuleType("login");
    setDisabled(true);
  };
  const handleAccount = () => {
    if (appState.accountType) history.push(`/home/${moduleType}`);
    else swapModule();
  };

  const handleAccountModule = () => {
    if (appState.accountType)
      history.push(`/home/${moduleType === "login" ? "register" : "login"}`);
    else swapModule();
  };

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div>
          <div className={styles.title}>
            <span>{moduleType === "login" ? "Sign In" : "Welcome to"}</span>
            <span> Heroes of Hope! </span>
          </div>

          <div className={styles.subTitle}>
            <span>
              Choose Account Type to{" "}
              {moduleType === "login" ? "Sign In" : "Register"}
            </span>
          </div>
          <div className={styles.accountTypeBlock}>
            <button
              type="button"
              id="admin"
              className={clsx(
                styles.accountTypeButton,
                accountType === "admin" && styles.accountTypeButtonActive
              )}
              onClick={handleClick}
            >
              <Admin />
              <span>Admin</span>
            </button>
            <button
              type="button"
              id="athlete"
              className={clsx(
                styles.accountTypeButton,
                accountType === "athlete" && styles.accountTypeButtonActive
              )}
              onClick={handleClick}
            >
              <Athlete />
              <span>Athlete</span>
            </button>
            <button
              type="button"
              id="coach"
              className={clsx(
                styles.accountTypeButton,
                accountType === "coach" && styles.accountTypeButtonActive
              )}
              onClick={handleClick}
            >
              <WhistleAccount />
              <span>Junior Coach</span>
            </button>
          </div>
        </div>

        <div>
          <button
            type="button"
            disabled={disabled}
            className={
              !appState.accountType
                ? styles.signinRegisterButton
                : styles.signinRegisterButtonActive
            }
            onClick={handleAccount}
          >
            <span>{moduleType === "login" ? "Sign In" : "Register"}</span>
          </button>
          <div className={styles.actions}>
            <span>
              {" "}
              {moduleType === "login"
                ? "You don’t have an account yet?"
                : "Already registered?"}{" "}
            </span>
            <span
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={handleAccountModule}
              id={moduleType}
            >
              {moduleType === "login" ? "Register" : "Sign In"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Accounts);
